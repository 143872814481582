import React from 'react'
import { Row, Col, Collapse, ButtonGroup } from 'reactstrap'
import { ComboBox } from '@progress/kendo-react-all'
import { accentUtils, t, showOK, showDialog, from } from '../../services/HelperService'
import { update, queryNoTracking, useQueryAllNoTracking, useQueryFirstOrDefaultNoTracking } from '../../services/DataService'
import { AccentButton } from '../AccentButton'
import { InsyteQueryCtrl } from './InsyteQueryCtrl'
import { getUser } from '../../services/UserService'
import { AccentSpinner } from '../AccentSpinner'
import { RTLFilterExportDlg, RTLFilterImportDlg } from './RTLFilterExportDlg'
import { GridFilterView } from './../advancedfilter/GridFilterView'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { getAdhocFilter, getAdvancedFilter, getQuickFilters } from '../grids/GridDataManager'
import { advancedFilterCache } from './AdvancedFilterConstants'


export const filterSchemaNames = {
    job: "Job",
    opportunity: "Opportunity",
    supplyJob: "PurchaseOrder"
};




const ComboItemRender = (props) => {


    const [showDelete, setShowDelete] = React.useState(false);

    const itemProps = props.itemProps;
    const li = props.li;

    const index = itemProps.index;
    const value = itemProps.dataItem.ID;
    const desc = itemProps.dataItem[props.textField];

    const deleteClicked = React.useCallback((e, v) => {
        e.preventDefault();
        e.stopPropagation();

        if (showDelete) {
            props.onDelete(v);
        }


        setShowDelete(!showDelete);
        return false;
    }, [props, showDelete, setShowDelete]);


    const allowDelete = accentUtils.isNull(props.allowDelete) ? true : props.allowDelete(itemProps.dataItem);

    const del = showDelete ? <div className="p-2 bg-danger" style={{ color: "white" }} onClick={e => deleteClicked(e, value)}>DEL</div>
        : <div className="p-2" onClick={e => deleteClicked(e, value)}>X</div>;

    const itemChildren = <div className="d-flex" style={{ width: "100%" }}>
        <div className="p-2 flex-fill">{desc}</div>
        {allowDelete && del}
    </div>;


    return React.cloneElement(li, li.props, itemChildren);
}



export const RTLGridFilterCtrl = React.memo(props => {


    const [filterQueryArgs,] = React.useState({ entityType: (accentUtils.isNull(props.filterSchemaName) ? null : props.filterSchemaName) });
    const [schemaQueryArgs,] = React.useState({ name: (accentUtils.isNull(props.filterSchemaName) ? null : props.filterSchemaName) });


    const setQuickFilter = useSetRecoilState(getQuickFilters(props.gridID));
    const [advancedFilter, setAdvancedFilter] = useRecoilState(getAdvancedFilter(props.gridID));
    const [adhocFilter, setAdhocFilter] = useRecoilState(getAdhocFilter(props.gridID));

    const [filters, setFilters] = React.useState(null);
    const filterRefreshKey = React.useRef(0);




    const schema = useQueryFirstOrDefaultNoTracking("AdvancedQueries_GetSchema", schemaQueryArgs);


    const onFilterChange = React.useCallback((filter) => {
        

        var isEmpty = filter.filter.length == 0;
        var isValid = !JSON.stringify(filter).includes("select_field");
        var isSavedFilter = false;// !accentUtils.isNull(currentFilter) && currentFilter.ID > 0;

        if (isEmpty) {

            setAdhocFilter(null);
            setAdvancedFilter(null);

        } else {
            if (isValid) {

                if (isSavedFilter) {

                    //setAdvancedFilter({ ID: currentFilter.ID, Name: filter.Name });
                    //setAdhocFilter(filter);
                    
                } else {

                    setAdhocFilter(JSON.parse(JSON.stringify(filter)));

                }
            }
        }

    }, [props, setAdvancedFilter, setAdhocFilter]);

    const onSelectedFilterChange = React.useCallback((e) => {

        filterRefreshKey.current++;

        if (accentUtils.isNull(e.value)) {
            setAdhocFilter(null);
            setAdvancedFilter(null);
            
        } else {
            setAdhocFilter(JSON.parse(e.value.Filter).filter);
            setAdvancedFilter({ ID: e.value.ID, Name: e.value.Name });
        }


    }, [filterRefreshKey]);



    

    const onEditFilter = React.useCallback((e) => {

        if (!accentUtils.isNull(advancedFilter)) {
            
            showDialog(<GridFilterView filter={adhocFilter} filterID={advancedFilter.ID} filterSchemaName={props.filterSchemaName} />).then(s => {
                if (!s.canceled) {

                    setAdvancedFilter({ ID: s.newID, Name: s.newName });

                    filterRefreshKey.current++;
                    setFilters(null);
                }

                if (e.promise) e.promise();
            });
        }
    }, [props, filterRefreshKey, advancedFilter, adhocFilter])

    const onNewFilter = React.useCallback((e) => {
        showDialog(<GridFilterView filter={adhocFilter} filterSchemaName={props.filterSchemaName} />)
            .then(s => {

            if (!s.canceled) {

                filterRefreshKey.current++;
                setFilters(null);

                setAdvancedFilter({ ID: s.newID, Name: s.newName });

            }

            if (e.promise) e.promise();

        });
    }, [props, filterRefreshKey, adhocFilter])

    const onSaveFilter = React.useCallback((e) => {

        

        if (accentUtils.isNull(advancedFilter) || advancedFilter.ID <= 0) {
            onNewFilter(e);
            return;

        }
        else {
            update('UpdateGridFilter', {
                ID: advancedFilter.ID,
                Filter: { filter: adhocFilter },
                EntityType: accentUtils.isNull(props.filterSchemaName) ? null : props.filterSchemaName
            }).then(r => {

                setFilters(null);

                e.promise();
            })
        }


    }, [props, adhocFilter, advancedFilter, setFilters,  onNewFilter])

    const allowDeleteFilter = React.useCallback((e) => {
        
        const user = getUser();

        return e.OwnerID == user.ID;        

    }, [props, filters])


    const onDeleteFilter = React.useCallback((e) => {

        var filterToDelete = from(filters).firstOrDefault(t => t.ID == e);

        if (!accentUtils.isNull(filterToDelete)) {


            update('DeleteGridFilter',{ ID: filterToDelete.ID }).then(r => {
                filterRefreshKey.current++;                

                advancedFilterCache.clear();

                setFilters(null);


                if (filterToDelete.ID === advancedFilter?.ID) {
                    setAdvancedFilter(null);
                }

                setQuickFilter(q => accentUtils.isNull(q) ? [] : q.filter(x => x.ID !== filterToDelete.ID));
                

            });


            
        }


    }, [props, filters, setFilters, filterRefreshKey, setAdvancedFilter, advancedFilter, setQuickFilter])


    const onImportFilter = React.useCallback(e => {

        showDialog(<RTLFilterImportDlg />).then(ca => {
            if (!ca.canceled) {
                advancedFilterCache.clear();
                filterRefreshKey.current++;
                setFilters(null);
            }

            e.promise();

        });
        

        

    }, [filters, filterRefreshKey, setFilters]);

    const onExportFilter = React.useCallback(e => {

        const f = filters;

        var selection = [];

        showOK("application_strings.views.filterView.exportFilterTitle", <RTLFilterExportDlg filters={f} onSelectionChange={v => selection = v} />, ["application_strings.application.buttons.ok"]).then(function (res) {

            if (res == "application_strings.application.buttons.ok") {

                RTLFilterExportDlg.ExportFilters(selection);

            }


            if (e.promise) {
                e.promise();
            }

        });



    }, [filters]);
    

    React.useEffect(() => {

        if (accentUtils.isNull(filters) && !schema.loading) {

            advancedFilterCache.getFilters(filterQueryArgs.entityType).then(s => {
                filterRefreshKey.current++;
                setFilters(s);
            });
        }


    }, [filters, setFilters, props, schema.loading, filterQueryArgs]);





    if (schema.loading) {
        if (props.show)
            return <AccentSpinner />;
        else
            return null;
    }

    const filterList = filters == null ? [] : filters;

    const currentFilter = accentUtils.isNull(adhocFilter)
        ? (accentUtils.isNull(advancedFilter) ? null : from(filters).where(f => f.ID === advancedFilter.ID).select(s => JSON.parse(s.Filter)?.filter).firstOrDefault())
        : JSON.parse(JSON.stringify(adhocFilter));


    return <Collapse isOpen={props.show} className="acc-grid-filter-ctr">
        <Row>
            <Col md={1}>
                <label>{t("application_strings.views.filterView.selectFilter")}</label>
            </Col>
            <Col md={11}>
                <ComboBox
                    style={{maxWidth:"300px" }}
                    disabled={ props.readOnly}
                    key={filterRefreshKey.current}
                    data={filterList}
                    value={advancedFilter}
                    textField="Name"
                    dataItemKey="ID"
                    itemRender={(l, p) => <ComboItemRender li={l} itemProps={p} onDelete={onDeleteFilter} textField="Name" allowDelete={allowDeleteFilter} />}
                    onChange={onSelectedFilterChange}
                />
                <ButtonGroup className="acc-fitler-btn" >
                    <AccentButton disabled={props.readOnly} tagName="editFilter" onClick={onEditFilter}>{t("application_strings.views.filterView.editFilter")}</AccentButton>
                    <AccentButton disabled={props.readOnly} tagName="saveFilter" onClick={onSaveFilter}>{t("application_strings.views.filterView.saveFilter")}</AccentButton>
                    <AccentButton disabled={props.readOnly} tagName="newFilter" onClick={onNewFilter}>{t("application_strings.views.filterView.newFilter")}</AccentButton>
                    <AccentButton disabled={props.readOnly} tagName="importFilter" onClick={onImportFilter}>{t("application_strings.views.filterView.importFilter")}</AccentButton>
                    <AccentButton disabled={props.readOnly} tagName="exportFilter" onClick={onExportFilter}>{t("application_strings.views.filterView.exportFilter")}</AccentButton>
                </ButtonGroup>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <InsyteQueryCtrl key={filterRefreshKey.current} readOnly={props.readOnly} value={currentFilter} schema={schema.data} onChange={onFilterChange} fieldStringBase="application_strings.views.filterView.schemas" />
            </Col>
        </Row>
    </Collapse>;
    

});