import React from 'react'
import ReactDOM from 'react-dom'
import { $} from 'jquery'
import { Input } from '@progress/kendo-react-all'
import { accentUtils, t, formHelper } from '../../services/HelperService'
import AccentIcons from '../../icons/Icons'

export class AccentPassword extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        this.hideText = true;

        this.toggleHide = this.toggleHide.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.getControlValue = this.getControlValue.bind(this);
    }

    handleFocus(e) {
        var me = this;

        $(me.ctrl.current.element).select();

        //setTimeout(function () {
        //    $(e.target).select();
        //});
    }

    handleBlur(e) {



    }

    handleChange() {

        var value = this.ctrl.current.value;
        this.props.model.setValue(value);

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange();
        }

    }

    focus() {
        alert("$(ReactDOM.findDOMNode(this.ctrl.current)).trigger('focus')");
    }


    getControlValue() {
        return this.ctrl.current.value;
    }


    toggleHide() {
        this.hideText = !this.hideText;

        this.forceUpdate();

    }

    render() {

        var disabled = formHelper.isControlReadOnly(this.props.model);

        var className = "acc-pass-hide";

        return (
            <div className="acc-pass">
                <Input type={this.hideText ? "password" : "text"} ref={this.ctrl} disabled={disabled} label={this.props.model.displayLabel} onBlur={this.handleBlur} onFocus={this.handleFocus} onChange={this.handleChange} defaultValue={this.props.model.getValue()} />
                <AccentIcons.Eye onClick={this.toggleHide} />
            </div>
        );
    }
}
