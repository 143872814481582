import * as React from 'react'
import { UncontrolledButtonDropdown, DropdownToggle, Button } from 'reactstrap'
import { accentUtils, t} from '../services/HelperService'

export class AccentDropdownButton extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        this.handleOnSelect = this.handleOnSelect.bind(this);
    }




    handleOnSelect(e) {
        this.props.onSelect(e);
    }

    render() {

        const direction = accentUtils.isNull(this.props.direction) ? 'down' : this.props.direction;

        return (
            <UncontrolledButtonDropdown disabled={this.props.disabled} className={this.props.className} ref={this.ctrl} direction={direction} onSelect={this.handleOnSelect} >                
                <DropdownToggle caret color={this.props.bsStyle ?? "primary" }>
                    {t(this.props.title)}
                </DropdownToggle>
                {this.props.children}
            </UncontrolledButtonDropdown>
        );
    }
}
