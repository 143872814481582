import { GridColumn } from '@progress/kendo-react-grid';
import PQueue from 'p-queue'
import { debounce } from 'perfect-debounce';
import * as React from 'react';
import { atom, useRecoilValue } from "recoil";
import { queryNoTracking, update } from '../../services/DataService';
import { accentUtils, enqueue, from, memoize, t } from '../../services/HelperService';
import { RTLQueryFetcher } from '../RTLFetch';



const query_Grid_Settings = queryNoTracking("Grid_Settings");


export const getLastPage = memoize(gridID => atom({
    key: `gridLastPage_${gridID}`,
    default: null,
}));


export const getAdhocFilter = memoize(gridID => atom({
    key: `gridAdhocFilter_${gridID}`,
    default: null,
}));


export const getAdvancedFilter = memoize(gridID => atom({
    key: `gridAdvancedFilter_${gridID}`,
    default: new Promise(resolve => gridSettingsManager.getSettings(gridID).then(s => resolve(s?.SelectedFilter))),
    effects: [
        ({ onSet }) => {
            onSet(advancedFilter => {

                const settingsKey = gridID;
                gridSettingsManager.updateGridSettings(settingsKey, { SelectedFilter: advancedFilter });

            });
        }
    ]
}));

export const getQuickFilters = memoize(gridID => atom({
    key: `gridQuickFilters_${gridID}`,
    default: new Promise(resolve => gridSettingsManager.getSettings(gridID).then(s => resolve(s?.QuickFilters))),
    effects: [
        ({ onSet }) => {
            onSet(quickFilters => {

                const settingsKey = gridID;
                gridSettingsManager.updateGridSettings(settingsKey, { QuickFilters: accentUtils.isNull(quickFilters) ? [] : quickFilters });

            });
        }
    ]
}));


export const getGridFilter = memoize(gridID => atom({
    key: `gridGridFilter_${gridID}`,
    default: null,
}));


export const getGridSearch = memoize(gridID => atom({
    key: `gridGridSearch_${gridID}`,
    default: null,
}));


export const getGridSort = memoize(gridID => atom({
    key: `gridGridSort_${gridID}`,
    default: new Promise(resolve => gridSettingsManager.getSettings(gridID).then(s => resolve(s?.Sort))),
    effects: [
        ({ onSet }) => {
            onSet(sort => {
                const settingsKey = gridID;
                gridSettingsManager.updateGridSettings(settingsKey, { Sort: sort });
            });
        }
    ]
}));


const updateColumnWidthsDebounced = debounce(action => action(), 500, { trailing: false });

export const getGridColumnsWidths = memoize(gridID => atom({
    key: `gridGridColumnsWidth_${gridID}`,
    default: new Promise(resolve => gridSettingsManager.getSettings(gridID).then(s => resolve(s?.ColumnWidths ?? {}))),
    effects: [
        ({ onSet }) => {
            onSet(widths => {

                updateColumnWidthsDebounced(() => {

                    const settingsKey = gridID;
                    gridSettingsManager.updateGridSettings(settingsKey, { ColumnWidths: widths });

                });

                
            });
        }
    ]
}));

export const getGridHiddenColumns = memoize(gridID => atom({
    key: `gridGridHiddenColumns_${gridID}`,
    default: new Promise(resolve => gridSettingsManager.getSettings(gridID).then(s => resolve(s?.HiddenColumns ?? []))),
    effects: [
        ({ onSet }) => {
            onSet(hidden => {
                const settingsKey = gridID;
                gridSettingsManager.updateGridSettings(settingsKey, { HiddenColumns: hidden });
            });
        }
    ]
}));


export const getGridDetails = memoize(gridID => atom({
    key: `gridGridDetails_${gridID}`,
    default: false
}));

export const getGridExpandedItems = memoize(gridID => atom({
    key: `gridGridExpandedItems_${gridID}`,
    default: {}
}));

export const getGridSelectedItems = memoize(gridID => atom({
    key: `gridGridSelectedItems_${gridID}`,
    default: {}
}));


export function processDataForExport(data, columns, detailsField){


    let cols = columns.filter(c => c.field !== "selected" && c.field !== "action");

    if (!accentUtils.isEmpty(detailsField)) {
        cols.push(<GridColumn field={detailsField} title={detailsField} />);
    }

    
    var dateCols = from(columns).where(c => c.filter === "date").toDictionary(c => c.field);

    data.map(function (rec) {

        var dateFields = from(Object.keys(rec)).where(f => dateCols.contains(f)).toArray();

        

        Object.keys(rec).map(f => {
            if (!accentUtils.isEmpty(rec[f])) {

                if (dateFields.indexOf(f) !== -1) {
                    rec[f] = new Date(rec[f]);
                } else {

                    if (!Array.isArray(rec[f])) {
                        if ((typeof (rec[f]) === "boolean")) {
                            rec[f] = rec[f] ? "Yes" : "No";
                        } else if (typeof rec[f] !== "number") {
                            rec[f] = t(rec[f]);
                        }
                    }
                }
            }
        });


    });


    return {
        data: data,
        cols: cols.map(c => {
            if (!accentUtils.isNull(c.format)) {
                c.cellOptions = {
                    format: c.format,
                };
            }
            return c;
        })
    };




};


let pagerVersion = 0;

const PAGE_SIZE = 40;

class GridPager {

    constructor(
        gridFilterState,
        query,
        customHeaders,
        onRefresh,
        initialPages = 1
    ) {
        this.queue = new PQueue({ concurrency: 1 });

        this.initialPageSize = (accentUtils.isNull(initialPages) ? 1 : initialPages) * PAGE_SIZE;
        this.refreshFired = false;
        this.onRefresh = onRefresh;
        this.total = 0;
        this.pages = {};
        this.fetchStarted = {};

        pagerVersion++;

        this.version = pagerVersion;
        this.lastPage = 0;

        this.adhocFilter = gridFilterState.adhocFilter;
        this.advancedFilter = gridFilterState.advancedFilter;
        this.quickFilters = gridFilterState.quickFilters;
        this.gridFilter = gridFilterState.gridFilter;
        this.gridSearch = gridFilterState.gridSearch;
        this.gridSort = gridFilterState.gridSort;
        this.query = query;
        this.customHeaders = customHeaders;
        this.fetchNext = this.fetchNext.bind(this);
        this.getData = this.getData.bind(this);
        this.getTotal = this.getTotal.bind(this);
        this.getLoaded = this.getLoaded.bind(this);
        this.canFetch = this.canFetch.bind(this);
        this.refresh = this.refresh.bind(this);
        this.updateDataItem = this.updateDataItem.bind(this);
        this.getLastPage = this.getLastPage.bind(this);
    }


    getLastPage() {
        return this.lastPage;
    }

    getTotal() {
        return this.total;
    }

    getData() {        
        return from(Object.keys(this.pages)).orderBy(k => k).selectMany(k => this.pages[k]?.data ?? []).toArray();
    }


    getLoaded() {
        return from(Object.keys(this.pages)).sum(k => this.pages[k]?.data?.length ?? 0);
    }

    canFetch(pageToFetch) {

        const maxPages = Math.ceil(this.getTotal() / PAGE_SIZE);

        
        const alreadyFetchedOrFetching = !accentUtils.isNull(this.pages[pageToFetch]) || this.fetchStarted[pageToFetch];


        return !alreadyFetchedOrFetching && (accentUtils.isNull(this.pages[1]) || pageToFetch <= maxPages);
    }


    updateDataItem(updatedItem) {


        for (const pageNo of Object.keys(this.pages)) {

            const page = this.pages[pageNo];

            const index = from(page.data).indexOf(i => i.ID === updatedItem.ID);

            if (index !== -1) {
                page.data[index] = updatedItem;
                break;
            }

        }


    }

    refresh() {
        this.refreshFired = false;
        this.pages = {};
        this.fetchStarted = {};
        return this.fetchNext(1, true);
    }


    async doFetch(page, queryFetcher, dataStateFilter, done, bustCache) {

        console.log("Start Loading Page: ", page);
        try {

            const res = await queryFetcher.fetchData(dataStateFilter, bustCache);

            this.pages[page] = res;
            this.total = res.total;

            console.log("Finish Loading Page: ", page);

            done(page);

            const me = this;

            window.setTimeout(() => {
                if (!me.refreshFired) {
                    me.refreshFired = true;
                    me.onRefresh();
                }
            }, 0);


        } catch (ex) {

            done(page);

            console.log("doFetch : ", ex);
        }
        


    }

    fetchNext(page, bustCache) {


        const requireLoad = this.canFetch(page);

        if (!requireLoad) return Promise.resolve(null);

        this.lastPage = page;
        this.fetchStarted[page] = true;

        const dataStateFilter = {
            filter: this.gridFilter,
            skip: PAGE_SIZE * (page - 1),
            take: page === 1 ? this.initialPageSize : PAGE_SIZE,
            sort: this.gridSort,
            customHeaders: {
                adhocFilter: accentUtils.isNull(this.adhocFilter) ? null : JSON.stringify({ filter: this.adhocFilter }),
                advancedFilter: accentUtils.isNull(this.advancedFilter) ? null : this.advancedFilter.ID,
                quickFilters: (this.quickFilters?.length ?? 0) === 0 ? null : this.quickFilters.filter(x=>x.Selected).map(x=>x.ID),
                customQuery: accentUtils.isNull(this.customHeaders) ? null : JSON.stringify(this.customHeaders())
            }
        };



        const queryFetcher = new RTLQueryFetcher({
            query: this.query(this.gridSearch)
        });


        return new Promise(resolve => {
            this.queue.add(() => this.doFetch(page, queryFetcher, dataStateFilter, resolve, bustCache));
        });

    }



    async fetchExport() {


        const dataStateFilter = {
            filter: this.gridFilter,
            sort: this.gridSort,
            customHeaders: {
                adhocFilter: accentUtils.isNull(this.adhocFilter) ? null : JSON.stringify({ filter: this.adhocFilter }),
                advancedFilter: accentUtils.isNull(this.advancedFilter) ? null : this.advancedFilter.ID,
                quickFilters: (this.quickFilters?.length ?? 0) === 0 ? null : this.quickFilters.filter(x => x.Selected).map(x => x.ID),
                customQuery: accentUtils.isNull(this.customHeaders) ? null : JSON.stringify(this.customHeaders())
            }
        };



        const queryFetcher = new RTLQueryFetcher({
            query: this.query(this.gridSearch)
        });


        var result = await queryFetcher.fetchData(dataStateFilter);


        if (Array.isArray(result)) {
            return result;
        }

        return result.data;

    }


}




export const useGridFilterState = (gridID) => {

    const adhocFilter = useRecoilValue(getAdhocFilter(gridID));
    const advancedFilter = useRecoilValue(getAdvancedFilter(gridID));
    const quickFilters = useRecoilValue(getQuickFilters(gridID));
    const gridFilter = useRecoilValue(getGridFilter(gridID));
    const gridSearch = useRecoilValue(getGridSearch(gridID));
    const gridSort = useRecoilValue(getGridSort(gridID));


    const state = React.useMemo(() => {
        return {
            adhocFilter: adhocFilter,
            advancedFilter: advancedFilter,
            quickFilters: quickFilters,
            gridFilter: gridFilter,
            gridSearch: gridSearch,
            gridSort: gridSort
        }
    }, [adhocFilter, advancedFilter, quickFilters, gridFilter, gridSearch, gridSort]);


    React.useEffect(() => { }, []);


    return state;

};


export const useGridData = (gridID, query, customHeaders, onRefresh, initialPages) => {


    const gridFilterState = useGridFilterState(gridID);


    const stackCount = React.useRef(0);

    const pager = React.useMemo(
        () => new GridPager(gridFilterState, query, customHeaders, onRefresh, initialPages),
        [gridFilterState, query, customHeaders]
    );

    const [loading, setLoading] = React.useState(true);


    const onBeforeDataChange = React.useCallback(() => {

        stackCount.current++;
        setLoading(true);

    }, [stackCount, setLoading]);


    const onAfterDataChanged = React.useCallback(() => {

        stackCount.current = Math.max(0, --stackCount.current);
        if (stackCount.current == 0) {
            setLoading(false);
        }


    }, [stackCount, setLoading]);

    const updateDataItem =  React.useCallback(updatedItem => {

        if (pager) {

            onBeforeDataChange();

            pager.updateDataItem(updatedItem);

            onAfterDataChanged();

        }

    }, [pager, onBeforeDataChange, onAfterDataChanged]);

    const refresh = React.useCallback(async () => {
        if (pager) {

            onBeforeDataChange();

            await pager.refresh();

            onAfterDataChanged();


        }

    }, [pager, onBeforeDataChange, onAfterDataChanged]);
    
    
    const fetchNext = React.useCallback(async (page) => {
        if (pager && pager.canFetch(page)) {

            console.log("Pager Start Loading: ");

            onBeforeDataChange();

            await pager.fetchNext(page);


            console.log("Pager Finished Loading: ");

            onAfterDataChanged();

        }


    }, [pager, onBeforeDataChange, onAfterDataChanged]);


    const fetchExport = React.useCallback(async () => {

        onBeforeDataChange();

        const exportData = await pager.fetchExport();




        onAfterDataChanged();

        return exportData;


    }, [pager, onBeforeDataChange, onAfterDataChanged])
   



    




    React.useEffect(() => {
        fetchNext(1);
    }, [pager]);
    

    return {
        version: pager.version,
        loading: loading,
        lastPage: pager.lastPage,
        data: pager.getData(),
        total: pager.getTotal(),
        fetchPage: (p) => {
            fetchNext(p);
        },
        fetchExport: () => {
            return fetchExport();
        },
        refresh: async () => {
            refresh();
        },
        updateDataItem: updatedItem => {
            updateDataItem(updatedItem);
        }
    };

    

};



class GridSettingsManager {

    constructor() {
        this.data = {};

        this.resetGridSettings = this.resetGridSettings.bind(this);
        this.updateGridSettings = this.updateGridSettings.bind(this);
        this.getSettings = this.getSettings.bind(this);
    }


    async resetGridSettings(gridID) {

        const key = gridID;

        await update("ResetGridSettings", { Key: key });
        
        delete this.data[key];
    }

    async updateGridSettings(key, e) {
        
        if (key && key !== 'undefined') {


            const settings = await this.getSettings(key);
            const newSettings = { ...settings, ...e };
            this.data[key] = newSettings;

            enqueue(() => update("SaveGridSetting", { Key: key, Options: newSettings }));

        }

    }



    async getSettings(key) {

        if (accentUtils.isEmpty(key)) return null;


        if (accentUtils.isNull(this.data[key])) {

            var settings = await query_Grid_Settings.getFirstOrDefault({ key: key })

            this.data[key] = accentUtils.isNull(settings?.Settings) ? {} : JSON.parse(settings.Settings);

        }

        return this.data[key];

    }


}

export const gridSettingsManager = new GridSettingsManager();


