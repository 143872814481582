import * as React from 'react'
import { TreeList, TreeListBooleanEditor } from '@progress/kendo-react-all'
import { accentUtils, from, showDialog, goTo, t } from './../services/HelperService'
import * as JobSerivce from './../services/JobService'
import { update, queryNoTracking } from './../services/DataService'
import NewServiceJobWizard from './../wizards/NewServiceJobWizard'
import { SplitLineDlg } from './SplitLineDlg';
import { AccentDisplay, isDesktop } from './../controls/AccentDisplay'
import { AccentSpinner } from './../controls/AccentSpinner'
import { AccentButton } from './../controls/AccentButton'
import  AccentIcons from './../icons/Icons'
import { getContextData } from '../services/EntityContextService'
import { AccentDialog } from './AccentDialog'



export default class CompleteDispatchDlg extends React.PureComponent {


    static Show(activityID) {
        return showDialog(<CompleteDispatchDlg activityID={activityID} />);        
    }


    static SplitLine(jobLineID, qty, activityID) {


        SplitLineDlg.Show(jobLineID, qty).then(r => {
            CompleteDispatchDlg.Show(activityID);
        });

    }

    constructor(props) {
        super(props);


        this.query_Activity_ProcessCompleteDispatch = queryNoTracking("Activity_ProcessCompleteDispatch");

        this.state = {
            expandedItems: { '0': true },
        };

        this.onClose = this.onClose.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.getProductKey = this.getProductKey.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.setItemValue = this.setItemValue.bind(this);
        this.setActionRequired = this.setActionRequired.bind(this);
        this.save = this.save.bind(this);
        this.onOpenServiceJob = this.onOpenServiceJob.bind(this);
        this.gotoNewSerivceJob = this.gotoNewSerivceJob.bind(this);
        this.gotoOriginalJob = this.gotoOriginalJob.bind(this);
    }

    componentDidMount() {

        this.loadData();

    }

    loadData() {

        this.query_Activity_ProcessCompleteDispatch.getFirstOrDefault({ activityID : this.props.activityID }).then(r => {

            const processDetails = accentUtils.isEmpty(r.ProcessDetails) ? {} : JSON.parse(r.ProcessDetails);

            const lines = r.JobLines.map(l => {

                if (!accentUtils.isNull(processDetails[l.JobLineID])) {
                    l.T_Completed = processDetails[l.JobLineID].Completed;
                    l.T_Reschedule = processDetails[l.JobLineID].Reschedule;
                    l.T_Fault = processDetails[l.JobLineID].Fault;
                    this.setActionRequired(l);
                } else if (!accentUtils.isNull(l.ServiceLineID)) {

                    processDetails[l.JobLineID] = {};

                    processDetails[l.JobLineID].Fault = true;
                    l.T_Fault = true;
                    this.setActionRequired(l);
                }
            });

            this.setState({
                lines: r.JobLines,
                jobInfo: from(r.JobLines).firstOrDefault()
            });
        });

    }

    save() {

        const details = {};

        this.state.lines.map(l => {
            details[l.JobLineID] = {
                Completed: l.T_Completed,
                Reschedule: l.T_Reschedule,
                Fault: l.T_Fault
            };
        })


        return new Promise(p => {

            const detailsJson = JSON.stringify(details);

            update("Activity_SaveProcessDetails", {
                ID: this.props.activityID,
                ProcessDetails: detailsJson
            }).then(r => {
                p({ ...r, processDetails: detailsJson });
            });

        });



    }

    getProductKey(l) {
        return `${l.ProductName} (${l.SupplierName})`
    }

    onCancel(e) {

        if (e.promise) e.promise();

        this.props.onClose({
            canceled: true,
        });


    }

    gotoOriginalJob(e) {

        this.save().then(r => {

            e.promise();
            this.props.onClose({
                canceled: false,
            });

            goTo(`/Job/${this.state.jobInfo.JobID}`);
        });
    }

    onOpenServiceJob(id) {

        this.save().then(r => {


            this.props.onClose({
                canceled: false,
            });

            goTo(`/Job/${id}`);

        });

    }


    gotoNewSerivceJob(e) {



        if (JobSerivce.Helper.allowCreateServiceJob()) {

            this.save().then(r => {

                e.promise();
                this.props.onClose({
                    canceled: false,
                });

                getContextData({
                    id: this.state.jobInfo.JobID,
                    type: "Job"
                }).then(c => {
                    NewServiceJobWizard.CreateNewServiceJob(c);
                });


            });
        }
    }


    onClose(e, completeActivity) {

        this.save().then(r => {

            e.promise();
            this.props.onClose({
                canceled: false,
                completeActivity: completeActivity,
                processDetails: r.processDetails
            });


        });



    }

    onExpand(e) {


        const expanded = !e.dataItem.expanded;

        const itms = { ...this.state.expandedItems };
        itms[e.dataItem.ID] = expanded;
        this.setState({ expandedItems: itms });


    }

    setItemValue(item, field, value) {

        item["T_Completed"] = false;
        item["T_Reschedule"] = false;
        item["T_Fault"] = false;

        item[`T_${field}`] = value;

        this.setActionRequired(item);
    }

    setActionRequired(item) {

        item["T_ActionRequired"] = null;

        if (item["T_Fault"] === true && accentUtils.isNull(item.ServiceLineID)) {
            item["T_ActionRequired"] = "application_strings.wizards.completeDispatch.faultAction";
        }

    }



    onItemChange(e) {

        //e.dataItem[`T_${e.field}`] = e.value


        if (e.dataItem.isJob) {

            from(e.dataItem.children).selectMany(p => p.children).toArray().map(li => {

                var line = from(this.state.lines).firstOrDefault(l => l.JobLineID === li.ID);

                if (!accentUtils.isNull(line)) {
                    this.setItemValue(line, e.field, e.value);
                }

            });



        } else if (e.dataItem.isProduct) {

            e.dataItem.children.map(li => {

                var line = from(this.state.lines).firstOrDefault(l => l.JobLineID === li.ID);

                if (!accentUtils.isNull(line)) {
                    this.setItemValue(line, e.field, e.value);
                }

            });

        } else {

            var line = from(this.state.lines).firstOrDefault(l => l.JobLineID === e.dataItem.ID);

            if (!accentUtils.isNull(line)) {
                this.setItemValue(line, e.field, e.value);
            }

        }

        this.forceUpdate();

    }





    render() {

        const me = this;

        var content = <AccentSpinner />;


        const RequiredActionCell = (props) => {
            const { dataItem } = props;
            const field = props.field || "";

            const hasSelection = dataItem.Completed || dataItem.Reschedule || dataItem.Fault;

            const isFault = !accentUtils.isNull(dataItem[field]);

            const hasServiceJobLink = !accentUtils.isNull(dataItem.ServiceLineID);

            const showBlank = dataItem.expanded;

            var cellData = "";
            var link = null;
            if (!showBlank) {

                if (isFault) {
                    cellData = dataItem[field];
                } else if (hasSelection) {

                    if (hasServiceJobLink) {
                        cellData = "application_strings.wizards.completeDispatch.hasServiceJobLink";
                        link = <div style={{ display: "inlineBlock" }} className="acc-link" onClick={e => me.onOpenServiceJob(dataItem.ServiceJobID)}>{dataItem.ServiceReference}</div>;

                    } else {
                        cellData = "application_strings.wizards.completeDispatch.noAction";
                    }
                }

            }


            return (
                <td className={props.className}>
                    <span
                        style={{
                            color: !isFault ? "green" : "red",
                        }}
                    >
                        {t(cellData)}
                        {link}
                    </span>
                </td>
            );
        };


        const QtyCell = (props) => {
            const { dataItem } = props;
            const field = props.field || "";


            const cellData = dataItem[field];

            const qty = Number(cellData);
            const isLine = !dataItem.isProduct && !dataItem.isJob;

            const showIcon = isLine && qty > 1;

            const style = showIcon ? { ...props.style, minWidth: "120px" } : props.style;

            const splitIconStyle = {
                width: "24px",
                height: "24px",
                padding: "3px",
            };


            return (
                <td className={props.className} style={style}>
                    {t(cellData)} {showIcon && <div className="acc-link" style={{ display: "inline" }} onClick={e => CompleteDispatchDlg.SplitLine(dataItem.ID, qty, dataItem.ActivityID)}><AccentIcons.Split style={splitIconStyle} /></div>}
                </td>
            );
        };


        const isLoaded = !accentUtils.isNull(this.state.lines);

        if (isLoaded) {

            const columns = [
                {
                    field: "Item",
                    title: "Item",
                    expandable: true,
                    locked: isDesktop
                },
                {
                    field: "Qty",
                    title: "Qty",
                    expandable: false,
                    locked: isDesktop,
                    width: "100px",
                    cell: QtyCell
                },
                {
                    field: "Completed",
                    title: "Completed",
                    expandable: false,
                    editCell: TreeListBooleanEditor,
                    locked: isDesktop
                },
                {
                    field: "Reschedule",
                    title: "Reschedule",
                    expandable: false,
                    editCell: TreeListBooleanEditor,
                    locked: isDesktop
                },
                {
                    field: "Fault",
                    title: "Fault",
                    expandable: false,
                    editCell: TreeListBooleanEditor,
                    locked: isDesktop
                },
                {
                    field: "RequiredAction",
                    title: "Action Required",
                    expandable: false,
                    locked: isDesktop,
                    width: "350px",
                    cell: RequiredActionCell
                },
                {
                    field: "DisplayOption1",
                    title: "Option 1",
                    width: "250px",
                    expandable: false,
                },
                {
                    field: "DisplayOption2",
                    title: "Option 2",
                    width: "250px",
                    expandable: false,
                },
                {
                    field: "DisplayOption3",
                    title: "Option 3",
                    width: "250px",
                    expandable: false,
                },
                {
                    field: "DisplayOption4",
                    title: "Option 4",
                    width: "250px",
                    expandable: false,
                },
                {
                    field: "DisplayOption5",
                    title: "Option 5",
                    width: "250px",
                    expandable: false,
                },
                {
                    field: "DisplayOption6",
                    title: "Option 6",
                    width: "250px",
                    expandable: false,
                },
                {
                    field: "DisplayOption7",
                    title: "Option 7",
                    width: "250px",
                    expandable: false,
                },
                {
                    field: "DisplayOption8",
                    title: "Option 8",
                    width: "250px",
                    expandable: false,
                },
                {
                    field: "DisplayOption9",
                    title: "Option 9",
                    width: "250px",
                    expandable: false,
                },
                {
                    field: "DisplayOption10",
                    title: "Option 10",
                    width: "250px",
                    expandable: false,
                },
            ];


            const data = [{
                ID: 0,
                Item: this.state.jobInfo.JobReference,
                isJob: true,
                editing: true,
                Qty: from(this.state.lines).select(l => l.Qty).sum(),
                Completed: from(this.state.lines).all(l => l.T_Completed),
                Reschedule: from(this.state.lines).all(l => l.T_Reschedule),
                Fault: from(this.state.lines).all(l => l.T_Fault),
                RequiredAction: from(this.state.lines).where(l => !accentUtils.isEmpty(l.T_ActionRequired)).select(l => l.T_ActionRequired).firstOrDefault(),
                expanded: this.state.expandedItems[0],
                children: from(this.state.lines).groupBy(
                    l => this.getProductKey(l),
                    v => {
                        return {
                            ParentKey: this.getProductKey(v),
                            Qty: v.Qty,
                            ID: v.JobLineID,
                            Item: v.JobLineNo + ' - ' + v.LocationOther,
                            DisplayOption1: v.DisplayOption1,
                            DisplayOption2: v.DisplayOption2,
                            DisplayOption3: v.DisplayOption3,
                            DisplayOption4: v.DisplayOption4,
                            DisplayOption5: v.DisplayOption5,
                            DisplayOption6: v.DisplayOption6,
                            DisplayOption7: v.DisplayOption7,
                            DisplayOption8: v.DisplayOption8,
                            DisplayOption9: v.DisplayOption9,
                            DisplayOption10: v.DisplayOption10,
                            editing: true,
                            Completed: v.T_Completed,
                            Reschedule: v.T_Reschedule,
                            Fault: v.T_Fault,
                            RequiredAction: v.T_ActionRequired,
                            ServiceLineID: v.ServiceLineID,
                            ServiceJobID: v.ServiceJobID,
                            ServiceReference: v.ServiceReference,
                            ActivityID: v.ID
                        };
                    },
                    (key, itms) => {
                        return {
                            ParentKey: 0,
                            ID: key,
                            Item: key,
                            isProduct: true,
                            editing: true,
                            editing: true,
                            Completed: from(itms).all(l => l.Completed),
                            Reschedule: from(itms).all(l => l.Reschedule),
                            Fault: from(itms).all(l => l.Fault),
                            RequiredAction: from(itms).where(l => !accentUtils.isEmpty(l.RequiredAction)).select(l => l.RequiredAction).firstOrDefault(),
                            expanded: this.state.expandedItems[key],
                            Qty: from(itms).select(i => i.Qty).sum(),
                            children: itms.orderBy(i => i.SortIndex).toArray()
                        };
                    }, r => r)
                    .toArray()

            }];

            content = <TreeList
                style={{
                    overflow: "auto",
                }}
                editField="editing"
                expandField="expanded"
                subItemsField="children"
                onExpandChange={this.onExpand}
                onItemChange={this.onItemChange}
                data={data}
                columns={columns}
            />;


        }



        const hasServiceJob = isLoaded && from(this.state.lines).any(l => !accentUtils.isNull(l.ServiceJobID));

        const allowComplete = isLoaded && from(this.state.lines).all(l => l.T_Completed || l.T_Reschedule || (l.T_Fault && l.T_ActionRequired === null));
        const requireServiceJob = isLoaded && (from(this.state.lines).any(l => l.T_Fault && l.T_ActionRequired !== null) || hasServiceJob);


        const serviceButton = !requireServiceJob ? null
            : !hasServiceJob ? <AccentButton tagName="btnSJ" onClick={this.gotoNewSerivceJob}>{t("application_strings.application.buttons.newServiceJob")}</AccentButton>
                : null;

        const originalJobButton = <AccentButton tagName="btnOJ" onClick={this.gotoOriginalJob}>
            {t("application_strings.application.buttons.openOriginalJob")}
        </AccentButton>;



        return (
            <AccentDialog                
                fullscreen
                onClose={this.onCancel}
                noCancel
                headerContent={<React.Fragment>
                    <div className="me-auto" ><h5>{t("application_strings.wizards.completeDispatch.title")}</h5></div>
                    <div>{originalJobButton}</div>
                    <div>{serviceButton}</div>
                </React.Fragment>}
                actionsContent={[
                    <AccentButton key="1" tagName="calCancelBtn" className="me-auto" disabled={false} onClick={this.onCancel}>
                        <AccentDisplay large tablet>
                            {t("application_strings.application.buttons.cancel")}
                        </AccentDisplay>
                        <AccentDisplay mobile>
                            <AccentIcons.Back />
                        </AccentDisplay>
                    </AccentButton>,
                    <AccentButton key="2" tagName="calCloseBtn" onClick={this.onClose}>
                        <AccentDisplay large tablet>
                            {t("application_strings.application.buttons.saveAndClose")}
                        </AccentDisplay>
                        <AccentDisplay mobile>
                            <AccentIcons.Save />
                        </AccentDisplay>
                    </AccentButton>,
                    <AccentButton key="3" tagName="calCloseBtn" disabled={!allowComplete} onClick={e => this.onClose(e, true)}>
                        <AccentDisplay large tablet>
                            {t("application_strings.application.buttons.completeActivity")}
                        </AccentDisplay>
                        <AccentDisplay mobile>
                            <AccentIcons.Done />
                        </AccentDisplay>
                    </AccentButton>
                ]}
            >
                    {content}
            </AccentDialog>

        );


    }

}


