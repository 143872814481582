import * as React from 'react';
import { Settings } from '../../services/AppSettingsService';
import { queryNoTracking } from '../../services/DataService';
import { AccentButton } from '../AccentButton';
import { DocumentTemplateSelection } from '../documents/DocumentTemplateSelection';
import { PrintSettingSelection } from '../documents/PrintSettingSelection';
import { ActivityContactViewCtrl } from './ActivityContactViewCtrl';
import { ActivityInstallerLineViewCtrl } from './ActivityInstallerLineViewCtrl';



const query_Job = queryNoTracking("Job");
const query_DocumentTemplateForID = queryNoTracking("DocumentTemplateForID");

export class ActivityInstallerViewCtrl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            job: {},
            printSettings: Settings.GetAppSettings().DefaultPrintSettings,
            documentTemplateId: Settings.GetAppSettings().DefaultJobSummaryDocumentTemplate,
            documentTemplate: undefined
        }
        this.onPrintSettingChanged = this.onPrintSettingChanged.bind(this);
        this.getNoTemplateContent = this.getNoTemplateContent.bind(this);
        this.getTemplateContent = this.getTemplateContent.bind(this);
        this.onDocumentTemplateChanged = this.onDocumentTemplateChanged.bind(this);
        this.onClipboardCopy = this.onClipboardCopy.bind(this);
    }

    componentDidMount() {
        var me = this;
        query_Job.getFirstOrDefault({ id: this.props.jobID, includeSiteContact: true, includeAddress: true}).then(function (r) {
            me.setState({ job: r });
        });
    }

    onDocumentTemplateChanged(e) {
        var me = this;
        if (e == null) {
            me.setState({ documentTemplate: undefined });
        } else {
            query_DocumentTemplateForID.getFirstOrDefault({ id: e, include: null}).then(function (t) {
                me.setState({ documentTemplate: t });
            });
        }
    }

    onPrintSettingChanged(e) {

        this.setState({ printSettings: e });
    }

    getNoTemplateContent() {
        var me = this;
        var jobInstallNotes = this.state.job.InstallNotes;
        var customerName = this.state.job.SiteContact ? this.state.job.SiteContact.FullName : '';
        var address = this.state.job.Address ? this.state.job.Address.Formatted : '';
        var jobLines = [];
        if (this.props.jobLines) {
            this.props.jobLines.forEach(function (jobLine) {
                jobLines.push(<ActivityInstallerLineViewCtrl jobLineID={jobLine.JobLineID} printSettings={me.state.printSettings} />);
            });

        }
        return (<div><div>
            <div className="im-sum-hdr">
                Customer Details
            </div>
            <div className="im-sum-txt">
                <div>{customerName}</div>
            </div>
            <div className="im-sum-txt">
                {address}
            </div>
        </div>
            <div className="im-sum-hdr">
                Job Details
            </div>
            <div >
                <div className="im-sum-bld" >
                    Job Install Notes
                </div>
                <div className="im-sum-txt">
                    {jobInstallNotes}
                </div>
            </div>

            <div className="im-sum-line-sct">
                Items for Installation
            </div>
            <div >
                {jobLines}
            </div>
        </div>);
    }

    formatAddress(address) {
        var formatted = '';
        if (address.AddressLines) {
            formatted = formatted + address.AddressLines;
        }
        if (address.City) {
            formatted = formatted + ' ' + address.City;
        }
        if (address.State) {
            formatted = formatted + ' ' + address.State;
        }
        if (address.Postcode) {
            formatted = formatted + ' ' + address.Postcode;
        }
        if (address.Country) {
            formatted = formatted + ' ' + address.Country;
        }
        return formatted;
    }

    parseStyle(styleJson) {
        var newStyle = {};
        if (styleJson === undefined) return newStyle;
        if (styleJson.Bold) {
            newStyle.fontWeight = "bold";
        }
        if (styleJson.Italic) {
            newStyle.fontStyle = "italic";
        }
        if (styleJson.FontName !== undefined) {
            newStyle.fontFamily = styleJson.FontName;
        }
        if (styleJson.Size !== undefined) {
            newStyle.fontSize = styleJson.Size;
        }
        if (styleJson.Color !== undefined) {
            newStyle.color = styleJson.Color;
        }
        if (styleJson.Size !== undefined) {
            newStyle.fontSize = styleJson.Size + "px";
        }
        if (styleJson.BackgroundColor != undefined) {
            newStyle.backgroundColor = styleJson.BackgroundColor;
        }
        return newStyle;
    }

    getTemplateContent() {
        var me = this;
        var jobInstallNotes = this.state.job.InstallNotes;
        var customerNotes = this.state.job.CustomerNotes;
        var supplyNotes = this.state.job.SupplyNotes;
        var address = this.props.address ? (this.props.address.Formatted ? this.props.address.Formatted : this.formatAddress(this.props.address)) : (this.state.job.Address ? this.state.job.Address.Formatted : '');
        var template = JSON.parse(this.state.documentTemplate.TemplateParams);
        var showInstallNotes = template.Parameters.report_param_show_install_notes !== undefined ? template.Parameters.report_param_show_install_notes === "report_param_show_install_notes_show" : false;
        var showSizes = template.Parameters.report_param_show_sizes !== undefined ? template.Parameters.report_param_show_sizes === "report_param_show_sizes_show" : false;
        var showPrice = template.Parameters.report_param_show_price !== undefined ? template.Parameters.report_param_show_price === "report_param_show_price_show" : false;
        var showCustomerNotes = template.Parameters.report_param_show_customer_notes !== undefined ? template.Parameters.report_param_show_customer_notes === "report_param_show_customer_notes_show" : false;
        var showManufacturingNotes = template.Parameters.report_param_show_manufacturing_notes !== undefined ? template.Parameters.report_param_show_manufacturing_notes === "report_param_show_manufacturing_notes_show" : false;
        var showLineNumber = template.Parameters.report_param_show_line_number !== undefined ? template.Parameters.report_param_show_line_number === "report_param_show_line_number_show" : false;
        var showLocation = template.Parameters.report_param_show_location !== undefined ? template.Parameters.report_param_show_location === "report_param_show_location_show" : false;
        var titleStyle = this.parseStyle(template.Styles.report_style_title);
        var captionStyle = this.parseStyle(template.Styles.report_style_caption);
        var noteStyle = this.parseStyle(template.Styles.report_style_notes);
        var optionStyle = this.parseStyle(template.Styles.report_style_options);
        var optionLayout = template.Parameters.report_param_options_format !== undefined ? template.Parameters.report_param_options_format : "report_param_options_format_comma";
        var optionColumns = template.Parameters.report_param_option_colums !== undefined ? template.Parameters.report_param_option_colums : 4;
        var jobLines = [];
        if (this.props.jobLines) {
            this.props.jobLines.forEach(function (jobLine) {
                jobLines.push(
                    <ActivityInstallerLineViewCtrl
                        jobLineID={jobLine.JobLineID}
                        printSettings={me.state.printSettings}
                        showSizes={showSizes}
                        showPrice={showPrice}
                        showInstallNotes={showInstallNotes}
                        showCustomerNotes={showCustomerNotes}
                        showManufacturingNotes={showManufacturingNotes}
                        showLineNumber={showLineNumber}
                        showLocation={showLocation}
                        noteStyle={noteStyle}
                        captionStyle={captionStyle}
                        optionLayout={optionLayout}
                        optionColumns={optionColumns}
                    />);
            });

        }
        return (<div>
            <div style={{ textAlign: "center" }}>
                <div className="im-sum-line-block">
                    <div style={titleStyle}>
                        {template.Parameters.report_param_title}
                    </div>
                </div>
                <div className="im-sum-line-block">
                    <div className="im-sum-hdr"  >
                        Customer Details
                    </div>
                    <ActivityContactViewCtrl contactID={this.props.contactID} />
                    <div className="im-sum-txt">
                        {address}
                    </div>
                </div>
            </div>
            <div className="im-sum-line-block">
                <div className="im-sum-hdr">
                    Job Details
                </div>
                {showInstallNotes &&
                    <div >
                        <div className="im-sum-bld" >
                            Job Install Notes
                        </div>
                        <div className="im-sum-txt" style={noteStyle}>
                            {jobInstallNotes}
                        </div>
                    </div>
                }
                {showCustomerNotes &&
                    <div >
                        <div className="im-sum-bld" >
                            Customer Notes
                        </div>
                        <div className="im-sum-txt" style={noteStyle}>
                            {customerNotes}
                        </div>
                    </div>
                }
                {showManufacturingNotes &&
                    <div >
                        <div className="im-sum-bld" >
                            Manufacturing Notes
                        </div>
                        <div className="im-sum-txt" style={noteStyle}>
                            {supplyNotes}
                        </div>
                    </div>
                }
            </div>
            <div style={{ borderBottomWidth: 2, borderBottomColor: 'black', width: "90%" }} />

            <div >
                {jobLines}
            </div>
        </div>
        );
    }

    onClipboardCopy() {
        var r = document.createRange();
        r.selectNode(document.getElementById("clipboard-copy"));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(r);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    }

    render() {
        var me = this;
        var jobInstallNotes = this.state.job.InstallNotes;
        var customerName = this.state.job.SiteContact ? this.state.job.SiteContact.FullName : '';
        var address = this.state.job.Address ? this.state.job.Address.Formatted : '';
        var jobLines = [];
        if (this.props.jobLines) {
            this.props.jobLines.forEach(function (jobLine) {
                jobLines.push(<ActivityInstallerLineViewCtrl jobLineID={jobLine.JobLineID} printSettings={me.state.printSettings} />);
            });

        }
        var content = this.state.documentTemplate === undefined ? this.getNoTemplateContent() : this.getTemplateContent();
        return (
            <div>
                <div className="row">
                    <div className="col">
                        <DocumentTemplateSelection type="JobSummary" onChange={this.onDocumentTemplateChanged} onReady={this.onDocumentTemplateChanged} />
                    </div>
                    <div className="col">
                        <PrintSettingSelection onChange={this.onPrintSettingChanged} onReady={this.onPrintSettingChanged} />
                    </div>
                    <AccentButton onClick={this.onClipboardCopy}>Copy to clipboard</AccentButton>
                </div>
                <div id="clipboard-copy">
                    {content}

                </div>
            </div>
        );
    }
}
