import * as React from 'react'
import $ from 'jquery'
import { Row, Col } from 'reactstrap'
import { accentUtils, from } from '../../services/HelperService'
import * as DataService from '../../services/DataService'
import * as ProductService from '../../services/ProductService'
import { AccentSpinner } from '../AccentSpinner';
import { ProductConfigurator } from '../products/ProductConfigurator'

const query_JobLineForID = DataService.queryNoTracking("JobLineForID");



export class BulkUpdateCtrl extends React.Component {

    constructor(props) {
        super(props);


        this.configCtrl = React.createRef();

        this.configChanged = this.configChanged.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);        
        this.updateMandatoryOptions = this.updateMandatoryOptions.bind(this);
        this.findProduct = this.findProduct.bind(this);
        this.diffObjects = this.diffObjects.bind(this);

        this.getChanges = this.getChanges.bind(this);

        var me = this;

        this.currentOLOs = [];


        this.state = { entity: null };

        this.productID = '00000000-0000-0000-0000-000000000000';

        query_JobLineForID.getFirstOrDefault({ id: this.props.id, include: null }).then(function (data) {
            me.productID = accentUtils.isNull(data.ProductID) ? '00000000-0000-0000-0000-000000000000' : data.ProductID;

            if (!accentUtils.isEmpty(data.OrderLineOptionsData)) {
                me.currentOLOs = JSON.parse(data.OrderLineOptionsData);
            }

            me.setState({ entity: data });
        });





    }

    findProduct(id) {
        return from(ProductService.getUserProductList()).firstOrDefault(function (p) { return p.ID == id });
    }

    diffObjects(original, final) {

        var res = {
        };

        for (var key in final) {
            if (final[key] !== original[key]) {

                if (accentUtils.isEmpty(final[key]) && accentUtils.isEmpty(original[key])) {
                    continue;
                }

                if (!accentUtils.isEmpty(final[key]) && !accentUtils.isEmpty(original[key])) {
                    if (("" + final[key]).toUpperCase() == ("" + original[key]).toUpperCase()) {
                        continue;
                    }
                }



                res[key] = final[key]
            }
        }

        return res;

    }

    getChanges() {

        const me = this;

        const oringalOLOs = from(me.currentOLOs).select(function (o) { return { ...o }; }).toArray();


        const current = JSON.parse(me.state.entity.OrderLineOptionsData);

        const changes = from(current).select(
            function (o) {

                var originalOLO = from(oringalOLOs).firstOrDefault(function (oo) { return oo.ProductOptionID.toUpperCase() == o.ProductOptionID.toUpperCase(); });

                if (accentUtils.isNull(originalOLO)) {
                    return o;
                }

                //preserve userset
                var isUserSet = o.UserSet;

                var res = me.diffObjects(originalOLO, o);

                if (Object.keys(res).length == 0) {
                    return null;
                }


                res.UserSet = isUserSet;
                res.SupplierID = o.SupplierID;

                res.ProductOptionID = o.ProductOptionID;
                return { ...o, ...res };
            }).where(function (o) { return o != null; }).toArray();


        return {
            changes: changes,
            original: oringalOLOs
        };
    }

    updateMandatoryOptions() {


    }



    componentDidMount() {
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }



    
    configChanged() {
        

        const me = this;
        window.setTimeout(() => {

            me.configCtrl.current.save();
            me.props.onChange(me.getChanges());


        }, 0);

    }




    render() {

        var me = this;

        if (accentUtils.isNull(me.state.entity)) return <AccentSpinner />;


        var product = this.findProduct(me.productID);


        var configCrl = <ProductConfigurator
            methods={this.configCtrl}
            orderLine={this.state.entity}
            isQuote={this.props.isQuote}
            productID={product.ID}
            onChange={this.configChanged}
            mpResellerKeys={product.ResellerMarketplaceKeys}
            businessUnitID={this.props.businessUnitID}
            onLoaded={this.updateMandatoryOptions}
            ignoreSizes
            hideSize
            hideExtras
            filter={[]}
        />;


        return (
            <Col sx={12} className="product-line-vw">
                {configCrl}
            </Col>
        );
    }
}

export const ProductChangeReviewCtrl = props => {


    //const values = from(props.changes).select(c => ({ id: c.ProductOptionID.toUpperCase(), name: c.ProductOptionName })).concat(from(props.original).select(o=> ({ id: o.ProductOptionID.toUpperCase(), name: o.ProductOptionName }))).distinct(x => x.id).toArray();

    const changes = props.changes.map(c => {

        const original = from(props.original).firstOrDefault(x => x.ProductOptionID.toUpperCase() === c.ProductOptionID.toUpperCase());
        
        return <Row key={`o_${c.ProductOptionID}`}>
            <Col xs={4} sm={4} xl={{ offset: 4, size: 1 }} md={{ offset: 4, size: 2 }}>{c.ProductOptionName}</Col>
            <Col xs={4} sm={4} xl={1} md={2}>{original?.CustomValue ?? "<NONE>"}</Col>
            <Col xs={4} sm={4} xl={1} md={2}>{c.CustomValue ?? "<NONE>"}</Col>
        </Row>;
    });

    return <Row>
        <Col md={12}>
            <Row style={{fontWeight: "bold"}}>
                <Col xs={4} sm={4} xl={{ offset: 4, size: 1 }}  md={{ offset: 4, size: 2 }}>Value</Col>
                <Col xs={4} sm={4} xl={1} md={2}>Original</Col>
                <Col xs={4} sm={4} xl={1} md={2}>Changed</Col>
            </Row>
            <hr />
            {changes}
        </Col>        
    </Row>
};