import * as React from 'react'
import { Table, Col, Row } from 'reactstrap'
import { accentUtils, showOK, t, formHelper,  format} from './../services/HelperService'
import { AccentSpinner } from './../controls/AccentSpinner'
import * as DataService from '../services/DataService';


import { AccentProductsOfInterest } from '../controls/AccentProductsOfInterest'
import { AccentWizard, AccentWizardPage } from './RTLWizard'
import { AccentFormCtrl } from '../controls/bound/AccentFormCtrl';

const query_PL_LeadSources = DataService.queryNoTracking("PL_LeadSources")
const query_OpportunitiesForContact = DataService.queryNoTracking("OpportunitiesForContact");

export default class LinkOpportunityWizard extends React.Component {

    constructor(props) {
        super(props);

        var me = this;
        this.contactID = this.props.pageprops.contactID;


        this.state = {
            leadData: null,
            selectedRow: 0
        };

        this.wizardModel = {
            viewModel: {
                userModel: this.props.pageprops.userModel,
                entity: {
                    PL_LeadSourceID: null,
                    VisitedShowroom: this.props.pageprops.isShowroom,
                    setProperty: function (name, value) {
                        this[name] = value;
                    }
                }
            }
        };

        this.getLeadQuery = this.getLeadQuery.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.getLeadSourceQuery = this.getLeadSourceQuery.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        
    }

    componentDidMount() {
        const me = this;
        this.getLeadQuery().then(function (res) {
            me.setState({ leadData: res });
        });

    }

    getLeadSourceQuery() {
        return query_PL_LeadSources.getAll({ includeID: this.wizardModel.viewModel.entity.PL_LeadSourceID, activeOnly: true });
    }

    getLeadQuery() {
        return query_OpportunitiesForContact.getAll({ id: this.contactID });
    }

    onFinish() {

        var me = this;


        return new Promise(p => {

            var res = {

            };

            

            if (this.state.selectedRow > 0) {
                res.OpportunityID = this.state.leadData[this.state.selectedRow - 1].ID;
            } else {
                res.PL_LeadSourceID = me.wizardModel.viewModel.entity.PL_LeadSourceID;
                res.VisitedShowroom = me.wizardModel.viewModel.entity.VisitedShowroom;
                res.POIs = me.refs.poiCtrl.getAsDetached().map(x => ({ PL_ProductOfInterestID: x.PL_ProductOfInterestID, Qty: x.Qty}));
            }

            p(res);

        });

    }


    render() {

        var activeCN = "webLeads-active";

        var me = this;

        var rowIndex = 0;
        var existing = null;

        if (accentUtils.isNull(this.state.leadData)) {
            existing = <AccentSpinner />;
        } else {

            existing = <Table className="table-hover"><tbody>
                {
                    [
                        <tr className={me.state.selectedRow === 0 ? activeCN : undefined} onClick={function () { me.setState({ selectedRow: 0 }); }}>
                            <td colSpan={9}>{t("application_strings.wizards.linkOpportunityWizard.page01NewLine")}</td>
                        </tr>
                    ].concat(
                        this.state.leadData.map(function (l) {
                            rowIndex++;
                            var currRow = rowIndex;

                            return <tr className={me.state.selectedRow === currRow ? activeCN : undefined} onClick={function () { me.setState({ selectedRow: currRow }); }}>
                                <td>{format.formatDate(l.LeadDate)}</td>
                                <td>{l.Description}</td>
                                <td>{l.Contact}</td>
                                <td>{l.Company}</td>
                                <td>{l.Phone}</td>
                                <td>{l.Email}</td>
                                <td>{t(l.PipelineStage)}</td>
                                <td>{l.Representative}</td>
                                <td>{l.Address}</td>
                            </tr>;
                        }))
                }
            </tbody></Table>;

        }


        var leadSource = formHelper
            .getFieldCtrlModel(this.wizardModel.viewModel.entity, "PL_LeadSourceID", "select", true, { label: "application_strings.wizards.linkOpportunityWizard.leadSourceLable", abbreviateLabel: true, source: me.getLeadSourceQuery, sourceType: "PL_LeadSource" });

        var visitedShowroom = formHelper.getFieldCtrlModel(this.wizardModel.viewModel.entity, "VisitedShowroom", "check", false, { label: "application_strings.wizards.linkOpportunityWizard.visitedShowroomLable" });

        var showFinish = this.state.selectedRow !== 0;

        var nextTitle = "application_strings.wizards.linkOpportunityWizard.nextCreate";

        if (this.state.selectedRow !== 0) {
            nextTitle = "application_strings.wizards.linkOpportunityWizard.nextExisting";
        }

        var overrideIndex = undefined;
        var allowBack = undefined;
        if (!accentUtils.isNull(this.state.leadData) && this.state.leadData.length === 0) {
            overrideIndex = 1;
            allowBack = false;
        }

        return (
            <AccentWizard wizardProps={this.props} indexOverride={overrideIndex} >
                <AccentWizardPage title="application_strings.wizards.linkOpportunityWizard.page01Title" nextTitle={nextTitle} isFinish={showFinish} >
                    <label>{t("application_strings.wizards.linkOpportunityWizard.page01SelectExistingMsg")}</label>
                    {existing}
                </AccentWizardPage>
                <AccentWizardPage title="application_strings.wizards.linkOpportunityWizard.page02Title" allowBack={allowBack}>
                    <label>{t("application_strings.wizards.linkOpportunityWizard.page02LeadDetailsMsg")}</label>
                    <Row>
                        <Col md="6"><AccentFormCtrl labelCol={3} model={leadSource} /></Col>
                        <Col md={6}>
                            <AccentFormCtrl labelCol={3} model={visitedShowroom} />
                        </Col>

                    </Row>
                    <hr />
                    <Row>
                        <Col xs="12">
                            <label>{t("application_strings.wizards.linkOpportunityWizard.page02POIMsg")}</label>
                            <AccentProductsOfInterest ref="poiCtrl" setDirty={function () { }} required={true} oppID={-99} />
                        </Col>
                    </Row>
                </AccentWizardPage>
            </AccentWizard>
        );
    }

}
