import React from 'react'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import { Input } from '@progress/kendo-react-all'
import { accentUtils, t, formHelper } from '../../services/HelperService'
import { debounce } from 'perfect-debounce'


export class AccentText extends React.Component {
    
    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        if (this.props.model.options.translate) {
            this.initialValue = t(this.props.model.getValue());
        } else {
            this.initialValue = this.props.model.getValue();
        }

        this.currentUserValue = this.initialValue;
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.getControlValue = this.getControlValue.bind(this);
        this.insertText = this.insertText.bind(this);
        
    }


    insertText(pos, value) {

        var currValue = this.props.model.getValue();

        var newValue = accentUtils.stringInsert(currValue, value, pos);


        this.props.model.setValue(newValue);
        this.props.model.modified();
        this.forceUpdate();
    }

    handleFocus(e) {
        var me = this;

        $(me.ctrl.current.element).select();

    }

    handleBlur(e){

        

        var value = e.currentTarget.value;
        this.props.model.setValue(value);

        
        if (!accentUtils.isNull(this.props.model.options.onFocusLost)) {
            this.props.model.options.onFocusLost({ selectionStart: e.target.selectionStart }  );
        }

        if (!accentUtils.isNull(this.props.model.options.onChange)){
            this.props.model.options.onChange({previous: this.initialValue, value: value});
        }

    }

    
    handleChange(e) {        

        var value = e.value;
        this.currentUserValue = value;
        this.props.model.modified();
        this.props.model.setValue(value);



    }

    focus(){        
        alert(" $(ReactDOM.findDOMNode(this.ctrl.current)).trigger('focus')");
    }


    getControlValue() {
        return this.ctrl.current.value;
    }



    render() {
        var disabled = formHelper.isControlReadOnly(this.props.model);

        var currValue = undefined;


        var modelValue = this.props.model.getValue();
        var max = this.props.model.options.maxLength;

        if (disabled) {

            if (this.props.model.options.translate) {
                currValue = t(modelValue);
            } else {
                currValue = modelValue;
            }

            
        }else {
            if (modelValue !== this.currentUserValue) { // model changed so hack                

                if (accentUtils.isNull(modelValue)) {
                    modelValue = "";
                }

                this.currentUserValue = modelValue;
                this.ctrl.current.state.value = modelValue;
            }
        }

        // need to be able to refresh if model has changed
        return (            
            <Input title={ this.props.title} ref={this.ctrl} maxLength={max} disabled={disabled} value={currValue??undefined} label={this.props.model.displayLabel} onBlur={this.handleBlur} onFocus={this.handleFocus} onChange={this.handleChange} defaultValue={this.initialValue??""} onContextMenu={this.props.model.options.onContextMenu}/>
	    );
  }
}
