import * as React from 'react'
import { Stepper } from '@progress/kendo-react-layout';
import { Table, Row, Col } from "reactstrap";
import { AccentSpinner } from "../controls/AccentSpinner";
import { JobDiffCtrl } from "../controls/jobs/JobDiffCtrl";
import { AccentRadioUnboundCustom } from "../controls/unbound/AccentRadioUnbound";
import { queryNoTracking, update } from "../services/DataService";
import { format, t, from, accentUtils, showWait } from "../services/HelperService";
import { Helper } from "../services/JobService";
import { getAccess } from "../services/UserService";
import { AccentDialog } from './AccentDialog';
import { AccentButton } from '../controls/AccentButton';


const jobEditPriceAction = {
    Quote: 1,
    CurrentQuote: 2,
    BeforeEdit: 3,
    NewPrice: 4,
    CurrentBeforeEdit: 5
};


export class FinishEditJobDlg extends React.Component {

    constructor(props) {
        super(props);

        
        this.jobID = props.jobID;
        this.editModel = props.editModel;
        this.quoted = this.editModel.QuoteInfo;
        this.userModel = props.userModel;

        this.state = {
            step :0
        };
        this.model = {
            priceActionType: null
        };

        this.skipPriceActionPage = (this.editModel.Diff.NewSalePrice - this.editModel.Diff.OriginalSalePrice) === 0;


        this.loadingPriceInfo = false;
        this.priceUpdateRequired = false;
        this.priceSetToAction = jobEditPriceAction.NewPrice;
        this.getPriceAmountFor = this.getPriceAmountFor.bind(this);
        this.updatePrice = this.updatePrice.bind(this);
        this.onPriceActionChanged = this.onPriceActionChanged.bind(this);
        this.getConflictTable = this.getConflictTable.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.onStepChanged = this.onStepChanged.bind(this);
        this.getPricingContent = this.getPricingContent.bind(this);
        this.getReviewContent = this.getReviewContent.bind(this);
        this.getWarningsContent = this.getWarningsContent.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {
       

    }



    onStepChanged(e) {

        const me = this;


        if (e.value === 1 && this.state.step === 0) {
            this.setState({ step: e.value, priceLoading: true }, () => {
                this.updatePrice();
            });
        }else if (e.value === 0 && this.state.step !== 0) {
                this.setState({ step: e.value, priceLoading: true }, () => {
                    update("EditJobBackToPricePrice", {
                        ID: this.jobID
                    }).then(function (res) {
                        me.priceSetToAction = me.model.priceActionType;

                        me.editModel = res;
                        me.setState({ priceLoading: false });

                    });
                });
        } else if (e.value === 3) {

            const done = showWait();

            Helper.completeEditJobWizard(this.jobID).then(function (res) {
                done();
                me.props.onClose({ canceled: false });                                
            });

        } else {
            this.setState({ step: e.value });
        }

        

        if (e.promise) e.promise();

    }

    getPriceAmountFor(selectedType) {

        if (selectedType === jobEditPriceAction.BeforeEdit) {
            return this.editModel.Diff.OriginalSalePrice;
        } else if (selectedType === jobEditPriceAction.CurrentQuote) {
            return this.editModel.QuoteInfo.FilteredSalesPriceInc;
        } else if (selectedType === jobEditPriceAction.Quote) {
            return this.quoted.SalesPriceInc;
        } else if (selectedType === jobEditPriceAction.CurrentBeforeEdit) {
            return this.editModel.Diff.FilteredOriginalSalePrice;
        } else if (selectedType === jobEditPriceAction.NewPrice) {
            return this.editModel.Diff.NewSalePrice;
        }

        return 0;
    }

    updatePrice() {

        var me = this;

        var newSalePrice = this.getPriceAmountFor(this.model.priceActionType);


        var lines = from(this.editModel.Diff.Lines).where(l => l.Stage !== "stage_job_line_unconfirmed" && l.Stage !== "stage_job_line_unconfirmed_sent")
            .select(l => l.ID)
            .toArray();


        const currentPrice = this.getPriceAmountFor(jobEditPriceAction.NewPrice);


        update("FinishEditJobSetPrice", {
            ID: this.jobID,
            SalePriceIncTax: newSalePrice,
            LeavePriceUnchanged: currentPrice === newSalePrice,
            Lines: lines
        }).then(function (res) {
            me.priceSetToAction = me.model.priceActionType;

            me.editModel = res;
            me.setState({ priceLoading: false });

        });




    }

    onPriceActionChanged(action) {
        this.model.priceActionType = action;

        this.forceUpdate();
    }

    onCancel() {
        var me = this;

        return new Promise(function (p) {

            Helper.cancelEditJobWizard(me.editModel).then(function (res) {
                p(me.jobID);
            });

        });
    }



    getConflictTable() {
        var rows = this.editModel.Conflicts.map(function (c) {
            return <tr key={c.Type + c.ID}>
                <td>{t(c.Type)}</td>
                <td>{format.formatDate(c.Date)}</td>
                <td>{c.Description}</td>
                <td className="acc-diff-sugg">{t(c.Type + "_suggestion")}</td>
            </tr>;
        });


        rows.unshift(<tr key={"job" + this.editModel.ID}>
            <td>{t("job_edit_conflict_order")}</td>
            <td>{format.formatDate(this.editModel.JobDate)}</td>
            <td>{this.editModel.Reference}</td>
            <td className="acc-diff-sugg">{t("job_edit_conflict_order_suggestion")}</td>
        </tr>);

        return <Table className="table-sm">
            <tbody>
                {rows}
            </tbody >
        </Table>;

    }


    getPricingContent() {



        if (this.state.priceLoading) {
            return <div className="text-center"><label>{t("application_strings.application.dialogs.finishEditJob.updatingPrice")}</label><AccentSpinner /></div>;
        }

        const userAllowCost = getAccess().features().FeatureAllowViewCostPrice();

        const me = this;
        const showQuote = this.quoted.SalesPriceInc !== this.editModel.Diff.OriginalSalePrice;
        var showCurrentQuote = this.quoted.SalesPriceInc !== this.quoted.FilteredSalesPriceInc;
        var showCurrentOriginal = this.editModel.Diff.FilteredOriginalSalePrice !== this.editModel.Diff.OriginalSalePrice;



        var marginForQuote = accentUtils.calculateMargin(this.quoted.SalesPriceInc, this.editModel.Diff.NewCost);
        var marginForCurrentQuote = accentUtils.calculateMargin(this.quoted.FilteredSalesPriceInc, this.editModel.Diff.NewCost);
        var marginForCurrentOriginal = accentUtils.calculateMargin(this.editModel.Diff.FilteredOriginalSalePrice, this.editModel.Diff.NewCost);

        var quoteVar = this.quoted.SalesPriceInc - this.editModel.Diff.OriginalSalePrice;
        var quoteMarginVar = marginForQuote - this.editModel.Diff.OriginalMargin;
        var currQuoteVar = this.quoted.FilteredSalesPriceInc - this.editModel.Diff.OriginalSalePrice;
        var currQuoteMarginVar = marginForCurrentQuote - this.editModel.Diff.OriginalMargin;
        var currPriceVar = this.editModel.Diff.NewSalePrice - this.editModel.Diff.OriginalSalePrice;
        var currPriceMarginVar = this.editModel.Diff.NewMargin - this.editModel.Diff.OriginalMargin;
        var currOriginalVar = this.editModel.Diff.FilteredOriginalSalePrice - this.editModel.Diff.OriginalSalePrice;
        var currOriginalMarginVar = marginForCurrentOriginal - this.editModel.Diff.OriginalMargin;


        var quoteVarClass = (quoteVar > 0) ? "diff-warn" : "diff-ok";
        var currQuoteVarClass = (currQuoteVar > 0) ? "diff-warn" : "diff-ok";
        var currPriceVarClass = (currPriceVar > 0) ? "diff-warn" : "diff-ok";


        var quoteMarginVarClass = (quoteMarginVar < 0) ? "diff-warn" : "diff-ok";
        var currQuoteMarginVarClass = (currQuoteMarginVar < 0) ? "diff-warn" : "diff-ok";
        var currPriceMarginVarClass = (currPriceMarginVar < 0) ? "diff-warn" : "diff-ok";
        var currOriginalPriceMarginVarClass = (currOriginalMarginVar < 0) ? "diff-warn" : "diff-ok";


        const currOriginalPriceVarClass = (currOriginalVar > 0) ? "diff-warn" : "diff-ok";
        return <div style={{ maxWidth: "700px" }} >            
            <Table className="table-sm table-borderless" >
                <thead>
                    <tr>
                        <th></th>
                        <th colSpan="2">{t("application_strings.application.dialogs.finishEditJob.salePriceInc")}</th>
                        <th colSpan="2">{t("application_strings.application.dialogs.finishEditJob.margin")}</th>
                    </tr>

                </thead>
                <tbody>
                    {!showQuote ? null :
                        <tr>
                            <td><AccentRadioUnboundCustom label="application_strings.application.dialogs.finishEditJob.matchPriceBeforeEdit" checked={me.model.priceActionType === jobEditPriceAction.BeforeEdit} name="priceAction" id="matchBeforeEdit" onChange={function () { me.onPriceActionChanged(jobEditPriceAction.BeforeEdit); }} /></td>
                            <td className="accentNumCol">{format.formatCurrency(this.editModel.Diff.OriginalSalePrice, true)}</td>
                            <td className="accentNumCol"></td>
                            <td className="accentNumCol">{userAllowCost ? format.formatPercentage(this.editModel.Diff.OriginalMargin, true) : ""}</td>
                            <td className="accentNumCol"></td>
                        </tr>
                    }
                    {!showCurrentOriginal ? null :
                        <tr>
                            <td><AccentRadioUnboundCustom label="application_strings.application.dialogs.finishEditJob.matchCurrentPriceBeforeEdit" checked={me.model.priceActionType === jobEditPriceAction.CurrentBeforeEdit} name="priceAction" id="matchCurrentBeforeEdit" onChange={function () { me.onPriceActionChanged(jobEditPriceAction.CurrentBeforeEdit); }} /></td>
                            <td className="accentNumCol">{format.formatCurrency(this.editModel.Diff.FilteredOriginalSalePrice, true)}</td>
                            <td className={"accentNumCol " + currOriginalPriceVarClass}>{format.formatCurrency(currOriginalVar, true)}<span className={(currOriginalVar > 0 ? "accentUp" + currOriginalPriceVarClass : currOriginalVar < 0 ? "accentDown " + currOriginalPriceVarClass : undefined)} /></td>
                            <td className="accentNumCol">{userAllowCost ? format.formatPercentage(marginForCurrentOriginal, true) : ""}</td>
                            <td className={"accentNumCol " + currOriginalPriceMarginVarClass}>{userAllowCost ? format.formatPercentage(currOriginalMarginVar, true) : ""}<span className={(currOriginalMarginVar > 0 ? "accentUp " + currOriginalPriceMarginVarClass : currOriginalMarginVar < 0 ? "accentDown " + currOriginalPriceMarginVarClass : undefined)} /></td>
                        </tr>
                    }
                    <tr>
                        <td><AccentRadioUnboundCustom label="application_strings.application.dialogs.finishEditJob.matchOriginalQuotePrice" checked={me.model.priceActionType === jobEditPriceAction.Quote} name="priceAction" id="matchQuote" onChange={function () { me.onPriceActionChanged(jobEditPriceAction.Quote); }} /></td>
                        <td className="accentNumCol">{format.formatCurrency(this.quoted.SalesPriceInc, true)}</td>
                        <td className={"accentNumCol " + quoteVarClass}>{format.formatCurrency(quoteVar, true)}<span className={(quoteVar > 0 ? "accentUp" + quoteVarClass : quoteVar < 0 ? "accentDown " + quoteVarClass : undefined)} /></td>
                        <td className="accentNumCol">{userAllowCost ? format.formatPercentage(marginForQuote, true) : ""}</td>
                        <td className={"accentNumCol " + quoteMarginVarClass}>{userAllowCost ? format.formatPercentage(quoteMarginVar, true) : ""}<span className={(quoteMarginVar > 0 ? "accentUp " + quoteMarginVarClass : quoteMarginVar < 0 ? "accentDown " + quoteMarginVarClass : undefined)} /></td>
                    </tr>
                    {!showCurrentQuote ? null :
                        <tr>
                            <td><AccentRadioUnboundCustom label={t("application_strings.application.dialogs.finishEditJob.matchCurrentQuotePrice")} checked={me.model.priceActionType === jobEditPriceAction.CurrentQuote} name="priceAction" id="matchQuoteCurrent" onChange={function () { me.onPriceActionChanged(jobEditPriceAction.CurrentQuote); }} /></td>
                            <td className="accentNumCol">{format.formatCurrency(this.quoted.FilteredSalesPriceInc, true)}</td>
                            <td className={"accentNumCol " + currQuoteVarClass}>{format.formatCurrency(currQuoteVar, true)}<span className={(currQuoteVar > 0 ? "accentUp " + currQuoteVarClass : currQuoteVar < 0 ? "accentDown " + currQuoteVarClass : undefined)} /></td>
                            <td className="accentNumCol">{userAllowCost ? format.formatPercentage(marginForCurrentQuote, true) : ""}</td>
                            <td className={"accentNumCol " + currQuoteMarginVarClass}>{userAllowCost ? format.formatPercentage(currQuoteMarginVar, true) : ""}<span className={(currQuoteMarginVar > 0 ? "accentUp " + currQuoteMarginVarClass : currQuoteMarginVar < 0 ? "accentDown " + currQuoteMarginVarClass : undefined)} /></td>
                        </tr>
                    }
                    <tr>
                        <td><AccentRadioUnboundCustom label="application_strings.application.dialogs.finishEditJob.useNewPrice" checked={me.model.priceActionType === jobEditPriceAction.NewPrice} name="priceAction" id="keepNewPrice" onChange={function () { me.onPriceActionChanged(jobEditPriceAction.NewPrice); }} /></td>
                        <td className="accentNumCol">{format.formatCurrency(this.editModel.Diff.NewSalePrice, true)}</td>
                        <td className={"accentNumCol " + currPriceVarClass}>{format.formatCurrency(currPriceVar, true)}<span className={(currPriceVar > 0 ? "accentUp " + currPriceVarClass : currPriceVar < 0 ? "accentDown " + currPriceVarClass : undefined)} /></td>
                        <td className="accentNumCol">{userAllowCost ? format.formatPercentage(this.editModel.Diff.NewMargin, true) : ""}</td>
                        <td className={"accentNumCol " + currPriceMarginVarClass}>{userAllowCost ? format.formatPercentage(currPriceMarginVar, true) : ""}<span className={(currPriceMarginVar > 0 ? "accentUp " + currPriceMarginVarClass : currPriceMarginVar < 0 ? "accentDown " + currPriceMarginVarClass : undefined)} /></td>
                    </tr>
                </tbody>
            </Table>
            
        </div>;
    }

    getReviewContent() {

        return <div style={{width:"100%", maxWidth:"1500px"}}>
            {this.state.priceLoading ? <div className="text-center"><label>{t("application_strings.application.dialogs.finishEditJob.updatingPrice")}</label><AccentSpinner /></div>                    
                    : <JobDiffCtrl diff={this.editModel.Diff} quoted={this.editModel.QuoteInfo} userModel={this.userModel} />}
            </div>;

    }

    getWarningsContent() {
        return <div style={{ width: "100%", maxWidth: "1500px" }}>
            <div className="alert alert-info" role="alert">{t("application_strings.application.dialogs.finishEditJob.affectsInfo")}</div>
            {this.getConflictTable()}
        </div>;
    }


    render() {

        const userAllowCost = getAccess().features().FeatureAllowViewCostPrice();

        var me = this;
        var allowNext = false;
        var overrideIndex = undefined;
        var pageIndex = this.props.pageIndex;

        if (pageIndex === 0 && this.skipPriceActionPage) {
            overrideIndex = 1;
            pageIndex = 1;

            this.props.onOverridePage(pageIndex);

        }



        if (this.state.step === 0) {
            allowNext = !accentUtils.isNull(this.model.priceActionType);
        } else if (pageIndex === 1) {
            allowNext = true;
        } else {
            allowNext = true;
        }

        


        



        const steps = [
            {
                label: t("application_strings.application.dialogs.finishEditJob.pricingTitle")
            },
            {
                label: t("application_strings.application.dialogs.finishEditJob.reviewTitle"),
                disabled: !allowNext
            },
            {
                label: t("application_strings.application.dialogs.finishEditJob.affectsTitle"),
                disabled: !allowNext
            }
        ];



        const content = (this.state.step === 0)
            ? this.getPricingContent()
            : (this.state.step === 1)
                ? this.getReviewContent()
                :this.getWarningsContent();

        return (
            <AccentDialog
                fullscreen
                headerContent={t("application_strings.application.dialogs.finishEditJob.title") }
                onClose={this.props.onClose}
                actionsContent={[
                    <AccentButton key="1" tagName="prevBtn" disabled={this.state.step === 0} onClick={e => this.onStepChanged({ ...e, value: this.state.step - 1 })}>{t("application_strings.application.buttons.previous")}</AccentButton>,
                    <AccentButton key="2" tagName="nextBtn" disabled={!allowNext} onClick={e => this.onStepChanged({ ...e, value: this.state.step + 1 })}>{t(`application_strings.application.buttons.${this.state.step === 2 ? "finish" : "next" }`)}</AccentButton>
                ]}
            >
                
                <Stepper value={this.state.step} onChange={this.onStepChanged} items={steps} />
                <hr />
                <div className="d-flex justify-content-around">
                    {content}
                </div>
            </AccentDialog>
        );
    }

}


