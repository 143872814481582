import React from 'react'
import { Row, Col } from 'reactstrap'
import AccentIcons from '../../icons/Icons';
import { accentUtils, t, from } from '../../services/HelperService'
import { AccentButton } from '../AccentButton';
import { filterOps } from './AdvancedFilterConstants';
import { InsyteQueryPredicateCtrl } from './InsyteQueryPredicateCtrl'


const groupLogic = {
    and: "and",
    or: "or",
    notand: "notand",
    notor: "notor"
};

export const InsyteQueryGroupHeaderCtrl = React.memo(props => {

    const [actionsVisible, setActionsVisible] = React.useState(true);

    const showActions = React.useCallback(e => {
        setActionsVisible(true);
    }, [setActionsVisible]);
    const hideActions = React.useCallback(e => {
        setActionsVisible(true);
    }, [setActionsVisible]);

    const toggleType = React.useCallback(e => {
        if (props.type === groupLogic.and) {
            props.onChange(groupLogic.or);
        } else if (props.type === groupLogic.or) {
            props.onChange(groupLogic.notand);
        } else if (props.type === groupLogic.notand) {
            props.onChange(groupLogic.notor);
        } else {
            props.onChange(groupLogic.and);
        }
        e.promise();

    }, [props]);

    const addPredicate = React.useCallback(e => {

        props.onAddPredicate();
        e.promise();

    }, [props]);

    const addGroup = React.useCallback(e => {
        props.onAddGroup();
        e.promise();

    }, [props]);

    const delGroup = React.useCallback(e => {
        props.onDeleteGroup();
        e.promise();

    }, [props]);


 


    return <Row className="acc-qry-grp-hdr">
        <Col md="12">
            <div className="d-flex justify-content-start" >
                <AccentButton tagName="toggleType" disabled={props.readOnly } bsStyle="link" outline={false} onClick={toggleType}>{t(`application_strings.views.filterView.${props.type}`)}</AccentButton>
                {actionsVisible && <AccentButton tagName="qToggleType" disabled={props.readOnly} bsStyle="link" outline={false} onClick={addPredicate} ><AccentIcons.New top="4" /></AccentButton>}
                {actionsVisible && <AccentButton tagName="qAddGrp" disabled={props.readOnly} bsStyle="link" outline={false} onClick={addGroup} >{t("application_strings.views.filterView.addGroup")}</AccentButton>}
                {actionsVisible && !props.disableDelete && <AccentButton tagName="qDelGrp" disabled={props.readOnly} bsStyle="link" outline={false} onClick={delGroup} ><AccentIcons.Delete top="5"/></AccentButton>}
            </div>
        </Col>
    </Row>;
});

export const InsyteQueryGroupBodyCtrl = React.memo(props => {
    return <Row className="acc-qry-grp-bdy">
        <InsyteQueryGroupItemsCtrl items={props.items} level={props.level} readOnly={props.readOnly} onItemChanged={props.onItemChanged} schema={props.schema} fieldStringBase={props.fieldStringBase}/>
    </Row>
});


export const InsyteQueryGroupCtrl = React.memo(props => {

    const type = props.item.logic;
    var items = props.item.filter;

    const onTypeChanged = React.useCallback(t => {

        const itm = props.item;

        itm.logic = t;


        props.onItemChanged();

    }, [props]);

    const addPredicate = React.useCallback(e => {
        props.item.filter.push({ field: "select_field", op: filterOps.eq, value : null });
        props.onItemChanged();

    }, [props]);

    const addGroup = React.useCallback(e => {
        props.item.filter.push({ logic: groupLogic.and, filter: [] });
        props.onItemChanged();
    }, [props]);

    const delGroup = React.useCallback(e => {
        props.onDelete();

    }, [props]);


    return <Row className={`acc-qry-grp ${props.last ? 'last' : 'normal'}`} style={{ paddingLeft: "$30px" }}>
        <Col md="12">
            <InsyteQueryGroupHeaderCtrl type={type} level={props.level} readOnly={props.readOnly} onChange={onTypeChanged} onAddPredicate={addPredicate} onAddGroup={addGroup} onDeleteGroup={delGroup} disableDelete={props.disableDelete} fieldStringBase={props.fieldStringBase} />
            <InsyteQueryGroupBodyCtrl key={type} items={items} level={props.level} readOnly={props.readOnly} onItemChanged={props.onItemChanged} schema={props.schema} fieldStringBase={props.fieldStringBase}/>
        </Col>
    </Row>;
});

export const InsyteQueryGroupItemsCtrl = React.memo(props => {


    const onDelete = React.useCallback(itm => {

        const arr = props.items;

        var itemIndex = from(arr).indexOf(i => i === itm);

        if (itemIndex >= 0) {
            arr.splice(itemIndex, 1);
            props.onItemChanged();
        }

    }, [props]);


    const last = from(props.items).lastOrDefault();

    var items = from(props.items.map((i, ix) => {

        const isLast = last === i;
        const isGroup = !accentUtils.isEmpty(i.logic);
        const isLastGroup = isLast && isGroup;


        if (isGroup) {
            return {
                isGroup: isGroup,
                ctrl: <InsyteQueryGroupCtrl key={ix} item={i} level={props.level + 1} readOnly={props.readOnly} onItemChanged={props.onItemChanged} onDelete={() => onDelete(i)} schema={props.schema} fieldStringBase={props.fieldStringBase} last={isLastGroup} />
            };
        } else {
            return {
                isGroup: isGroup,
                ctrl: <InsyteQueryPredicateCtrl key={ix} item={i} onDelete={() => onDelete(i)} readOnly={props.readOnly} onItemChanged={props.onItemChanged} schema={props.schema} fieldStringBase={props.fieldStringBase} last={isLastGroup} />
            };
        }

    })).orderBy(i => i.isGroup)
        .toArray();



    return <Col className="acc-qry-grp-itms" style={{ paddingLeft: "50px" }} md="12">
        {from(items).select(i => i.ctrl).toArray()}
    </Col>
});

