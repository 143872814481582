import $ from 'jquery'
import accentUtils from './AccentUtils'


export class FormHelper {

    

    getCalendarScheduleJobModel(scheduleJobMessage, onSheduleJob, additionalInfoCtrl, selectAllProducts) {
        return {
            scheduleJobMessage: scheduleJobMessage,
            onSheduleJob: onSheduleJob,
            additionalInfoCtrl: additionalInfoCtrl,
            selectAllProducts: selectAllProducts
        };
    }
    
    updateCreateCalendarAppt() {
        $(".k-scheduler-content td.calanderFirstSelect").removeClass('calanderFirstSelect');

        var first = formHelper.findFirstCalendarItem();

        if (!accentUtils.isNull(first)) {

            var scheduler = $(".k-scheduler").data("kendoScheduler");

            var selection = scheduler.select();

            if (selection.events.length == 0) {
                var header = $(".k-scheduler-header-wrap");
                var footer = $(".k-scheduler-footer");

                var f = $(first);

                if (footer.length == 0 || (f.offset().top + 5.0 >= header.offset().top + header.height() && f.offset().top <= footer.offset().top)) {
                    $("#calendarAddBtn").removeClass("hide");
                } else {
                    var fff = 999;
                }



                $("#calendarAddBtn").positionRelativeTo(f, 5, f.outerWidth() + 3);


                f.addClass('calanderFirstSelect');
            }
        }
    }

    hookCalendarSelect() {

        $(".k-scheduler-content").mousedown(function () {
            formHelper.clearCalendarAdd();
        });


        $(".k-scheduler-content").mouseup(function () {

            formHelper.updateCreateCalendarAppt();


        });

    }

    findFirstCalendarItem() {

        var first = null;

        var leftPos = 999999999999999;
        var topPos = 9999999999999999;

        $.each($(".k-scheduler-content .k-state-selected"), function (key, value) {
            var pos = $(value).position()

            if (pos.left < leftPos || pos.left == leftPos && pos.top < topPos) {
                leftPos = pos.left;
                topPos = pos.top;
                first = value;
            }
        });


        //$(first).css('background-color', 'red');
        return first;

    }



    getSendEmailModel(sessionID, type, title, toName, toEmail, from, replyTo, subject, body, copyMe, attachments, template, templateProcessor, documentTemplateID, onPreviewDocument, onGetEmailTemplateQuery, onGetDocumentTemplateQuery, defaultEmailTemplateAppSettingField, defaultDocumentTemplateAppSettingField) {


        if (accentUtils.isNull(attachments))
            attachments = [];

        var entity = {
            sessionID: sessionID,
            EmailType: type,
            To: [{
                Name: toName,
                Email: toEmail
            }],
            From: {
                From: from,
                ReplyTo: replyTo
            },
            Subject: subject,
            Body: body,
            CopyMe: copyMe,
            Attachments: attachments,
            TemplateID: !accentUtils.isNull(template) ? template.ID : null,
            Template: !accentUtils.isNull(template) ? template : null,
            DocumentTemplateID: documentTemplateID,
            ProcessTemplate: templateProcessor,
            setProperty(name, val) {
                this[name] = val;
            },
        };

        return {
            title: title,
            entity: entity,
            onPreviewDocument: onPreviewDocument,
            onGetDocumentTemplateQuery: onGetDocumentTemplateQuery,
            onGetEmailTemplateQuery: onGetEmailTemplateQuery,
            defaultDocumentTemplateAppSettingField: defaultDocumentTemplateAppSettingField,
            defaultEmailTemplateAppSettingField: defaultEmailTemplateAppSettingField
        };

    }

    isControlReadOnly(model) {


        var overridemodelreadonly = (formHelper.getFormCtrlOption(model, "overridemodelreadonly") === true);


        var readOnly = model.isReadOnly;

        if (!readOnly)
            readOnly = (formHelper.getFormCtrlOption(model, "readonly") === true);
        else {
            if (overridemodelreadonly)
                readOnly = false;
        }



        return readOnly;

    }


    isValid(parentSelector) {

        var invalidCtrls = $(parentSelector).find(".accentInvalidCtrl");
        var invalidUnboundCtrls = $(parentSelector).find(".accentComboUnbound.invalid");
        var invalidUnboundCtrls2 = $(parentSelector).find(".accentTextUnbound.invalid");
        var invalidUnboundCtrls3 = $(parentSelector).find(".accentTextAreaUnbound.invalid");
        var tokens = $(parentSelector).find(".accentErrorToken");


        return invalidCtrls.length === 0 && invalidUnboundCtrls.length === 0 && invalidUnboundCtrls2.length === 0 && tokens.length === 0 && invalidUnboundCtrls3.length === 0;
    }

    isModalValid() {
        var invalidCtrls = $(".modal-body").find(".accentInvalidCtrl");
        var invalidUnboundCtrls = $(".modal-body").find(".accentComboUnbound.invalid");
        var invalidUnboundCtrls2 = $(".modal-body").find(".accentTextUnbound.invalid");


        return invalidCtrls.length == 0 && invalidUnboundCtrls.length == 0 && invalidUnboundCtrls2.length == 0;
    }
    isWizardValid() {
        var invalidCtrls = $(".accentWizard").find(".accentInvalidCtrl");
        var invalidCtrls2 = $(".rtl-wizard").find(".accentInvalidCtrl");
        return invalidCtrls.length == 0 && invalidCtrls2.length == 0;
    }
    isViewValid() {
        return formHelper.isValid("#pageView");
    }
    showInvalidFields() {

        var invalidCtrls = $("#pageView").find(".accentInvalidCtrl");
        var headers = invalidCtrls.find(":hidden").parents('.accentSectionHeaderGroup').find('.accentSectionHeader');
        headers.find(".accentSectionHeaderClick").find("i.material-icons:contains('keyboard_arrow_down')").click();

    }
    isEmailValid() {

        var invalidCtrls = $(".accentEmail").find(".accentInvalidCtrl");
        return invalidCtrls.length == 0;
    }


    getRequiredValidation(value) {
        if (accentUtils.isEmpty(value))
            return formHelper.getValidation("Required", "Error");

        return null;
    }

    getValidation(msg, type) {
        return { Msg: msg, Type: type };
    }

    getErrorValidation(msg) {
        return formHelper.getValidation(msg, "Error");
    }

    getStandaloneCtrlModel(entity, notifyDirty) {

        entity.setProperty = function (n, v) {
            entity[n] = v;
        };

        return {
            entity: entity,
            setDirty: notifyDirty
        };

    }

    getFormCtrlOption(fieldModel, option) {
        if (accentUtils.isNull(fieldModel.options)) {
            fieldModel.options = {};
        }

        if (accentUtils.isNull(fieldModel.options[option])) {
            return "";
        }

        return fieldModel.options[option];
    }


    getFieldCtrlModelOptions(opts) {

        if (accentUtils.isNull(opts)) {
            opts = {};
        }

        var defaults = {
            label: null,
            labelLink: null,
            placeHolder: "",
            readOnly: false,
            displayField: "Description",
            valueField: "ID",
            source: [],
            sourceType: null,
            shouldUpdate: null,
            template: null,
            emptyValue: null,
            onChange: null,
            itemRender: undefined,
            tagRender: undefined,
            onDataRecieved: null,
            onFocusLost: null,
            allTools: false,
            defaultSingle: false,
            translate: false,
            caseInsensitive: false,
            selectAllOnInitialLoad: false,
            country: null,
            multiSelectRequiredIDs() { return [] },
            tagName: null,
            tagNamePrefix: null,
            preventIsDirty: false
        };

        return $.extend({}, defaults, opts);
    }

    getFieldCtrlModel(entity, field, type, required, opts) {
        var options = formHelper.getFieldCtrlModelOptions(opts);

        if (!(typeof required == 'function')) {

            if (required) {
                required = formHelper.getRequiredValidation;
            } else {
                required = null;
            }
        }


        if (type == "select2" && accentUtils.isNull(options.emptyValue)) {
            options.emptyValue = {};
            options.emptyValue[options.valueField] = null;
            options.emptyValue[options.displayField] = "";
        }


        if (type == "select" && accentUtils.isNull(options.emptyValue)) {
            options.emptyValue = {};
            options.emptyValue[options.valueField] = null;
            options.emptyValue[options.displayField] = "";
        }

        var res = {
            entity: entity,
            field: {
                fieldName: field,
                type: type,
                validate: required,
                options: {
                    tagName: (options.tagNamePrefix || "") + (options.tagName || field),
                    emptyValue: options.emptyValue,
                    template: options.template,
                    displayField: options.displayField,
                    defaultSingle: options.defaultSingle,
                    valueField: options.valueField,
                    source: options.source,
                    sourceType: options.sourceType,
                    readonly: options.readOnly,
                    pageSize: options.pageSize,
                    placeHolder: options.placeHolder,
                    comboAddNewValue: options.comboAddNewValue,
                    shouldUpdate: options.shouldUpdate,
                    overridemodelreadonly: false,
                    onChange: options.onChange,
                    itemRender: options.itemRender,
                    tagRender: options.tagRender,
                    onDataRecieved: options.onDataRecieved,
                    onFocusLost: options.onFocusLost,
                    htmlOption: options.htmlOption,
                    multiSelectRequiredIDs: options.multiSelectRequiredIDs,
                    selectAllOnInitialLoad: options.selectAllOnInitialLoad,
                    translate: options.translate,
                    caseInsensitive: options.caseInsensitive,
                    country: options.country,
                    preventIsDirty: options.preventIsDirty,
                    onContextMenu: options.onContextMenu,
                    rows: options.rows,
                    min: options.min,
                    max: options.max,
                    maxLength: options.maxLength
                }
            }
        };


        if (!accentUtils.isNull(options.label)) {
            res.field.label = options.label;
        }

        if (options.labelLink == null) {
            res.field.link = null;
        } else {
            res.field.link = {
                view: options.labelLink,
                id: entity[field]
            };
        }


        return res;
    }

    getFieldLabel(t, entityTypes, fieldName, abbreviated) {

        if (accentUtils.isNull(abbreviated)) {
            abbreviated = false;
        }

        if (entityTypes.length == 1 && entityTypes[0] === undefined) return fieldName;


        if (entityTypes.length == 1 && entityTypes[0].substring(0, 3) == "PL_") {
            entityTypes.push("BasePicklist");
        }


        var result = null;

        entityTypes.some(function (entityName) {
            var key = "";
            entityName = entityName.substring(0, 1).toLowerCase() + entityName.substring(1);

            if (abbreviated) {

                key = "application_strings.entities." + entityName + "." + fieldName + "_abbrev";

                if (t(key) != key) {
                    result = key;
                    return true;
                }
            }

            key = "application_strings.entities." + entityName + "." + fieldName;
            if (t(key) != key) {
                result = key;
                return true;
            }
        });


        if (accentUtils.isNull(result)) {
            result = "application_strings.entities." + entityTypes[0] + "." + fieldName;
        }

        return result;
    }

    getFilterType(format) {
        switch (format) {

            case "decimal": return "numeric";
            case "currency": return "numeric";
            case "check": return "boolean";
            case "date": return "date";
            case "numeric": return "numeric";
            default: return "text";

        }
    }

    getGridColumn(opts, index) {



        if (accentUtils.isNull(opts)) {
            opts = { fieldName: null };
        }

        var name = opts.fieldName;


        if (accentUtils.isNull(opts.encoded)) {
            opts.encoded = true;
        }

        if (accentUtils.isNull(opts.visible)) {
            opts.visible = true;
        }

        if (accentUtils.isNull(opts.requireTranslate)) {
            opts.requireTranslate = false;
        }


        var col = {
            field: name,
            title: opts.caption,
            encoded: opts.encoded,
            details: opts.details,
            visible: opts.visible,
            requireTranslate: opts.requireTranslate,
            filterType: formHelper.getFilterType(opts.fieldType),
            fieldType: opts.fieldType,
            locked: opts.locked,
            defaultWidth: opts.defaultWidth,
            class: opts.class
        };


        if (!accentUtils.isNull(opts.linkEntity)) {

            var linkField = '';

            if (index > 0) {
                linkField = opts.linkEntity;
            }

            col.linkEntity = opts.linkEntity;

            if (accentUtils.isNull(opts.linkEntityIDField)) {
                col.linkField = linkField + "ID";
            } else {
                col.linkField = opts.linkEntityIDField;
            }


        }

        if (!accentUtils.isNull(opts.errorField)) {
            col.errorField = opts.errorField;
        }


        if (!accentUtils.isNull(opts.template)) {
            col.template = opts.template;
        }

        if (!accentUtils.isNull(opts.aggregate)) {

            col.aggregates = [opts.aggregate];
            if (opts.fieldType == "currency") {
                col.footerTemplate = '<div style="text-align:right;">#:  kendo.toString(' + opts.aggregate + ', "c")  # </div>';
            }
            else {
                col.footerTemplate = "#=" + opts.aggregate + " #";
            }
        }

        return col;
    }

}



const formHelper = new FormHelper();

export default formHelper;


window.formHelper = formHelper;