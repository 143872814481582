import * as React from 'react';
import AccentIcons from '../../icons/Icons';
import { accentUtils } from '../../services/HelperService';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';
import { AccentNumberUnbound } from '../unbound/AccentNumberUnbound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { onSizeChanged, fromInt, toDecimal, useSizeChanged, configEvents, configEventControlType } from './ProductConfiguratorShared';




export const ConfigSizeSingle = props => {

    const ctx = React.useContext(ConfiguratorContext);
    const orderLine = ctx.config.OrderLine;

    const sizeChanged = useSizeChanged(accentUtils.getEmptyGuid(), `ConfigSizeSingle_${props.width}`);

    const fireChange = React.useMemo(() => onSizeChanged(), [ctx.productID]);
    


    const onChange = React.useCallback(e => {

        if (props.width) {
            orderLine.Width = toDecimal( e ?? 0);
        } else {
            orderLine.Height = toDecimal( e ?? 0);
        }

        fireChange(ctx).then(() => {
            ctx.config?.Publish(configEvents.controlLostFocus, configEventControlType.singleSize);
        });

        

    }, [ctx, orderLine, props, fireChange]);


    const onFocus = React.useCallback(e => {

        ctx.config?.Publish(configEvents.controlGotFocus, configEventControlType.singleSize);

    }, [ctx]);

    const notifyDirty = React.useCallback(e => {

        if (ctx.notifyDirty) {
            ctx.notifyDirty();
        }

    }, [ctx]);

    const value = props.width ? fromInt(orderLine.Width) : fromInt(orderLine.Height);

    const key = props.isMulti ? sizeChanged.changeID : 0;

    return <div className="config-size-single d-flex">
        <div>
            <AccentNumberUnbound key={key} defaultValue={value} onBlur={onChange} onChange={notifyDirty} onFocus={ onFocus } required disabled={props.readOnly} min={0} invalid={accentUtils.isEmpty(value)} debounceMilliseconds={ 50} />
        </div>
    </div>;
};