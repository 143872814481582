import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import * as React from 'react'
import { AccentButton } from '../controls/AccentButton';
import { isMobileOnly, useAccentDisplay } from '../controls/AccentDisplay';
import AccentIcons from '../icons/Icons';
import { accentUtils, t } from '../services/HelperService';


const getMin = (x, y) => {

    if (accentUtils.isNull(y)) {
        return x;
    }

    return Math.min(x, y);

}
const getMax = (x, y) => {

    if (accentUtils.isNull(y)) {
        return x;
    }

    return Math.max(x, y);

}

function getAutoSize(screen, maxWidth) {

    if (accentUtils.isNull(maxWidth)) {
        maxWidth = screen;
    }

    if (screen >= 1440) {
        return Math.min(maxWidth, screen - 800);
    }


    if (screen >= 1024) {
        return Math.min(maxWidth, screen - 300);
    }

    if (screen >= 768) {
        return Math.min(maxWidth, screen - 100);
    }

    return screen + 50;
}


function getDefaultActionLayout(width) {

    if (width >= 450) return "end";

    return undefined;

}


export const AccentDialog = React.memo(props => {


    const display = useAccentDisplay();

    const title = accentUtils.isNull(props.headerContent) ? undefined : props.headerContent;

    const hasActions = !accentUtils.isNull(props.actionsContent);

    let actions = hasActions ? [...props.actionsContent] : [];


    const onCancel = React.useCallback(e => {

        if (props.onClose) {
            props.onClose({ canceled: true });
        }

        if (e.promise) e.promise();

    }, [props]);

    
    if (hasActions && !props.noCancel) {
        if (isMobileOnly) {
            actions.unshift(<AccentButton key="dlgCancel" tagName="dlgCancel" className="me-auto" onClick={onCancel}>{<AccentIcons.Back style={{fontSize:"15px"}} top="1"  noPadding/>}</AccentButton>);
        } else {
            actions.unshift(<AccentButton key="dlgCancel" tagName="dlgCancel" className="me-auto" onClick={onCancel}>{t("application_strings.application.buttons.cancel")}</AccentButton>);
        }
    }

    

    const minWidthAdjusted = accentUtils.isNull(props.minWidth) ? getAutoSize(display.width, props.maxWidth) : props.minWidth;

    const isFullScreen = props.fullscreen || display.width < minWidthAdjusted;


    const minWidth = isFullScreen ? undefined : minWidthAdjusted;
    const minHeight = isFullScreen ? undefined : props.minHeight;

    const width = isFullScreen  ? display.width : undefined;
    const height = isFullScreen ? display.height : undefined;

    


    return <Dialog
        id={props.id}
        className={`accentDlg ${props.className} ${isFullScreen ? "acc-fullscreen-dlg" : ""}`}
        title={title}
        minWidth={minWidth}
        minHeight={minHeight}
        width={width}
        height={height}
        onClose={onCancel}
        closeIcon={!props.noCancel}        
    >
        { props.children}
        {hasActions && <DialogActionsBar layout={accentUtils.isNull(props.actionLayout) ? getDefaultActionLayout(isFullScreen ? display.width : minWidth) : props.actionLayout}>{actions}</DialogActionsBar>}
    </Dialog>

});