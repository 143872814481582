import * as React from 'react'
import { Col, Row } from 'reactstrap';
import { AccentToolbar } from '../controls/AccentToolbar';
import { clearEntities, saveEntities } from '../services/DataService';
import { accentUtils, formHelper, goBack, goTo, setToolbarActionState, showOK, showWait, t, toolbarActionStates } from '../services/HelperService';



class ViewToolbar extends React.Component {

    constructor(props) {

        super(props);



        this.delayAmount = 500;



        this.state = {
            hide: false
        };

        this.setDirty = this.setDirty.bind(this);
        this.setUnchangedState = this.setUnchangedState.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
        this.closeClicked = this.closeClicked.bind(this);
        this.undoClicked = this.undoClicked.bind(this);
        this.redrawDelayed = this.redrawDelayed.bind(this);
        this.redraw = this.redraw.bind(this);
        this.toolBarItemClicked = this.toolBarItemClicked.bind(this);
        this.getToolbarItems = this.getToolbarItems.bind(this);
    }

    setDirty() {
        this.redrawDelayed();

    }

    setUnchangedState() {
        this.setState({});
    }

    show() {
        this.setState({ hide: false });
    }

    hide() {
        this.setState({ hide: true });
    }



    saveClicked() {

        setToolbarActionState(toolbarActionStates.saving);

        const done = showWait();

        
        this.props.saveView({ refreshOnSaved : true, navigating: false}).then(r => {

            done();

            


        }).catch(() => {
            done();
        });
    }


    closeClicked() {
        setToolbarActionState(toolbarActionStates.closing);
        goBack();
    }

    undoClicked() {
        setToolbarActionState(toolbarActionStates.undoing);

        this.props.refresh();        
    }


    redrawDelayed() {
        var me = this;

        window.setTimeout(function () {
            me.setState({});
        }, me.delayAmount);
    }


    redraw() {
        this.setState({});
    }

    toolBarItemClicked(e) {


        var args = {
            id: e.id,
            handled: false
        };

        this.props.toolbarItemClicked(args);


        if (args.handled)
            return;

        if (args.id === "entityToolbarUndoBtn" || args.id === "entityToolbarUndoBtn_Top") {
            this.undoClicked();
        } else if (args.id === "entityToolbarCloseBtn" || args.id === "entityToolbarCloseBtn_Top") {
            this.closeClicked();
        } else if (args.id === "entityToolbarSaveBtn" || args.id === "entityToolbarSaveBtn_Top") {
            this.saveClicked();
        } else {

        }
        console.log("toolbar clicked");
    }

    getToolbarItems() {

        var tagType = (this.props.top) ? "_Top" : "";

        var viewActions = this.props.toolbarActions(this.props.top, (this.props.top) ? "actionsBtn_Top" : "actionsBtn");

        if (viewActions == null)
            return null;


        let res = [
            { type: 'button', text: this.props.display.mobile ? "" : t("application_strings.application.buttons.save"), id: "entityToolbarSaveBtn" + tagType, enable: this.props.viewState.isDirty, icon: "save", overflow: "never" },
            { type: 'button', text: this.props.display.mobile ? "" : t("application_strings.application.buttons.close"), id: "entityToolbarCloseBtn" + tagType, enable: true, icon: "close", overflow: "never" },
            { type: 'button', text: this.props.display.mobile ? "" : t("application_strings.application.buttons.undo"), id: "entityToolbarUndoBtn" + tagType, enable: this.props.viewState.isDirty, icon: "undo", overflow: "never" },
            ];
        




        if (!accentUtils.isNull(viewActions)) {


            if (viewActions.length > 0) {
                var activeOnlyItems = [];

                viewActions.forEach(function (i) {
                    if (!accentUtils.isNull(i.enable) && i.enable) {
                        activeOnlyItems.push(i);
                    }

                    if (i.type == 'separator') {
                        if (activeOnlyItems.length > 0) {
                            if (activeOnlyItems[activeOnlyItems.length - 1].type != 'separator') {
                                activeOnlyItems.push(i);
                            }
                        }
                    }


                });

                if (activeOnlyItems.length > 0 && activeOnlyItems[activeOnlyItems.length - 1].type == 'separator') {
                    activeOnlyItems.splice(-1, 1);
                }

                viewActions = activeOnlyItems;
            }




            if (viewActions.length > 0)
                res.push({ type: 'separator' });
            res = res.concat(viewActions);
        }



        return res;
    }

    render() {


        const hideOther = this.props.display.mobile;

        const toolbarclass = "float-right accentViewToolBar";


        const tagType = (this.props.top) ? "_Top" : "";
        
        return (<div>
            {!this.state.hide && <AccentToolbar
                className={toolbarclass}
                getToolbarItems={this.getToolbarItems}
                onClick={this.toolBarItemClicked}
                toolBarName={"entityToolbar" + tagType}
                hideAll={hideOther}
                display={ this.props.display}
            />}
        </div>
        );
    }
}



export class ViewWithToolbars extends React.Component {

    constructor(props) {
        super(props);

        this.toolBar = React.createRef();
        this.toolBarBottom = React.createRef();
        


        this.toolbarItemClicked = this.toolbarItemClicked.bind(this);
        this.getToolbarActions = this.getToolbarActions.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.refreshToolbar = this.refreshToolbar.bind(this);
    }

    componentDidUpdate() {
        this.refreshToolbar();
    }
    componentDidMount() {
        this.refreshToolbar();
    }
    refreshToolbar() {
        var me = this;
        window.setTimeout(function () {

            if (!accentUtils.isNull(me.toolBar.current))
                me.toolBar.current.redraw();
            if (!accentUtils.isNull(me.toolBarBottom.current))
                me.toolBarBottom.current.redraw();

        }, 350);

    }

    toolbarItemClicked(e) {

        if (accentUtils.isNull(this.props.viewRef.current)) {
            return null;
        }

        if (!accentUtils.isNull(this.props.viewRef.current) && !accentUtils.isNull(this.props.viewRef.current.toolbarItemClicked))
            return this.props.viewRef.current.toolbarItemClicked(e);

    }

    getToolbarActions(isTop, actionsTagName) {

        if (accentUtils.isNull(this.props.viewRef.current)) {
            return null;
        }

        if (!accentUtils.isNull(this.props.viewRef.current) && !accentUtils.isNull(this.props.viewRef.current.getToolbarActions))
            return this.props.viewRef.current.getToolbarActions(isTop, actionsTagName);
        return [];
    }

    
    

    render() {


        var viewClassName = undefined;

        if (this.props.viewState.isReadOnly) {
            viewClassName = "acc-readonly-view";
        }
        
        return (
            <Col md={12}>
                <ViewToolbar
                    ref={this.toolBar}
                    top={true}
                    toolbarActions={this.getToolbarActions}
                    toolbarItemClicked={this.toolbarItemClicked}
                    refresh={this.props.refresh}
                    viewState={this.props.viewState}
                    display={this.props.display}
                    navigate={this.props.navigate}
                    saveView={this.props.saveView }
                 />
                <Row className={viewClassName} style={{ paddingTop: "10px", paddingBottom: "10px" }} >
                    {this.props.view }
                </Row>
                <ViewToolbar
                    ref={this.toolBarBottom}
                    top={false}
                    toolbarActions={this.getToolbarActions}
                    toolbarItemClicked={this.toolbarItemClicked}
                    refresh={this.props.refresh}
                    viewState={this.props.viewState}
                    display={this.props.display}
                    navigate={this.props.navigate}
                    saveView={this.props.saveView}
                />
            </Col>
        );
    }
}
