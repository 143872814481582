import * as React from 'react';
import { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { suspend, clear, peek } from 'suspend-react'
import { accentUtils } from '../../services/HelperService';
import { getProductConfig, loadProductModel } from '../../services/ProductService';
import { AccentSpinner } from '../AccentSpinner';
import { ConfiguratorContextProvider } from './ConfigContextProvider';
import { saveOrderLineOptions} from './ProductConfiguratorShared';
import { TableConfiguratorCtrl } from './TableConfiguratorCtrl';
import { VerticalConfiguratorCtrl } from './VerticalConfiguratorCtrl';


export const ProductConfigurator = React.memo(props => {

    const provider = React.useRef(null);
    

    React.useImperativeHandle(
        props.methods,
        () => ({
            save: () => {
                provider.current.save();
            },
            isInProgress: () => {
                return provider.current?.isInProgress()??false;
            }
        }),
        [props, provider]
    );


    const onSaveOLOs = React.useCallback(e => {
        saveOrderLineOptions(props.orderLine, e);
    }, [props]);


    if (accentUtils.isEmpty(props.orderLine.ProductID)) return <div></div>;


    return <ConfiguratorContextProvider
        methods={provider}
        productID={props.productID}
        orderLine={props.orderLine}
        orderLineOptions={JSON.parse(props.orderLine.OrderLineOptionsData)}
        businessUnitID={props.businessUnitID}
        mpResellerKeys={props.mpResellerKeys}
        isQuote={props.isQuote}
        ignoreSizes={props.ignoreSizes}
        isReadOnly={props.isReadOnly}
        onChange={props.onChange}
        onSave={onSaveOLOs}
        onLoaded={props.onLoaded}
        filter={props.filter}
        notifyDirty={ props.notifyDirty}
    >
        <div>
            <VerticalConfiguratorCtrl                
                hideSize={props.hideSize}
                hideExtras={props.hideExtras}
            />
        </div>
    </ConfiguratorContextProvider>;


});


export const TableConfigurator = React.memo(props => {

    const ref = React.useRef();
    const [loading, setLoading] = React.useState(true);
    const [config, setConfig] = React.useState(null);

    React.useImperativeHandle(
        props.methods,
        () => ({
            save: () => {
                if (accentUtils.isNull(ref.current)) return;

                ref.current.save();
            },
            isInProgress: () => {
                return ref.current?.isInProgress() ?? false;
            }
        }),
        [props, ref]
    );


    React.useEffect(() => {
        loadProductModel(props.productID).then(c => {
            setConfig(c);
            setLoading(false);
        });
    }, []);


    if (loading) return <AccentSpinner />;

    return <TableConfiguratorCtrl
        methods={ref}
        productID={props.productID}
        onChange={props.onChange}
        notifyDirty={props.notifyDirty}
        onLoaded={props.onLoaded}
        lines={props.lines}
        isQuote={props.isQuote}
        ignoreSizes={props.ignoreSizes}
        isReadOnly={props.isReadOnly}
        isLineReadOnly={props.isLineReadOnly}
        businessUnitID={props.businessUnitID}
        mpResellerKeys={props.mpResellerKeys}
        preGroupHeaders={props.preGroupHeaders}
        postGroupHeaders={props.postGroupHeaders}
        preOptionHeaders={props.preOptionHeaders}
        postOptionHeaders={props.postOptionHeaders}
        getPreColumns={props.getPreColumns}
        getPostColumns={props.getPostColumns}
        config={config}
        configFilter={props.configFilter}
    />;


});
