export const actionIDs = {
    bookService: "bookService",
    bookServiceMaps: "bookServiceMaps",
    bookCM: "bookCM",
    bookCMMaps: "bookCMMaps",
    bookInst: "bookInst",
    bookInstMaps: "bookInstMaps",
    moveToSupply: "moveToSupply",
    resendToSupply: "resendToSupply",
    alterTags: "alterTags",
    updateCommitReason: "updateCommitReason",
    updateCommissionDate: "updateCommissionDate",
    updateETA: "updateETA",
    goodsReceived: "goodsReceived",
    closeJob: "closeJob",
    cancelJob: "cancelJob",
    invoiceJob: "invoiceJob",
    invoiceJobGenerate: "invoiceJobGenerate",
    editJob: "editJob",
    finishEditJob: "finishEditJob",
    newPayment: "newPayment",
    sendOrderConfirmation: "sendOrderConfirmation",
    printOrderConfirmation: "printOrderConfirmation",
    printInstallJobSheet: "printInstallJobSheet",
    showInstallSummary: "showInstallSummary",
    markAsDispatched: "markAsDispatched"

};


export const trackingViews = {
    quoting_active: 'quoting_active',
    quoting_open: 'quoting_open',
    quoting_sent: 'quoting_sent',
    quoting_expired: 'quoting_expired',
    quoting_lost: 'quoting_lost',
    jobs_active: 'jobs_active',
    jobs_all_orders: 'jobs_all_orders',
    opps_lead: 'opps_lead',
    opps_quotation: 'opps_quotation',
    opps_negotiation: 'opps_negotiation',
    opps_won: 'opps_won',
    opps_lost: 'opps_lost',
    projects_all: 'projects_all',
    mf_scheduler: 'mf_scheduler',
    service_pending_approval: 'service_pending_approval',
    service_sent: 'service_sent',
    service_approved: 'service_approved',
    service_expired: 'service_expired',

}