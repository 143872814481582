import { queryNoTracking } from '../../services/DataService';
import { accentUtils } from '../../services/HelperService';

export const filterOps = {
    eq: "eq",
    ne: "ne",
    ge: "ge",
    le: "le",
    gt: "gt",
    lt: "lt",
    in: "in",
    notin: "notin",
    startswith: "startswith",
    endswith: "endswith",
    isempty: "isempty",
    isnotempty: "isnotempty",
    within: "within",
    relative: "relative"
};

export const filterTypes = {
    text: "text",
    numeric: "numeric",
    date: "date",
    check: "check",
    select: "select",
    select_large: "select_large",
};



export const getQueryTypeDefault = type => {
    if (type === filterTypes.check) {
        return true;
    }

    if (type === filterTypes.date) {
        return null;
    }

    if (type === filterTypes.numeric) {
        return 0;
    }

    if (type === filterTypes.select) {
        return [];
    }

    if (type === filterTypes.relative) {
        return [0, 0];
    }

    return '';

};




export const schema_SearchObj = (obj, searchValue) => {


    const keys = Object.keys(obj);

    for (var j = 0; j < keys.length; j++) {

        const value = obj[keys[j]];


        if (Array.isArray(value)) {

            for (var i = 0; i < value.length; i++) {
                var res = schema_SearchObj(value[i], searchValue);

                if (res)
                    return res;
            }

        } else if (typeof value === 'object') {
            if (!accentUtils.isNull(value)) {
                var res2 = schema_SearchObj(value, searchValue);

                if (res2)
                    return res2;

            }
        }

        if (value === searchValue) {
            return obj;
        }

    }

}

export const findSchemaItem = (schema, query) => {


    for (var i = 0; i < schema.Entities.length; i++) {

        var schemaObj = schema.Entities[i];

        var res = schema_SearchObj(schemaObj, query);

        if (res)
            return res;

    }


};

export const changeFilterPredicateValue = (item, value) => {

    var itemInfo = parseFilterPredicate(item);

    item.value = value;

    //right[itemInfo.op] = value;

};



export const parseFilterPredicate = item => {

    const field = item.field;
    var op = item.op;
    var value = item.value;


    const isEmpty = op === filterOps.eq && accentUtils.isNull(value);
    const isNotEmpty = op === filterOps.ne && accentUtils.isNull(value);

    if (isEmpty) {
        op = filterOps.isempty;
    }
    if (isNotEmpty) {
        op = filterOps.isnotempty;
    }


    return {
        field: field,
        op: op,
        arg: value
    };

};


class AdvancedFilterCache {

    constructor() {

        this.filterCache = {};
        this.quickFilterCache = {};
        this.query_AdvancedQueries_Filters_SelectList = queryNoTracking('AdvancedQueries_Filters_SelectList');
        this.query_QuickFilters_SelectList = queryNoTracking("QuickFilters_SelectList");
        this.getFilters = this.getFilters.bind(this);
        this.getQuickFilters = this.getQuickFilters.bind(this);
        this.clear = this.clear.bind(this);
    }


    clear() {
        this.filterCache = {};
        this.quickFilterCache = {};
    }

    async getQuickFilters(entityType) {

        if (accentUtils.isNull(this.quickFilterCache[entityType])) {

            this.quickFilterCache[entityType] = await this.query_QuickFilters_SelectList.getAll({ entityType: entityType });

        }

        return this.quickFilterCache[entityType];

    }

    async getFilters(entityType) {

        if (accentUtils.isNull(this.filterCache[entityType])) {

            this.filterCache[entityType] = await this.query_AdvancedQueries_Filters_SelectList.getAll({ entityType: entityType });

        }

        return this.filterCache[entityType];
    }

}

export const advancedFilterCache = new AdvancedFilterCache();
