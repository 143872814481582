import * as React from 'react'
import { Row, Col } from 'reactstrap'

import { showDialog, showOK, t} from './../services/HelperService'

import { AccentNumberUnbound } from './../controls/unbound/AccentNumberUnbound'
import { AccentButton, LongClickBtn } from './../controls/AccentButton'
import { NumberOfCopiesInputCtrl } from './../controls/NumberOfCopiesInputCtrl'
import { RTLGridRowDeleteAction } from './../controls/AccentButton'

import AccentIcons from './../icons/Icons'
import { update } from '../services/DataService'
import { AccentDialog } from './AccentDialog'




export class SplitLineDlg extends React.Component { 


    static Show(jobLineID, qty) {

        return showDialog(<SplitLineDlg
                jobLineID={jobLineID}
                qty={qty}
                />);        
    }

    constructor(props) {
        super(props)


        this.lineKeys = {};
        this.lineKeys[String(0)] = 1;

        this.state = { lines: [this.props.qty] };


        this.getBody = this.getBody.bind(this);
        this.onClose = this.onClose.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onQtyChanged = this.onQtyChanged.bind(this);
        this.onAddLine = this.onAddLine.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }



    componentDidMount() {

    }

    
    


    onClose(e) {
        const s = this.state;

        update("JobLine_Split", { ID: this.props.jobLineID, Qtys: s.lines }).then(r => {

            this.props.onClose({
                ...e,
            });


        });


    }

    onAddLine(multi) {

        const me = this;
        const originalQty = this.state.lines[0];

        const linesBefore = this.state.lines;

        const ss = e => {
            this.setState({ lines: e });
        };

        if (multi) {

            var noOfCopies = 1;

            showOK("application_strings.views.splitLine.noOfLines", < NumberOfCopiesInputCtrl defaultValue={noOfCopies} label="application_strings.views.splitLine.noOfLines" max={originalQty - 1} onChange={v => noOfCopies = v} />, ["application_strings.application.buttons.ok"]).then(function (res) {

                if (res == "application_strings.application.buttons.ok") {

                    const lines = [...linesBefore, ...Array.from({ length: noOfCopies }, (_, i) => 1)];
                    lines[0] -= noOfCopies;
                    me.lineKeys[String(0)]++;
                    ss(lines);

                }
            });
        } else {

            const lines = [...linesBefore, 1];
            lines[0]--;
            me.lineKeys[String(0)]++;
            ss(lines);
        }


    }

    onQtyChanged(e, index) {

        const lines = [...this.state.lines];


        const diff = lines[index] - e;

        lines[index] = e;
        lines[0] += diff;

        this.lineKeys[String(0)]++;


        this.setState({ lines: lines });

    }

    onDelete(e) {

        const lines = [...this.state.lines];


        const diff = lines[e];

        lines[0] += diff;

        this.lineKeys[String(0)]++;

        lines.splice(e, 1);

        this.setState({ lines: lines });

    }


    getBody() {

        const me = this;

        const s = this.state;

        const lines = s.lines;

        const orignalLine = lines[0];

        const items = lines.map((l, i) => {

            const lineDesc = i == 0 ? t("application_strings.views.splitLine.originalLine") : t("application_strings.views.splitLine.newLine", { num: i });

            const qty = <AccentNumberUnbound key={`k_${i}_${me.lineKeys[String(i)]}`} disabled={i == 0} defaultValue={l} onChange={(e) => me.onQtyChanged(e, i)} min={1} max={l + orignalLine - 1} />;
            const btn = i == 0 ? (l === 1 ? null : <LongClickBtn onClick={this.onAddLine} icon={AccentIcons.New} />) : <RTLGridRowDeleteAction onDelete={me.onDelete} data={i} />;
            return <Row>
                <Col xs={6}>{lineDesc}</Col>
                <Col xs={4}>{qty}</Col>
                <Col xs={2}>{btn}</Col>
            </Row>;
        });


        return <Row >
            <Col md={12} style={{ minHeight: "500px" }}>
                {items}
            </Col>
        </Row>;
    }



    render() {



        return <AccentDialog
            headerContent={t("application_strings.views.splitLine.title")}
            onClose={this.props.onClose}
            maxWidth={600}            
            actionsContent={[
                <AccentButton key="2" tagName="filterImpPkBtn" onClick={this.onClose}>
                    {t("application_strings.application.buttons.save")}
                </AccentButton>
            ]}
        >
            { this.getBody()}
        </AccentDialog>


    }
}