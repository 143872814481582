import React from 'react'
import { NumericTextBox } from '@progress/kendo-react-all'
import { accentUtils, t, formHelper } from '../../services/HelperService'

export class AccentDecimal extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();
        this.focused = false;

        this.currentUserValue = this.props.model.getValue();

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.isFocused = this.isFocused.bind(this);
        this.clearValue = this.clearValue.bind(this);
    }


    //kendo bug event not working
    handleBlur(e) {


        //var value = this.ctrl.current.value;
        //this.props.model.setValue(value);

        //if (!accentUtils.isNull(this.props.model.options.onChange)) {
        //    this.props.model.options.onChange();
        //}


        this.focused = false;
    }

    clearValue() {
        if (!accentUtils.isNull(this.ctrl.current)) {
            // total hack!!!
            this.ctrl.current.value = 0;
            //this.ctrl.current.forceUpdate();
        }
        this.props.model.setValue(0);
        this.forceUpdate();

    }

    handleChange(e) {

        var value = this.ctrl.current.value;

        this.currentUserValue = value;


        this.props.model.setValue(value);

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange();
        }


        this.props.model.modified();
    }

    handleFocus(e) {
        this.focused = true;
    }

    isFocused() {
        if (accentUtils.isNull(this.focused)) {
            this.focused = false;
        }
        return this.focused;
    }


    render() {
        var disabled = formHelper.isControlReadOnly(this.props.model);

        var currValue = undefined;


        var modelValue = this.props.model.getValue();


        return (
            <NumericTextBox ref={this.ctrl} format="n2" value={modelValue} disabled={disabled} label={this.props.model.displayLabel} onBlur={this.handleBlur} onFocus={this.handleFocus} onChange={this.handleChange} defaultValue={this.props.model.getValue()} />
        );


    }
}
