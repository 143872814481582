import classNames from 'classnames';
import * as React from 'react'
import { AccentPopup } from '../../dialogs/AccentPopup';
import { accentUtils , t, format, from} from '../../services/HelperService';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';
import { AccentTextUnbound } from '../unbound/AccentTextUnBound';
import { CurrencyCellTemplate, EntityLinkCellTemplate, HtmlCellTemplate, TranslateCellTemplate } from './GridColumnTemplates';
import { RTLGridContext } from './RTLGridContext';


export function onTemplateCellClick(props, e) {
    if (props.selectionChange) {
        props.selectionChange({ ...e, syntheticEvent: { shiftWas: e?.shiftKey ?? false, ctrlWas: e?.ctrlKey ?? false } });
    }
}



class GridCell extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
     
    }

    onClick(e) {


        onTemplateCellClick(this.props, e);

        if (this.props.onRowClick) {
            this.props.onRowClick(this.props);
        }
    }

    render() {

        let result = "";

        if (accentUtils.isNull(this.props.template)) {

            var value = this.props.value;



            if (accentUtils.isEmpty(value))
                value = "";

            

            if (this.props.fieldType === "date") {
                value = format.formatDate(value);
            }
            value = value.toString();

            if (this.props.requireTranslate || this.props.fieldType === "check") {
                value = <TranslateCellTemplate value={value} />;
            } else if (this.props.fieldType === "currency") {
                value = <CurrencyCellTemplate value={value} />;
            } else if (this.props.isLink) {
                value = <EntityLinkCellTemplate                    
                    value={ this.props.value}
                    linkEntity={this.props.linkEntity}
                    linkID={this.props.linkID}
                />;
            } else if (this.props.encoded) {
                value = <HtmlCellTemplate value={this.props.value} field={this.props.field} />;
            }




            result = value;
        } else {
            const tt = 4;


            result = this.props.template(this.props);
            //result = <this.props.template {...this.props} />

        }


        const className = classNames(
            this.props.className,
            {
                "k-state-selected": this.props.isSelected
            });



        return <td className={className} data-grid-col-index={this.props.columnIndex} style={this.props.style} onClick={this.onClick}>
            {result}
        </td>
    }

}

export const RTLGridCellRender = (cell, props, cols, updateDataItem, onRowClick, actionTemplate) => {


    const isSelectCol = props.field === "selected";
    const isExpandCol = props.field === "expanded"



    const col = from(cols).firstOrDefault(c => c.field === props.field);

    if (isSelectCol || isExpandCol) return cell;

    if (props.field === "action") {

        return <GridCell
            field={props.field}
            dataItem={props.dataItem}
            //selectionChange={props.selectionChange}
            template={actionTemplate}
            //updateDataItem={updateDataItem}
            //onRowClick={onRowClick}
        />;
    }





    return <GridCell
        className={ cell.props.className}
        style={ props.style}
        field={ props.field}
        dataItem={ props.dataItem}
        selectionChange={props.selectionChange}
        encoded={col?.encoded}
        value={ props.dataItem[props.field]}
        filterType={col?.filterType}
        fieldType={col?.fieldType}
        isLink={col?.isLink}
        linkID={props.dataItem[col?.linkField]}
        linkField={col?.linkField}
        linkEntity={col?.linkEntity}
        template={col?.cellTemplate}
        requireTranslate={col?.requireTranslate}
        updateDataItem={updateDataItem}
        onRowClick={onRowClick} />;

};



export const RTLGridHeaderCellRender = ({ cell, props }) => {

    
    const ctrl = React.useRef();
    const [show, setShow] = React.useState(false);



    
    if (!props.columnMenuWrapperProps.columnMenu) return cell;
     
    return (
        <span className="k-cell-inner" >
            <span className="k-link" onClick={props.onClick}>
                <span className="k-column-title" title={props.title}>{props.title}</span>
                { props.children}
            </span>
            <div className="k-grid-column-menu k-grid-filter">
                <span className="k-icon k-i-more-vertical" style={{ marginLeft: "15px" }} ref={ctrl} onClick={e=> setShow(s=> !s) }></span>
                <AccentPopup
                    show={show}
                    anchor={ctrl}
                    placement="bottom"
                >
                    <props.columnMenuWrapperProps.columnMenu {...props.columnMenuWrapperProps} onCloseMenu={e=> setShow(false) } />
                </AccentPopup>
            </div>
        </span>
    );
};



export const RTLGridRowRender = (row , dataItem, onOpen) => {

    const trProps = {
        ...row.props,
        onContextMenu: (e) => {

            const type = e.target?.tagName?.toLowerCase();
            const isLinkDiv = e.target?.tagName?.toLowerCase() === "div" && e.target?.classList?.contains("acc-link");

            if (type !== 'a' && !isLinkDiv) {
                e.preventDefault();
                onOpen(e, dataItem.dataItem);
            }            
        },
    };
    return React.cloneElement(
        row,
        { ...trProps },
        row.props.children
    );

};


