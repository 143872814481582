import React from 'react';
import { Tooltip } from 'reactstrap'
import { accentUtils } from '../services/HelperService'

export class AccentTooltip extends React.Component {
    static displayName = AccentTooltip.name;

    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
            placement: this.props.placement
        };

        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {


        var content = null;

        if (!accentUtils.isNull(this.props.html)) {
            content = <span dangerouslySetInnerHTML={{ __html: this.props.html }}></span>;
        } else if (!accentUtils.isNull(this.props.text)) {
            content = <span>{ this.props.text }</span>;
        } else {
            content = <div>{ this.props.children}</div>;
        }

        return (
            <Tooltip
                data-tagname={encodeURIComponent(this.props.text) }
                className={ this.props.className}
                delay={{ show: accentUtils.isNull(this.props.delayShow) ? 500 : Number(this.props.delayShow), hide: accentUtils.isNull(this.props.delayHide) ? 0 : Number(this.props.delayHide) }}
                placement={accentUtils.isNull(this.props.placement) ? 'top' : this.props.placement}
                isOpen={this.state.tooltipOpen}
                autohide={false}
                target={this.props.target}
                toggle={this.toggle}>
                {content}
            </Tooltip>
        );
    }
}