import * as React from "react";
import { MultiSelectTree } from "../controls/jobs/JobLineTreeMultiSelectCtrl";
import { accentUtils, from, t } from "../services/HelperService";
import { AccentWizard, AccentWizardPage } from "./RTLWizard";



export default class ScheduleActivityWizard extends React.Component {

    constructor(props) {
        super(props);


        this.validation = this.props.pageprops.validation;

        this.state = {
            lines: this.props.pageprops.data.Items.map(l => { return { ...l, selected: this.validation[l.ID].isValid } })
        };



        this.onFinish = this.onFinish.bind(this);
        this.getDetails = this.getDetails.bind(this);
        this.onItemChanged = this.onItemChanged.bind(this);
        this.onValidateRow = this.onValidateRow.bind(this);
    }


    onItemChanged(e) {

        this.setState({
            lines: this.state.lines.map(l => {
                return {
                    ...l,
                    selected: e.includes(l.ID) && !this.validation[l.ID].isPendingService && !this.validation[l.ID].isCmUnconfirmed
                }
            })
        });


    }

    onValidateRow(e) {

    }

    onFinish() {
        return Promise.resolve({
            lineIDs: from(this.state.lines)
                .where(v => v.selected)
                .select(v => v.ID)
                .distinct()
                .toArray()
        });
    }


    getDetails() {


        const info = this.props.pageprops.data;




        if (this.state.lines.length == 0) {
            return <div>
                No lines to schedule
            </div>;
        }

        var qty = 0;

        const data = this.state.lines.map(l => {

            const v = [];

            if (!accentUtils.isNull(this.validation[l.ID])) {

                const val = this.validation[l.ID];
                if (l.selected) {
                    qty += l.Qty;
                }

                if (!val.isRequired) {
                    v.push(t("application_strings.wizards.scheduleActivity.validation.notRequired", info));
                }

                if (val.isPendingService) {
                    v.push(t("application_strings.wizards.scheduleActivity.validation.hasPendingService", info));
                }

                if (val.hasExisting) {
                    v.push(t("application_strings.wizards.scheduleActivity.validation.hasExisting", info));
                }
                if (val.isCmUnconfirmed) {
                    v.push(t("application_strings.wizards.scheduleActivity.validation.hasCMUnconfirmed", info));
                }
                if (val.warnHasSupplyItem) {
                    v.push(t("application_strings.wizards.scheduleActivity.validation.isInSupply", info));
                }
                if (val.warnHasBeenReceipted) {
                    v.push(t("application_strings.wizards.scheduleActivity.validation.hasBeenReceipted", info));
                }

                if (val.warnHasFutureOpenAppointmentTypes && val.warnHasFutureOpenAppointmentTypes.length > 0) {
                    v.push(t("application_strings.wizards.scheduleActivity.validation.hasExistingOpenActivities", { list: val.warnHasFutureOpenAppointmentTypes.join(', ') }));
                }


            }

            l.validation = v;

            return l;
        });

        return <div>
            <MultiSelectTree
                items={this.state.lines}
                minHeight={510}
                validationField="validation"
                onChange={this.onItemChanged} />
            <div className="float-right"><label>{t("application_strings.wizards.scheduleActivity.validation.selectedQty")}</label>:<span style={{ laddingLeft: "20px" }}>{qty}</span></div>
        </div>;



    }

    render() {

        var me = this;


        const content = this.getDetails();

        const allowFinish = from(this.state.lines).any(v => v.selected);

        return (
            <AccentWizard wizardProps={{ ...this.props, large: true }} allowNext={allowFinish}>
                <AccentWizardPage title="application_strings.wizards.scheduleActivity.page01Title" >
                    <React.Fragment>
                        {content}
                    </React.Fragment>
                </AccentWizardPage>
            </AccentWizard>
        );
    }

}
