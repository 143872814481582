import * as React from 'react'
import $ from 'jquery'
import { Row, Col, ListGroup, ListGroupItem, FormGroup, Input } from 'reactstrap'
import { Stepper } from '@progress/kendo-react-layout';
import { from, t } from '../services/HelperService';
import { AccentDialog } from './AccentDialog';
import { AccentButton } from '../controls/AccentButton';
import { MultiSelectTree } from '../controls/jobs/JobLineTreeMultiSelectCtrl';
import { usePosition } from '../controls/AccentDisplay';
import { BulkUpdateCtrl, ProductChangeReviewCtrl } from '../controls/jobs/BulkUpdateCtrl';
import { MessageTypes, useMessage } from '../services/MessageService';
import { update } from '../services/DataService';
import { getProduct, getProducts } from '../services/ProductService';



function getProductPage(products, clearProductSelection, onProductSelectionChanged, onSelectLine) {
    if (products.length == 0) {
        return <label>{t("application_strings.wizards.bulkUpdateWiz.noProducts")}</label>;
    } else {

        return products.map(function (p) {
            return (
                <Row key={p.ProductID}>
                    <Col xs="12">
                        <ListGroup>
                            <ListGroupItem active><FormGroup check><Input className={'p-c p-' + p.ProductID} type="checkbox" onChange={function (e) { onProductSelectionChanged({ Product: p, Value: e.target.checked }); }} />&nbsp;{p.ProductName}</FormGroup></ListGroupItem>
                            {
                                p.Lines.map(function (l) {
                                    return <ListGroupItem key={l.ID} ><FormGroup check><Input name={l.ID} className={'p-i-c p-i-' + p.ProductID} type="checkbox" onChange={function (e) { clearProductSelection(p); onSelectLine(p); }} />&nbsp; {l.Qty} : {l.Location} - {l.BulkUpdateOptions}</FormGroup></ListGroupItem>;
                                })
                            }
                        </ListGroup>
                    </Col>
                </Row>
            );
        });
    }
}

function getUpdatingPage(products, linesToUpdate, priceUpdated) {


    const res = linesToUpdate.map(function (l) {

        var line = from(products).selectMany("p=>p.Lines").firstOrDefault(function (r) { return r.ID == l.ID; });

        var status = !l.UpdateCompleted ? "application_strings.wizards.bulkUpdateWiz.inProgress" : "application_strings.wizards.bulkUpdateWiz.done"
        var className = !l.UpdateCompleted ? "ql-status-inprog" : "ql-status-done"

        return (
            <Row key={l.ID}>
                <Col xs={6}>
                    {line.Qty} : {line.Location} - {line.BulkUpdateOptions}
                </Col>
                <Col xs={6} className={className}>
                    {t(status)}
                </Col>
            </Row>
        );
    });

    var priceStatus = !priceUpdated ? "application_strings.wizards.bulkUpdateWiz.inProgress" : "application_strings.wizards.bulkUpdateWiz.done"
    var priceClassName = !priceUpdated ? "ql-status-inprog" : "ql-status-done"


    res.push(<Row key="price">
        <Col xs={6}>
            {t("application_strings.wizards.bulkUpdateWiz.updatingPrice")}
        </Col>
        <Col xs={6} className={priceClassName}>
            {t(priceStatus)}
        </Col>
    </Row>);

    return res;
}



export const BulkChangeDlg = React.memo(props => {

    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [priceUpdated, setPriceUpdated] = React.useState(false);
    const [step, setStep] = React.useState(0);
    const [linesToUpdate, setLinesToUpdate] = React.useState([]);
    const [changes, setChanges] = React.useState([]);
    const [original, setOriginal] = React.useState([]);
    const updatingInprogress = React.useRef(false);

    const updateChanged = useMessage("BulkUpdateWizard", MessageTypes.BulkUpdateChanged);


    const next = React.useCallback(e => {


        if (step === 3) {
            props.onClose();
            return;
        }

        setStep(s => s + 1);



        e.promise();

    }, [step, setStep, props]);


    const updateSelection = React.useCallback(e => {

        const newLinesState = [];

        $('.p-i-c').each(function (i) {
            if (this.checked) {
                var lineID = this.name;

                newLinesState.push({ ID: lineID, UpdateCompleted: false });

            }
        });

        setLinesToUpdate(newLinesState);


        setSelectedProduct(newLinesState.length > 0 ? e?.ProductID : null);


    }, [setLinesToUpdate, setSelectedProduct]);


    const clearProductSelection = React.useCallback((product) => {

        props.data.Products.forEach(function (p) {

            if (p.ProductID != product.ProductID) {
                $('.p-i-' + p.ProductID).prop('checked', false);
                $('.p-' + p.ProductID).prop('checked', false);
            }

        });

    }, [props]);

    const onProductSelectionChanged = React.useCallback((e) => {

        $('.p-i-' + e.Product.ProductID).prop('checked', e.Value);
        clearProductSelection(e.Product);

        updateSelection(e.Product);

    }, [clearProductSelection, updateSelection]);



    const onStepChanged = React.useCallback(e => {
        setStep(e.value);
    }, [setStep]);


    const onChange = React.useCallback(e => {

        setChanges(e.changes);
        setOriginal(e.original);

    }, [setChanges, setOriginal]);

    React.useEffect(() => {

        setLinesToUpdate(lines => {
            return lines.map(l => {

                if (l.ID == updateChanged.JobLineID) {
                    return { ...l, UpdateCompleted: true };
                } else {
                    return l;
                }
            });

        });


    }, [updateChanged, setLinesToUpdate]);


    React.useEffect(() => {

        if (step !== 3 || priceUpdated || updatingInprogress.current) return;

        updatingInprogress.current = true;

        const product = getProduct(selectedProduct);

        const changesArgs = {
            Changes: changes.map(c => {
                return {
                    ProductOptionID: c.ProductOptionID,
                    UserSet: c.UserSet,
                    Change: {
                        ProductOptionValueID: c.ProductOptionValueID,
                        CustomValue: c.CustomValue,
                        AdditionalValue: c.AdditionalValue,
                        SupplierID: c.SupplierID,
                        Notes: ""
                    }
                }
            })
        };


        update("PerformBulkUpdate", {
            Lines: from(linesToUpdate).select(i => i.ID).toArray(),
            ChangeArgs: changesArgs,
            ProductID: product.ID,
            ProductURL: product.ModelURI,
            MPResellerKeys: product.ResellerMarketplaceKeys
        }).then(res => {
            setLinesToUpdate(lines => lines.map(l => ({ ...l, UpdateCompleted: true })));
            setPriceUpdated(true);            
        });

    }, [step, changes, linesToUpdate, setLinesToUpdate, setPriceUpdated, selectedProduct, updatingInprogress]);


    const allowStep2 = (step === 0 || step === 1) && linesToUpdate.length > 0;
    const allowStep3 = step === 1 || step === 2;
    const allowFinish = step === 3 && priceUpdated;
    const steps = [
        {
            label: t("application_strings.wizards.bulkUpdateWiz.page1Title"),
            disabled: step > 0
        },
        {
            label: t("application_strings.wizards.bulkUpdateWiz.page2Title"),
            disabled: !allowStep2
        },
        {
            label: t("application_strings.wizards.bulkUpdateWiz.page3Title"),
            disabled: !allowStep3
        },
        {
            label: t("application_strings.wizards.bulkUpdateWiz.page4Title"),
            disabled: step !== 3
        }
    ];


    
    const allowNext = allowStep2 || allowStep3 || allowFinish;

    const btnText = step === 2 ? t("application_strings.application.buttons.update") : (step === 3) ? t("application_strings.application.buttons.finish") : t("application_strings.application.buttons.next");

    return <AccentDialog
        fullscreen
        headerContent={t("application_strings.wizards.bulkUpdateWiz.title")}
        onClose={props.onClose}
        actionsContent={[            
            <AccentButton key="1" tagName="nextBtn" disabled={!allowNext} onClick={next}>{btnText}</AccentButton>
        ]}
    >        
        <div style={{ paddingBottom: "50px"}}>
            <Stepper value={step} onChange={onStepChanged} items={steps} />
        </div>
        <div>
            {step === 0 && getProductPage(props.data.Products, clearProductSelection, onProductSelectionChanged, updateSelection)}
            {step === 1 && <BulkUpdateCtrl id={linesToUpdate[0].ID} businessUnitID={props.data.BusinessUnitID} onChange={onChange} />}
            {step === 2 && <ProductChangeReviewCtrl changes={changes} original={original} />}
            {step === 3 && getUpdatingPage(props.data.Products, linesToUpdate, priceUpdated)} 
        </div>        
    </AccentDialog >;

});