import React from 'react'
import { Menu } from '@progress/kendo-react-all'
import { Row, Col } from 'reactstrap' 
import { accentUtils, t , from} from '../../services/HelperService'
import AccentIcons from '../../icons/Icons'
import { parseFilterPredicate } from './AdvancedFilterConstants'
import { InsyteQueryFieldCtrl } from './InsyteQueryFieldCtrl'
import { InsyteQueryOpCtrl } from './InsyteQueryOpCtrl'
import { InsyteQueryValueCtrl } from './InsyteQueryValueCtrl'
import { AccentButton } from '../AccentButton'




export const InsyteQueryPredicateCtrl = React.memo(props => {

    const [actionsVisible, setActionsVisible] = React.useState(false);

    const itemInfo = parseFilterPredicate(props.item);



    const showActions = React.useCallback(e => {
        setActionsVisible(true);
    }, [setActionsVisible]);
    const hideActions = React.useCallback(e => {
        setActionsVisible(false);
    }, [setActionsVisible]);

    const delItem = React.useCallback(e => {
        props.onDelete();
    }, [props]);



    const parent = from(props.schema.Entities).selectMany(e => e.Fields, (e, f) => { return { entity: e, field: f.ID }; }).where(r => r.field == props.item.field).select(r => r.entity).firstOrDefault();
    const parentLabel = accentUtils.isNull(parent) ? undefined : t(`${props.fieldStringBase}.${parent.Prompt}`);


    return <Row className="acc-qry-pred" onMouseEnter={showActions} onMouseLeave={hideActions} title={parentLabel}>
        <Col className="acc-qry-pred-field" md={2}><InsyteQueryFieldCtrl item={props.item} readOnly={props.readOnly} onItemChanged={props.onItemChanged} schema={props.schema} fieldStringBase={props.fieldStringBase} /></Col>
        <Col className="acc-qry-pred-op" md={2}><InsyteQueryOpCtrl item={props.item} schema={props.schema} readOnly={props.readOnly} onItemChanged={props.onItemChanged} /></Col>
        <Col className="acc-qry-pred-val" md={2}><InsyteQueryValueCtrl item={props.item} schema={props.schema} readOnly={props.readOnly} onItemChanged={props.onItemChanged} /></Col>
        <Col className="acc-qry-pred-acts" md={2} >{actionsVisible && <AccentButton tagName="qDelItm" bsStyle="link" disabled={props.readOnly} outline={false} onClick={delItem}><AccentIcons.Delete top="5" /></AccentButton>}</Col>
    </Row>;
});
