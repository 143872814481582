import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap' 
import { accentUtils, t, formHelper } from './../../services/HelperService'


export class AccentCheck extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();
        this.initialValue = this.props.model.getValue();

        this.updateKey = 0;

        this.currentUserValue = this.initialValue;
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.setValue = this.setValue.bind(this);
    }

    componentDidMount(){                
        
    }


    toggle() {

        this.updateKey++;
        this.setValue(!this.props.model.getValue());
        
        
    }

    handleChange(e) {
        this.currentUserValue = e.target.checked;
        this.setValue(e.target.checked);
        
    }


    setValue(value) {

        
        this.props.model.setValue(value);
        this.props.model.modified();

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange();
        }

    }
    
    render() {

        
        var disabled = formHelper.isControlReadOnly(this.props.model);


        
        var modelValue = this.props.model.getValue();

        if (!disabled) {
             if (modelValue != this.currentUserValue) { // model changed so hack                
                
                this.currentUserValue = modelValue;                
                this.initialValue = modelValue;
                this.updateKey++;
            }
        }


        return (
            <div ref={this.ctrl} className="accentCheckbox" key={this.updateKey} style={ this.props.style}>
                <FormGroup check>
                    <Input type="checkbox" defaultChecked={this.initialValue} onChange={this.handleChange} disabled={ disabled} />
                    {' '}
                    <Label check onClick={ this.toggle }>
                        {this.props.model.displayLabel}
                    </Label>
                </FormGroup>
            </div>

        );
    }
}
