//DataLoader still to resolve
import * as React from 'react'
import ReactDOM from 'react-dom'
import { MultiSelect } from '@progress/kendo-react-all'
import { accentUtils, t, formHelper, from } from '../../services/HelperService'
import { RTLQueryFetcher } from '../RTLFetch';

export class AccentMultiSelect extends React.Component {
    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        this.dataKey = 0;
        var pageSize = 100;
        this.dataTextField = this.props.model.options.displayField;
        this.dataValueField = this.props.model.options.valueField;

        this.queryFetcher = new RTLQueryFetcher({
            query: this.props.model.options.source
        })

        this.initialValue = this.props.model.getValue();

        this.selectAllOnDataLoad = this.props.model.options.selectAllOnInitialLoad;

        this.state = {
            data: { data: [], total: 0 },
            dataState: { take: pageSize, skip: 0 },
            selectedID: -1,
            value: [],
            valueObjects: []
        };

        this.componentDidMount = this.componentDidMount.bind(this);
        this.includePrimary = this.includePrimary.bind(this);
        this.getCurrentValue = this.getCurrentValue.bind(this);
        this.onDataRecieved = this.onDataRecieved.bind(this);
        this.onGetDOM = this.onGetDOM.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearData = this.clearData.bind(this);
        this.tagRender = this.tagRender.bind(this);
        this.isPrimaryID = this.isPrimaryID.bind(this);
        this.runQuery = this.runQuery.bind(this);
    }

    onGetDOM() {
        return this.ctrl.current;        
    }

    onDataRecieved() {

        var me = this;


        if (this.selectAllOnDataLoad) {
            this.selectAllOnDataLoad = false;


            
            var ids = from(this.state.data.data).select(function (v) { return v[me.dataValueField]; })
                .where(v => !accentUtils.isNull(v)).toArray();

            ids = this.includePrimary(ids);


            this.props.model.setValue(ids);

            if (this.props.model.modified)
                this.props.model.modified();


            if (!accentUtils.isNull(this.props.model.options.onChange)) {
                this.props.model.options.onChange({ value: ids });
            }

            this.setState({
                value: ids
            });

        }


        
    }

    filterChange(event) {

        this.setState({
            dataState: {
                take: this.state.dataState.take,
                skip: this.state.dataState.skip,
                filter: {
                    logic: 'and',
                    filters: [event.filter]
                }
            }
        }, ()=> this.runQuery());

    }

    componentDidMount() {
        this.runQuery().then(() => {

            if (!accentUtils.isEmpty(this.initialValue)) {

                this.setState({
                    value: this.initialValue,
                    valueObjects: this.getCurrentValue(this.initialValue)
                })

            }

        });
    }

    runQuery() {

        return new Promise(p => {

            this.queryFetcher.fetchData(this.state.dataState).then(res => {

                this.setState({
                    data: { ...res },
                    loading: false
                }, () => {
                    this.onDataRecieved();
                    p();
                });

            });



            this.setState({ loading: true });

        });
    }

    clearData () {        
        this.props.model.setValue([]);
        this.forceUpdate();
    }

    refresh() {
        this.runQuery().then(() => {


            const ids = this.props.model.getValue();

            const currValue = this.getCurrentValue(ids);


            this.setState({ value: ids, valueObjects: currValue });


        });
    }

    includePrimary(values) {

        var me = this;

        var result = [];

        if (!accentUtils.isNull(me.props.model.options.multiSelectRequiredIDs))
            result = me.props.model.options.multiSelectRequiredIDs();
        
        return from(result).union(from(values)).where(v=> !accentUtils.isNull(v)).toArray();

    }

    getCurrentValue(ids) {

        var me = this;

        var result = [];

        if (this.state.data.data.length > 0 && !accentUtils.isEmpty(ids)) { 
            result = from(this.state.data.data).where(function (v) { return ids.indexOf(v[me.dataValueField]) != -1}).toArray();            
        }

        return result;

    }

    handleChange(e) {

        var me = this;

        var ids = from(e.target.value).select(function (v) { return v[me.dataValueField]; })
            .where(v=> !accentUtils.isNull(v)).toArray();

        ids = this.includePrimary(ids);


        this.props.model.setValue(ids);

        if (this.props.model.modified)
            this.props.model.modified();


        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange({ value: ids });
        }

        const valueObjects = e.value.length == 0 && ids.length > 0 ? from(this.state.data.data).where(r=> from(ids).any(i=> i === r[me.dataValueField])).toArray()  : e.value;


        this.setState({
            value: ids,
            valueObjects: valueObjects
        });
    }

    

    isPrimaryID(id) {

        var me = this;

        if (!accentUtils.isNull(me.props.model.options.multiSelectRequiredIDs)) {
            var ids = me.props.model.options.multiSelectRequiredIDs();

            return ids.indexOf(id) != -1;
        }

        return false;
    }

    tagRender(tagData, li) {

        var isPrim = this.isPrimaryID(tagData.data[0][this.dataValueField])

        return React.cloneElement(li, li.props,
            isPrim ? (<span className="custom-tag">{tagData.data[0][this.dataTextField]}</span>) : li.props.children);
    }

    render() {

        var me = this;


        var disabled = formHelper.isControlReadOnly(this.props.model);

        //var currVal = this.getCurrentValue(this.props.model.getValue());

        return (
            <div>
                <MultiSelect
                    ref={this.ctrl}
                    data={this.state.data.data}
                    disabled={disabled}
                    value={this.state.valueObjects}
                    label={this.props.model.displayLabel}
                    onChange={this.handleChange}
                    filterable
                    loading={this.state.loading}
                    textField={this.dataTextField}
                    onFilterChange={this.filterChange}
                    dataItemKey={this.dataValueField}
                    tagRender={(this.props.model.options) ? this.props.model.options.tagRender : this.tagRender}
                    itemRender={(this.props.model.options) ? this.props.model.options.itemRender : undefined}
                />                
            </div>
            );

    }
}


