import React from 'react'
import { Menu } from '@progress/kendo-react-all'
import { Row, Col } from 'reactstrap'
import { accentUtils, from, t } from '../../services/HelperService'
import { queryNoTracking } from '../../services/DataService'
import { AccentDateUnbound } from '../unbound/AccentDateUnbound'
import { AccentCheckUnbound } from '../unbound/AccentCheckUnbound'
import { AccentNumberUnbound } from '../unbound/AccentNumberUnbound'
import { AccentTextUnbound } from '../unbound/AccentTextUnBound'
import { FilterDropdown } from '../FilterButtons'
import { findSchemaItem, filterTypes, filterOps, parseFilterPredicate, changeFilterPredicateValue } from './AdvancedFilterConstants'



const query_AdvancedQuery_LookupByType = queryNoTracking('AdvancedQuery_LookupByType', 0);
const query_AdvancedQuery_Lookup = queryNoTracking('AdvancedQuery_Lookup', 0);


export const InsyteQueryValueCtrl = React.memo(props => {


    const opInfo = parseFilterPredicate(props.item);

    const schemaItem = findSchemaItem(props.schema, opInfo.field);

    const type = accentUtils.isNull(schemaItem) ? "text" : schemaItem.Type;


    const onChange = React.useCallback(e => {
        changeFilterPredicateValue(props.item, e);
        props.onItemChanged(true);
    }, [props]);


    if (opInfo.op === filterOps.isempty || opInfo.op === filterOps.isnotempty) return null;

    switch (type) {
        case filterTypes.select: return <InsyteQueryValueSelectCtrl itemInfo={opInfo} schemaItem={schemaItem} onChange={onChange} readOnly={ props.readOnly}/>;
        case filterTypes.check: return <AccentCheckUnbound tagName={opInfo.field + "_chk"} defaultValue={opInfo.arg} onChange={e => onChange(e.checked)} disabled={props.readOnly}/>;
        case filterTypes.date:
            if (opInfo.op === filterOps.relative)
                return <InsyteQueryValueRelativeCtrl itemInfo={opInfo} onChange={onChange} readOnly={props.readOnly}/>;
            else if (opInfo.op === filterOps.within)
                return <AccentNumberUnbound defaultValue={opInfo.arg} onChange={onChange} isInt disabled={props.readOnly}/>;
            else
                return <AccentDateUnbound defaultValue={opInfo.arg} onChange={onChange} disabled={props.readOnly}/>;
        case filterTypes.numeric: return <AccentNumberUnbound defaultValue={opInfo.arg} onChange={onChange} disabled={props.readOnly}/>;
        default: return <AccentTextUnbound defaultValue={opInfo.arg} onChange={onChange} disabled={props.readOnly} />;
    }
});

export const InsyteQueryValueSelectCtrl = React.memo(props => {

    const queryLookup = React.useCallback(e => {
        if (!accentUtils.isEmpty(props.schemaItem.LookupKeyType))
            return query_AdvancedQuery_LookupByType.getAllWithCache({lookupKey: props.schemaItem.LookupKeyType });
            
        return query_AdvancedQuery_Lookup.getAllWithCache({ lookupKey: props.schemaItem.LookupKey });
    }, [props]);

    const onChange = React.useCallback(e => {

        const selection = from(e.value).select(i => i.ID).toArray();

        props.onChange(selection);

    }, [props]);

    const existingValue = accentUtils.isNull(props.itemInfo.arg) ? [] : from(props.itemInfo.arg).select(v => { return { ID : v}}).toArray();


    return <FilterDropdown valueField="ID" textField="Text" dataSource={queryLookup} onChange={onChange} translate selectAllOnLoad={false} value={existingValue} readOnly={props.readOnly}/>;
});


export const InsyteQueryValueRelativeCtrl = React.memo(props => {

    

    const [start, setStart] = React.useState(accentUtils.isNull(props.itemInfo.arg) ? 0 : props.itemInfo.arg[0]);
    const [end, setEnd] = React.useState(accentUtils.isNull(props.itemInfo.arg) ? 0 : props.itemInfo.arg[1]);

    const onChange = React.useCallback((e, t) => {

        
        var res = [0,0];

        if (t === "start") {            
            res = [accentUtils.isNull(e) ? start : Number(e), end];
            setStart(s=> res[0]);
        } else {
            res = [start, accentUtils.isNull(e) ? end: Number(e)];
            setEnd(e => res[1]);
        }

        props.onChange(res);

    }, [props, start, end, setStart, setEnd]);

    
    return <div style={{ display: "flex" }}>
        <AccentNumberUnbound defaultValue={start} onChange={e => onChange(e, "start")} label="Start" isInt disabled={props.readOnly}/>
        <AccentNumberUnbound defaultValue={end} onChange={e => onChange(e, "end")} label="End" isInt disabled={props.readOnly}/>
    </div>;
});