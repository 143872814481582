import * as React from 'react'
import $ from 'jquery'
import { DropdownMenu, DropdownItem, Row, Col } from 'reactstrap'

import { update, queryNoTracking, deleteEntity, addEntity, clearEntities, saveEntities, queryTracking, isDirtyEntity } from './../services/DataService'
import { accentUtils, from, formHelper, showOK, goTo, t, showDialog } from './../services/HelperService'
import * as ActivityService from './../services/ActivityService'
import * as JobService from './../services/JobService'
import { getAccess } from './../services/UserService'
import { showWizard } from './../wizards/RTLWizard'
import NewServiceJobWizard from './../wizards/NewServiceJobWizard'
import LinkOpportunityWizard from './../wizards/LinkOpportunityWizard'
import CompleteDispatchDlg from './CompleteDispatchDlg'

import AccentIcons from './../icons/Icons'
import { AccentButton } from './../controls/AccentButton'
import { onSelectAddCompany, onSelectAddContact } from './../controls/helpers/CompanyContactHelper'
import { AccentDropdownButton } from './../controls/AccentDropdownButton'
import { AccentFormCtrlNoViewState, useValidation } from './../controls/bound/AccentFormCtrl'
import { UserTeamSelectCtrl } from './../controls/UserTeamSelectCtrl'
import { AccentDisplay, useAccentDisplay } from './../controls/AccentDisplay'
import { AccentAddress } from './../controls/bound/AccentAddress'
import { ActivityJobCtrl, ActivityJobLineCtrl } from './../controls/activities/ActivityJobLineCtrl'
import { ContactInfoCtrl } from '../controls/contacts/ContactInfoCtrl'
import { AccentDialog } from './AccentDialog'
import { ActivityInstallerViewCtrl } from '../controls/activities/ActivityInstallerViewCtrl'
import { validate } from 'uuid'
import { contextSources, getContext, getContextData, setContext } from '../services/EntityContextService'



const query_AddressForCompany = queryNoTracking("AddressForCompanyID");
const query_AddressForContact = queryNoTracking("AddressForContactID");
const query_ActivityCategories = queryNoTracking("PL_ActivityCategories");
const query_Opportunity_SelectList = queryNoTracking("Opportunity_SelectList");
const query_ActivityAttendee_SelectList = queryNoTracking("ActivityAttendee_SelectList", null, true);
const query_ActivityView_ContactSelectList = queryNoTracking("ActivityView_ContactSelectList");
const query_Company_SelectList = queryNoTracking("Company_SelectList");
const query_Contact_HasActiveJobs = queryNoTracking("Contact_HasActiveJobs");
const query_Activity_View = queryTracking("Activity_View");




export const showActivity = (args) => {

    return showDialog(<ActivityDlg activityID={args.activityID}
        onNew={function (e) {

            if (!accentUtils.isNull(args.representativeID)) {
                e.RepresentativeID = args.representativeID
            }

            if (!accentUtils.isNull(args.start)) {
                e.Start = new Date(args.start);
            }

            if (!accentUtils.isNull(args.end)) {
                e.End = new Date(args.end);
            }

            if (args.defaults) {

                Object.keys(args.defaults).map(k => e[k] = args.defaults[k]);

            }

            if (args.onNew) {
                args.onNew(e);
            }

            //if (!accentUtils.isNull(me.scheduleJobModel)) {
            //    if (!accentUtils.isNull(me.scheduleJobModel.onSheduleJob)) {
            //        me.scheduleJobModel.onSheduleJob(e);
            //    }
            //}


        }}
    />);
}


const activityCanHaveJob = (entity) => {

    return !accentUtils.isNull(entity.JobID) || !accentUtils.isNull(entity.OpportunityID) ||
        ActivityService.Helper.activityTypeCanHaveJob(entity.ActivityType);
};

export const ActivityDlg = React.memo(React.forwardRef((props, ref) => {


    const cboContact = React.createRef();
    const cboCompany = React.createRef();
    const ctrlAttendees = React.createRef();
    const siteAddressCtrl = React.createRef();
    const [attendeeReadOnly, setAttendeeReadOnly] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState(false);
    const [forceUpdateKey, setForceUpdateKey] = React.useState(1);
    const [forceUpdateDatesKey, setForceUpdateDatesKey] = React.useState(1);
    const [viewModel, setViewModel] = React.useState(null);
    const [ownerChanged, setOwnerChanged] = React.useState(null);
    const [showInstallSummary, setShowInstallSummary] = React.useState(false);
    const [jobRequired, setJobRequired] = React.useState(false);
    const [addressRequired, setAddressRequired] = React.useState(false);
    const [canHaveJob, setCanHaveJob] = React.useState(false);




    const [printSettings, setPrintSettings] = React.useState(null);
    const [productsChanged, setProductsChanged] = React.useState(false);
    const [allProducts, setAllProducts] = React.useState([]);
    const [reloadProducts, setReloadProducts] = React.useState(0);


    const display = useAccentDisplay();
    const validation = useValidation("div[aria-labelledby='accentCalendarDlg']");


    const tagDataAsObj = (act, merge) => {

        var tag = accentUtils.isEmpty(act.TagData) ? {} : JSON.parse(accentUtils.isEmpty(act.TagData));

        return { ...tag, ...merge };

    };


    

    const doSave = React.useCallback(() => {

        return new Promise(p => {

            if (formHelper.isModalValid()) {

                beforeActivitySave(viewModel).then(allowSave => {

                    if (allowSave) {

                        const attendeesToDelete = from(viewModel.entity.Attendees).where(at => viewModel.entity.AttendeeList.indexOf(at.RepID) == -1).toArray();
                        const attendeesToAdd = from(viewModel.entity.AttendeeList).where(i => !from(viewModel.entity.Attendees).any(at => at.RepID == i)).toArray();

                        attendeesToDelete.map(a => {
                            deleteEntity(a);
                        });


                        if (!getShowContactDetails() && viewModel.entity.JobLines && viewModel.entity.JobLines.length != 0) {

                            viewModel.entity.JobLines.map(l => {
                                deleteEntity(l);
                            });


                        }

                        var createdAttendees = attendeesToAdd.map(repID => {
                            return addEntity(
                                "ActivityRep", {
                                RepID: repID,
                                ActivityID: viewModel.entity.ID
                            });
                        });


                        Promise.all(createdAttendees).then(ats => {

                            saveEntities().then(s => {
                                clearEntities();
                                p(true);
                                return;
                            });

                        });


                    } else {
                        p(false);
                    }
                });
            } else {
                p(false);
            }
        });
    }, [props, viewModel, isDirty]);


    const newServiceJob = React.useCallback((e) => {

        if (JobService.Helper.allowCreateServiceJob()) {

            if (!accentUtils.isNull(viewModel.entity.JobID)) {
                showWizard(NewServiceJobWizard, true, { context: { id: viewModel.entity.JobID, type: "Job" } });
            } else {

                getContextData(getContext()).then(ctx => {
                    NewServiceJobWizard.CreateNewServiceJob(ctx);
                });

                
            }

        }


    }, [props, doSave, viewModel]);

    const newQuote = React.useCallback((e) => {

        doSave().then(saved => {

            if (saved) {
                ActivityService.Helper.createNewQuote(viewModel.entity.ID, viewModel.entity.RepresentativeID).then(created => {

                    if (created) {
                        // close dialog
                        props.onClose({
                            canceled: false,
                            saved: true,
                            changed: false,
                            activity: viewModel.entity
                        });
                    }

                });

                
            } else {
            }


        })



    }, [props, doSave, viewModel]);


    const startCM = React.useCallback((e) => {


        const productsValid = $(".acc-sel-tag.accentInvalidCtrl").length == 0;

        if (productsValid) {

            ActivityService.Helper.startCM(viewModel.entity.ID, viewModel.entity.JobID);

        } else {
            showOK("application_strings.application.dialogs.activityInvalidProductsTitle", "application_strings.application.dialogs.activityInvalidProductsMsg", ["application_strings.application.buttons.ok"]);
        }

    }, [props, ownerChanged, viewModel, doSave]);

    const closeActivity = React.useCallback((e) => {


        showOK("application_strings.application.dialogs.completeActivityTitle", "application_strings.application.dialogs.completeActivityMsg", ["application_strings.application.buttons.completeAppointment"], null, null, null, 800)
            .then(function (res) {
                if (res == "application_strings.application.buttons.completeAppointment") {

                    var doClose = () => {

                        viewModel.entity.Closed = true;
                        doSave().then(function (saved) {
                            if (saved) {
                                // close dialog
                                props.onClose({
                                    canceled: false,
                                    saved: true,
                                    changed: true,
                                    activity: viewModel.entity
                                });
                            }
                        });
                    };


                    if (viewModel.entity.ActivityType === ActivityService.Helper.typeCheckMeasure) {
                        JobService.Helper.showByPassCMWarning().then(byPass => {
                            if (byPass) {

                                JobService.Helper.byPassCMForActivities([viewModel.entity.ID]).then(r => {

                                    if (r.InProgressSkipped == 1) {

                                        showOK("application_strings.application.dialogs.completeCMInprogressTitle", "application_strings.application.dialogs.completeCMInprogressMsg", ["application_strings.application.buttons.ok"]);


                                    } else {
                                        props.onClose({
                                            canceled: false,
                                            saved: true,
                                            changed: true,
                                            activity: viewModel.entity
                                        });


                                    }
                                });

                            }
                        });
                    } else if (viewModel.entity.ActivityType === ActivityService.Helper.typeInstall) {

                         update("Activity_HasPendingCMLine", { IDs: [viewModel.entity.ID] }).then(r => {

                            if (r.HasPendingCM) {

                                showOK("application_strings.application.dialogs.installHasCMPendingTitle", "application_strings.application.dialogs.installHasCMPendingMsg", ["application_strings.application.buttons.ok"]);

                            } else {

                                if (accentUtils.isEmpty(viewModel.entity.JobLines) || !getAccess().modules().RTL_Service()) {
                                    doClose();
                                } else {
                                    CompleteDispatchDlg.Show(viewModel.entity.ID).then(r => {
                                        if (r.completeActivity) {
                                            viewModel.entity.ProcessDetails = r.processDetails;
                                            doClose();
                                        }
                                    });
                                }

                            }


                        });


                    } else {
                        doClose();
                    }


                } else {
                }
            });



    }, [props, ownerChanged, viewModel, doSave]);

    const openActivity = React.useCallback((e) => {


        showOK("application_strings.application.dialogs.reOpenActivityTitle", "application_strings.application.dialogs.reOpenActivityMsg", ["application_strings.application.buttons.open"], false, null, false, 600)
            .then(function (res) {
                if (res == "application_strings.application.buttons.open") {
                    viewModel.entity.Closed = false;
                    doSave().then(function (saved) {
                        if (saved) {
                            // close dialog
                            props.onClose({
                                canceled: false,
                                saved: true,
                                changed: true,
                                activity: viewModel.entity
                            });
                        }
                    });
                } else {
                }
            });

    }, [props, viewModel, doSave]);

    const cancelActivity = React.useCallback((e) => {

    }, [props]);




    const saveActivity = React.useCallback((e, showInCalendar) => {

        const wasDirty = isDirty || isDirtyEntity(viewModel.entity);

        doSave().then(saved => {
            if (saved) {

                e.promise();
                props.onClose({
                    canceled: false,
                    saved: true,
                    changed: wasDirty,
                    activity: viewModel.entity,
                    showInCalendar: showInCalendar
                });

                if (showInCalendar) {
                    ActivityService.Helper.showInCalendar(viewModel.entity.ID);
                }





            } else {
                e.promise();
            }
        });
    }, [props, viewModel, isDirty, doSave]);
    const cancelDlg = React.useCallback((e) => {
        clearEntities();

        props.onClose({
            canceled: true
        });
    }, [props]);


    const beforeActivitySave = (e) => {

        const act = e.entity;

        return new Promise(function (p) {

            if ((act.ActivityType === ActivityService.Helper.typeSales || act.ActivityType === ActivityService.Helper.typeShowroomQuote) && accentUtils.isNull(act.OpportunityID)) {
                showWizard(LinkOpportunityWizard, true, { contactID: act.ContactID, isShowroom: act.ActivityType === ActivityService.Helper.typeShowroomQuote }, true).then(function (res) {

                    if (accentUtils.isNull(res)) {
                        p(false);
                    } else {

                        var tag = tagDataAsObj(act, res);
                        if (!accentUtils.isNull(tag.OpportunityID)) {
                            act.OpportunityID = tag.OpportunityID;
                        }
                        act.TagData = JSON.stringify(tag);


                        p(true);
                    }
                });
            } else {
                p(true);
            }
        });
    };

    const onOwnerChanged = React.useCallback((e) => {

        var att = from(viewModel.entity.AttendeeList)
            .select(v => Number(v))
            .where(v => accentUtils.isNull(e.previous) || v !== e.previous)
            .toArray();

        if (att.length == 1) {
            ctrlAttendees.current.getFormControl().clearData();
        }


        if (!from(att).any(a => a === viewModel.entity.RepresentativeID)) {
            att.push(Number(viewModel.entity.RepresentativeID));
        }

        const hasOwner = !accentUtils.isEmpty(viewModel.entity.RepresentativeID);

        viewModel.entity.AttendeeList = !hasOwner ? [] : att;

        ctrlAttendees.current.getFormControl().refresh();

        setOwnerChanged(true);
        setAttendeeReadOnly(!hasOwner);
    }, [viewModel, ctrlAttendees, setOwnerChanged, setAttendeeReadOnly]);


    const onAttendeeChanged = React.useCallback((e) => {

    }, [viewModel, ctrlAttendees, setOwnerChanged]);

    const onContactDataReceived = React.useCallback((e) => {
        var currContactID = viewModel.entity.ContactID;

        if (!accentUtils.isNull(currContactID)) {
            if (!accentUtils.isNull(e.data)) {
                var contactValue = from(e.data).firstOrDefault(c => c.ID === currContactID);

                if (!accentUtils.isNull(contactValue)) {
                    if (!accentUtils.isEmpty(contactValue.CompanyID) && contactValue.CompanyID !== viewModel.entity.CompanyID) {
                        viewModel.entity.CompanyID = contactValue.CompanyID;
                    }
                }
            }
        }

    }, [viewModel, cboCompany]);

    const onNewContactWizardCompleted = React.useCallback((e) => {
        if (!accentUtils.isEmpty(e.CompanyID)) {
            viewModel.entity.CompanyID = e.CompanyID;
        }
    }, [viewModel]);

    const onCompanyWizardCompleted = React.useCallback((e) => {
        if (!accentUtils.isEmpty(e.ContactID)) {
            viewModel.entity.ContactID = e.ContactID;
        }
    }, [viewModel]);

    const copyAddress = React.useCallback((query) => {
        query.then(function (address) {
            if (!accentUtils.isNull(siteAddressCtrl.current)) {
                siteAddressCtrl.current.copyAddressFrom(address);
            }
        });
    }, [siteAddressCtrl]);

    const copyCompanyAddress = React.useCallback((e) => {
        if (!accentUtils.isEmpty(viewModel.entity.CompanyID)) {
            copyAddress(query_AddressForCompany.getFirstOrDefault({ companyID: viewModel.entity.CompanyID }));   
        }

        e.promise();
    }, [viewModel, copyAddress]);

    const copyContactAddress = React.useCallback((e) => {
        if (!accentUtils.isEmpty(viewModel.entity.ContactID)) {
            copyAddress(query_AddressForContact.getFirstOrDefault({ contactID: viewModel.entity.ContactID }));               
        }

        e.promise();

    }, [viewModel, copyAddress]);

    const validateContact = React.useCallback((value) => {

        if (!ActivityService.Helper.activityRequiresContact(viewModel.entity)) {
            return undefined;
        }

        if (accentUtils.isEmpty(value))
            return { Msg: "Required", Type: "Error" };

        if (cboContact.current != null) {

            var combo = cboContact.current.getFormControl();
            var item = combo.getSelectedItem();

            if (!accentUtils.isNull(item)) {
                if (!accentUtils.isNull(item.ID)) {
                    if (!accentUtils.isEmpty(viewModel.entity.CompanyID)) {
                        if (item.CompanyID != viewModel.entity.CompanyID) {
                            return { Msg: "Contact does not match the Company", Type: "Error" };
                        }
                    }
                }
            }
        }

    }, [props, viewModel]);
    const validateDates = React.useCallback((e) => {

        var allDay = viewModel.entity.AllDay;
        var hasStart = !accentUtils.isNull(viewModel.entity.Start);
        var hasEnd = !accentUtils.isNull(viewModel.entity.End);

        if (allDay) {
            if (!hasStart) {
                return { Msg: "Start time is required", Type: "Error" };
            }
        } else {

            var isValid = viewModel.entity.Start < viewModel.entity.End;

            if (!isValid || !hasStart || !hasEnd) {
                return { Msg: "End time cannot be before the start time", Type: "Error" };
            }
        }
        return null;

    }, [viewModel]);

    const getOwnerID = React.useCallback(e => {
        return [viewModel.entity.RepresentativeID];
    }, [viewModel]);

    const getActivityCategoryQuery = React.useCallback(e => {
        return query_ActivityCategories.getAllAsQueryableWithCache({ includeID: viewModel.entity.PL_ActivityCategoryID, activeOnly: true });
    }, [viewModel]);


    const getOppQuery = React.useCallback((e) => {
        return query_Opportunity_SelectList.getAllAsQueryableWithCache({ includeID: viewModel.entity.OpportunityID });
    }, [viewModel]);

    const getSalesRepQuery = React.useCallback((e) => {
        return query_ActivityAttendee_SelectList.getAllAsQueryableWithCache({ IncludeIDs: viewModel.entity.AttendeeList });
    }, [viewModel]);
    const getContactQuery = React.useCallback((e) => {
        return query_ActivityView_ContactSelectList.getAllAsQueryableWithCache({
            includeID: viewModel.entity.ContactID,
            companyID: viewModel.entity.CompanyID,
            opportunityID: viewModel.entity.OpportunityID,
            jobID: viewModel.entity.JobID
        });        
    }, [viewModel]);
    const getComanyQuery = React.useCallback((e) => {
        return query_Company_SelectList.getAllAsQueryableWithCache({ includeID: viewModel.entity.CompanyID });
    }, [viewModel]);

    const onShowInstallSummary = React.useCallback((e) => {

        showOK("", <ActivityInstallerViewCtrl contactID={contactID} address={address} jobLines={jobLines} jobID={viewModel.entity.JobID} printSettings={printSettings} />, []).then(r => {

            e.promise();
        });

    }, [viewModel]);

    const onHideInstallSummary = React.useCallback((e) => {
        setShowInstallSummary(false);
    }, [viewModel]);

    const activityRequireJob = () => (viewModel.entity.ActivityType === ActivityService.Helper.typeCheckMeasure || viewModel.entity.ActivityType === ActivityService.Helper.typeInstall);

    const UpdateJobRequired = React.useCallback((e) => {

        new Promise(p => {

            const actRequireJob = activityRequireJob();
            const actHasContact = !accentUtils.isNull(viewModel.entity.ContactID);

            var required = false;

            if (actRequireJob) {

                if (!actHasContact) {
                    required = true;
                } else {

                    query_Contact_HasActiveJobs.getFirstOrDefault({contactID: viewModel.entity.ContactID}).then(r => {
                        p(r.Exists);
                    });

                    return;
                }

            }

            p(required);

        }).then(res => {
            setJobRequired(res);
        });


    }, [viewModel, setJobRequired]);


    const onJobChanged = React.useCallback((e) => {
        viewModel.setDirty();

        setForceUpdateKey(forceUpdateKey => forceUpdateKey + 1);

    }, [viewModel, setForceUpdateKey, forceUpdateKey]);


    const onUserChanged = React.useCallback((e) => {
        viewModel.entity.RepresentativeID = e.value;
        viewModel.setDirty();
        onOwnerChanged(e);
    }, [viewModel, onOwnerChanged]);

    const onTeamChanged = React.useCallback((e) => {
        viewModel.entity.TeamID = e.value;
        viewModel.setDirty();
    }, [viewModel]);

    const getShowContactDetails = React.useCallback((e) => {
        if (viewModel.entity.ActivityType === ActivityService.Helper.typeLeave)
            return false;
        else
            return true;
    }, [viewModel]);

    const onAddressChanged = (e) => {

    }
    

    
    React.useEffect(() => {
        if (viewModel == null) {

            if (accentUtils.isEmpty(props.activityID)) {

                clearEntities();

                addEntity("Activity", {}).then(newActivity => {

                    if (props.onNew) {
                        props.onNew(newActivity);
                    }

                    newActivity.AttendeeList = [];
                    if (newActivity.RepresentativeID) {
                        newActivity.AttendeeList = [newActivity.RepresentativeID];
                    }
                    
                    newActivity.DefaultProductList = [];
                    newActivity.DefaultJobLineList = [];

                    if (!accentUtils.isEmpty(newActivity.TagData)) {
                        var td = JSON.parse(newActivity.TagData);

                        if (!accentUtils.isEmpty(td.GroupKeys)) {
                            newActivity.DefaultProductList = td.GroupKeys;
                        }

                        if (!accentUtils.isEmpty(td.JobLineIDs)) {
                            newActivity.DefaultJobLineList = td.JobLineIDs;
                        }

                    }

                    setViewModel({
                        defaultUserTeam: { UserID: newActivity.RepresentativeID, TeamID: null },
                        entity: newActivity,
                        isNew: true,
                        setDirty: function () { setIsDirty(true); }
                    });

                    setCanHaveJob(activityCanHaveJob( newActivity));

                    setAddressRequired(ActivityService.Helper.activityRequiresAddress(newActivity))

                }).catch(err => {
                    console.log(err);
                });
            } else {


                query_Activity_View.getFirstOrDefault({ id: props.activityID }).then(r => {

                    const a = r.Activity;

                    a.AttendeeList = from(a.Attendees).select(i => i.RepID).toArray();
                    a.DefaultProductList = [];
                    a.DefaultJobLineList = [];
                    setViewModel({
                        defaultUserTeam: { UserID: a.RepresentativeID, TeamID: a.TeamID },
                        entity: a,
                        setDirty: function () { setIsDirty(true); },
                        isNew: false,
                    });

                    

                    setCanHaveJob(activityCanHaveJob(a));

                    setAttendeeReadOnly(accentUtils.isEmpty(a.RepresentativeID));
                    setAddressRequired(ActivityService.Helper.activityRequiresAddress(a))

                    setContext(contextSources.view, "Activity", props.activityID);

                });
            }

        }
    }, []);



    if (viewModel == null) {
        return <div />
    }


    var isReadOnly = viewModel.entity.Closed;

    const allowEditJobOpp = !isReadOnly && viewModel.isNew;
    const showJobOpp = canHaveJob;

    viewModel.onNewContactWizardCompleted = onNewContactWizardCompleted;

    var subject = formHelper.getFieldCtrlModel(viewModel.entity, "Subject", "text", true, { label: "application_strings.entities.activity.Subject", readOnly: isReadOnly });
    var start = formHelper.getFieldCtrlModel(viewModel.entity, "Start", "datetime", validateDates, { label: "application_strings.entities.activity.Start", readOnly: isReadOnly || viewModel.entity.AllDay });
    var end = formHelper.getFieldCtrlModel(viewModel.entity, "End", "datetime", validateDates, { label: "application_strings.entities.activity.End", readOnly: isReadOnly || viewModel.entity.AllDay });
    var allDay = formHelper.getFieldCtrlModel(viewModel.entity, "AllDay", "check", false, { label: "application_strings.entities.activity.AllDay", readOnly: isReadOnly });
    var confirmedDay = formHelper.getFieldCtrlModel(viewModel.entity, "Confirmed", "check", false, { label: "application_strings.entities.activity.Confirmed", readOnly: isReadOnly });
    var apptType = formHelper
        .getFieldCtrlModel(viewModel.entity, "ActivityType", "select", true, { label: "application_strings.entities.activity.ActivityType", readOnly: isReadOnly, abbreviateLabel: true, source: ActivityService.Helper.getActivityTypePicklistSource() });

    var apptPriority = formHelper
        .getFieldCtrlModel(viewModel.entity, "Priority", "select", false, { label: "application_strings.entities.activity.Priority", readOnly: isReadOnly, source: ActivityService.Helper.getActivityPriorityPicklistSource() });


    var apptCategory = formHelper
        .getFieldCtrlModel(viewModel.entity, "PL_ActivityCategoryID", "select", false, { readOnly: isReadOnly, abbreviateLabel: true, source: getActivityCategoryQuery, sourceType: "PL_ActivityCategory" });


    var notes = formHelper.getFieldCtrlModel(viewModel.entity, "Notes", "textArea", false, { readOnly: isReadOnly, placeHolder: "application_strings.views.calendar.notesPlaceHolder" });
    notes.field.options.rows = 4;

    var attendees = formHelper.getFieldCtrlModel(viewModel.entity, "AttendeeList", "multiSelect", true, { label: "application_strings.entities.activity.Attendees", readOnly: (isReadOnly || attendeeReadOnly), displayField: "FullName", source: getSalesRepQuery, sourceType: "Representative", multiSelectRequiredIDs: getOwnerID, onChange: onAttendeeChanged });

    var opp = !showJobOpp ? null :
        formHelper.getFieldCtrlModel(viewModel.entity, "OpportunityID", "select", false, { label: "application_strings.entities.activities.OpportunityID", readOnly: !allowEditJobOpp, displayField: "Description", labelLink: "Opportunity", source: getOppQuery, sourceType: "Opportunity" });

    var company = formHelper
        .getFieldCtrlModel(viewModel.entity, "CompanyID", "select", false, { label: "application_strings.entities.activity.Company", readOnly: isReadOnly, labelLink: "Company", displayField: "Name", source: getComanyQuery, sourceType: "Company" });

    var contact = formHelper
        .getFieldCtrlModel(viewModel.entity, "ContactID", "select", validateContact, { label: "application_strings.entities.activity.Contact", readOnly: isReadOnly, labelLink: "Contact", displayField: "FullName", source: getContactQuery, sourceType: "Contact", onDataRecieved: onContactDataReceived });

    start.field.options.onChange = function (e) {

        if (!accentUtils.isNull(viewModel.entity.End) && !accentUtils.isNull(e.originalValue)) {

            var diffMs = (viewModel.entity.End - e.originalValue);
            var diffMins = Math.round((diffMs / 1000 / 60));
            viewModel.entity.End = new Date(viewModel.entity.Start.getTime() + diffMins * 60000);


        } else {

            if (viewModel.entity.Start > viewModel.entity.End) {
                viewModel.entity.End = new Date(viewModel.entity.Start.getTime() + 30 * 60000);
            }
        }
    };

    allDay.field.options.onChange = function (e) {
        setForceUpdateDatesKey(x => x + 1);
    };

    apptType.field.options.onChange = function (e) {        
        setCanHaveJob(activityCanHaveJob(viewModel.entity));
        UpdateJobRequired();        

        setAddressRequired(ActivityService.Helper.activityRequiresAddress(viewModel.entity));

        cboContact.current.refresh();
    };


    company.field.options.comboAddNewValue = v => onSelectAddCompany({
        value: v,
        done: onCompanyWizardCompleted,
        contactID: viewModel.entity.ContactID,
    });


    company.field.options.onChange = function (e) {
        if (e.newValue) {
            cboContact.current.refreshData();
        } else {
            viewModel.entity.ContactID = null;
            cboContact.current.clearValue();
            cboContact.current.refreshData();
        }
    };

    contact.field.options.comboAddNewValue = v => onSelectAddContact({
        value: v,
        done: onNewContactWizardCompleted,
        companyID: viewModel.entity.CompanyID,
    });
    contact.field.options.onChange = function (e) {
        if (e.newValue) {
            cboCompany.current.getFormControl().selectValue(viewModel.entity.CompanyID);
        }

        UpdateJobRequired();

    };


    end.field.options.onChange = function (e) {
        
    };
    const isNew = accentUtils.isEmpty(viewModel.entity.ID) || viewModel.entity.ID < 0;
    var allowDelete = !isNew && !isReadOnly;



    var showNewServiceJob = !isNew && getAccess().modules().RTL_Service() && (viewModel.entity.ActivityType == ActivityService.Helper.typeInstall || viewModel.entity.ActivityType == ActivityService.Helper.typeRework || viewModel.entity.ActivityType == ActivityService.Helper.typeService || viewModel.entity.ActivityType == ActivityService.Helper.typeWarranty);
    var showNewQuote = (viewModel.entity.ActivityType == ActivityService.Helper.typeSales || viewModel.entity.ActivityType == ActivityService.Helper.typeShowroomQuote) && !(isReadOnly || !validation.isValid);
    var showReOpen = !isNew && viewModel.entity.Closed;
    var showComplete = !isNew && !isReadOnly; // && viewModel.entity.ActivityType !== ActivityService.Helper.typeCheckMeasure;
    var showCancel = false;
    var showStartCM = !isNew && !viewModel.entity.Closed && viewModel.entity.ActivityType === ActivityService.Helper.typeCheckMeasure;


    const jobLinesRequired = jobRequired || activityRequireJob() && !accentUtils.isNull(viewModel.entity.JobID);

    var showContDetails = getShowContactDetails();
    const requireAddress = addressRequired;

    var addressButtons = [
        (<AccentButton tagName="calCopyCompanyBtn" disabled={isReadOnly} bsStyle="primary" onClick={copyCompanyAddress}>
            <AccentIcons.Company top="4" />
            {t("application_strings.application.buttons.copyAddress")}
        </AccentButton>),
        (<AccentButton tagName="calCopyContactBtn" disabled={isReadOnly} bsStyle="primary" onClick={copyContactAddress}>
            <AccentIcons.Contact top="4" />
            {t("application_strings.application.buttons.copyAddress")}
        </AccentButton>)
    ];
    var jobLines = viewModel.entity.JobLines;

    
    const contactInfo = accentUtils.isEmpty(viewModel.entity.ContactID) ? null :
        <ContactInfoCtrl
            isLarge
            style={{ display: 'inline-block' }}
            id={viewModel.entity.ContactID}
            JobID={viewModel.entity.JobID}
            ActivityID={viewModel.entity.id}
            OpportunityID={viewModel.entity.OpportunityID} />;

    var address = siteAddressCtrl.current ? siteAddressCtrl.current.getAddress() : viewModel.entity.Address;
    var contactID = viewModel ? viewModel.entity.ContactID : null;

    const headerContent = <React.Fragment >
        <div className="me-auto" ><h5>{t("application_strings.views.calendar.appointment")}</h5></div>
        <AccentDropdownButton tagName="calendarActions" title={t("application_strings.application.buttons.actions")}>
            <DropdownMenu>
                {!showNewQuote ? null :
                    (
                        <DropdownItem onClick={newQuote}>
                            {t("application_strings.application.buttons.newQuote")}
                        </DropdownItem>
                    )}
                {!showNewServiceJob ? null :
                    (
                        <DropdownItem onClick={newServiceJob}>
                            {t("application_strings.application.buttons.newServiceJob")}
                        </DropdownItem>
                    )}
                {!showNewQuote && !showNewServiceJob ? null :
                    (
                        <DropdownItem divider />
                    )}
                {!showStartCM ? null :
                    <DropdownItem disabled={!getAccess().features().FeatureAllowPerformCheckMeasure()} onClick={startCM}>
                        {t("application_strings.application.buttons.startCM")}
                    </DropdownItem>
                }
                {!showComplete ? null :
                    <DropdownItem onClick={closeActivity}>
                        {t("application_strings.application.buttons.completeAppointment")}
                    </DropdownItem>
                }
                {!showReOpen ? null :
                    <DropdownItem onClick={openActivity}>
                        {t("application_strings.application.buttons.reOpenAppointment")}
                    </DropdownItem>
                }
                {!showCancel ? null :
                    <DropdownItem onClick={cancelActivity}>
                        {t("application_strings.application.buttons.cancelAppointment")}
                    </DropdownItem>
                }
            </DropdownMenu>
        </AccentDropdownButton>
    </React.Fragment>;


    const actionsContent = [        
        <AccentButton key="2" tagName="calCloseAndShowBtn" disabled={isReadOnly || !validation.isValid} onClick={(e) => saveActivity(e, true)}>
            <AccentDisplay large tablet>
                {t("application_strings.application.buttons.save")} and show in <AccentIcons.Calendar style={{ marginTop: "-10px" }} top="5" />
            </AccentDisplay>
            <AccentDisplay mobile>
                <AccentIcons.Calendar top="4" />
            </AccentDisplay>
        </AccentButton>,
        <AccentButton key="3" tagName="calCloseBtn" disabled={isReadOnly || !validation.isValid} onClick={(e) => saveActivity(e, false)}>
            <AccentDisplay large tablet>
                {t("application_strings.application.buttons.save")} and Close
            </AccentDisplay>
            <AccentDisplay mobile>
                <AccentIcons.Save top="4" />
            </AccentDisplay>
        </AccentButton>
    ];

    return (
        <div>
            <AccentDialog                
                id="accentCalendarDlg"
                className="accentCalendarDlg"
                fullscreen
                onClose={cancelDlg }
                headerContent={headerContent}
                actionsContent={actionsContent}
            >
                    <Row>
                        <Col md={6}>
                            <AccentFormCtrlNoViewState model={apptType} />
                            <AccentFormCtrlNoViewState model={apptCategory} />
                            <AccentFormCtrlNoViewState model={subject} />
                            <UserTeamSelectCtrl defaultValue={viewModel.defaultUserTeam} onTeamChange={onTeamChanged} onUserChange={onUserChanged} tagName="activityUserTeam" readOnly={isReadOnly} />

                            <AccentFormCtrlNoViewState key={`att${attendeeReadOnly}`} ref={ctrlAttendees} model={attendees} />
                        </Col>
                        <Col md={6}>
                            <AccentFormCtrlNoViewState model={apptPriority} />
                            <AccentFormCtrlNoViewState key={`start_${forceUpdateDatesKey}`} model={start} />
                            <AccentFormCtrlNoViewState key={`end_${forceUpdateDatesKey}`} model={end} />
                            <AccentFormCtrlNoViewState model={allDay} />
                            {!showJobOpp ? null : <div className='row'>
                                <div className="col-12">
                                    <ActivityJobCtrl
                                        viewModel={viewModel}
                                        onChange={onJobChanged}
                                        readOnly={!allowEditJobOpp}
                                        required={jobRequired}
                                        post={[<AccentButton onClick={onShowInstallSummary}><AccentIcons.Eye top="3" style={{ fontSize: "18px"} } /></AccentButton>]  }
                                    />
                                </div>
                            </div>}
                            {!showJobOpp ? null : <AccentFormCtrlNoViewState model={opp} />}
                        </Col>
                    </Row>
                    {!showContDetails ? null :
                        <React.Fragment>                                                        
                            <Row>
                                <Col md={6} xs={12}>
                                    <div className="acc-hr">Contact Details</div>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <AccentFormCtrlNoViewState ref={cboCompany} model={company} post={[display.mobile ? null : addressButtons[0]] } />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <AccentFormCtrlNoViewState
                                                ref={cboContact}
                                                model={contact}
                                                post={[contactInfo,  display.mobile? null : addressButtons[1]]} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <AccentFormCtrlNoViewState model={confirmedDay} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <ActivityJobLineCtrl key={forceUpdateKey} viewModel={viewModel} readOnly={isReadOnly} required={jobLinesRequired} />
                                        </Col>
                                    </Row>
                                </Col>
                                <AccentDisplay large tablet>
                                    <Col md={6}>
                                        <div className="acc-hr">Address Details</div>
                                        <AccentAddress ref={siteAddressCtrl} entity={viewModel.entity} addressMember="Address" readOnly={isReadOnly} onChange={onAddressChanged} required={requireAddress} />
                                    </Col>
                                </AccentDisplay>
                            </Row>                            
                        </React.Fragment>
                    }
                    <AccentDisplay mobile>
                        <div className="acc-hr">Address Details</div>
                        <div className="d-flex justify-content-between">
                            <div className="pt-2 pb-2">
                                {addressButtons[0]}
                            </div>
                            <div className="pt-2 pb-2 pe-4">
                                {addressButtons[1]}
                            </div>
                        </div>
                        <AccentAddress ref={siteAddressCtrl} entity={viewModel.entity} addressMember="Address" readOnly={isReadOnly} onChange={onAddressChanged} required={requireAddress} />
                    </AccentDisplay>
                    <div className="acc-hr">Notes</div>
                    <Row>
                        <Col md={12}>
                            <Row><Col md={12}><AccentFormCtrlNoViewState id="act-notes" noLabel model={notes} /></Col></Row>
                        </Col>
                    </Row>
            </AccentDialog>

        </div>
    );

}));
