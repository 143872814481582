import * as React from "react";
import { TreeListBooleanEditor, TreeList, mapTree, treeToFlat } from '@progress/kendo-react-treelist'
import { AccentButton } from "../controls/AccentButton";
import { accentUtils, from, t } from "../services/HelperService";
import { AccentDialog } from "./AccentDialog";
import { AccentSpinner } from "../controls/AccentSpinner";
import { usePosition } from "../controls/AccentDisplay";
import { update } from "../services/DataService";
import { Alert } from "reactstrap";





const processTree = (dataTree, childrenField, processItem) => {    
    return mapTree(dataTree, childrenField, (item) =>processItem(item));
};


export const MoveToSupplyDlg = props => {

    const [data, setData] = React.useState(null);
    const [expanded, setExpanded] = React.useState({});
    const [selected, setSelected] = React.useState({});
    const [hasAccess, setHasAccess] = React.useState(true);

    const pos = usePosition("moveToSupplyTree");

    const onExpandChanged = React.useCallback(e => {

        setExpanded(existing => {
            return { ...existing, [e.dataItem.ID]: !e.value };
        });

    }, [setExpanded])

    const onItemChanged = React.useCallback(e => {


        const items = treeToFlat([e.dataItem], "ID", "Children");

        const newChecked = from(items).select(i => i.ID).toArray().reduce((a, v) => ({ ...a, [v]: e.value }), {});

        setSelected(existing => {
            return { ...existing, ...newChecked };
        });
    }, [setSelected]);


    const onMoveToSupply = React.useCallback(e => {

        const allItems = treeToFlat(data, "ID", "Children");

        props.onClose({
            canceled: false,
            items : from(allItems)
                .where(i => selected[i.ID] && !accentUtils.isEmpty(i.LineID))
                .groupBy(i => i.JobID)
                .select(g => {
                    return {
                        ID: g.key(),
                        LineIDs: g.select(x => x.LineID).toArray()
                    };
                }).toArray()
        });

    },[data, selected]);



    React.useEffect(() => {


        update("GetMoveToSupplyWizardModel", { Jobs: props.jobIDs }).then(model => {

            if (!model.UserHasAccess) {

                setHasAccess(false);

            } else {
                setHasAccess(true);

                const allExpanded = {};
                const defaultSelection = {};
                const r = model.Tree;

                

                setData(processTree([r], "Children", item => {

                    allExpanded[item.ID] = true;

                    const itemJob = from(props.jobIDs).firstOrDefault(x => x.ID === item.JobID);
                    const hasLineFilter = (itemJob?.LineIDs ?? []).length > 0;
                    const lineIDFilterSelect = from((itemJob?.LineIDs ?? [])).any(id => id === Number(item.ID));


                    defaultSelection[item.ID] = hasLineFilter && lineIDFilterSelect || !hasLineFilter;

                    return { ...item, expanded: true, editing: true, Stage: t(item.Stage), Dispatch: t(item.Dispatch) };
                }));



                setSelected(defaultSelection);
                setExpanded(allExpanded);


            }
        })


    }, []);


    const hasSelection = from(Object.values(selected)).any(s => s === true);

    let hasInvalidSelection = !hasSelection;
    let qty = 0;
    const treeData = processTree(data ?? [], "Children", item => {

        const itemSelected = selected[item.ID] ?? false;

        const isLineItem = (item.Children?.length ?? 0) === 0;

        if (itemSelected && !item.Validation.CanMove && isLineItem)
            hasInvalidSelection = true;

        if (isLineItem && itemSelected) {
            qty += (item.Qty ?? 0);
        }


        return { ...item, expanded: expanded[item.ID] ?? false, selected: itemSelected };
    });

    

    const height = pos.toScreenHeight - 50;

    return <AccentDialog
        fullscreen
        headerContent={t("application_strings.application.dialogs.moveToSupply.title")}
        onClose={props.onClose}
        actionsContent={[<AccentButton key="1" disabled={hasInvalidSelection} tagName="save" onClick={onMoveToSupply}>{t("application_strings.application.buttons.moveToSupply")}</AccentButton>]}
    >
        {!hasAccess && <Alert color="danger">{t("application_strings.application.dialogs.moveToSupply.noAccess")}</Alert>}
        {hasAccess && accentUtils.isNull(data) && <AccentSpinner />}
        {hasAccess && !accentUtils.isNull(data) &&

            <div id="moveToSupplyTree">
                <TreeList                    
                    data={treeData}
                    style={{
                        height: `${height}px`,
                        overflow: "auto",
                    }}
                    editField="editing"
                    expandField="expanded"
                    subItemsField="Children"
                    validationField="validation"
                    columns={columns}
                    onExpandChange={onExpandChanged}
                    onItemChange={onItemChanged} />
                <div className="float-right"><label>{t("application_strings.application.dialogs.moveToSupply.selectedQty")}</label>:<span style={{ laddingLeft: "20px" }}>{qty}</span></div>
            </div>
        }
    </AccentDialog>

};

function getValidationMessage(validation) {

    if (validation.CanMove) {
        return t("application_strings.application.dialogs.moveToSupply.canMove");
    } else if (validation.InEdit) {
        return t("application_strings.application.dialogs.moveToSupply.inEdit");
    } else if (validation.ValidationErrors) {
        return t("application_strings.application.dialogs.moveToSupply.validationErrors");
    } else if (validation.RequireCM) {
        return t("application_strings.application.dialogs.moveToSupply.requireCM");
    } else if (validation.InSupply) {
        return t("application_strings.application.dialogs.moveToSupply.inSupply");
    } else if (validation.Dispatched) {
        return t("application_strings.application.dialogs.moveToSupply.dispatched");
    } else if (validation.Dispatch) {
        return t("application_strings.application.dialogs.moveToSupply.dispatch");
    }

    return "";
}

const ValidtionCell = (props) => {
    const { dataItem } = props;
    const field = props.field || "";
    const validation = dataItem[field];

    const isLine = !accentUtils.isEmpty(dataItem.LineID);
    
    const cellData = isLine ? getValidationMessage(validation) : "";

    const items = treeToFlat([dataItem], "ID", "Children");

    const hasChildren = dataItem?.Children?.length ?? 0 > 0;
    const isExpanded = dataItem.expanded;

    

    let value = String(accentUtils.isEmpty(cellData) ? "" : cellData);
    let isValid = validation.CanMove;
    if (hasChildren && !isExpanded) {

        const hasInvalidChild = from(items).where(i => i.ID !== dataItem.ID).any(i => !i.Validation.CanMove && (i.Children?.length ?? 0) === 0);

        if (hasInvalidChild) {
            value = t("application_strings.application.dialogs.moveToSupply.hasError");
        } else {
            value = t("application_strings.application.dialogs.moveToSupply.ready");
        }

        isValid = !hasInvalidChild;
    }

    return (
        <td>
            <span
                style={{
                    color: isValid ? "green" : "red",
                }}
            >
                {value}
            </span>
        </td>
    );
};



const columns = [
    {
        field: "selected",
        title: " ",
        expandable: true,
        editCell: TreeListBooleanEditor,
        locked: true,
        width: 30,
    },
    {
        field: "Item",
        title: "Item",
        width: 250,
        expandable: true,

    },
    {
        field: "Validation",
        title: "Validation",
        width: 250,
        expandable: false,
        cell: ValidtionCell
    },
    {
        field: "Qty",
        title: "Qty",
        width: 70,
        expandable: false,
    },
    {
        field: "Stage",
        title: "Stage",
        width: 250,
        expandable: false,
    },
    {
        field: "LocationOther",
        title: "Location",
        width: 250,
        expandable: false,
    },
    {
        field: "Dispatch",
        title: "Dispatch",
        width: 250,
        expandable: false,
    },
    {
        field: "DisplayOption1",
        title: "Option 1",
        width: 250,
        expandable: false,
    },
    {
        field: "DisplayOption2",
        title: "Option 2",
        width: 250,
        expandable: false,
    },
    {
        field: "DisplayOption3",
        title: "Option 3",
        width: 250,
        expandable: false,
    }

];