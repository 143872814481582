import { isNumeric } from 'jquery';
import * as React from 'react';
import { useState } from 'react';
import { suspend, clear, peek } from 'suspend-react'
import { accentUtils } from '../../services/HelperService';
import { getProductConfig } from '../../services/ProductService';
import { AccentSpinner } from '../AccentSpinner';
import { useConfigInProgress } from './ProductConfiguratorShared';

export const ConfiguratorContext = React.createContext();


export const ConfiguratorContextProvider = React.memo(props => {

    const [config, setConfig] = useState(null);

    const isInProgress = useConfigInProgress(`ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`, config, props.onInProgressChanged);

    let companyClientRef = "";
    if (!accentUtils.isNull(props.businessUnitID)) {
        companyClientRef = `BU_${props.businessUnitID}`;
    }

    var companyInfo =
        [
            { CompanyID: props.mpResellerKeys, CompanyClientReference: companyClientRef }
        ];


    const isReadOnly = props.isReadOnly;



    React.useImperativeHandle(
        props.methods,
        () => ({
            save: () => {
                config.DoSave();
            },
            isInProgress: () => {
                return isInProgress.current;
            }

        }),
        [config, isInProgress]
    );


    React.useEffect(() => {

        getProductConfig(
            props.orderLine,
            props.orderLineOptions,
            isReadOnly,
            props.isQuote,
            props.onSave, //save olos
            props.onChange,
            props.ignoreSizes,
            companyInfo).then(c => {
                setConfig(c);
            });

    }, [setConfig, props.orderLine.ID, props.productID]);

    React.useEffect(() => {

        if (accentUtils.isNull(config)) return;

        console.log("ConfiguratorContextProvider: (Mounted): ",props.orderLine.ID);

        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, props.onChange, `ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`);
        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, props.onChange, `ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`);

        if (props.onLoaded) props.onLoaded();

        return () => {

            if (accentUtils.isNull(config)) return;

            config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, `ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`)
            config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, `ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`)
        };
    }, [config, props.productID, props.orderLine.ID]);


    if (accentUtils.isNull(config)) return (props.loader) ? props.loader() :  <AccentSpinner />;

    return <ConfiguratorContext.Provider value={{ config: config, isReadOnly: isReadOnly, orderLine: props.orderLine, productID: props.productID, filter: props.filter, isInProgress: isInProgress.current, notifyDirty: props.notifyDirty, baseImagePath : window.imageBaseURL }}>
        {props.children}
    </ConfiguratorContext.Provider>


});
