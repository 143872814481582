import * as React from 'react'
import { Row, Col } from 'reactstrap'
import { accentUtils, t, formHelper, from, newGuid} from '../services/HelperService'
import { queryNoTracking, addEntity, queryTracking, deleteEntity, detachEntity  } from '../services/DataService'
import { AccentFormCtrl } from './bound/AccentFormCtrl'
import { AccentSpinner } from './AccentSpinner'
import { AccentNumberUnbound } from './unbound/AccentNumberUnbound'
import { RTLGridLayout } from './RTLGridLayout'


const query_PL_ProductOfInterests = queryNoTracking('PL_ProductOfInterests');


export class AccentProductsOfInterest extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            items: []
        };

        this.entity= {
            setProperty: function (name, val) {
                this[name] = val;
                props.setDirty();
            }
          
        };

        this.required = props.required;
        this.readOnly = props.readOnly;
        this.onChange = props.onChange;
        this.loaded = false;
        this.products = [];
        
        this.oppID = props.oppID;
        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onItemChanged = this.onItemChanged.bind(this);
        this.reset = this.reset.bind(this);
        this.getProductsOfInterestDescriptions = this.getProductsOfInterestDescriptions.bind(this);
        this.getAsDetached = this.getAsDetached.bind(this);

    }

    componentDidMount() {
        this.loadData();
    }


    async getListFromProducts() {

        const itemPromises = this.products.map(prod => {
            const existing = from(this.props.oppPOI).firstOrDefault(x => x.PL_ProductOfInterestID === prod.ID);

            if (accentUtils.isNull(existing)) {
                return addEntity("ProductOfInterest", { OpportunityID: this.oppID, PL_ProductOfInterestID: prod.ID, ProductOfInterestID : newGuid() });
            } else {
                return Promise.resolve(existing);
            }
        });


        return await Promise.all(itemPromises);
    }



    async loadData() {

            
        this.products = await query_PL_ProductOfInterests.getAll({ IncludeID: null, activeOnly: null });
                
        this.products = from(this.products).where(i => i.Active || from(this.props.oppPOI).any(o => o.PL_ProductOfInterestID == i.ID)).toArray();

        this.loaded = true;
        
        this.setState({
            items: await this.getListFromProducts()
        });

        

    }

    async reset() {
        this.setState({
            items: await this.getListFromProducts()
        });
    }

    onItemChanged(e, itm) {

        const items = [...this.state.items];

        items.map(i => {
            if (i === itm)
                i.Qty = e;
        });

        this.setState({
            items: items
        })

        if (this.props.setDirty)
            this.props.setDirty();
    }

    getProductsOfInterestDescriptions() {
        return this.state.items.map(i => {

            const product = from(this.products).firstOrDefault(p => p.ID === i.PL_ProductOfInterestID);

            if (accentUtils.isNull(product)) {
                return {
                    Description: "Unknown",
                    Qty: i.Qty
                };
            } else {
                return {
                    Description: product.Description,
                    Qty: i.Qty
                };
            }


        });
    }


    getAsDetached() {

        const res = [];

        this.state.items.map(i => {
            const isNew = (i.ID === -1);
            const isRequired = i.Qty > 0;

            const toDelete = !isNew && !isRequired;
            const toAdd = isNew && isRequired;
            const toSave = !isNew && isRequired

            detachEntity(i);

            if (!toDelete) {
                res.push(i);
            }
        }); 

        return res;

    }

    save() {

        return new Promise(p => {

            this.state.items.map(i => {
                const isNew = (i.ID === -1);
                const isRequired = i.Qty > 0;

                const toDelete = !isNew && !isRequired;
                const toAdd = isNew && isRequired;
                const toSave = !isNew && isRequired

                if (toDelete) {
                    deleteEntity(i);
                }
            }); 

            p();

        });

        
    }


   
    render() {


        if (!this.loaded) {
            return (<AccentSpinner />);
        }


        const required = !from(this.state.items).any(i => i.Qty > 0);
        const items = this.state.items.map(i => {

            const product = from(this.products).firstOrDefault(p=> p.ID === i.PL_ProductOfInterestID);

            return <AccentNumberUnbound disabled={this.props.readOnly} min={0} label={product.Description} defaultValue={i.Qty} invalid={required} required={required} onChange={e => this.onItemChanged(e, i)} />;
        });


        return (
            <Row className="accentPOI">
                <Col md={12}>
                    <RTLGridLayout cols={5} items={ items} />
                </Col>
            </Row>
        );
    }
}