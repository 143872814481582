//insyteData, insyteapp, dataloader
import * as React from 'react'

import { ComboBox} from '@progress/kendo-react-all'

import { accentUtils, t, formHelper, from } from '../../services/HelperService'
import { RTLQueryFetcher } from '../RTLFetch';

export class AccentComboBox extends React.Component {
    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        var template = {};

        if (!accentUtils.isNull(this.props.model.options.template)) {
            template = this.props.model.options.template;
        }

        this.pageSize = 100;


        this.queryFetcher = new RTLQueryFetcher({
            query : this.props.model.options.source
        })

        if (!accentUtils.isNull(this.props.model.options.pageSize)) {
            this.pageSize = this.props.model.options.pageSize;
        }

        this.state = {
            data: { data: [], total: 0 },
            dataState: { take: this.pageSize, skip: 0 },
            selectedID: -1
        };

        this.comboKey = 0;

        this.sourceType = this.props.model.options.sourceType;
        this.allowAdd = !accentUtils.isNull(this.props.model.options.comboAddNewValue);
        this.dataTextField = this.props.model.options.displayField;
        this.dataValueField = this.props.model.options.valueField;
        this.defaultSingle = this.props.model.options.defaultSingle;

        if (accentUtils.isNull(this.defaultSingle))
            this.defaultSingle = false;

        this.placeholder = this.props.model.options.placeHolder;

        this.onDataRecieved = this.onDataRecieved.bind(this);
        this.onGetDOM = this.onGetDOM.bind(this);
        this.filterChange = this.filterChange.bind(this);

        this.clear = this.clear.bind(this);
        this.getSelectedItem = this.getSelectedItem.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.clearValue = this.clearValue.bind(this);
        this.addNewValue = this.addNewValue.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.selectValue = this.selectValue.bind(this);
        this.setNewValue = this.setNewValue.bind(this);
        this.getDataIndexOf = this.getDataIndexOf.bind(this);
        this.caseInsensitiveCompare = this.caseInsensitiveCompare.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.runQuery = this.runQuery.bind(this);
    }

    onGetDOM() {
        return this.ctrl.current;
    }

    handleFocus() {
        this.ctrl.current.focus();
    }
    onDataRecieved() {

        if (this.defaultSingle) {
            if (this.state.data.data.length === 1) {
                this.props.model.setValue(this.state.data.data[0][this.dataValueField]);

                if (!accentUtils.isNull(this.props.model.options.onChange)) {
                    this.props.model.options.onChange({ newValue: false, value: this.state.data.data[0] });
                }

            }
        }

        
        if (!accentUtils.isNull(this.props.model.options.onDataRecieved)){
            this.props.model.options.onDataRecieved(this.state.data);
        }

    }

    selectValue(value) {


        var me = this;

        return new Promise((p)=> {


            me.props.model.setValue(value);
            me.props.model.modified();
            me.forceUpdate();
            p();

        });

    }

    refreshData() {


        return new Promise(p => {

            this.runQuery().then(() => {
                this.selectValue(this.props.model.getValue()).then(() => {
                    p();
                });
            });

            

        });

        
    }

    getSelectedItem() {
        return this.ctrl.current.value;
    }

    componentDidMount() {
        this.runQuery();
    }

    runQuery() {

        return new Promise(p => {

            this.queryFetcher.fetchData(this.state.dataState).then(res => {

                this.setState({
                    data: { ...res },
                    loading: false
                }, () => {
                    this.onDataRecieved();
                    p();
                });

            });

            this.setState({ loading: true });


        });

        
    }

    addNewValue(e) {


        var me = this;
        var val = e.value;

        var combo = e.sender;


        me.props.model.options.comboAddNewValue(val).then(function (o) {
            alert("TODO comboAddNewValue");
            ////insyteData.saveEntityOnly([o]).then(function (a) {

            ////    me.props.model.setValue(o.ID);
            ////    me.props.model.modified();
            ////    me.dataKey += 1;
            ////    me.forceUpdate();


            ////});
        });






    }

    clear(e) {


        this.props.model.setValue(null);
        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange({ newValue: false, value: null });
        }

    }


    clearValue() {


        this.props.model.setValue(null);
        this.forceUpdate();

    }

    getAddNewValueArgs(val, sender) {
        return {
            sourceType: this.sourceType,
            value: val,
            sender: sender
        }
    }



    setNewValue(val) {

        var me = this;

        var combo = this.ctrl.current;


        if (this.allowAdd && !accentUtils.isEmpty(val)) {


            if (!accentUtils.isNull(this.props.model.options.comboAddNewValue)) {
                this.props.model.options.comboAddNewValue(val).then(function (o) {

    


                    var v = null;

                    if (o === null || typeof o === 'string') {
                        v = o;
                    } else {
                        v = o.ID;
                        me.ctrl.current.props.data.push(o);
                        me.comboKey++;
                        //me.ctrl.current.state.selectedIndex = me.ctrl.current.props.data.length - 1;
                    }

                    me.props.model.setValue(v);

                    me.props.model.modified();


                    if (!accentUtils.isNull(me.props.model.options.onChange)) {
                        me.props.model.options.onChange({ newValue: true , value: v});
                    }


                    me.setState({
                        dataState: { take: me.pageSize, skip: 0 },
                        selectedID: 0
                    });

                });
            }            

            return true;
        }

        return false;
    }

    onChange(e) {

        var value = e.target.value;



        if (accentUtils.isNull(this.props.model.getValue()) && accentUtils.isNull(value)) { // no change
            return;
        }



        var me = this;

        var combo = e.sender;



        var isCustomValue = value && value[this.dataValueField] === undefined;

        // check if new value is a custom one
        if (isCustomValue) {

            if (this.setNewValue(value)) {
                return;
            }

        }
        console.log('combo on change2');


        var val = null;

        if (!accentUtils.isNull(value)) {
            val = value[this.dataValueField];
        }



        this.props.model.setValue(val);

        this.props.model.modified();

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange({ newValue: false, value: !accentUtils.isNull(value) ? value : null });
        }


        if (!accentUtils.isNull(this.state.dataState.filter)) {
            // clear filter and cause requiry
            this.setState({
                dataState: {
                    take: this.state.dataState.take,
                    skip: this.state.dataState.skip,
                    filter: null
                }
            }, () => this.runQuery());
        }



    }

    onOpen(e) {
    }

    onClose(e) {
    }



  

    filterChange(event) {

        this.setState({
            dataState: {
                take: this.state.dataState.take,
                skip: this.state.dataState.skip,
                filter: {
                    logic: 'and',
                    filters: [event.filter]
                }
            }
        }, () => {
            this.runQuery();
        });



    }

    getDataIndexOf(value) {
        return this.state.data.data.indexOf(value);
    }


    caseInsensitiveCompare(v1, v2) {

        var v1IsNull = accentUtils.isNull(v1);
        var v2IsNull = accentUtils.isNull(v2);

        if (v1IsNull !== v2IsNull) {
            return false;
        }

        if (v1IsNull && v2IsNull) {
            return true;
        }

        return v1.toUpperCase() === v2.toUpperCase();
    }

    render() {


        var me = this;

        var currVal = this.props.model.getValue();

        var currItem = this.props.model.options.caseInsensitive ? 
            from(this.state.data.data).firstOrDefault(i => me.caseInsensitiveCompare(i[me.dataValueField], currVal))            
            : from(this.state.data.data).firstOrDefault(i => i[me.dataValueField] === currVal);



        var readOnly = formHelper.isControlReadOnly(this.props.model);


        var itemRender = undefined;

        if (!accentUtils.isNull(this.props.model.options.template)) {
            itemRender = function (li, itemProps) {
                return React.cloneElement(li, li.props, me.props.model.options.template(li, itemProps));
            };
        }



        return (
            <div className="accentValidCtrl" key={this.comboKey}>
                <ComboBox         
                        ref={this.ctrl}
                        data={this.state.data.data}
                        value={accentUtils.isNull(currItem) ? null : currItem}
                        onChange={this.onChange}
                        textField={this.dataTextField}
                        dataItemKey={this.dataValueField}
                        disabled={readOnly}
                        label={this.props.model.displayLabel}
                        filterable
                        allowCustom={this.allowAdd}
                        itemRender={itemRender}
                        onOpen={this.onOpen}
                        loading={ this.state.loading}
                        onFilterChange={this.filterChange}
                        
                        onClose={this.onClose}                        
                />                
            </div>
        );
    }
}
