import authService from './../api-authorization/AuthorizeService';
import { queryOther, queryAPIScript, entityManagerProvider } from './DataService';
import $ from 'jquery';
import { accentUtils } from './HelperService';



class UserService {


    wasAuthenticated = false;
    userModel = null;
    access = null;
    notifications = [];

    constructor() {

        this.wasAuthenticated = false;
        
    }



    async Initialize() {

        try {



            const isAuthenticated = await authService.isAuthenticated();

            if (isAuthenticated) {
                await entityManagerProvider.Initialize();


                var userInfo = await Promise.all([queryAPIScript("User", "GetUserSecurityModel"), queryOther("User", "GetUserModel", "GET")]);

                const userResult = userInfo[1];
                this.userModel = userResult.data;


                if (getAccess().isTrial() && getAccess().trialDays() < 0) {
                    alert("Your Insyte Demo has expired. Please contact Accent Sales on sales@accentsoftware.com.au");
                    logoff();
                    return;
                }

                if (getAccess().systemDisabled()) {
                    alert("Your Insyte System has been Disabled. Please contact your Administrator");
                    logoff();
                    return;
                }

                if (!getAccess().modules().RTL_Login() && !getAccess().isTrial()) {
                    alert("This account does not have a login license");
                    logoff();
                    return;
                }

                this.notifications.map(n => {
                    n.callBack();
                });

                return this.userModel;

            }
        } catch (e) {
            console.log(e);
            if (authService.isImpersonation()) {
                alert("You cannot log in as this user");
                
            }
            authService.signOutAuthFailed();
            return;

        }
    }

    registerOnInit(key, callBack) {
        this.notifications.push({ key: key, callBack: callBack });
    }
    deRegisterOnInit(key) {
        this.notifications = this.notifications.filter(n=>n.key !== key);
    }



    getUserModel() {
        return this.userModel;
    }

}

const userService = new UserService();

export default userService;

export function logoff() {

    authService.signOut();

};


export function getAccess() {

    if (!window.getAccess) {
        return false;
    }

    return window.getAccess();
};

export function getUser() {
    return userService.getUserModel();
};


