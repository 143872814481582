window.myinsyteresources = {
	en: {
        translation: {

            "disc_rule_level_base": "BASE PRICE",

            "bu_item_type_opportunity": "Opportunity",
            "bu_item_type_job": "Job",
            "bu_item_type_invoice": "Invoice",

            "report_param_title": "TITLE",
            "report_param_tandc": "TERMS AND CONDITIONS",
            "report_param_payment": "PAYMENT TERMS",
            "report_param_options_format": "OPTIONS LAYOUT",
            "report_param_options_format_comma": "Comma List",
            "report_param_options_format_table": "Table",
            "report_param_options_format_hidden": "Hidden",
            "report_param_option_colums":"COLUMNS",
            "report_style_title": "TITLE",
            "report_style_caption": "CAPTIONS",
            "report_style_notes": "NOTES",
            "report_style_options": "OPTIONS",

            "report_param_business_number_label": "BUSINESS NUMBER LABEL",
            "report_param_business_address": "BUSINESS ADDRESS",
            "report_param_business_address_address": "Address",
            "report_param_business_address_billing": "Billing",
            "report_param_business_address_delivery": "Delivery",
            "report_param_show_sizes": "SHOW SIZE",
            "report_param_show_sizes_show": "Show",
            "report_param_show_sizes_hide": "Hide",
            "report_param_show_price": "SHOW PRICE",
            "report_param_show_price_show": "Show",
            "report_param_show_price_hide": "Hide",
            "report_param_options_format_table_columns": "COLUMNS",
            "report_param_options_format_table_columns_1": "1",
            "report_param_options_format_table_columns_2": "2",
            "report_param_options_format_table_columns_3": "3",
            "report_param_options_format_table_columns_4": "4",
            "report_param_options_format_table_columns_5": "5",
            "report_param_show_customer_notes": "SHOW CUSTOMER NOTES",
            "report_param_show_customer_notes_show": "Show",
            "report_param_show_customer_notes_hide": "Hide",
            "report_param_show_manufacturing_notes": "SHOW SUPPLY MANUFACTURING NOTES",
            "report_param_show_manufacturing_notes_show": "Show",
            "report_param_show_manufacturing_notes_hide": "Hide",
            "report_param_show_install_notes": "SHOW INSTALLATION NOTES",
            "report_param_show_install_notes_show": "Show",
            "report_param_show_install_notes_hide": "Hide",
            "report_param_show_line_number": "SHOW LINE NUMBER",
            "report_param_show_line_number_show": "Show",
            "report_param_show_line_number_hide": "Hide",
            "report_param_show_location": "SHOW LOCATION",
            "report_param_show_location_show": "Show",
            "report_param_show_location_hide": "Hide",

            "acc_type_retail": "Retail",
            "acc_type_wholesale": "Wholesale",
            "acc_type_commercial": "Commercial",

            "stm_type_activity": "Activity",
            "stm_type_outstanding":"Outstanding",


            "doc_temp_type_quote": "Quote",
            "doc_temp_type_oc": "Order Confirmation",
            "doc_temp_type_inv": "Invoice",
            "doc_temp_type_stmt": "Statement",
            "doc_temp_type_install": "Install Job Sheet Report",
            "doc_temp_type_js": "Job Summary",

            "charge_to_na": "N/A",
            "charge_to_office": "Office",
            "charge_to_installer": "Installer",
            "charge_to_sales_rep": "Sales Rep",
            "charge_to_supplier": "Supplier",
            "charge_to_mf_department": "MF Department",


            "job_edit_conflict_order": "Job",
            "job_edit_conflict_order_suggestion": "May want to send order confirmation to customer",
            "job_edit_conflict_address": "Address Changed",
            "job_edit_conflict_address_suggestion": "May want to update appointments with new address",
            "job_edit_conflict_invoice": "Invoice",
            "job_edit_conflict_invoice_suggestion": "May want to cancel and resend invoice",
            "job_edit_conflict_supply_job": "Supply Job",
            "job_edit_conflict_supply_job_suggestion": "May need to cancel and resend Supply job.",
            "job_edit_conflict_appointments": "Appointment",
            "job_edit_conflict_appointments_suggestion": "May want to check that appointment is still valid",
            "job_edit_conflict_validation": "Validation Error",
            "job_edit_conflict_validation_suggestion": "Fix errors before continuing",

            "PurchaseOrder": "Supply Job",
            "status_invoice_overdue": "Overdue",
            "status_invoice_awaiting_payment": "Awaiting Payment",

            "quote_expired": "EXPIRED",
            "job_closed": "CLOSED",
            "job_cancelled": "CANCELLED",
            "job_lost": "LOST",
            "job_order": "ORDER",

            "type_job_sales": "Sales",
            "type_job_service": "Service",

            "stage_job_quote": "Quote",
            "stage_job_order": "Order",
            "stage_job_order_edit": "EDIT",
            "stage_job_lost": "Lost",
            "status_job_open": "Open",
            "status_job_closed": "Closed",
            "status_job_sent": "Sent",
            "status_job_supply": "Supply",
            "status_job_cancelled": "Cancelled",
            "status_job_on_hold": "OnHold",
            "status_job_lost": "Lost",

            "priority_job_standard": "Standard",
            "priority_job_high":"High",

            "dispatch_job_line_install_not_booked": "Install Not Booked",
            "dispatch_job_line_install_booked": "Install Booked",
            "dispatch_job_line_delivery_not_booked": "Delivery Not Booked",
            "dispatch_job_line_delivery_booked": "Delivery Booked",
            "dispatch_job_line_pickup_not_booked": "Pickup Not Booked",
            "dispatch_job_line_pickup_booked": "Pickup Booked",
            "dispatch_job_line_installed": "Installed",
            "dispatch_job_line_picked_up": "Collected",
            "dispatch_job_line_delivered": "Delivered",
            "dispatch_job_line_pending_service": "Service Pending",

            "stage_job_line_unconfirmed_sent": "Unconfirmed Sent",
            "stage_job_line_unconfirmed": "Unconfirmed",
            "stage_job_line_unconfirmed_cm": "CM Unconfirmed",
            "stage_job_line_cm_not_booked": "CM Not Booked",
            "stage_job_line_cm_booked": "CM Booked",
            "stage_job_line_cm_inprogress": "CM In Progress",
            "stage_job_line_cm_completed": "CM Completed",
            "stage_job_line_cm_completed_unconfirmed": "CM Completed (Unconfirmed)",
            "stage_job_line_cm_completed_confirmed": "CM Completed (Confirmed)",
            "stage_job_line_confirmed": "Confirmed",
            "stage_job_line_confirmed_cm": "CM Confirmed",
            "stage_job_line_confirmed_edit": "EDIT",
            "stage_job_line_supply": "Supply",
            "stage_job_line_supply_pending": "Supply Pending",
            "stage_job_line_dispatch": "Dispatch",
            "stage_job_line_invoicing": "Invoicing",
            "status_job_line_open": "Open",
            "status_job_line_closed": "Closed",
            "status_job_line_cancelled": "Cancelled",
            "type_job_line_new": "New",
            "type_job_line_remake": "Remake",
            "type_job_line_alter": "Alteration",
            "type_job_line_service": "Service",

            "status_po_released": "Released",
            "status_po_open": "Open",
            "status_po_sent": "Sent",
            "status_po_partial_delivered": "Partial Delivered",
            "status_po_delivered": "Delivered",
            "status_po_closed": "Closed",
            "status_po_priced": "Priced",
            "status_po_received": "Received",
            "status_po_manufacturing": "Manufacturing",
            "status_po_dispatch": "Dispatch",
            "status_po_invoiced": "Invoiced",
            "status_po_cancelled": "Cancelled",

            "status_po_line_released": "Released",
            "status_po_line_open": "Open",
            "status_po_line_sent": "Sent",
            "status_po_line_partial_delivered": "Partial Delivered",
            "status_po_line_delivered": "Delivered",
            "status_po_line_closed": "Closed",
            "status_po_line_priced": "Priced",
            "status_po_line_received": "Received",
            "status_po_line_manufacturing": "Manufacturing",
            "status_po_line_dispatch": "Dispatch",
            "status_po_line_invoiced": "Invoiced",
			"status_po_line_cancelled": "Cancelled",
			"status_po_line_inprogress": "In-Progress",
			"status_po_line_completed": "Completed",


            "status_invoice_open": "Open",
            "status_invoice_closed": "Closed",
            "status_invoice_sent": "Sent",
            "status_invoice_cancelled": "Cancelled",


            "type_payment_payment": "Payment",
            "type_payment_refund": "Refund",
            "type_payment_credit": "Credit",
            "type_payment_transfer": "Transfer",

            "status_payment_draft": "Draft",
            "status_payment_confirmed": "Confirmed",
            "status_payment_cancelled": "Cancelled",
            "status_payment_finalised": "Finalised",


            "status_lead_open": "Open",
            "status_lead_abandoned": "Abandoned",
            "status_lead_lost": "Lost",
            "status_lead_won": "Won",

            "stage_lead_lead": "Lead",
            "stage_lead_negotiation": "Negotiation",
            "stage_lead_quotation": "Quotation",
            "stage_lead_contract": "Contract",

            "pt_job_pod": "Payment On Delivery",
            "pt_job_ppd": "Payment Prior Delivery",
            "pt_job_invoice_terms": "Invoice Terms",
            "pt_job_account_customer": "Account Customer",

            "pd_no_of_days": "In Given No Of Days",
            "pd_eom": "Day of the Month",
            "pd_days_after_eom": "Days after EOM",
            "pd_day_of_month_after_eom": "Day of the Month after EOM",


            "sales_rep": "Sales Rep",
            "current_user": "Current User",

            "comm_type_email": "EMAIL",
			"comm_type_sms": "SMS",
			"comm_type_sms_reply": "SMS REPLY",

            "rep_view_data_all": "All",
            "rep_view_data_teams": "Teams",
            "rep_view_data_own": "Own",


            "priority_activity_high" :"High", 
            "priority_activity_medium": "Medium", 
            "priority_activity_low": "Low",

            "comm_summary_type_val":"Value",
            "comm_summary_type_sum":"Sum",
            "comm_summary_type_count":"Count",
            "comm_summary_type_avg":"Average",
            "comm_summary_type_max":"Max",
            "comm_summary_type_min": "Min",
            "comm_summary_type_count_dist": "Count Distinct",

            "status_draft": "Draft",
            "status_paid": "Paid",
            "type_standard": "Standard",
            "type_amendment": "Amendment",
            "type_manual": "Manual",


            "commission_run_error_run_not_found":"Commission run could not found",
            "commission_run_error_run_contains_paid": "Attempting to rerun a PAID commission run",
            "commission_run_error_no_data": "No Job data for commissions found",
            "commission_run_error_no_rules": "No commission rules configured",
            "commission_run_error_no_user_rules": "No users allocated to commission rules",
            "commission_run_msg_saving": "Saving results",
            "commission_run_msg_done": "Finished",
            "commission_run_msg_email_done": "Emails Sent Successfully",
            "commission_run_msg_email_error": "Error sending email to 1 or more users. Please see notifications.",

            "application_strings": {
                "toasts": {
                    "invoiceCreated": "Invoice for {{Prompt}} processed",
					"supplyJobCreated": "Supply jobs for {{Prompt}} processed",
					"supplyJobCreateFailed": "Supply jobs for {{Prompt}} has errors",
					"confirmJobValidationInvalidStage": "Unable to validate job {{Prompt}}, job is currently being edited",
					"confirmJobValidationErrors": "Job {{Prompt}} has some errors",

                },
                "operators": {
                    "eq": "Equals",
                    "ne": "Does not equal",
                    "ge": "Is greater than or equal to",
                    "le": "Is less than or equal to",
                    "gt": "Is greater than",
                    "lt": "Is less than",
                    "startswith": "Begins with",
                    "endswith": "Ends with",
                    "isempty": "Is empty",
                    "isnotempty": "Is not empty",
                    "in": "Is one of",
                    "notin": "Is not one of",
                    "within": "Is with in days",
                    "relative": "Relative"
                },
                "months": {
                    "1": "Jan",
                    "2": "Feb",
                    "3": "Mar",
                    "4": "Apr",
                    "5": "May",
                    "6": "Jun",
                    "7": "Jul",
                    "8": "Aug",
                    "9": "Sep",
                    "10": "Oct",
                    "11": "Nov",
                    "12": "Dec",
                },
                "showMe": {
                    "IntroWholesaleOrders": {
                        "step_01": "This is a quick introduction to help with your first Supply Job (Wholesale order)",
                        "step_02": "The Supply menu will take you to the Supply Job dashboard where you can track your jobs",
                        "step_03": "This is the Supply Job Dashboard",
                        "step_04": "These buttons show a summary of the Supply Job statuses. Clicking on a button will show all Supply Jobs in that status",
                        "step_05": "This chart shows purchases for last few months",
                        "step_06": "This grid shows Supply Jobs that are past the ETA date",
                        "step_07": "Clicking on New will create a new Supply Job",
                        "step_08": "Click on the 'Show me how' button on a page if you get stuck"

                    },
                    "introQuoting": {
                        "step_01": "This is a quick introduction to help you find your way",
                        "step_02": "Home: Your default landing page. Here you will find some dashboards",
                        "step_03": "Calendar: Here you can see your calendar, and possibly other users calendars",
                        "step_04": "Leads: Here you can find recently added Leads",
                        "step_05": "Tracking: Here you can track jobs and action follow-up items",
                        "step_06": "New: Look for the New button to create new Leads, Payments etc.",
                        "step_07": "Tutorials: Here you can find short tutorials. The tutorials are constantly being updated, so lookout for them.",
                        "step_08": "Search: Here you can search for Jobs, Supply Jobs, Invoices, etc.",
                        "step_09": "Notifications: Here you will see notifications that need your attention",
                        "step_10": "Click on the 'Show me how' button on a page if you get stuck"

                    },
                    "InsyteLeadsIntegrationSettings": {
                        "step_01": "These are the post details of the api, that you need to provide to your Web Developer.",
                        "step_02": "Generate New API Key will invalidate the old url and generate a new one. Clicking this will stop your existing web site from working.",
                        "step_03": "You can use this section to test the API and generate a real lead. Just fill in the form details.",
                        "step_04": "This sections displays what API post data will look like. This is needed by your Web Developer.",
                        "step_05": "Note the 'CustomProperties' attribute; this will allow your Web Developer to post extra information that will be added to the Lead notes.",
                        "step_06": "Clicking this button will generate the test Lead via the API",
                    },
                    "Home": {
                        "step_01": "The Home page is your dashboard",
                        "step_02": "Edit the layout and widgets for your dashboard",
                        "step_03": "Save any changes to your dashboard",
                        "step_04": "Select one of our predefined layouts",
                    },
                    "WholesaleOrderDashboard_1": {
                        "step_01": "This is the Wholesale Ordering dashboard. From here you can view a basic summary of recent purchases",
                        "step_02": "Shows a breakdown of purchases in the last 6 months by product",
                        "step_03": "Shows a breakdown of purchases in the last 6 months by month",
                    },
                    "QuotingDashboard_1": {
                        "step_01": "This is the Quoting dashboard. From here you can navigate to your quotes and orders. It also shows information for your active quotes.",
                        "step_02": "Click on these buttons to list the quotes for the category",
                        "step_03": "This button shows the number and corresponding value of your active quotes. This includes Open and Sent quotes. Clicking on the button will take you to the active quote list.",
                        "step_04": "Open quotes not sent to the customer. Click on the button to see the list",
                        "step_05": "Quotes sent to the customer. Click on the button to see the list",
                        "step_06": "Quotes that have expired. Click on the button to see the list",
                        "step_07": "Quotes that have been converted to an Order. Click on the button to see the list",
                        "step_08": "Quotes that have been marked as lost. It is useful to mark quotes that the customer did not proceed with as lost. This is useful for marketing research",
                        "step_09": "Shows the number of quotes to expire in the following time brackets. [$t(application_strings.views.quotes.daysToExpire.cat5)], [$t(application_strings.views.quotes.daysToExpire.cat4)], [$t(application_strings.views.quotes.daysToExpire.cat3)], [$t(application_strings.views.quotes.daysToExpire.cat2)], [$t(application_strings.views.quotes.daysToExpire.cat1) ]",
                        "step_10": "Shows your quote-to-order conversion rate for the month, compared to your year-to-date average.",
                        "step_11": "Shows your sales funnel.",
                        "step_12": "Shows quotes vs orders and the conversion rate for the last 6 months",
                    },
                    "Calendar": {
                        "step_01": "The calendar allows you to manage Appointments",
                        "step_02": "Select the date you want to see here. The bold days indicate there are appointments",
                        "step_03": "Here you can select which users' calender to view. There is a user feature that controls if you can see other calendars",
                        "step_04": "This button allows you to change the colour for the user",
                        "step_05": "Here you can change the calendar view to see a day, week, etc.",
                        "step_06": "This is the name and colour for the user whose calendar is being displayed",
                        "step_07": "Click in a time slot to create a new appointment or drag and drop Appointments to re-shuffle",
                        "step_08": "Clicking on this icon will show you the day's route on the map."
                    },
                    "Leads": {
                        "step_01": "The Leads view will allow to you find existing leads. The most recently created lead will be at the top",
                        "step_02": "Use these buttons to find leads within each category. Hover over the button for more details on each category",
                        "step_03": "Use the New button to create new Leads"
                    },
                    "WebLeads": {
                        "step_01": "This a quick introduction on processing Web Leads. If you have not received any Web Leads, you can skip this wizard and try again when you do",
                        "step_02": "This is the list of Web Leads that have not been processed. Select a Web Lead to see its details.",
                        "step_03": "This shows the details of the Lead entered from you web site",
                        "step_04": "This section is where you action the Web Lead by discarding it or creating a new Lead",
                        "step_05": "Use the 'Discard Lead' button if you want to delete the Web Lead. Once discarded, you will not be able to create a Lead from it.",
                        "step_06": "Use the 'New Contact' button if there is no existing matching contact to create a new contact",
                        "step_07": "Use the 'Existing' button in the 'Possible Matching Contacts' list if the contact already exists.",
                    },
                    "Orders": {
                        "step_01": "Supply Jobs are the orders to your Supplier or Manufacturer",
                        "step_02": "Clicking on New will create a new Supply Job",
                        "step_03": "These buttons show a summary of the Supply Job statuses. Clicking on a button will show all Supply Jobs in that status",
                        "step_04": "This chart shows purchases for last few months",
                        "step_05": "This grid shows Supply Jobs that are past the ETA date"
                    },
                    "Job": {
                        "step_01": "When a new Job is created it is in the Quote stage. If a customer wants to go ahead with the Job you can confirm it, and it will go into the Order stage",
                        "step_02": "Use the toolbar to perform actions such as Save, Undo, Close, etc.",
                        "step_03": "The stage the Job is in",
                        "step_04": "Project/PO is a reference for the customer's project or purchase order",
                        "step_05": "Job Customer",
                        "step_06": "Clicking on New will allow you to add Products to this Job"
                    },
                    "JobView": {
                        "step_01": "Once a Job has been confirmed, it will move to the Order stage",
                        "step_02": "Use the toolbar to perform actions such as Editing the Job, Booking the Dispatch appointment, etc.",
                        "step_03": "The stage the Job is in",
                        "step_04": "Supply Jobs for this Job",
                        "step_05": "Invoices for this Job",
                    },
                    "JobLine": {
                        "step_01": "This is how to fill out a Product Line",
                        "step_02": "Select the Product you wish to add",
                        "step_03": "Choose a preset location, or type any value you like",
                        "step_04": "Configure the size and product details",
                        "step_05": "Clicking on Duplicate will create a second line with the same configuration"
                    },
                    "PurchaseOrder": {
                        "step_01": "Supply Jobs are the orders to your Supplier or Manufacturer",
                        "step_02": "Use the toolbar to perform actions such as Save, Undo, Close, etc.",
                        "step_03": "Customer Reference allows you to track which customer this Supply Job is for",
                        "step_04": "Fill in the Supplier",
                        "step_05": "Clicking on New will create a Product Line for this Supply Job"
                    },
                    "OrderLine": {
                        "step_01": "This is how to fill out a Product Line",
                        "step_02": "Select the Product you wish to add",
                        "step_03": "Choose a preset location, or type any value you like",
                        "step_04": "Configure the size and product details",
                        "step_05": "Clicking on Duplicate will create a second line with the same configuration"
                    }
                },
                "application": {
                    "errors": {
                        "error_message_max_size_exceeded": "The maximum message size has been exceeded. Please review the size of attachments for this order."
                    },
                    "title": "Insyte Web",
                    "sms": {
                        "to": "Mobile Number",
                        "send": "Send"
                    },
					"menu": {
						"showHideContext": "Show/Hide Context",
	                    "login": "Log in",
                        "logoff": "Log Off",
                        "changePassword": "Change Password",
                        "changeProfile": "Edit Profile",
	                    "dashboard": "Dashboard",
                        "calendar": "Calendar",
                        "activities": "Activities",
                        "sales": "Sales",
                        "leads": "Leads",
                        "settings": "Settings",
                        "management": "Management",
                        "reports": "Reports",
                        "training": "Connect to Training",
                        "production": "Connect to Live",
                        "help": "Help",
                        "new": " New ",
                        "lead": " Lead",
                        "job": " Job",
                        "supply": " Supply",
                        "home": "Home",
                        "tracking": " Tracking",
                        "supplyJobs": " Supply",
                        "payment": " Payment",
                        "refund": " Refund",
                        "finance": " Finance",
                        "commissions": " Commissions",
                        "activity": " Activity",
                        "invoice": " Invoice",
                        "wholesaleOrder": " Wholesale Order",
                        "creditNote": " Credit Note",
                        "serviceJob": " Service Job",
                        "whatsNewTooltip": "Whats New",
                        "supportTooltip": "Help and Support"
	                },
					"buttons": {
						"update": "Update",
						"moveToSupply": "Send",
						"invoice": "Invoice",
						"generateLink": "Generate Link",
						"bookService": "Book Service Call in Calendar",
						"bookServiceMaps": "Book Service Call with Maps",
						"pay": "Pay",
                        "run": "Run",
                        "completeInstall": "Complete Appt.",
                        "previewStatement": "Preview Statements",
                        "emailStatement": "Email Statements",
                        "markAsPaid": "Mark As Paid",
                        "exportSummary": "Summary Export",
                        "createNextRun": "Create Next Run",
                        "showDiscounts": "Show Discounts",
                        "clearDiscounts": "Clear All Discounts",
                        "approveDiscount": "Approve Discount",  
						"viewSmsQueue": "View SMS Queue",
                        "setAlterProduct": "Set Alter Product",
                        "clearAlterProduct": "Clear Alter Product",
                        "docs": "Documentation",
                        "skipCM": "Skip Check Measure",
                        "startCM": "Start Check Measure",
                        "viewOtherCM": "View Other Check Measure",
                        "approveCM": "Approve Check Measure",
                        "completeCM": "Complete Check Measure",
                        "completeActivity": "Complete Activity",
						"enableSupport": "Click to Enable Support Access",
						"disableSupport": "Click to Disable Support Access",
						"disable2Factor": "Click to Disable 2 Factor Auth",
						"manage2Factor": "Click to Manage 2 Factor Auth",
                        "enable2Factor":"Click to Enable 2 Factor Auth",
                        "sendTestEmail": "Send Test Email",
                        "sendTestSms": "Send Test SMS",
                        "bold": "Bold",
                        "italic": "Italic",
						"discard": "Discard",
						"add": "Add",
                        "reset": "Reset",
                        "editJob": "Edit Job",
                        "finishEditJob": "Finish Edit",
                        "showMeHow": "Show me how",
                        "dismiss": "Dismiss",
                        "snooze": "Snooze",
                        "attach": " Attach",
                        "allocate": " Allocate",
                        "unAllocate": " Un-Allocate",
                        "new": " New",
                        "edit": " Edit",
                        "delete": " Delete",
                        "view": " View",
                        "changeBusinessUnit": "Change Business Unit",
                        "unconfirm": " Unconfirm",
                        "editProduct": " Edit Product",
                        "editPricing": " Edit Pricing",
                        "editReportSettings": "Edit Reports",
                        "insertField": " Insert Field",
                        "email": " Email",
                        "emailQuote": " Email Quote",
                        "closeOrReciept": "Close/Receipt",
                        "undo": " Undo",
                        "close": " Close",
                        "back": " Go Back",
                        "open": " Open",
                        "ok": "OK",
                        "openParent": "Go to Parent",
                        "search": "Search",
                        "previous": "Previous",
                        "yes": "Yes",
                        "no": "No",
                        "previewSupplySheets": "Preview Supply Sheets",
                        "installTimes": "Est. Install Time",
                        "sortLines": "Sort Lines",
                        "download": " Download",
                        "cancel": " Cancel",
                        "clearAll": " Clear All",
                        "completeAppointment": " Mark as Completed",
                        "reOpenAppointment": " Re-Open",
                        "cancelAppointment": " Cancel Appointment",
                        "finish": " Finish",
                        "next": " Next",
                        "save": " Save",
                        "copy": " Copy",
                        "duplicate" : " Duplicate",
                        "select": " Select",
                        "toggleDetails": " Details",
                        "actions": "Actions",
                        "review": " Review",
                        "refresh": " Refresh",
                        "priceJob": " Price Job",
                        "priceDetails": "Price Details",
                        "priceSupplyJob": " Price",
                        "adjustSupplyCosts": " Adjust Costs",
                        "confirmQuote": " Confirm Quote",
                        "confirmRemainder": "Confirm Lines",
                        "discounts": " Discounts",
                        "abandoned": " Abandoned",
                        "print": " Print",
                        "schedule": " Schedule",
                        "newShowroomQuote": "New Showroom Quote",
                        "scheduleInCalendar": " Schedule in Calendar",
                        "scheduleWidthMaps": " Schedule with Maps",
                        "scheduleCheckMeasureWithCalendar": " Check Measure in Calendar",
                        "scheduleCheckMeasureWithMaps": " Check Measure with Maps",
                        "bookCheckMeasureWithCalendar": " Book CM in Calendar",
                        "bookCheckMeasureWithMaps": " Book CM with Maps",
                        "bookInstallWithCalendar": " Book Intall in Calendar",
                        "bookInstallWithMaps": " Book Intall with Maps",
                        "scheduleInstallWithCalendar": " Install in Calendar",
                        "scheduleInstallWithMaps": " Install with Maps",
                        "viewInCalendar": " View in Calendar",
                        "send": " Send",
                        "sendRegistrationEmail": " Send Registration Email",
                        "syncCalendar": " Update Phone Calendar",                        
                        "reopenJob": " Re-Open Job",
                        "reopenSupplyJob": " Re-Open",
                        "reopenQuote": " Re-Open",
                        "reOpenWithRePrice": "Re-Open and Refresh Price",
                        "reOpenNoRePrice": "Re-Open and Keep Price",
                        "reQuote": " Re-Quote",
                        "reOpenJob": " Re-Open Order",
                        "quoteLost": " Opportunity Lost",
                        "copyAddress": " Copy Address",
                        "newQuote": " New Quote",
                        "newServiceJob": " New Service Job",
                        "openServiceJob": " Open Service",
                        "openOriginalJob": "Open Job",
						"discardChanges": "Discard Changes",
						"editChanges": "Edit Changes",
                        "saveChanges": "Save Changes",
                        "getSendFile": "Download Send File",
                        "newCustomer": " New Customer",
                        "undoCancel": " Undo Cancel",
                        "login": " Submit",
                        "submit": " Submit",
                        "sendCode": " Send Code",
                        "bulkChange": " Bulk Change",
                        "preview": " Preview",
                        "viewNotify": " View Notifications",
                        "notNow": " Not Now",
                        "export": "Export",
                        "import": " Import",
                        "useDefaultTemplate": "Replace with Default Template",
                        "useDefaultTemplateKeepOptions": "Replace with Default Template (Keep Product Options)",
                        "downloadDefaultTemplate": "Download Default Template",
                        "uploadTemplate": "Upload a Template",
                        "override": "Override",
                        "showHidden": "Show Hidden",
                        "fulfillment": "Move to Supply",
                        "resendToSupply": "Resend to Supply",
                        "emailInvoice": "Email Invoice",
                        "closeJob": "Close the Job",
                        "saveAndClose": "Save and Close",
                        "viewInCalendar": "View in Calendar",
                        "createNew": "Create New",
                        "viewJobList": "View Job List",
                        "details": "Details",
                        "confirmPayment": "Confirm Payment",
                        "unconfirmPayment": "Unconfirm Payment",
                        "selectAll": " Select All",
                        "selectNone": " Deselect All",
                        "linkAccount": "Link Account",
                        "status": "Status",
                        "generateLink": "Generate Link"
                    },
                    "controls": {
                        "accentLicensePackageSummary": {
                            "labelTotalQty": "Total Qty",
                            "labelRemainingQty": "Remaining Qty",
                            "updatePackage": "Update Package",
                            "updatePackageTooltip": "Please Log Off and In for package changes to become visible."
						},
						"configFilter": {
							all: "ALL",
							all_tooltip: "Displays all product options",
							quote: "QUOTE",
							quote_tooltip: "Displays product options required for quoting",
							order: "ORDER",
							order_tooltip: "Displays product options required for ordering",
							invalid: "INVALID",
							invalid_tooltip: "Displays product options that are invalid",
							userSet: "USER",
							userSet_tooltip: "not used",
							calculations: "CALC",
							calculations_tooltip: "Displays all calculations",
							changed: "CHANGED",
							changed_tooltip: "Displays product options that have changed since last save"
						}
                    },
					"dialogs": {
						"displayUrlDlg": {
							"title": "Viewer"
						},
						"finishEditJob": {
							"title": "Review Edit Changes",
							"selectPriceInfo": "The sales price of the job has changed. Please select the appropriate price action to take. Note variances are based on the before edit values.",
							"changesInfo": "Please review changes",
							"affectsInfo": "The following items may be affected by this change.",
							"salePriceInc": "Sale Price Inc",
							"salePriceIncChange": "Variance",
							"margin": "Margin",
							"marginChange": "Variance",
							"matchOriginalQuotePrice": "Match Quote price",
							"matchCurrentQuotePrice": "Match Quoted price (excluding unconfirmed lines)",
							"matchPriceBeforeEdit": "Match Before Edit price",
							"matchCurrentPriceBeforeEdit": "Match Before Edit price (excluding unconfirmed lines)",
							"useNewPrice": "Keep new price",
							"updatingPrice": "Updating price please wait.",
							"pricingTitle": "Confirm Pricing",
							"reviewTitle": "Review Changed",
							"affectsTitle": "Warnings",
						},
						"moveToSupply": {
							"title": "Generate and Send to Supply",
							"selectedQty": "Selected Qty",
							"canMove": "Ready for supply",
							"inEdit": "Is being edited",
							"validationErrors": "Has validation errors",
							"requireCM": "Check measure pending",
							"inSupply": "Already in supply",
							"dispatched": "Has already been dispatched",
							"dispatch": "Is in dispatch stage",
							"hasError": "1 or more lines not ready",
							"ready": "All ready for supply",
							"noAccess": "You do not have permission. Please see you administrator."
						},
						"selectLinesToInvoice": {
							"title": "Select For Invoicing",
							"selectedQty": "Selected Qty",
							"noLines": "There are no remaining lines to invoice"
						},
						"defaultChanged": "Default Business Changed",
                        "failedToImportLookupTableTitle": "Import Failed",
                        "failedToImportLookupTableMsg": "Import failed please check the file format.",
                        "failedToImportCommissionValuesTitle": "Import Failed",
                        "failedToImportCommissionValuesMsg": "Import failed please check the file format.",
                        "requireMarginApprovalTitle": "Discount Approval",
                        "requireMarginApprovalMsg": "Discount requires approval before continuing.",
                        "requireServiceJobFeatureTitle": "Permissions",
                        "requireServiceJobFeatureMsg": "You do not have permission to create service jobs, please contact your administrator to enable feature.",
                        "checkDiscountsTitle": "Confirm Discounts Required",
                        "checkDiscountsMsg": "Job discounts may have changed, click 'Show Discounts' to view and confirm discounts.",
                        "noDiscountPermission": "Discounting Permissions Required",
                        "noDiscountPermissionMsg": "Access to the Discounting feature is required to perform this action.",
                        "noOriginalLine": "No Original Line",
                        "noOriginalLineMsg":"There is no associated original line with this line",
                        "completeCMInprogressTitle": "Check Measure In-Progress",
                        "completeCMInprogressMsg": "This check measure is already in progress and was not completed. Check measures must be completed from the job page.",
                        "closeItemsSkippkedTitle": "Some Activites Skipped",
                        "completeCMsInprogressMsg": "Not all check measures were closed due to being in-progress.",
                        "installHasCMPendingTitle": "Pending Checkmeasures",
                        "installHasCMPendingMultiMsg": "Not all dispatch appointments were closed due to pending check measures.",
                        "installHasCMPendingMsg":"This is dispatch appointment cannot be closed due to pending check measures.",
                        "activityAddProductsSucessTitle": "Update Activity",
                        "activityAddProductsSucessMsg": "{{AddedCount}} lines(s) added",
                        "activityAddProductsFailTitle": "Update Activity",
                        "activityAddProductsFailMsg": "Failed to add products",
                        "confirmCreateNewSupplySheetTitle": "Create New Supply Sheet",
                        "confirmCreateNewSupplySheetMsg":"This product does not have a supply sheet. Do you want to create a new supply sheet?",
                        "productSelectionTitle": "Select a Product",
                        "confirmCompleteFinishEditTitle": "Validation Errors",
                        "confirmCompleteFinishEditMsg": "The job has some validation errors. Do you want to continue?",
                        "selectRepTitle": "Select Representative",
                        "selectRepMsg":"Please select a representative and try again",
                        "reopenExpiredQuoteTitle": "Re-Open Expired Quote",
                        "reopenExpiredQuoteMsg":"This action will re-open the expired quote.",
                        "jobRequireOppTitle": "Opportunity Link Required",
                        "jobRequireOppMsg": "Opportunity link is required for jobs that are not linked to wholesale companies.",
                        "payRollNoDataTitle": "No Commission Data",
                        "payRollNoDataMsg": "No commission data to export.",
                        "confirmPayCommissionsTitle": "Mark Run as Paid",
                        "confirmPayCommissionsMsg": "WARNING: This action cannot be undone. Click Pay to mark commission run as paid",
                        "commissionErrorTitle": "Commission Error",
                        "estimatedCommissionsTitle": "Estimated Commissions",
                        "noCommissionsTitle": "No Commission",
                        "noCommissionsMsg": "This job has been marked as no sales commission.",
                        "matchCompanyPaymentTermsTitle": "Update Payment Terms",
                        "matchCompanyPaymentTermsMsg": "Company has changed, do you want to use the payment terms from {{ Company }}?",
                        "matchSiteContactAddressTitle": "Update Site Address from Site Contact",
                        "matchSiteContactAddressMsg": "Site Contact has changed, do you want to update the job site address from contact?",
                        "matchContactAddressTitle": "Update Address from Contact",
                        "matchContactAddressMsg": "Contact has changed, do you want to update the address from the contact?",
                        "quickAuthTitle": "Authorize",                        
                        "quickAuthPassword": "Password",
                        "quickAuthPasswordInvalid": "The password you have entered is invalid",
                        "teamInUseTitle": "Team in use",
                        "priceDetailsTitle": "Price Details",
                        "teamInUseMsg": "This team is currently assigned to one or more users. Please ensure the team is not assigned to any users before deactivating the team. This can be done from the Manage Teams view",
                        "activityRepRequiredTitle": "Representative Required",
                        "activityRepRequiredMsg":"Please assign the activity to a Representative before trying again",
                        "missingDataTitle": "Required Fields",
                        "missingDataMsg":"Please complete all required fields",
                        "locationServicesOffTitle": "Location services is Disabled",
                        "locationServicesOffMsg": "Please turn on location services.",
                        "firstFinanceTransferTitle": "First Finance Transfer",
                        "firstFinanceTransferMsg": "This is your first finance transfer. On the first transfer no journals will be sent to your finance system, any finance movements from this point on will be journaled to your finance system.",
                        "existingShowroomQuoteTitle": "Existing Showroom Quote",
                        "existingShowroomQuoteMsg": "A job already exists. Do you want to create a new Quote?",
                        "existingSalesApptTitle": "Existing Sales Appointment",
                        "existingSalesApptMsg": "An open sales appointment already exists. Do you want to view it in the calendar or schedule a new appointment?",
                        "changeAPIKeyTitle": "Generate new API Key",
                        "changeAPIKeyMsg": "Generating a new API Key will in-activate you existing one. Do you want to continue?",
                        "notLicensedForFinanceIntegrationTitle": "No License",
                        "notLicensedForFinanceIntegrationMsg": "Your current license package does not allow for Finance Integration",
                        "jobInvoicedSupplyWarningTitle": "Job Invoiced/Supply",
                        "jobInvoicedSupplyWarningMsg": "Warning: This job has Invoices or Supply Jobs",
                        "getETATitle": "Update ETA",
                        "getAlterTagsTitle": "Alter Tags",
                        "confirmCloseJob": "Close Job",
                        "confirmCloseJobMsg1": "The following Job(s) are not in Invoicing Stage and will be closed.",
                        "confirmCloseJobMsg2": "Note: Closing jobs will close related Check Measures and Dispatch Appointment.",
                        "confirmCloseJobMsg3":"Are you sure you want to continue?",
                        "unsavedchangesTitle": "Unsaved Changes",
                        "unsavedchanges": "You have unsaved changes.",
                        "missingRequireFields": "You are missing some required fields.",
                        "newVersionTitle": "New Version",
                        "newVersionMsg": "A new version has been detected. Click OK to reload.",
                        "requireBUTitle": "Require Additional License",
                        "requireBUMsg": "Please upgrade your license package to include our Business Unit module.",
                        "whatsThis": "Whats this?",
                        "sendSupplyJob": "Send Supply Job",
                        "sendSupplyJobMsg": "You are about to automatically send the Supply Job. Click Send to continue.",
                        "sendSupplyJobSucessfullMsg": "Supply Job Sent",
                        "sendSupplyJobFailedMsg": "Supply failed to send. Please try again later or contact your administrator. ",
                        "confirmQuoteFailedTitle": "Confirm Quote",
                        "sendUserRegEmailSuccessTitle": "Send Registration Email",
						"sendOrderFailed": "Could not email order at this time.",
						"deleteActivityTitle": "Delete Activity",
						"deleteActivityMsg": "Are you sure you want to delete this activity?",
                        "deletePriceMarkupTitle": "Delete Markup",
                        "deletePriceMarkupSelectMsg": "Please select a markup to delete first.",
                        "deletePriceMarkupConfirmMsg": "You are about to delete markup {{product}}. Do you want to continue?",
                        "deleteAutomationTitle": "Delete Automation",
                        "deleteAutomationConfirmMsg": "You are about to delete Automation {{ name }}. Do you want to continue?",
                        "deleteOrderLineTitle": "Delete Line",
                        "deleteOrderLineSelectMsg": "Please select a line to delete first.",
                        "deleteOrderLineConfirmMsg": "You are about to delete line {{line}}. Do you want to continue?",
                        "deleteJobLineTitle": "Delete Line",
                        "deleteJobLineConfirmMsg": "You are about to delete line {{line}}. Do you want to continue?",
                        "unconfirmJobLineTitle": "Unconfirm Line",
                        "unconfirmJobLineConfirmMsg": "You are about to unconfirm line {{line}}. Do you want to continue?",
						"deleteTemplateTitle": "Delete Template",
						"deleteTemplateResponseTitle": "Delete Template Response",
						"deleteTemplateSelectMsg": "Please select a Template to delete first.",
						"deleteTemplateResponseSelectMsg": "Please select a Template Response to delete first.",
						"deleteTemplateConfirmMsg": "You are about to delete template {{desc}}. Do you want to continue?",
						"deleteTemplateResponseConfirmMsg": "You are about to delete template response {{desc}}. Do you want to continue?",
                        "deleteLookupTitle": "Delete Lookup entry",
                        "deleteLookupSelectMsg": "Please select an entry to delete first.",
                        "deleteLookupConfirmMsg": "You are about to delete the selected lookup entry. Do you want to continue?",
                        "copyOrderLineTitle": "Validation",
                        "copyOrderLineMsg": "Cannot duplicate line as one or more fields are required. Please complete the required fields before trying again.",
                        "copyJobLineTitle": "Validation",
                        "copyJobLineMsg": "Cannot duplicate line as one or more fields are required. Please complete the required fields before trying again.",
                        "amendmentNotAllowedTitle": "Paid Run",
                        "amendmentNotAllowedMsg": "Amendments cannot be created againts paid runs. Please create amendment in an unpaid run.",
                        "amendmentNoRunTitle": "Run not found",
                        "amendmentNoRunMsg": "No active commission run could be. Please create a new run for the amendment.",
                        "jobLineValidForCopyTitle": "Linked Service Line",
                        "jobLineValidForCopyMsg": "This service line is linked to another job line and cannot be copied",
                        "viewValidationTitle": "Validation",
                        "viewValidationMsg": "One or more fields are required.",
                        "licensingTitle": "Licensing",
                        "licQuoteCalendar": "Your current licensing does not allow for quoting from the calendar.",
                        "licExportCalendar": "Your current licensing does not allow for exporting the calendar.",
                        "loading": "Loading",
                        "errorSavingFileNote": "Error in saving file note.",
                        "errorSavingWizardData": "Error in saving wizard data.",
                        "addNew": "Add new {{type}}",
                        "updatingPhoneCalendar": " Updating Phone Calendar",                        
                        "calendarSyncTitle": "Update Phone Calendar",       
                        "calendarSyncComplete": " Phone calendar update complete. Please note it may take a few minutes for your phone to update.",       
                        "requireFeatureAllowSyncCalendar": "Please enable the 'Allow Sync Calendar' feature for this user.",
                        "completeActivityTitle": "Mark Appointment as Completed",
                        "completeActivityMsg": "Clicking on 'Complete' will mark the appointment as done.",
                        "existingCMInprogressTitle": "Another Check Measure In-Progress",
                        "existingCMInprogressMsg": "Another check measure has already been started for this job. Please complete the other check measure before starting a new one.",
                        "existingEditInprogressTitle": "Edit In-Progress",
                        "existingEditInprogressMsg":"You cannot start this check measure because the Job is already being edited. Please contact the office.",
                        "reOpenActivityTitle": "Mark Appointment as Open",
                        "reOpenActivityMsg": "Clicking on 'Open' will mark the appointment as open.",
                        "pleaseWait": "Please wait.",
                        "overrideTemplateTitle": "Override Existing Template",
                        "overrideTemplateConfirmMsg": "Uploading a new template will override your existing one. Click Overide if you want to continue.",
                        "linesInErrorTitle": "Product Lines",
                        "linesInErrorEmailQuoteMsg": "Some products have errors. All errors need to be addressed before emailing the quote to the customer",
                        "activityInvalidProductsTitle": "Invalid Products",
                        "activityInvalidProductsMsg": "One or more selected products is not valid. Please fix the products before trying again.",
                        "emailSent":"Email Sent"
                    },
                    "general": {
                        "trialBanner": "You have {{days}} day(s) remaining on your free demo.",
                        "trialBannerExpired": "Your free demo has EXPIRED.",
                        "trialBannerSignupLink": "Click here to sign-up now and get 30 Days Free.",
                        "addressLocatorTitle": "Locate Address on Map",
                        "requestFeatureTitle": "Make a suggestion or request feature or ask a question",
                        "pleaseWait": "Please wait.",
                        "mapCoordinateIndication": "A tick indicates if the address has map coordinates.",
                        "invalidPhoneFormat": "Not a recognized phone format",
                        "invalidEmailFormat": "Not a recognized email format",
                        "userDoesNothavePermissionDlgTitle": "Permission Error",
                        "userDoesNothavePermissionDlg": "Oops! You do not have permission to access this resource. Click OK to continue.",
                        "searchForAddress": "Search for address",
                        "addressLinesPlaceHolder": "street and number...",
                        "addressCityPlaceHolder": "City...",
                        "addressStatePlaceHolder": "State...",
                        "addressPostcodePlaceHolder": "Postcode...",
                        "addressCountryPlaceHolder": "Country...",
                        "fileNotes": "File Notes",
                        "fileNotesPlaceHolder": "Enter new file note",
                        "emailAttachments": "Attachments",
                        "emailTo": "To",
                        "smsTo": "To",
                        "smsTemplate": "Template",
                        "smsLimits":"Long SMS messages will consume additional SMS credits. Messages up to 160 characters will cost 1 credit, after that every 153 characters will cost an addition credit.\ni.e.\n1 - 160 = 1 SMS Credit\n161 - 306 = 2 SMS Credits\n307 - 459 = 3 SMS Credits\netc.",
                        "smsNotConfigured": "You cannot send an SMS as you have not configured an SMS gateway",
                        "emailFrom": "From",
                        "emailSubject": "Subject",
                        "emailBody": "Body",
                        "emailCopyMe": "Copy me ({{email}})",
                        "emailQuote": "Email Quote",
                        "emailOrderConfirmation": "Email Order",
                        "emailInvoice": "Email Invoice",
                        "emailOrder": "Send Order",
                        "emailReplyTo": "Reply To",
						"emailTemplate": "Email Template",
						"smsTemplate": "SMS Template",
                        "documentTemplate": "Document Template",
                        "emailMissing": "EMAIL MISSING!!!",
                        "notifications": "Notifications",
                        "notificationsEmptyMessage": "You currently have no notifications",
                        "progressUpload": "Uploading...",
                        "progressConfirmQuote": "Confirming quote...",
                        "progressSendEmail": "Sending email...",
                        "progressCancelOrder": "Cancelling order...",
                        "progressUndoCancelOrder": "Undo cancel order...",
                        "progressCopyLines": "Duplicating line...",
                        "progressCreateQuote": "Creating Quote...",
                        "progressSendOrders": "Sending Order(s)...",
                        "progressSavingQuote": "Saving Quote...",
                        "progressSaving": "Saving...",
                        "progressSavingSettings": "Saving Settings...",
                        "progressUpdatePrice": "Updating price...",
                        "progressReopenOrder": "Opening Order...",
                        "progressReQuote": "Re-Quoting...",
                        "progressPleaseWait": "Please Wait...",
                        "pricingJobPleaseWait": "Pricing job, please Wait...",
                        "fileTooLarge": "File {{file}} too large!!!",
                        "emptyFile": "File {{file}} is empty!!!",
                        "fileNameTooLong": "File name {{file}} too long. Max Length is {{maxLength}}",
                        "fileTypeNotAllowed": "File type {{type}} is not allowed",
                        "rateView1Title": "{{name}} Page Feedback",
                        "rateView1Message": "Click thumbs down to indicate the {{viewName}} page needs improvement or click on cancel to close the rating dialog.",
                    },
                    "message": {
                        "retry": "Resend selected messages",
                        "delete": "Delete selected messages",
                        "prev": "Previous",
                        "next": "Next",
                        "start": "Start",
                        "end": "End",
                        "query": "Search",
                        "noMessagesSelectTitle": "No selected messages",
						"noMessagesSelectMsg": "Please select one or more messages",
						"noAuthMethodSelectTitle": "No authentication method selected",
						"noAuthMethodSelecttMsg": "Please select one or authentication methods",
                    },
                    "status": {
                        "Failed": "Failed",
                        "Sent": "Sent",
                        "Pending": "Pending",
                    },
                },
                "wizards": {
                    "changeBusinessUnit": {
                        "title": "Change Business Unit",
                        "opportunities": "Opportunities",
                        "jobs": "Jobs",
                        "invoices": "Invoices",
                        "description": "The following item(s) will be updated",
                        "selectNewBusinessUnit": "New Business Unit",
                        "updateAddress": "Update Job Delivery Addressed"
					},
					"setReceivedQty": {
						"title": "Received Quantity",
						"receiveAll": "Receive All",
						"unreceiveAll": "Unreceive All",
						"noLines": "There are no valid lines to receive on this job"
					},
                    "setInstallTimes": {
                        "title": "Estimated Installation Times"
                    },
                    "columnChooser": {
                        "title": "Select Columns"
                    },
                    "moveToDispatched": {
                        "page01Title":"Mark as having been dispatched",
                        "ready": "Will be marked as Dispatached",
                        "requireCMs": "Require Check Measures to be completed",
                        "requireInstall": "Require Dispatch appointments to be scheduled",
                        "installs": "Has uncompleted dispatch appointments (WARNING: dispatch appointments will be closed)",
                        "requireSupply": "Goods have not been received",
                        "dispatched": "Already been dispatched (will be skipped)",
                        "reference": "Reference",
                        "product": "Product",
                        "force": "Force Anyway",
                        "qty": "Qty",
                        "selectedQty": "Selected Qty",
                        "jobInEdit": "Job is in edit mode and cannot be selected",
                        "noAccess": "You do not have permission. Please see you administrator."
                    },
                    "scheduleActivity": {
                        "page01Title": "Please confirm products to schedule",
                        "validation": {
                            "productHasErrors": "Product has warnings...",
                            "notRequired": "Is not required for {{ActivityType}}",
                            "hasPendingService": "Has a pending service and cannot be selected",
                            "hasExisting": "Has existing {{ActivityType}} activity",
                            "isInSupply": "Line already in supply",
                            "hasBeenReceipted": "Is ready for dispatch or has already been dispatched",
                            "selectedQty": "Selected Qty",
                            "hasCMUnconfirmed": "Has been unconfirmed in an existing Check Measure",
                            "hasExistingOpenActivities":"Has existing open activities. [{{list}}]"
                        }
                    },
                    "jobLineSort": {
                        "lines": {
                            "title": "Set Line Order"
                        },
                        "products": {
                            "title": "Set Product Order"
                        }
                    },
                    "closeJobWizard": {
                        "page01Title": "Close Jobs",
                        "blockerMessage": "One or more job(s) cannot be closed due to open related records. Please close or cancel related records before trying again. For unconfirmed payments you can either confirm or unallocate the payment",
                        "warningInvoiceMessage": "Warning,  one or more job/lines has not been invoiced. You will not be able to invoice a closed job. Click on Finish to close the job(s) anyway.",
                        "warningServiceJobsMessage": "Warning,  one or more jobs has related service jobs. Click on Finish to close the job(s) anyway.",
                        "okMessage": "The following job(s) will be closed.",
                    },
                    "bulkUpdateWiz": {
                        "title": "BULK CHANGE",
                        "page1Title": "PRODUCT",
                        "page2Title": "CHANGES",
						"page3Title": "REVIEW",
						"page4Title": "UPDATING",
                        "next": "Next",
                        "info_1": "Select lines to bulk change. Note only one product can be changed at a time",
                        "info_2": "Only select values that you want change.",
                        "info_3": "Please wait, your quote is being updated.",
                        "errorLineRequired": "Please select at least one line to update",
                        "errorInComplete": "Please wait for all lines to complete or you may loose your changes.",
                        "inProgress": "Updating...",
                        "done": "Done",
                        "updatingPrice":"Updating Price",
                        "noProducts": "This job does not have any products that can be bulk updated"
                    },

                    "productPrintSettings": {
                        "title": "{{product}} Report Settings",
                        "showSelected": "Show Selected",
                        "selectAll": "Select All",
                        "clearAll": "Clear All",
                        "option": "Product Option",
                        "show": "Show",
                        "settings": "Settings",
                        "showFabricWidth": "Show Fabric Width",
                        "showPatternRepeat": "Show Pattern Repeat"
                    },
                    "sendEmail": {
                        "email": {
							"title": "Email Preview",
							"titleMobile": "Email",
                        },
                        "preview": {
							"title": "Document Preview",
							"titleMobile": "Document",
                        }
                    },
                    "sendSms": {
                        "sms": {
                            "title":"Send SMS"
                        }
                    },
                    "completeDispatch": {
                        "title": "Complete Dispatch",
                        "noAction": "None",
                        "faultAction": "Please create service job",
                        "hasServiceJobLink": "Linked to Service Job"
                    },
                    "newLead": {
                        "title": "New Lead Wizard",
                        "customerSearch": {
                            "title": "1. Customer Search",
                            "newCustomer": "New Customer",
                            "existingCustomer": "New Lead for {{CustomerName}} {{CompanyName}}",
                            "selectedCustomer": "Selected Customer"
                        },
                        "customerDetails": {
                            "title": "2. Billing Details",
                            "contactTitle": "Billing Contact for {{Company}}"
                        },
                        "siteContact": {
                            "title": "3. Site Contact",
                            "selectBillingContact": "Billing Contact ({{FullName}})",
                            "selectContact": "{{FullName}}",
                            "selectOther": "Other",
                            "selectExisting": "Select",
                            "linkToCompany": "Link to Company ({{Name}})",
                            "matchTitle": "Possible Existing Matches",
                            "clearMatch": "Clear Selected Match ({{FullName}})",                            
                        },
                        "siteAddress": {
                            "title": "4. Site Address",
                            "selectBillingContact": "Billing Contact ({{FullName}})",
                            "selectContact": "{{FullName}}",
                            "selectOther": "Other",
                            "siteAddress": "Site Address"
                        }
                    },
                    "rePriceJob": {
                        "page01Title": "Re-Price Job Wizard",
                        "page01Message": "This wizard will re-price all the lines of the job",
                        "clearDiscounts": "Clear current discounts",
                        "priceNow": "Price Now"
                    },
                    "editJob": {
                        "page01Title": "Edit Job",
                        "page01Message": "The Job {{reference}} for customer {{contactName}} has supply jobs. Changes to the job may affect supply jobs with the following Supplier(s).",
                        "confirmSuppliersContacted": "Warning changes made to the job may affect existing supply jobs. Continue anyway."
                    },                    
                    "cancelActions": {
                        "page01Title": "Cancellation Wizard",
                        "page01Message": "The $t({{Type}}) ({{Description}}) could not be cancelled as it has related items. Complete this wizard to finalize the cancellation.",
                        "page02Title": "Cancellation Wizard",
                        "page02Message": "WARNING you are about to perform the following actions. Which may not be reversible.",
                        "page01Blocked_1":"STOP: Job {{Description}} has active service jobs and cannot be cancelled. Please cancel related jobs first.",
                        "types": {
                            "payment": "Payment",
                            "refund": "Refund",
                            "payments": "Payments",
                            "paymentBalanceDraft": "Draft Payment Balance",
                            "paymentBalanceConfirmed": "Confirmed Payment Balance",
                            "job": "Job",
                            "invoice": "Invoice",
                            "invoices": "Invoices",
                            "creditNote": "Credit Note",
                            "supplyJobs": "Supply Jobs",
                            "supplyJob": "Supply Job"
                        },
                        "reminders": {
                            "supplyJob": "Remind me to notify the supplier",
                        },
                        "descriptions": {
                            "cancelPayment": "The following payments will be cancelled",
                            "cancelJob": "Cancel the job.",
                            "cancelInvoice": "Cancel the invoice.",
                            "cancelSupplyJob": "Cancel the supply job. (Warning your suppliers will need to be manually notified by you as they will not be sent any automatic notifications. They may also have begun manufacturing and you may still incur a cost.)",
                            "refundPayment": "Refund payment to customer (will generate a refund)",
                            "refundPaymentBalance": "Refund balance to customer (will generate a refund)",
                            "refundCreditNote": "Refund credit note to customer (will generate a refund)",
                            "cancelCreditNote": "Cancel the credit note",
                            "cancelPartialCreditNote": "Cancel the credit note. (Warning this will affect other invoices too)",
                            "clearCreditNote": "Clear credit note allocations (will leave the credit against the customer and remove allocations to any jobs or invoices)",
                            "clearAllocations": "Clear allocations (will un-allocate the payment from the job or invoice and leave it as a payment against the customer)",
                            "clearPaymentBalanceAllocations" : "This will leave the balance as Credit against the customer"
                        }
                    },
                    "setupWizard": {
                        "page01Title": "Setup myinsyte...",
                        "page01Message": "Please take a minute to configure your new system",
                        "page02Title": "Setup - General",
                        "page02Message": "Configure your default settings...",
                        "logo": "Logo",
                        "businessPH": "Business Ph",
                        "page03Title": "Setup - Sales",
                        "page03Message": "Configure your default sales information...",
                        "defaultPriceMarkup": "Default Price Markup",
                        "taxCodeMgs": "Every sales item requires a tax rate. A tax rate with 0% tax has automatically been configured for you. Enter below your default sales tax.",
                        "taxCodeEg":"e.g.GST and 10 for a tax code called GST with a rate of 10%",
                        "taxCode": "Tax Code",
                        "taxRate": "Tax Rate %",
                        "page04Title": "Setup - Complete",
                        "page04Message": "Click on finish to save your settings",
                    },
                    "lostOpportunityWizard": {
                        "quoteOutcomReason": "Outcome Reason",
                        "outcomeNotes": "Outcome Notes",
                        "outcomeNotesPlaceHolder": "Enter any outcome notes...",
                        "page01Title": "Lost Reason",
                        "page01Message": "Select the reason the opportunity was lost",
                        "page01MessageNotAllowed": "This opportunity cannot be marked as lost because the following Job need to be cancelled first",
                        "page01MessageHasQuotes": "The following quotes will All be closed off and marked as lost"
                    },
                    "newContactWizard": {
                        "firstName": "First Name",
                        "lastName": "Last Name",
                        "workPhone": "Work Phone",
                        "homePhone": "Home Phone",
                        "mobile": "Mobile",
                        "email": "Email",
                        "company": "Company",
                        "companyName": "Name",
                        "companyEmail": "Email",
                        "companyPhone": "Phone",
                        "companyAccountType": "Account Type",
                        "companyMobile": "Mobile",
                        "companyWWW": "WWW",
                        "contactHeader": "Contact:",
                        "contactSiteAddressHeader": "Contact Address:",
                        "companyHeader": "Company:",
                        "companyAddressHeader": "Address:",
                        "page01Title": "New Contact",
                        "page01Message": "Please fill in the contact information",
                    },
                    "invoiceWizard": {
                        "page01Title": "New Invoice",
                        "page01Message": "Create a new BLANK invoice linked to...",
                        "page01JobMessage": "Current job {{Reference}}",
                        "page01ContactMessage": "Contact {{FullName}}",
                        "page01OtherMessage": "Other"
                    },
                    "paymentWizard": {
                        "page01Title": "New Payment",
                        "page01Message": "Create a new payment for...",
                        "page01JobMessage": "Current job {{Reference}}",
                        "page01ContactMessage": "Contact {{FullName}}",
                        "page01OtherMessage": "Other"
                    },
                    "newServiceJob": {
                        "page01Title": "New Service Job",
                        "page01Message": "Create a new service job for...",
                        "page01JobMessage": "Current job {{Reference}}",
                        "page01ContactMessage": "For contact {{Contact}}",
                        "page01OtherMessage": "Other"
                    },
                    "newServiceLine": {
                        "page01Title": "New Service Line",
                        "page01Message": "Please select the line type",
                        "page01Service": "Service",
                        "page01Remake": "Remake",
                        "page01Warranty": "Warranty",
                        "page01Alter": "Alteration",
                        "page01LinkExisting": "Link Existing Line",
                        "selectLine": "Select Existing Line",
                        "page01ExistingService": "WARNING: The selected line has a later service line on service job {{Reference}}",
                    },
                    "refundWizard": {
                        "page01Title": "New Refund",
                        "page01Message": "Create a new refund for...",
                        "page01JobMessage": "Current job {{Reference}}",
                        "page01ContactMessage": "Contact {{FullName}}",
                        "page01OtherMessage": "Other"
                    },
                    "creditNoteWizard": {
                        "page01Title": "New Credit Note",
                        "page01Message": "Create a new credit note for...",
                        "page01JobMessage": "Current job {{Reference}}",
                        "page01ContactMessage": "Contact {{FullName}}",
                        "page01OtherMessage": "Other",
                        "customerNamePlaceHolder": "Search Customer",
                        "page02Title": "New Credit Note",
                        "page02Message": "Enter the credit note details",
                        "description": "Description",
                        "amount": "Amount",
                        "taxRate": "Tax Rate",
                        "page03Title": "New Credit Note",
                        "page03Message": "Choose the action to be performed with the credit note",
                        "page03Transfer": "Apply to an existing invoice",
                        "page03Refund": "Refund to customer",
                        "page03None": "None (Not sure if this should even be an option)"
                    },
                    "linkOpportunityWizard": {
                        "page01Title": "Link Lead Details",
                        "page01SelectExistingMsg": "Select an existing Lead or create new",
                        "page01NewLine": "Create New - selecting this row will create a new lead",
                        "nextCreate": "Next",
                        "nextExisting": "Finish",
                        "page02Title": "Link Lead Details",
                        "page02LeadDetailsMsg": "Enter lead details",
                        "leadSourceLable": "Select a lead source",
                        "page02POIMsg": "Enter product of interest",
                        "visitedShowroomLable": "Visited Showroom"
                    },
                    "linkJobWizard": {
                        "page01Title": "Link Job Details",
                        "page01SelectExistingMsg": "Select a Job by clicking on the left hand side. Or select product by selecting on the right hand side.",
                        "select": "Select Job",
                        "reference": "Reference",
                        "job": "Job",
                        "salesRep": "Sales Rep",
                        "contact": "Contact",
                        "city": "City",
                        "type": "Type",
                        "stage": "Stage",
                        "product": "Product",
                        "orderDate": "Order Date",
                        "eta": "ETA",
                        "cmBy": "CM By",
                        "cmDate": "CM Date",
                        "instBy": "Dispatch By",
                        "instDate": "Dispatch Date"
                    },
                    "sendInvoiceWizard": { 
                        "page01Title": "Send Invoices",
                        "page01Message": "Select the email and invoice templates to use.",
                        "email": "Send as Email",
                        "pdf": "Send in person or snail mail (pdf documents will be emailed to {{email}})",
                        "skip": "Skip (do not send at this time)",
                        "withEmail": "{{count}} job(s) have an email address",
                        "noEmail": "{{count}} job(s) do NOT have an email address",
                        "noJobs": "There were no jobs selected that could be invoiced",
                        "noAmount": "INFO: {{count}} job(s) do NOT have a sales amount",
                        "taxInclusive": "Show Tax Inclusive",
                        "sortLines": "Use job line sort"
                    },
                    "sendStatementWizard": {
                        "page01Title": "Email Statements",
                        "page01Message": "Select the email templates to use.",
                        "email": "Send as Email",
                        "pdf": "Send in person or snail mail (pdf documents will be emailed to {{email}})",
                        "skip": "Skip (do not send at this time)",
                        "withEmail": "{{count}} statements have an email address",
                        "noEmail": "{{count}} statements do NOT have an email address",
                        "noStatements": "There were no statements selected to be sent",         
                        "statementTemplate": "Statement Template"
                    }

                },
				"views": {
					"companyPricelistMapping": {
						"title": "Company Pricelist",
						"product": "Product",
						"pricelist": "Pricelist",
						"existing": {
							"title": "Error selection exists",
							"message": "Selected product and pricelist already exists for this company"
						}
					},
					"userApiKey": {
						"title": "User Api Key",
					},
					"apiIntegration": {
						"title": "Api Integration",
						"header": "Api Keys",
						"help": "Click here to view the Api Documentation",
						"url": "API Endpoint - {{url}}"
					},
					"documentView": {
						"title": "Document ({{Description}})",
					},
					"documentTemplate": {
						"title": "Document Template ({{Name}})",
					},
					"printSetting": {
						"title": "Print Setting ({{Name}})",
					},
					"printSettings": {
						"title": "Print Settings",
					},
					"automation": {
						"title": "Automation {{Name}}",
						"SmsDeliveryReceipt": "Require SMS Delivery Receipt",
						"ScheduleTimeOfDay": "Time of day",
						"deliveryReceiptWarning": "This may attract an additional charge per SMS"
					},
					"jobLine": {
						"title": "Job Line {{JobLineNo}}",
					},
					"leadIntegrationSettings": {
						"title": "Lead Integration",
					},
					"financeIntegrationSettings": {
						"title": "Finance Settings",
					},
					"emailIntegrationSettings": {
						"title": "Email Settings",
						"businessUnitSettings": {
							"title": "Business Unit Settings",
							"businessUnit": "Business Unit"
						}
					},
					"smsIntegrationSettings": {
						"title": "Sms Settings",
						"headerSection": "Sms Integration",
						"businessUnitSettings": {
							"title": "Business Unit Settings",
							"businessUnit": "Business Unit"
						}
					},
					"supplier": {
						"title": "Supplier ({{Name}})"
					},
					"team": {
						"title": "Team ({{Name}})"
					},
					"salesDiscountRule": {
						"title": "Disc Rule ({{MinimumMargin}})"
					},
					"salesDiscountLimits": {
						"title": "Disc Lmit ({{MinimumMargin}})"
					},
					"lookupTable": {
						"title": "Lookup Table ({{Name}})"
					},
					"lookupTableItem": {
						"title": "Lookup Item ({{From}} - {{To}})"
					},
					"picklist": {
						"title": "Picklist ({{Description}})"
					},
					"taxRate": {
						"title": "Tax Rate ({{Description}})"
					},
                    "commissions": {
                        "title": "Commissions",
                        "valuesHeader": "Values to be used in Rules",
                        "rulesHeader": "Commission Rules",
                        "plansHeader": "Commission Plans",
                        "description": "Description",
                        "add": "New",
                        "sort": "Sort Values",
                        "optionLookup": "Lookup Product Option",
                        "name": "Value Name",
                        "ruleName": "Rule Name",
                        "byJob": "By Job",
                        "filter": "Filter",
                        "expression": "Value Expression",
                        "ruleExpression": "Rule Expression",
                        "textValues": "Text Values",
                        "summaryType": "Summary Type",
                        "summaryBy": "Distinct By",
                        "groupType": "Group By",
                        "showOnStatement": "Show On Statement",
                        "isText": "Is Text",
                        "JobLine": "Line",
                        "Job": "Job",
                        "importValuesTitle": "Import Values",
                        "importValuesWarning": "WARNING: Importing will clear all existing commision values",
                        "export": "Export",
                        "import": "Import",
                        "testRule": "Rule Results",
                        "toggleMatchCols": "Toggle Expression Columns",
                        "usersForRule": "Users for Rule - {{Name}}",
                        "rulesForPlan": "Rules for Plan - {{Description}}",
                        "commissionDateRules": "Commission Date Rules",
                        "commissionDate1": "Commission Date 1",
                        "commissionDate2": "Commission Date 2",
                        "validation": {
                            "required": "Required, must start with a alphabetical character and only contain alphnumeric characters or underscore character. Underscore will be displayed as a space character in reports",
                            "duplicate": "Name is already in use."
                        }
					},
					"support": {
						"selectDlg": {
							"title": "Support access expiry date",
						}
					},
					"mfa": {
						"selectDlg": {
							"title": "Multi-Factor Authentication Type",
							"none": "None",
							"sms": "SMS",
							"email": "Email",
							"totp": "TOTP",
							"manageDevices": "Manage the registered authenticator devices below",
							"noDevices":"You will be asked to register your authenticator next time you log in."
						}
					},
                    "commissionRun": {
                        "title": "Commission Run ({{- Description}})",
                        "amendmentSection": "Unresolved Amendments",
                        "runDlg": {
                            "title": "SELECT USERS TO RUN COMMISSIONS FOR",
                            "startRun": "Start Run",
                            "continue": "Continue"
                        },
                        "emailDlg": {
                            "title": "SELECT USERS TO EMAIL",
                            "sendEmails": "Send Emails",
                        },
                        "previewDlg": {
                            "title": "SELECT USERS TO PREVIEW",
                            "previewPdf": "Generate Pdf",
                            "previewExcel": "Generate Excel",
                        },
                        "amendmentDlg": {
                            "title": "Amendment",
                            "createAmendment": "Save",
                            "notesPlaceHolder":"Enter any notes here..."
                        }
                    },
                    "lookupTableListView": {
                        "lookupTables": "Lookup Tables",
                        "add":"Add"
                    },
                    "lookups": {
                        "title": "Lookup Tables",
                        "importValuesWarning": "WARNING: Importing will clear existing lookup values for this table",
                    },
                    "officeAddinSettings": {
                        "title": "Office Excel Addin",
                    },
                    "documentTemplateListView": {
                        "title": "Document Template",
                        "add": "New",
                        "quotes": "Quotes",
                        "orderConfirmations": "Order Confirmations",
                        "invoices": "Invoices",
                        "statements": "Statements",
                        "installers": "Install Job Sheet Report",
                        "jobSummaries": "Job Summary",
                        "copyAsType": "Copy as type",


                    },
                    "printSettingsListView": {
                        "title": "Print Settings",
                        "add": "New",
                        "printSettings": "Print Settings",

                    },
                    "businessUnit": {
						"title": "Business Unit ({{Name}})",
						"title2": "Business Unit",
                        "sectionExclusions": "Product Exclusions",
                        "add": "Add",
                        "missing": "<MISSING>",
                        "sectionAddress": "Address",
                        "address": "Address",
                        "billingAddress": "Billing Address",
                        "deliveryAddress": "Delivery Address"
                    },
                    "activity": {
                        "title": "Activity",
                        "notes": "Activity Notes",
                        "installNotes": "Installation Notes",
                    },
                    "scanMFJob": {
                        "title": "Scan Job",
                        "done": "Done",
                        "allCompleted": "All lines have been completed or Link has expired.",
                    },
                    "mfScheduling": {
                        "setCommitReason": "Set Commit Reason",
                        "setDepartment": "Set Department",
                        "setMFDate": "Set MF Date",
                        "setETADate": "Set ETA Date",
                        "release": "Release (Download)",
                        "preview": "Preview (Download)",
                        "updateStatus": "Set Status to Released",
                        "dowloadXLS": "Download Excel",
                        "dowloadXML": "Download UBL",
                        "complete": "Complete",
                        "supplyJobsToComplete": "Click OK to mark {{count}} selected item(s) as completed"
                    },
                    "mapScheduler": {
                        "title": "Map Scheduler",
                        "calendar": "Calendar",
                        "map": "Map",
                        "details": "Details",
                        "activityDate": "Activity Date",
                        "activityRep" :"Activity Rep",
                        "jobDetails": {
                            "estInstallTime": "Est. Install Time",
                            "reference": "Reference",
                            "company": "Company",
                            "contact": "Contact",
                            "siteContact": "Site Contact",
                            "eta": "ETA",
                            "handoverDate": "Handover Date",
                            "previousRep": "Previous Rep",
                            "products": "Products",
                            "salePriceInc": "Sale Price Inc",
                            "balance": "Balance",                            
                        },
                        "oppDetails": {
                            "description": "Description",
                            "company": "Company",
                            "contact": "Contact",
                            "siteContact": "Site Contact",
                            "notes": "Notes",
                        },
                        "activityDetails": {
                            "activityType": "Activity Type",
                            "opportunity": "Opportunity",
                            "products": "Products",
                            "category": "Category",
                            "subject": "Subject",
                            "job": "Job",
                            "notes": "Notes"
                        }
                    },
                    "splitLine": {
                        "title": "Split Line",
                        "originalLine": "Original Line",
                        "newLine": "New Line ({{num}})",
                        "noOfLines": "Number of lines"
                    },
                    "multiLineJobEdit": {
						"title": "Job Multi - {{Reference}}",
                    },
                    "multiLineSupplyEdit": {
						"title": "Supply Multi - {{OrderNumber}}"
                    },
                    "multiLineEdit": {
                        "title": "Multi Line",
                        "lineNo": "#",
                        "lineType": "Type",
                        "location": "Location",
                        "qty": "Qty",
                        "width": "Width",
                        "drop": "Height",
                        "addProduct": "ADD PRODUCT",
                        "lineDetails": "DETAILS",
                        "unitPrice": "Unit Price",
                        "price": "Price (Inc Tax)",
                        "addLine": "ADD {{ProductName}} LINE",
                        "refreshPrice": "Refresh Price",
                        "howManyCopies": "How many copies?",
                        "noOfCopies": "Select Number Of Copies",
                        "showAll": "Show All Lines",
                        "showInvalid": "Show Invalid Lines",
                        "total": "Total",
                        "detailsDialog": {
                            "requiredTitle": "Required Fields",
                            "requiredMsg": "Please ensure product and location fields are populated",
                        }
                    },
                    "queryPage": {
						"title": "Query - $t({{name}})",
						"Search_Job": "Search Jobs",
						"Search_SupplyJob": "Search Supply Jobs",
						"Search_Invoice": "Search Invoices",
						"Search_Contact": "Search Contacts",
						"Search_Company": "Search Companies",
						"PO_Open": "Open Supply Jobs",
						"PO_Sent": "Sent Supply Jobs",
						"PO_Priced": "Priced Supply Jobs",
						"PO_Received": "Received Supply Jobs",
						"PO_Manufacturing": "Supply Jobs in Manufacturing",
						"PO_Dispatch": "Supply Jobs in Dispatch",
						"PO_Invoiced": "Invoiced Supply Jobs",
						"PO_Invoiced": "Invoiced Supply Jobs",
						"Invoice_Open": "Open Invoices",
						"Invoice_AwaitingPayment": "Invoices Awaiting Payment",
						"Invoice_Overdue": "Overdue Invoices",
						"Dashboard_SalesPerformanceByMonth_Won": "Sales By Month (Won)",
						"Dashboard_SalesPerformanceByMonth_Lost": "Sales By Month (Lost)",
						"Dashboard_SalesPerformanceByRep_Lead": "Sales By Rep (Lead)",
						"Dashboard_SalesPerformanceByRep_Quotation": "Sales By Rep (Quotation)",
						"Dashboard_SalesPerformanceByRep_Negotiation": "Sales By Rep (Negotiation)",
						"Dashboard_SalesPerformanceByRep_Won": "Sales By Rep (Won)",
						"Dashboard_SalesPerformanceByRep_Lost": "Sales By Rep (Lost)",
						"Dashboard_SalesByRep": "Sales By Rep For Month"
                    },
                    "addjustSupplyCost": {
                        "title": "Adjust Supply Costs",
                        "lineNo": "Line No",
                        "location": "Location",
                        "product": "Product",
                        "unitPrice": "Unit Cost",
                        "qty": "Qty",
                        "price": "Cost",
                        "tax": "Tax",
                        "total": "Cost Inc"
                    },
                    "filterView": {
                        "title": "Grid Filter",
                        "owner": "Owner",
                        "filterName": "Name",
                        "users": "Select Users",
                        "teams": "Select Teams",
                        "selectFilter": "Select Filter",
                        "saveFilter": "Save",
                        "editFilter": "Edit",
                        "newFilter": "Save As",
                        "importFilter": "Import",
                        "exportFilter": "Export",
                        "exportFilterTitle": "Select Filter(s) to Export",
                        "importFilterTitle": "Select File to Import",
                        "selectFiltersImportFile": "Select File (.json)",
                        "and": "AND",
                        "or": "OR",
                        "notand": "NOT AND",
                        "notor": "NOT OR",
                        "addPredicate": "+",
                        "addGroup": "[...]",
                        "schemas": {
                            "ActivityAlert": "Activity Alert",
                            "JobAlert": "Job Alert",
                            "OpportunityAlert": "Lead Alert",
                            "LJ__ActivityAlertActive": "Activity Alert Is Active",
                            "LJ__ActivityAlertMessage": "Activity Alert Message",
                            "LJ__ActivityAlertType": "Activity Alert Type",
                            "LJ__JobAlertActive": "Job Alert Is Active",
                            "LJ__JobAlertMessage": "Job Alert Message",
                            "LJ__JobAlertType": "Job Alert Type",
                            "LJ__OpportunityAlertActive": "Lead Alert Is Active",
                            "LJ__OpportunityAlertMessage": "Lead Alert Message",
                            "LJ__OpportunityAlertType": "Lead Alert Type",
                            "select_field": "Select a field",
                            "Contact": "Contact",
                            "SiteContact": "Site Contact",
                            "Company": "Company",
                            "Job": "Job",
                            "JobLine": "Job Line",
                            "Opportunity": "Lead",
                            "OrderLine": "Supply Job Line",
                            "PurchaseOrder": "Supply Job",
                            "Activity": "Activity",
                            "Invoice": "Invoice",
                            "ID": "Record ID",
                            "QuoteDate": "Quote date",
                            "HandoverDate": "Handover date",
                            "LJ__OrderDate": "Order date",
                            "LJ__CommissionDate1": "Commission Date",
                            "LJ__ETADate": "Eta date",
                            "LJ__Amount": "Amount inc tax",
                            "LJ__Paid": "Amount paid",
                            "LJ__HasPayment": "Has a payment",
                            "Stage": "Stage",
                            "LJ__JobLineStatus": "Status",
                            "LJ__JobLineType": "Line Type",
                            "LJ__JobLineStage": "Stage",
                            "LJ__JobLineDispatchStage": "Dispatch Stage",
                            "SalesRepID": "Sales rep",
                            "BusinessUnitID": "Business Unit",
                            "LJ__Balance": "Job Balance",
                            "LJ__PercentagePaid": "Paid %",
                            "LJ__JobLineProductID": "Product",
                            "LJ__JobLineSupplierID": "Supplier",
                            "LJ__CompanyName": "Name",
                            "LJ__OrganisationType": "Type",
                            "LJ__AccountType": "Account Type",
                            "LJ__ContactFirstName": "First name",
                            "LJ__ContactLastName": "Last name",
                            "LJ__ContactHasCredit": "Has credit",
                            "LJ__SiteContactFirstName": "First name",
                            "LJ__SiteContactLastName": "Last name",
                            "LJ__ActivityType": "Type",
                            "LJ__ActivityCategory": "Category",
                            "LJ__ActivityStart": "Start date",
                            "LJ__ActivityRepresentative": "Representative",
                            "LJ__ActivityClosed": "Is closed",
                            "LJ__ActivityConfirmed": "Is confirmed",
                            "LJ__ReferredByID": "Referred by",
                            "LJ__LeadGenerator": "Lead Generator",
                            "LJ__OpportunityConfidenceLevel": "Confidence level",
                            "LJ__OpportunityStatus": "Status",
                            "LJ__OpportunityOutcomeReason": "Outcome reason",
                            "LJ__OpportunityForecast": "Forecast",
                            "LJ__LeadSource": "Lead source",
                            "LJ__VisitedShowroom": "Visited showroom",
                            "LJ__OpportunityRepresentative": "Representative",
                            "LJ__CMRequired": "Require check measure",
                            "LJ__InstallRequired": "Require install",
                            "LJ__PickupRequired": "Require pickup",
                            "LJ__DeliveryRequired": "Require delivery",
                            "LJ__Tags": "Tags",
                            "QuoteExpiryDate": "Quote expiry date",
                            "LJ__PercentageInvoiced": "Invoiced %",
                            "LJ__CompanyAccountType": "Account Type",
                            "LJ__JobID": "Record ID",
                            "LJ__JobSalesRep": "Sales rep",
                            "LJ__JobStage": "Stage",
                            "LJ__JobTags": "Tags",
                            "LJ__QuoteDate": "Quote date",
                            "LJ__QuoteExpiryDate": "Quote expiry date",
                            "LJ__QuoteLastSentDate": "Quote last sent date",
                            "LJ__JobLineCMRequired": "Require check measure",
                            "LJ__JobLineInstallRequired": "Require install",
                            "LJ__JobLinePickupRequired": "Require pickup",
                            "LJ__JobLineDeliveryRequired": "Require delivery",
                            "LJ__JobLineETADate": "Eta date",
                            "LJ__CommitmentReason": "Commit reason",
                            "LJ__HandoverDate": "Handover date",
                            "LJ__SalesRepID": "Sales rep",
                            "LJ__DispatchBy": "Dispatch by",
                            "LJ__DispatchDate": "Dispatch date",
                            "LJ__SupplyLineDepartment": "Department",
                            "LJ__SupplyLineMFDate": "MF Date",
                            "LJ__SupplyLineProductID": "Product",
                            "LJ__SupplyLineStatus": "Status",
                            "LJ__Internal": "Internal",
                            "LJ__AtFaultSupplierID": "Service At Fault Supplier",
                            "LJ__AtFaultUserID": "Service At Fault User",
                            "LJ__FaultReasonID": "Service Fault Reason",
                            "CustomerReference": "Cust. Ref",
                            "OrderNumber": "Order Number",
                            "SupplierID": "Supplier",
                            "ETADate": "Eta date",
                            "PL_QuoteOutcomeReasonID": "Outcome reason",
                            "Description": "Description",
                            "LJ__JobCompanyName": "Name",
                            "LJ__JobOrganisationType": "Type",
                            "LJ__JobCompanyAccountType": "Account type",
                            "LJ__JobContactFirstName": "First name",
                            "LJ__JobContactLastName": "Last name",
                            "Forecast": "Forecasted",
                            "LeadGeneratorID": "Lead generator",
                            "PL_LeadSourceID": "Lead source",
                            "PL_OppConfidenceLevelID": "Confidence level",
                            "PL_CommitmentReasonID": "Commit reason",
                            "ReferedByID": "Referred by",
                            "RepresentativeID": "Representative",
                            "Status": "Status",
                            "VisitedShowroom": "Visited showroom",
                            "MarginApprovalRequired": "Sales Discount Approval Required",
                            "JobType": "Job Type",


                        }
                    },
                    "userProfileSettings": {
                        "title": "Profile Settings",
                        "quickAuthInfo": "Please enter you password to access your profile.",
                        "resetPassword": "Click here to change your log in password",
                        "sectionPasswords": "Passwords",
						"loginPassword": "Log in Password",
						"defaultBusinessChanged": "Your default business has been changed. Please log in again to access."
                    },
					"discountLevel": {
						"title": "Discount Level ({{Description}})",
						"searchCompanies": "Search for Companies",
						"sectionDiscountItems": "Discounts"
					},
					"discountItem": {
						"title": "Discount Item ({{Percentage}} - {{Product}})",
					},
                    "projectTracking": {
                        "projectTemplate": "Template",
                        "newTemplate": "+",
                        "templateDetails": "...",
                        "deleteTemplate": "Delete",
                        "editTemplate": "Edit Template",
                        "newStage": "Add Stage",
                        "description": "Description",
                        "selectTemplateDlgTitle": "Missing Template",
                        "selectTemplateDlgMsg": "Please select a project template first before trying again",
                        "duplicateFilter": "Each row must have a unique combination of Activity Type and Activity Category"
                    },
                    "activitiesView": {
                        "title": "Activities",
                        "closeActivityTitle": "Close Activities",
                        "closeActivities": "Close Activities",
                        "reassignActivityTitle": "Reassign Activities",
                        "reassignActivities": "Reassign",
                        "noActivitiesSelectTitle": "No Selected Activites",
                        "noActivitiesSelectMsg": "Please select one or more activities",
                        "assignTo": "Reassign to:",
                        "filterDlgTitle": "Activity Filters",
                        "filters": {
                            "myActivities": "Mine",
                            "futureActivities": "Future",
                            "any": "Any",
                            "low": "Low",
                            "medium": "Medium",
                            "high": "High",
                            "what": "What",
                            "priority": "Priority",
                            "teams": "Teams",
                            "users": "Users",
                            "showDetails": "Show Details",
                            "hideDetails": "Hide Details",
                            "status": "Status",
                            "open": "Open",
                            "all": "All",
                            "show": "Show",
                            "hide": "Hide",
                            "detailsLabel": "Details"
                        }
                    },
                    "manageTeamsView": {
                        "title": "Manage Teams",
						"searchPlaceholder": "Filter User by Name",
						"invalidUser": {
							"title": "Missing team",
							"message": "The following users require a team"
						}
                    },
                    "managePackageView": {
                        "title": "Manage Package",
                        "searchPlaceholder": "Filter User by Name",
                        "overallocatedTitle": "Over Allocated",
                        "overallocatedMsg": "One or more licenses has been overallocated",
                        "upgradePackage": "Upgrade Package"
                    },
                    "contactInfo": {
                        "title": "Contact Details",
                        "name": "Name",
                        "jobTitle": "Job Title",
                        "jobCategory": "Job Category",
                        "email": "Email",
                        "mobile": "Mobile",
                        "workPhone": "Work Ph",
                        "homePhone": "Home Ph",
                        "address": "Address",
                        "businessUnit": "Business Unit",
                        "team": "Team"
                    },
                    "snoozeDialog": {
                        "title": "Snooze Lead",
                    },
                    "jobHealthCheck": {
                        "title": "Job Health Check",
                        "noData": "No data available  for this job",
                        "depost": "Deposit",
                        "payment_terms": "Payment Terms",
                        "not_booked": "Not Booked",
                        "not_required": "Not Required",
                        "check_measures": "Check Measures",
                        "installs": "Dispatch Appointments",
                        "supply_jobs": "Supply Jobs",
                        "invoices": "Invoices",
                        "booked": "Booked",
                        "confirmed_partial": "Partial ({{x}} / {{n}})",
                        "confirmed_all": "All ({{n}})",
                        "confirmed": "Confirmed",
                        "not_created": "Not Created",
                        "not_sent": "Not Sent",
                        "sent": "Sent",
                        "released": "Released",
                        "overdue": "Overdue",
                        "paid": "Paid",
                        "required": "Required",
                        "received": "Received",
                        "completed": "Completed",
                        "collected_warning": "Collected*",
                        "collected": "Collected",
                        "tooltips": {
                            "job_ignore_deposit_limit": "The deposit limit has been overridden on the job",
                            "no_min_dep_configured": "There is no mimimum deposit amount configured for your system",
                            "min_dep_collected": "The minimum deposit requirement has been met.",
                            "min_dep_not_collected": "The minimum deposit requirement has NOT been met",
                            "confirmed_all": "All {{n}} line(s) from the quote have been confirmed",
                            "confirmed_partial": "{{x}} out of {{n}} line(s) from the quote have been confirmed",
                            "cm_not_required": "Check Measure was not required.",
                            "cm_booked": "A Check Measure appointment has been book but is not yet confirmed",
                            "cm_not_booked": "A Check Measure Appointment is required and has not yet been scheduled",
                            "cm_confirmed": "The Check Measure Appointment has been confirmed with the customer",
                            "cm_completed": "The Check Measure has been completed",

                            "inst_completed": "The Dispatch Appointment has been completed",
                            "inst_confirmed": "The Dispatch Appointment has been confirmed with the customer",
                            "inst_not_required": "A Dispatch Appointment is not required for this item",
                            "inst_booked": "The Dispatch Appointment has been booked but not yet confirmed with the customer",
                            "inst_not_booked": "A Dispatch appointment is required for this item but has not yet been scheduled",
                            "inst_not_booked_in_supply": "The item is in supply and a Dispatch Appointment has not yet been scheduled",
                            "inst_not_booked_in_goods_receipted": "The stock has arrived and the Dispatch Appointment has not yet been scheduled",
                            "inst_booked_in_goods_receipted": "The stock has arrived and the Dispatch Appointment has not been confirmed with the customer",
                            "inst_overdue": "The Dispatch Appointment is overdue",
                            "inst_warning_days": "The install has not yet been confirmed OR The install is comming up and the stock has not arrived",
                            "inst_booked_but_not_required": "A Dispatch Appointment has been scheduled for this item but was not required (as indicated on the job).",
                            "inst_on_or_before_eta": "Stock will not arrive on time, based on supply ETA.",

                            "supply_not_created_service": "Service products do not require supply jobs",
                            "supply_not_created": "Supply jobs have not yet been created. Check Measures are still outstanding",
                            "supply_not_created_warning": "Supply jobs have not yet been created.",
                            "supply_not_sent": "Supply jobs have been NOT yet been Sent to the supplier.",
                            "supply_sent": "Supply jobs have been sent to the supplier",
                            "supply_released": "Supply jobs have been released to manufacturing",
                            "supply_overdue": "Stock has not arrived and the ETA date has passed",
                            "supply_received": "Stock has been received",
                            "supply_received_partial": "Some of the stock has been received",
                            "supply_stock_due": "The ETA Date is comming up and the stock has not yet arrived",
                            "supply_waiting_cm": "Check Measure must be completed first",


                            "invoice_not_created": "An Invoice for this item has not yet been created. Note stock is still outstanding",
                            "invoice_not_created_warning": "An Invoice for this item has not yet been created and all stock has arrived",
                            "invoice_not_sent": "An invoice has been created and has not yet been sent to the customer",
                            "invoice_overdue": "The payments for the invoice are overdue",
                            "invoice_sent": "Invoice has been sent to the customer",
                            "invoice_paid": "Invoice has been paid.",
                        }
                    },
                    "jobDiff": {
                        "BasePrice": "Base Price",
                        "DeliveryPrice": "Delivery Price",
                        "InstallPrice": "Install Price",
                        "OptionsPrice": "Options Price",
                        "OriginalCostPrice": "Original Cost Price",
                        "OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
                        "OriginalCostPriceTax": "Original Cost Price Tax",
                        "Price": "Price",
                        "PriceIncTax": "Price (Inc Tax)",
                        "PriceTax": "Price Tax",
                        "AdjustmentCost": "Adjustment Cost",
                        "BaseCost": "Base Cost",
                        "DeliveryCost": "Delivery Cost",
                        "Drop": "Drop",
                        "InstallCost": "Install Cost",
                        "OptionsCost": "Options Cost",
                        "Width": "Width",
                        "MaterialType": "Material Type",
                        "Material Type": "Material Type",
                        "title": "Edit Changes",
                        "noChanges": "There are no changes to view.",
                        "originalValue": "Before Edit",
                        "quoted": "Quoted",
                        "newValue": "After Edit",
                        "change": "Variance",
                        "CostPrice": "Cost Price",
                        "CostPriceIncTax": "Cost Price (Inc Tax)",
                        "CostPriceTax": "Cost Price Tax",
                        "salePriceInc": "Sale Price (Inc Tax)",
                        "SalePriceIncTax": "Sale Price (Inc Tax)",
                        "SalePrice": "Sale Price",
                        "SalePriceTax": "Sale Price Tax",
                        "margin": "Margin",
                        "newLine": "New Line",
                        "jobDetails": "Job Details",
                        "lineDetails": "Item Details",
                        "lineNo": "Line",
                        "location": "Location",
                        "supplier": "Supplier",
                        "product": "Product",
                        "details": "Details",
                        "originalSalePrice": "Original Sale Price",
                        "newSalePrice": "New Sale Price",
						"property": "Property",
						"BaseCostAdjustment": "Base Cost Adjustment",
						"BaseCostDiscount":"Base Cost Discount",
						"BasePriceAdjustment": "Base Price Adjustment",
						"BasePriceDiscount": "Base Price Discount",
						"DeliveryCostAdjustment": "Delivery Cost Adjustment",
						"DeliveryCostDiscount":"Delivery Cost Discount",
						"DeliveryPriceDiscount": "Delivery Price Discount",
						"DeliveryPriceAdjustment":"Delivery Price Adjustment",
						"InstallCostAdjustment": "Install Cost Adjustment",
						"InstallCostDiscount":"Install Cost Discount",
						"InstallPriceDiscount": "Install Price Discount",
						"InstallPriceAdjustment":"Install Price Adjustment"
                    },
                    "productTester": {
                        "title": "Product Tester",
                        "addLine": "Add Test Line",
                        "testLineDlgTitle": "Test Line",
                        "generateOutput": "Generate Artifacts",
                        "copy": "Copy",
                        "edit": "Edit",
                        "quoteDocumentTemplate": "Quote Template",
                        "invoiceDocumentTemplate": "Invoice Template",
                        "lockWarning": "WARNING: This feature WILL affect other users. Only user this when no other user are using the system.",
                        "businessUnit": "Business Unit"
                    },
                    "accentTutorials": {
                        "title": "Tutorials",
                        "salesReps": "Sales Representatives",
                        "wholesaleOrders": "Wholesale Orders (Supply Jobs)"
                    },
                    "paymentReconciliation": {
                        "title": "Payment Reconciliation"
                    },
                    "statements": {
                        "title": "Statements",
                        "statementType": "Statement Type",
                        "asAtDate": "Raised on or before",
                        "activityDateRange": "Activity Range",
                        "generatingStatements": "Generate {{count}} statement(s)",
                        "generateStatementsTitle": "Generating Statements",
                        "noSelection": "No companies were selected"                        
                    },
                    "invoices": {
                        "title": "Finance",
                        "expectedIncome": "Incoming Money",
                        "sectionInvoices": "Invoices",
                        "summaryBox": {
                            "open": "Open",
                            "openTooltip": "Invoices in open status, not sent to customer",
                            "awaitingPayment": "Awaiting Payment",
                            "awaitingPaymentTooltip": "Invoices sent to customer and awaiting payment",
                            "overdue": "Overdue",
                            "overdueTooltip": "Invoices awaiting payment that are past the due date",
                            "statements": "Statements",
                            "statementsTooltip": "View and Generate Statements"
                        },
                        "sectionPayments": "Payments",
                        "paymentSummaryBox": {
                            "unreconciled": "Unreconciled",
                            "unreconciledTooltip": "Payments that have not yet been marked as reconciled with your back account",
                            "reconciled": "Reconciled",
                            "reconciledTooltip": "Payments that have been marked as reconciled with your back account"
                        }
                    },
                    "tracking": {
						"title": "Tracking",
						"titleWithType": "Tracking - {{viewType}}",
						"existingAppointment": "There are existing open activities for this job",
						"existingAppointmentOpenSelect": "Open Selected",
						"existingAppointmentCreateNew": "Create New",
                        "selectAppointment": "Select Appointment",
                        "selectViewLabel": "Tracking View",
                        "selectViewPlaceholder": "Select View",
                        "searchPlaceholder": "Search...",
                        "newNotePlaceholder": "Enter file note text and press <enter>",
                        "noResults": "No Result Found",
                        "total": "TOTAL",
                        "showDetails": "Show Details",
                        "hideDetails": "Hide Details",
                        "quoting": "Quoting",
                        "jobs": "Jobs",
                        "opportunities": "Opportunities",
                        "projects": "Projects",
                        "followUp": "Follow Up",
                        "snooze1Day": "1 Day",
                        "snooze2Day": "2 Days",
                        "snooze3Day": "3 Days",
                        "snooze1Week": "1 Week",
                        "snooze2Week": "2 Weeks",
                        "snooze3Week": "3 Weeks",
                        "snooze1Month": "1 Month",
                        "recordChanged": "This job has changed. Click the refresh icon to refresh the data",
                        "quoting_active": "Active Quotes",
                        "quoting_active_desc": "$t(application_strings.views.tracking.quoting_active) - All active jobs in the quoting stage",
                        "quoting_open": "Open Quotes",
                        "quoting_open_desc": "$t(application_strings.views.tracking.quoting_open) - Jobs in the quoting stage that have an open status",
                        "quoting_sent": "Sent Quotes",
                        "quoting_sent_desc": "$t(application_strings.views.tracking.quoting_sent) - Jobs in the quoting stage that have had the quote document sent to the customer",
                        "quoting_expired": "Expired Quotes",
                        "quoting_expired_desc": "$t(application_strings.views.tracking.quoting_expired) - Jobs in the quoting stage that expired before the customer accepted the quote document",
                        "quoting_lost": "Lost Quotes",
                        "quoting_lost_desc": "$t(application_strings.views.tracking.quoting_lost) - Jobs where the customer did not progress and have been marked as lost",
                        "jobs_active": "Active Orders",
                        "jobs_active_desc": "$t(application_strings.views.tracking.jobs_active) - All active jobs in the order stage",
                        "jobs_all_orders": "All Orders",
                        "jobs_all_orders_desc": "$t(application_strings.views.tracking.jobs_all_orders) - All active and closed jobs in the order stage.",
                        "jobs_book_appt": "Book Appointments",
                        "jobs_book_appt_desc": "$t(application_strings.views.tracking.jobs_book_appt) - All active jobs that require appointments to be booked",
                        "projects_all": "Project Management View",
                        "projects_all_desc": "$t(application_strings.views.tracking.projects_all) - Project Management view of active jobs",
                        "opps_lead": "Leads",
                        "opps_lead_desc": "$t(application_strings.views.tracking.opps_lead) - Leads that are not profiled and/or have not been scheduled an appointment",
                        "opps_quotation": "Quotation",
                        "opps_quotation_desc": "$t(application_strings.views.tracking.opps_quotation) - Lead becomes an Opportunity as a quote has been created and has been sent to the customer",
                        "opps_negotiation": "Negotiation",
                        "opps_negotiation_desc": "$t(application_strings.views.tracking.opps_negotiation) - Leads profiled and/or have not been scheduled an appointment",
                        "opps_won": "Won",
                        "opps_won_desc": "$t(application_strings.views.tracking.opps_won) - Opportunity won.  Customer has confirmed to proceed with a quote",
                        "opps_lost": "Lost",
                        "opps_lost_desc": "$t(application_strings.views.tracking.opps_lost) - Opportunity Lost.  Customer is not proceeding with any quotes given",
                        "service_pending_approval": "Pending Approval",
                        "service_pending_approval_desc": "$t(application_strings.views.tracking.service_pending_approval) - All active service jobs in the quoting stage",
                        "service_sent": "Sent",
                        "service_sent_desc": "$t(application_strings.views.tracking.service_sent) - Service jobs in the quoting stage that have had the quote document sent to the customer",
                        "service_approved": "Approved",
                        "service_approved_desc": "$t(application_strings.views.tracking.service_approved) - Active service jobs that have been confirmed",
                        "service_expired": "Expired Service Jobs",
                        "service_expired_desc": "$t(application_strings.views.tracking.service_expired) - Service jobs in the quoting stage that expired before the being approved",
                        "expired": "Expired",
                        "service": "Service",
                        "mf": "Manufacturing",
                        "mf_scheduler": "MF Scheduling",
                        "mf_scheduler_desc": "$t(application_strings.views.tracking.mf_scheduler) - All supply jobs ready for or in Manufacturing",
                        "updateCommitReasonTitle": "Bulk Update Commitment Reason",
                        "updateCommitReasonLabel": "Select Commitment Reason",
                        "updateCommissionDateTitle": "Bulk Update Commission Date",
                        "updateCommissionDateLabel": "Select Commission Date",
                        "updateCommissionDateNoPermission": "You do not have permission to change commission dates",
                        "alerts": {
                            "requireCM": "The job was confirmed {{days}} day(s) ago with no check measure booked",
                            "stockDue": "Stock has not been receipted and is due in {{days}} day(s)",
                            "jobEdit": "Job has been in edit mode for {{days}} day(s)",
                            "supplyPending": "This job failed to successfully send to your supplier. Please investigate or try resending it to the supplier.",
                            "stockNotArrived":"Dispatch Appt. is due in {{days}} day(s) and stock has not been receipted",
                            "quoteToExpire": "The quote will expire in {{days}} day(s)",
                            "validationErrors": "Validation Errors",
                            "requireDep": "A minimum deposit of {{amount}} is required",
							"cmNotConfirmed": "Check Measure Appointment was booked {{days}} day(s) ago and has not been confirmed.",
							"instNotConfirmed": "Dispatch Appointment was booked {{days}} day(s) ago and has not been confirmed.",
                            "instNotCompleted": "Dispatch Appointment was {{days}} day(s) ago and has not been completed.",
                            "availableCredit": "{{name}} has {{amount}} of credit"
                        },
                        "actions": {
                            "bookService": "Book Service in Calendar",
                            "bookServiceMaps": "Book Service with Maps",
                            "bookCM": "Book Check Measure in Calendar",
                            "bookCMMaps": "Book Check Measure with Maps",
                            "bookInst": "Book Dispatch in Calendar",
                            "bookInstMaps": "Book Dispatch with Maps",
                            "moveToSupply": "Move to Supply",
                            "alterTags": "Alter Tags",
                            "updateCommitReason": "Set Commit Reason",
                            "updateCommissionDate": "Set Commission Date",
                            "resendToSupply": "Resend to Supply",
                            "updateETA": "Update ETA",
                            "goodsReceived": "Receipt Goods",
                            "closeJob": "Close Job",
                            "cancelJob": "Cancel Job",
                            "invoiceJobGenerate": "Generate Invoice Only",
                            "invoiceJob": "Generate and Send Invoice",
                            "editJob": "Edit Job",
                            "finishEditJob": "Finish Edit",
                            "newPayment": "New Payment",
                            "sendOrderConfirmation": "Email Order Confirmation",
                            "printOrderConfirmation": "Print - Order Confirmation",
                            "printInstallJobSheet": "Print - Install Job Sheet",
                            "markAsDispatched": "Mark as Dispatched"
                        },
                        "filters": {
                            "alertHasAlert": "Show Alert",
                            "all": "Show All",
                            "dayToday": "Today",
                            "dayTomorrow": "Tomorrow",
                            "day_1": "Monday",
                            "day_2": "Tuesday",
                            "day_3": "Wednesday",
                            "day_4": "Thursday",
                            "day_5": "Friday",
                            "day_6": "Saturday",
                            "day_0": "Sunday"
                        },
                        "quotingGrid": {
                            "reference": "Reference",
                            "salesRep": "Sales Rep",
                            "company": "Company",
                            "quoteDate": "Quote Date",
                            "daysSinceSent": "Days since Sent",
                            "contact": "Contact",
                            "stage": "Stage",
                            "alert": "Alert",
                            "city": "City",
                            "productSummary": "Products",
                            "daysToExpiry": "Days to Expiry",
                            "amountPaid": "Paid",
                            "salePriceInc" : "Sale Price Inc",
                            "validation": "Validation",
                            "followUp": "Follow Up",
                            "expired": "EXPIRED",
                            "today": "TODAY"
                        },
                        "orderingGrid": {
                            "check": "Select",
                            "alert": "Alerts",
                            "reference": "Reference",
                            "orderDate": "Order Date",
                            "salesRep": "Sales Rep",
                            "company": "Company",
                            "contact": "Contact",
                            "siteContact": "Site Contact",
                            "city": "City",
                            "status": "Status",
                            "amountPaid": "Paid",
                            "products": "Product",
                            "eta": "ETA",
                            "jobType": "Type",
                            "supplier": "Supplier",
                            "priority": "Priority",
                            "cmBy": "CM By",
                            "installedBy": "Dispatch By",
                            "installDate": "Dispatch Date",
                            "salesInc": "Sales Inc",
                            "actions": "Actions"                            
                        },
                        "bookApptGrid": {
                            "reference": "Reference",
                            "contact": "Name",
                            "city": "City",
                            "contactInfo": "Contact",
                            "paidPerc": "Paid %",
                            "product": "Product",
                            "daysSinceConfirmed": "Days Since Ordered",
                            "checkMeasuresRequired": "CM Required",
                            "installsRequired": "Installs Required",
                            "eta": "ETA",
                            "validation": "Validation",
                            "followUp": "Follow Up",
                            "today": "TODAY"
                        }

                    },
                    "scheduler": {
                        "title": "Scheduler",
                        "user": "Representative",
                        "missingMapCoordinates": "One or more items to be scheduled are missing map coordinates",
                        "noData": "No items to schedule.",
                        "missingAddressExistingApt": "<p>The location of this address in not known. Please drag this appointment to the correct location on the map.</p><p>{{address}}</p>"
                    },
                    "newVersionDialog": {
                        "title": "Insyte Web Updated",
                        "message": "Insyte Web has been updated. Please see your notifications for important changes."
                    },
	                "login": {
	                    "title": " Insyte Web",
	                    "username": "Email",
                        "password": "Password",
                        "passwordVerify": "Confirm Password",
	                    "rememberMe": "Remember Me",
	                    "submit": "Log in",
                        "forgotPassword": "Forgot your password?",
                        "loginFailedMsg": "The email or password entered was in correct. If you are having trouble logging in you may want to try the Forgot your password feature below",
                        "passwordMissmatch": "Passwords do not match",
                        "passwordRequired": "Password is required",
                        "account": "Select Business",
                        "accountMsg": "You email is associated with multiple businesses. Please select the business you want to login to.",
                        "passwordRquirementTitle": "Password Strength",
                        "passwordRquirementMin": "Minimum length 6",
                        "passwordRquirementDigit": "At least 1 digit",
                        "passwordRquirementLower": "At least 1 lowercase letter",
                        "passwordRquirementUpper": "At least 1 uppercase letter",
                        "passwordRquirementMatch": "Confirm password matches",
	                },
                    "resetPassword2FA": {
                        "title": " Reset Password",
                        "username": "Email",
                        "code": "Enter Code",
                        "invalidCode": "Invalid Code",
                        "passwordMissmatch": "Passwords do not match",
                        "passwordRequired" : "Password is required",
                        "password": "Password",
                        "passwordVerify": "Confirm Password",
                    },
                    "dashboard": {
                        "title": "Dashboard",   
                        "noOfMonthsLabel": {
                            "label0": "# of Months (current month)",
                            "label1": "# of Months (from previous month)",
                            "labelX": "# of Months (from {{months}} months ago)"
                        },
                        "businessUnit": "Business Units",
                        "widgets": {
                            "oppPipeLine": {
                                "leads": "Leads",
                                "leads_desc": "Leads that are not profiled and/or have not been scheduled an appointment",
                                "quotations": "Quotations",
                                "quotations_desc": "Leads profiled and have been scheduled an appointment, but no quote has been sent to the customer",
                                "negotiations": "Negotiations",
                                "negotiations_desc": "Lead becomes an Opportunity as a quote has been created and has been sent to the customer",
                                "won": "Won",
                                "won_desc": "Opportunity won.  Customer has confirmed to proceed with a quote",
                                "lost": "Lost",
                                "lost_desc": "Opportunity Lost.  Customer is not proceeding with any quotes given"
                            },
                            "salesConversion": {
                                "rep": "Rep",
                                "rep_desc": "KS to provide",
                                "rep": "Rep",
                                "rep_desc": "KS to provide",
                            },
                            "salesPerformanceByMonth": {
                                "month": "Month",
                                "wonCount": "Won (Count)",
                                "wonCountDesc": "Opportunities with a status of Won that was closed in the Month",
                                "lostCount": "Lost (Count)",
                                "lostCountDesc": "Opportunities in the pipline stage negotiation or contract with a status of Lost that was closed in the Month",
                                "conversionCount": "Conversion %",
                                "conversionCountDesc": " Win(Count)  /( (Win_count) + (Lost Count))",
                                "wonAmount": "Won $",
                                "wonAmountDesc": "sum(confirmed orderines inc GST) linked to Opportunities with a status of Won that was closed in the Month",
                                "lostAmount": "Lost $",
                                "lostAmountDesc": "sum(Quotes inc GST forecasted) linked Opportunities in the pipline stage of negotiation with a status of Lost that was closed in the Month",
                                "conversionAmount": "Conv $",
                                "conversionAmountDesc": "Win $   / ( (Win $) + (Lost $))",
                            },
                            "salesPerformanceByRep": {
                                "month": "Month",
                                "leadCount": "Leads (Count)",
                                "leadCountDesc": "All leads in the lead stage that are assigned to the rep.",
                                "quotationCount": "Quotation (Count)",
                                "quotationCountDesc": "All leads in the Quotation pipeline stage that are assigned to the rep.",
                                "negotiationCount": "Negotiation (Count)",
                                "negotiationCountDesc": "Opportunities in Negotiation Stage.",
                                "wonCount": "Won (Count)",
                                "wonCountDesc": "Opportunities with a status of Won that was closed in the time frame",
                                "lostCount": "Lost (Count)",
                                "lostCountDesc": "ALl opportunities with a status changed to Lost in the time frame",
                                "conversionCount": "Conversion %",
                                "conversionCountDesc": " Win(Count)  /( (Win_count) + (Lost Count))",
                                "negotiationAmount": "Negotiation $",
                                "negotiationAmountDesc": "Sum of quotes link to opportunities in negotiation stage ( quote is forecasted)",
                                "wonAmount": "Won $",
                                "wonAmountDesc": "All opportunites Won - when the opportunity status changed to won, in the time frame",
                                "lostAmount": "Lost $",
                                "lostAmountDesc": "All opportunties Lost - when the opporutnity status is changed to lost in the time frame",
                                "conversionAmount": "Conv $",
                                "conversionAmountDesc": "Win $   / ( (Win $) + (Lost $))",
                            },
                            "salesAmountByRepByMonth": {
                                "total" : "TOTAL"
                            }

                        }
                    },
                    "picklists": {
                        "title": "Picklists"
                    },
                    "calendar": {
                        "title": "Calendar",
                        "notesPlaceHolder": "enter notes",
                        "appointment": "Appointment",
                        "owner": "Owner",
                        "ownerTeam": "Owner/Team",
                        "newAppointment": "New",
						"useDialogTitle": "Select Calendars",
						"settingsDialogTitle": "Calendar Settings",
                        "exportPdfDialogTitle": "Export PDF",
                        "exportPdfDialogFitAndRun": "Fitter and Run sheets",
                        "exportPdfDialogSplitByRep": "Split by representative",
                        "exportPdfDialogEmailRep": "Email representative",
                        "setupTitle": "Setting up your calendar",
                        "setupMessage": "Make sure you have selected to include either Sales or Installation users. If you still can't see any users make sure the 'Show in calendar' and either quoting or installation features are enabled in settings for your users",
                        "setupLink": "",
                        "deleteCMWithCompletedTitle": "Deleting Check Measure Appointment",
                        "deleteCMWithCompletedTitleMsg": "You are deleting this check measure appointment. There is a previously completed check measure. Do you want to update the job as having been check measured",
                        "deleteInstallWithCompletedTitle": "Deleting Dispatch Appointment",
                        "deleteInstallWithCompletedTitleMsg": "You are deleting this dispatch appointment. There is a previously completed appointment. Do you want to update the job as having been dispatched",
                        "deleteClosedActivityTitle": "Delete Closed Activity",
                        "deleteClosedActivityMsg": "Closed activities cannot be deleted",
                        "deleteActivityFeatureDisabledTitle":"Delete Appointment not permitted",
                        "deleteActivityFeatureDisabledMsg":"You are not permitted to perform this action",
                        "byPassCMTitle": "Skip Check Measure",
                        "byPassCMMsg": "STOP: If you continue one or more jobs that require a Check Measure will be marked as Check Measure Completed.",
                        "byPassCMButton": "Complete Check Measures",
                        "deleteInProgressCMTitle": "Check Measure In-Progress",
                        "deleteInProgressCMMsg": "This check measure is in-progress and cannot be deleted. Please complete the check measure first.",
                        "runSheet": "Generate Run Sheet",
                        "jobSheet": "Generate Job Sheet",
                        "combined": "Both",
                        "noReps": "No representatives are selected.",
                        "invalidDates": "Invalid date range selected.",
                        "noTemplate": "No document template selected.",
                        "oneRepForPreview": "Preview is only available when selecting a single representative.",
                        "summary": {
                            "rep": ""
                        }
                    },
                    "company": {
                        "title": "Company",
                        "sectionDetails": "Details",
                        "sectionAddress": "Address/Notes",
                        "sectionCustomPricing": "Custom Pricing",
                        "sectionJobs": "Jobs",
                        "sectionPayments": "Payments",
                        "sectionActivities": "Activities",
                        "sectionContacts": "Contacts",
                        "sectionFinancialDetails": "Financial Details",
                        "duplicatePricing": {
                            "title": "Duplicate Products",
                            "msg": "Duplicate Product found. Please ensure each product is only specified once"
                        }
                    },
                    "contact": {
                        "title": "Contact",
                        "sectionDetails": "Details",
                        "sectionAddress": "Address/Notes",
                        "sectionJobs": "Jobs",
                        "sectionPayments": "Payments",
                        "sectionActivities": "Activities",
                        "sectionCommunications": "Communication Log",
                    },
                    "job": {
						"title": "Job - {{Reference}}",
						"all": "Select All",
						"unconfirmed": "Unconfirmed Only",
						"confirmed": "Confirmed Only",
						"clear": "Clear",
                        "serviceJobs": "Service Jobs",
                        "invoices": "Invoices",
                        "servicesEmpty": "No Service Jobs",
                        "invoicesEmpty": "No invoices",
                        "supplyJobs": "Supply Jobs",
                        "supplyJobsEmpty": "No supply jobs",
                        "sectionDetailsJob": "Job - $t({{Stage}}) Stage ",
                        "sectionDetailsServiceJob": "Service Job - $t({{Stage}}) Stage ",
                        "sectionAddress": "Address",
                        "sectionJobCommissions": "Commissions",
                        "sectionCommunications": "Communication Log",
                        "sectionNotes": "Notes",
                        "sectionJobDetails": "Job Details",
                        "siteAddress": "Site Address",
                        "deliveryAddress": "Delivery Address",
                        "useCompanyAddress": "Copy Company Address",
                        "useContactAddress": "Copy Contact Address",
                        "useSiteContactAddress": "Copy Site Contact Address",
                        "sectionPayments": "Payments",
                        "sectionActivities": "Activities",
                        "customerNotes": "Customer Notes",
                        "customerNotesPlaceHolder": "Enter customer notes here...",
                        "customerNamePlaceHolder": "name of your customer...",
                        "siteContactNamePlaceHolder": "name of the site contact...",
                        "installNotes": "Installation Notes",
                        "supplyNotes": "Supply/Manufacturing Notes",
                        "supplyNotesPlaceHolder": "Enter notes concerning the supplier here...",
                        "installNotesPlaceHolder": "Enter notes concerning the installation here...",
						"internalNotesPlaceHolder": "Enter internal notes here...",
						"scheduleServiceMessage": "Create a new Appointment to schedule a service appointment for '{{order}}'",
                        "scheduleCheckMeasureMessage": "Create a new Appointment to schedule a check measure appointment for '{{order}}'",
                        "scheduleInstallMessage": "Create a new Appointment to schedule a dispatch appointment for '{{order}}'",
                        "scheduleAppointmentSubject": "{{type}} - {{order}} - {{name}} - {{city}}",
                        "reprice": "Reprice Now",
                        "hideLineDetails": "Hide Details",
                        "showLineDetails": "Show Details",
                        "reviewJobTitle": "Preview",
                        "printJobTitle": "Print",
                        "mobileJobReviewTitle": "Review Details",
                        "mobileJobReviewCommon": "COMMON",
                        "reQuoteInfo": "When re-quoting you can change from one product to another if you choose to.",
                        "excludeProduct": "EXCLUDE PRODUCT",
                        "percentage": "%",
                        "dollar": "$",
                        "incTax": "Inc. Tax",
                        "exTax": "Ex. Tax",
                        "lines": {
                            "lineNo": "Line No",
                            "productName": "Product",
                            "qty": "Qty",
                            "width": "Width",
                            "drop": "Drop",
                            "location": "Location/Desc",
                            "stage": "Stage",
                            "status": "Status",
                            "dispatchStage": "Dispatch Stage",
                            "cost": "Cost Ex",
                            "salePrice": "Sale Price", 
                            "salePriceTax": "Tax",                            
                            "salePriceInc": "Sale Price Inc",                            
                            "lessCredit": "Less Credit ({{Credit}})",
                            "paid": "Paid",
                            "balance": "Balance",
                            "confirmedLines" : "Confirmed Lines",
                            "unconfirmedLines": "Unconfirmed Lines",
                            "lineType": "Type"
                        },
                        "openDispatchAppointment": "Unresolved Dispatch Appointment",
                        "closeDispatchAppointments": "The following appoint must be closed before continuing.",
						"viewEstimatedCommissions": "Est. Commissions",
						"viewEstimatedCommissionsMobile": "Est.",
						"viewComissionDates": "Commission Dates",
						"viewComissionDatesMobile": "Dates",
						"viewActualCommissions": "Statement",
						"etaDatesDlg": {
							"title": "ETA Dates",
							"warning": ""
						},
                        "commissionDatesDlg": {
                            "title": "Commission Dates",
                            "warning": "WARNING: Changing the date may result in commissions being paid more than once. The automatic commission date rule only applies to jobs with no commission date set."
                        }
                    },
                    "reportAccess": {
                        "title": "Report Access",
                    },
					"report": {
						"title": "Reports",
						"prompt": "Please select a report to view.",
						"noAccess": "You have not been given access to any reports. Please see your administrator or assign access under reports in Management.",
						"toggleDesignFrom": {
							"true": "View",
							"false": "Design"
						}
					},
                    "jobDiscount": {
                        "title": "Discounts",
                        "cost": "COST",
                        "sales": "SALES",
                        "profit": "PROFIT",
                        "show": "Show",
                        "discountType": "Discount",
                        "dollar": "$",
                        "percentage": "%",
                        "amountType": "Amounts",
                        "incTax": "Inc. Tax",
                        "exTax": "Ex. Tax",
                        "showTax": "Tax",
                        "amount": "Amount",
                        "install": "Install",
                        "lineGroup": "Show Lines By",
                        "groupProduct": "Product",
                        "groupLine": "Job",
                        "margin": "Margin",
                        "markup": "Markup",
                        "marginBtn": "MARGIN",
                        "markupBtn": "MARKUP",
                        "delivery": "Delivery",
                        "showAmount": "Show Amounts",
                        "taxWarning": "*Tax will be calculated by line and so may change",
                        "approvalRequired": "Approval Required",
                        "minJobPrice": "Min approved price {{amount}}",
                        "toolTips": {
                            "BaseCostAmount": "Cost amount excluding install and delivery before any discounts",
                            "BaseCostInstall": "Cost amount for installation before any discounts",
                            "BaseCostDelivery": "Cost amount for delivery before any discounts",
                            "BaseCostTotal": "Total cost amount including base cost, installation cost and delivery cost before any discounts",
                            "DiscCostAmount": "The amount of discount to be applied to the cost excluding installation and delivery",
                            "DiscCostInstall": "The amount of discount to be applied to the installation portion of the cost",
                            "DiscCostDelivery": "The amount of discount to be applied to the delivery portion of the cost",
                            "DiscCostTotal": "The amount of discount to be applied to the total cost including installation and delivery",
                            "FinalCostAmount": "Total cost amount excluding installation and delivery costs after the discount is applied",
                            "FinalCostInstall": "Total installation cost amount after the installation discount is applied",
                            "FinalCostDelivery": "Total delivery cost amount after the delivery discount is applied",
                            "FinalCostTotal": "Total cost amount including installation and delivery after the discounts are applied",

                            "BaseRetailAmount": "Sales list price excluding install and delivery before any discounts",
                            "BaseRetailInstall": "Installation sales list price before any discounts",
                            "BaseRetailDelivery": "Delivery sales list price before any discounts",
                            "BaseRetailTotal": "Total sales list price including base price, installation price and delivery price before any discounts",
                            "DiscRetailAmount": "The amount of discount to be applied to the sales list price excluding installation and delivery",
                            "DiscRetailInstall": "The amount of discount to be applied to the installation portion of the sales list price",
                            "DiscRetailDelivery": "The amount of discount to be applied to the delivery portion of the sales list price",
                            "DiscRetailTotal": "The amount of discount to be applied to the total sales list price including installation and delivery",
                            "FinalRetailAmount": "Total sales price excluding installation and delivery after the discount is applied",
                            "FinalRetailInstall": "Total installation sales price after the installation discount is applied",
                            "FinalRetailDelivery": "Total delivery sales price after the delivery discount is applied",
                            "FinalRetailTotal": "Total sales price including installation and delivery after the discounts are applied",
                        },
                        "warnings": {
                            "productsHidden": "Select show lines by product to display hidden product level discounts",
                            "amountHidden": "Select 'Amount' to display hidden discounts on the amount columns",
                            "installHidden": "Select 'Install' to display hidden discounts on the install columns",
                            "deliveryHidden": "Select 'Delivery' to display hidden discounts on the delivery columns",
                            "salesHidden": "Select 'SALES' to display hidden discounts on sale price columns",
                            "costHidden": "Select 'COST' to display hidden discounts on cost price columns",
                        }
                    },
                    "opportunity": {
						"title": "Opportunity {{Description}}",
                        "sectionContactDetails": "Contact / Status",
                        "sectionAddress": "Address / Notes",
                        "sectionLeadDetails": "Lead Details",
                        "useCompanyAddress": "Copy Company Address",
                        "useContactAddress": "Copy Contact Address",
                        "useSiteContactAddress": "Copy Site Contact Address",
                        "useCompanyAddressSmall": "Company",
                        "useContactAddressSmall": "Contact",
                        "useSiteContactAddressSmall": "Site Contact",
                        "siteAddress": "Site Address",
                        "deliveryAddress": "Delivery Address",
                        "fullHouse": "Full",
                        "notes": "Notes",
                        "notesPlaceHolder": "enter notes here...",
                        "customerNamePlaceHolder": "name of your customer...",
                        "siteContactNamePlaceHolder": "name of site contact",
                        "scheduleOpportunityMessage": "Create a new Appointment to schedule '{{opportunity}}' opportunity",
                        "sectionProductOfInterest": "Lead Source / Products of Interest",
                        "poi": "Products of Interest",
                        "sectionJobs": "Jobs"
                    },
                    "leads": {
                        "title": "Leads",
                        "sectionLeads": "Leads",
                        "hideDetails": "Hide Details",
                        "showDetails": "Show Details",
                        "summaryBox": {
                            "webLeads": "Web Leads",
                            "webLeadsTooltip": "Unprocessed leads imported from other websites",
                            "leads": "Leads",
                            "leadsTooltip": "Unqualified leads, no appointment and no quote",
                            "quotationLeads": "Quotation",
                            "quotationLeadsTooltip": "Leads profiled and have been scheduled an appointment, but no quote has been sent to the customer",
                            "negotiationLeads": "Negotiation",
                            "negotiationLeadsTooltip": "Lead becomes an Opportunity as a quote has been created and has been sent to the customer",
                            "lostLeads": "Lost",
                            "lostLeadsTooltip": "Leads lost in the last 12 months"
                        },
                        "leadGrid": {
                            "actions": "Actions",
                            "matches": "Possible Matching Contacts:",
                            "noMatches": "No matches found.",
                            "createNew": "New Contact",
                            "snooze": "Snooze Lead",
                            "wake": "Wake Lead",
                            "createExising": "Existing",
                            "discard": "Discard Lead",
                            "leadDetails": "Lead Details",
                            "productsOfInterest": "Products Of Interest",
                            "customProperties": "Custom Properties",
                            "fileNotes": "File Notes",
                            "date": "Date",
                            "snoozeDate": "Select Snooze Date",
                            "selectSnoozeDateTitle": "Snooze lead until...",
                            "leadSource": "Lead Source",
                            "firstName": "First Name",
                            "lastName": "Last Name",
                            "email": "Email",
                            "mobile": "Mobile",
                            "workPh": "Work Ph",
                            "homePh": "Home Ph",
                            "phone": "Phone",
                            "snoozed": "Snoozed",
                            "discardDlgTitle": "Discard Lead",
                            "discardReasonLabel": "Discard Reason",
                            "discardNotesLabel": "Discard Notes",
                        }
                    },
                    "newLead": {
                        "title": "New Lead",
                        "searchLabel": "Search",
                        "description": "Existing Customer Results",
                        "useExisting": "Use Existing",
                        "searchPlaceholder": "Search to see if customer exists",
                        "instruction1": "1. First check for existing customer by searching on either name, company, phone numbers, suburb, postcode or address",
                        "instruction2": "2. Select Use Existing if the customer already exists OR select New Customer if the customer cannot be found."
                    },
                    "payment": {
                        "title": "Payment",
                        "notesPlaceHolder": "Enter payment notes",
                        "type": "Customer $t({{Type}})",
                        "required": "Required",
                        "requiredPositive": "Value must be positive",
                        "overAllocated": "This payment has been over allocated. Please un-allocate some monies",
                        "maxRefund": "The maximum amount that can be refunded is {{maxRefund}}",                        
                        "cancelDraft": {
                            "title": "Canceling Confirmed Payments",
                            "msg": "This has been confirmed (reconciled). To cancel the payment please unconfirm the payment first.",
                        },
                        "noAllocations": {
                            "title": "Warning: No Allocations",
                            "msg": "Payment/Refund has unallocated monies. Are you sure you want to continue?",
                            "continue": "Continue",
                            "goBack": "Go Back"
                        },
                        "allocations": {
                            "job": "Job",
                            "invoice": "Invoice",
                            "amount": "Amount",
                            "minDeposit": "Min Deposit",
                            "paid": "Paid",
                            "outstanding": "Outstanding",
                            "allocated": "Allocated",
                            "refund": "Refund",
                            "payment": "Payment",
                            "allocations": "Allocations",
                            "unallocated": "Unallocated",
                            "total": "Total"
                        }
                    },
                    "quoteDiscount": {
                        "title": "Discounts",
                        "line": "Line",
                        "product": "Product Name",
                        "price": "Price",
                        "tax": "Tax",
                        "total": "Total",
                        "discount": "Discount",
                        "discount_abbrev": "Disc.",
                        "final": "Final",
					},
					"management": {
						"title": "Management"
					},
                    "invoice": {
                        "title": "Invoice {{InvoiceNo}}",
                        "sectionDetails": "Invoice - $t({{Status}}) ",
                        "sectionAddress": "Details / Billing Address",
                        "sectionLines": "Invoice Lines",
                        "billingAddress": "Customer Billing Address",
                        "useCompanyAddress": "Copy Company Address",
                        "useContactAddress": "Copy Contact Address",
                        "sectionCommunications": "Communication Log",
                        "invoiceType": "Customer Invoice",
                        "creditNoteType": "Customer Credit Note",
                        "reOpen": "Re-Open",
                        "email": "Email",
                        "emailSend": "Email (Mark as Sent)",
                        "print": "Print to PDF",
                        "printSend": "Print to PDF (Mark as Sent)",
                        "reviewInvoiceMsg": "Please select the invoice template to use",
                        "reviewInvoiceTitle": "Review Invoice",
                        "lines": {
                            "line": "Line",
                            "description": "Description",
                            "unitPrice": "Unit Price",
                            "qty": "Qty",
                            "price": "Price",
                            "tax": "Tax",
                            "total": "Total",
                            "paid": "Paid",
                            "balance": "Balance",
                            "allocated": "Allocated",
                            "remainingCredit": "Remaining Credit",
                            "lessCredit": "Less Credit ({{Credit}})" 
                        }
                    },
                    "supplyJob": {
                        "title": "Supply Job {{OrderNumber}}",
                        "relatedOrders": "Related",
                        "relatedOrdersEmpty": "No related items",
                        "sectionDetails": "Supply Job - $t({{Status}}) ",
                        "sectionAddress": "Address",
                        "sectionCommunications": "Communication Log",
                        "sectionNotes": "Notes",
                        "sectionOrderDetails": "Details",
                        "billingAddress": "Billing Address",
                        "deliveryAddress": "Delivery Address",
                        "useCompanyAddress": "Copy Company Address",
                        "useContactAddress": "Copy Contact Address",
                        "supplyNotes": "Supply/Manufacturing Notes",
                        "supplyNotesPlaceHolder": "Enter notes concerning the supplier here...",
                        "internalNotes": "System Notes",
                        "internalNotesPlaceHolder": "System generated note...",
                        "scheduleCheckMeasureMessage": "Create a new Appointment to schedule a check measure appointment for '{{order}}'",
                        "scheduleInstallMessage": "Create a new Appointment to schedule a dispatch appointment for '{{order}}'"
                    },
                    "orderLine": {
                        "title": "Product Line",
                        "sectionDetails": "Details",
                        "sectionConfiguration": "Configuration",
                        "sectionService": "Service",
						"required": "Required",
						"requiredOrdering": "Ordering",
						"requiredQuoting": "Quoting",
                        "all": "All",
                        "sectionNotes": "Notes",
                        "sizeLabel": "Size",
                        "width": "Width",
                        "drop": "Drop",
                        "product": "Product",
                        "customerNotes": "Customer Notes",
                        "customerNotesPlaceHolder": "Enter customer notes here...",
                        "installNotes": "Installation Notes",
                        "installNotesPlaceHolder": "Enter install notes here...",
                        "supplyNotes": "Supply/Manufacturing Notes",
                        "supplyNotesPlaceHolder": "Enter notes concerning the supplier here...",
                        "reasonNotesPlaceHolder": "Enter notes concerning the fault here...",
                        "pricingInProgress": "Pricing in progress. If this message persists click the reprice button.",
                        "dispatchType": "Dispatch Type",
                        "install": "Install",
                        "pickup": "Pickup",
                        "delivery": "Delivery",
                        "dispatchStage": "Dispatch Stage",
                        "changeServiceLine": "Change Original Line"
                    },
                    "priceMarkup": {
                        "title": "Price Markup",
                    },
                    "quote": {
                        "title": "Quote",
                        "sectionDetails": "Quote",
                        "sectionAddress": "Site Address/Notes",
                        "sectionQuoteDetails": "Quote Details",
                        "siteAddress": "Site Address",
                        "useCompanyAddress": "Copy Company Address",
                        "useContactAddress": "Copy Contact Address",
                        "useSiteContactAddress": "Copy Site Contact Address",
                        "customerNotes": "Customer Notes",
                        "customerNotesPlaceHolder": "Enter customer notes here...",
                        "customerNamePlaceHolder": "name of your customer...",
                    },
                    "quoteReportSettings": {
                        "title": "Quote Report Settings",
                        "termsAndConditions": "Terms and Conditions",
                        "paymentDetails": "Payment Details",
                        "notes": "Notes",
                        "options": "Options",
                        "optionsAsList": "Comma list",
                        "optionsAsTable": "Table",
                        "optionsAsListLabel": "Format As"
                    },
                    "representative": {
                        "title": "User ({{FullName}})",
                        "mobile": "Mobile",
                        "workPhone": "Work Phone",
                        "jobTitle": "Job Title",
                        "emailSignature": "Email Signature",
                        "sectionGeneral": "General",
                        "sectionFeatures": "Features",
                        "manageTeams": "Manage Teams",
                        "manageLicense": "Manage License",
                        "manageSalesDiscountLimits": "Sales Discount Limits",
                        "changeEmail": "Change Email",
                        "changeEmailMsg": "Enter the new email",
                        "changeEmailDone": "Change Email Processed",
                        "canViewCalendarLabel": "Feature Can View Calendars / Activities",
						"commisionRules": "Commission Rules",
						"calendarSync": {
							"confirmNewLinkTitle": "Generate New Calandar Link",
							"confirmNewLinkMsg": "The user will need to update their phone calendar with the new link to continue receiving calendar updates",
							"header": "Calandar Sync Settings",
							"url": "Link",
							"generateNew": "Generate New Link",
							"emailUser": "Email Link to User",
							"smsUser": "SMS Link to User",
							"loading": "...",

							"emailNoLinkTitle": "Link Required",
							"emailNoLinkMsg": "Please generate a new link before trying again",
							"emailNoConfigTitle": "Email Configuration Required",
							"emailNoConfigMsg": "Please check your Email settings",
							"emailNoCredTitle": "Email Credentials Required",
							"emailNoCredMsg": "Please check the users email credentials",
							"emailErrorTitle": "Error Sending Email",
							"emailErrorMsg": "The following error was returned {{err}}",

							"smsNoLinkTitle": "Link Required",
							"smsNoLinkMsg": "Please generate a new link before trying again",
							"smsNoMobileTitle": "Mobile Number Required",
							"smsNoMobileMsg": "Please provide a mobile number of the user",
							"smsNoConfigTitle": "SMS Configuration Required",
							"smsNoConfigMsg": "Please check your SMS settings",
							"smsNoCredTitle": "SMS Configuration Required",
							"smsNoCredMsg": "Please check your SMS settings",
							"smsErrorTitle": "Error Sending SMS",
							"smsErrorMsg": "The following error was returned {{err}}",
							"linkSentTitle": "Link Sent",
							"linkSentMsg": "The link has been sent.",

						},
                        "changeEmailDlg": {
                            "emailInUse": {
                                "title": "EMAIL IN USE",
                                "msg": "This email is already in use by another account. Click \"Link Account\" to sent a link request to {{email}}. Once the request is approved by {{email}} the accounts will be linked."
                            },
                            "requestAccountLinkSent": {
                                "title": "LINK REQUEST SENT",
                                "msg": "A link request has been sent to {{email}}"
                            },
                            "emailChanged": {
                                "title": "EMAIL CHANGED",
                                "msg": "Email has been changed"
                            },
                            "unknownError": {
                                "title": "UNKNOWN ERROR",
                                "msg": "An unknown error has occured. Please try again and if the problem persists please contact your administrator"
                            }
                        },
                        "registration": {
                            "emailInUse": {
                                "title": "EMAIL IN USE",
                                "msg": "This email is already in use by another account. Click \"Link Account\" to sent a link request to {{email}}. Once the request is approved by {{email}} the accounts will be linked."
                            },
                            "requestAccountLinkSent": {
                                "title": "LINK REQUEST SENT",
                                "msg": "A link request has been sent to {{email}}"
                            },
                            "createAccountFailed": {
                                "title": "ERROR CREATING ACCOUNT",
                                "msg": "Failed to send the email. There was an error creating the account. Please contact your administrator if the problem persists.",
                            },
                            "sendFailed": {
                                "title": "ERROR SENDING EMAIL",
                                "msg": "Failed to send the email. There was an error sending the email. Please contact your administrator if the problem persists.",
                            },
                            "sentNoLic": {
                                "title": "REGISTRATION EMAIL",
                                "msg": "Registration email has been sent. WARNING, this user has no license allocated, please allocate a license to the user.",
                            },
                            "sent": {
                                "title": "REGISTRATION EMAIL",
                                "msg": "Registration email has been sent."
                            },
                            "localEmailInUse": {
                                "title": "Email In Use",
                                "msg": "The email is already assigned to another representative"
                            }
                        },
                        "featuresHeaders": {
                            "administration": "Administration",
                            "calendar": "Calendar",
                            "finance": "Finance",
                            "commissions": "Commissions",
                            "purchasing": "Purchasing",
                            "sales": "Sales",
                            "projects": "Projects",
                            "installation": "Installation",
                            "dashboards": "Dashboards"
                        }
                    },
                    "search": {
                        "title": "Search",
                    },
                    "quotes": {
                        "title": "Quotes",
                        "searchPlaceholder": "Search sales data",
                        "sectionQuotesTitle": "Quotes",
                        "outstandingQuotesTitle": "Outstanding Quotes",
                        "largestQuotesTitle": "Largest Quotes",
                        "daysToExpire": {
                            "title": "Quote Expiry (Count)",
                            "cat1": "30 days above",
                            "cat2": "20 days to 29 days",
                            "cat3": "10 days to 19 days",
                            "cat4": "5 days to 9 days",
                            "cat5": "1 day to 4 days",
                        },
                        "jobFunnel": {
                            "title": "Job Funnel",
                            "cat1": "Leads",
                            "cat2": "Quote Stage (Not Sent)",
                            "cat3": "Quote Stage (Sent)",
                            "cat4": "Order Stage"
                        },
                        "summaryBox": {
                            "open": "Open",
                            "active": "Active",
                            "sent": "Sent",
                            "received": "Received",
                            "expired": "Expired",
                            "won": "Won",
                            "lost": "Lost",
                            "manufacturing": "Manufacturing",
                            "dispatch": "Dispatch",
                            "invoiced": "Invoiced",
                        },
                    },
	                "orders": {
	                    "title": "Supply",
	                    "searchPlaceholder": "Search sales data",
	                    "sectionOrdersTitle": "Supply Jobs",
	                    "ordersTitle": "Supply Jobs",
	                    "ordersDueTitle": "Supply Jobs Due",
	                    "summaryBox": {
                            "open": "Open",
                            "openTooltip": "Supply that can be modified",
                            "priced": "Priced",
                            "pricedTooltip": "Supply jobs that have been priced, validated and are ready to be sent to the supplier",
                            "sent": "Sent",
                            "sentTooltip": "Supply jobs that have been sent to the supplier but not yet received",
                            "received": "Received",
                            "receivedTooltip": "Supply jobs that have been sent and successfully received by the supplier",
                            "expired": "Expired",
	                        "won": "Won",
	                        "lost": "Lost",
	                        "manufacturing": "Manufacturing",
                            "manufacturingTooltip": "Supply jobs that are being manufactured",
                            "dispatch": "Dispatch",
                            "dispatchTooltip": "Supply jobs that have been manufactured and are being dispatched",
                            "invoiced": "Invoiced",
                            "invoicedTooltip": "Supply jobs for wich invoices have been raised by the supplier",
	                    },
                    },
                    "sendOrderDialog": {
                        "title": "Send Order(s)",
                        "titleResult": "Send Order(s) Result",
                        "order": "Order",
                        "result": "Result",
                        "error": "Error",
                        "email": "Email",
                        "summary": "Summary",
                        "status": "Status",
                    },
                    "sendSmsDialog": {
                        "title": "Send SMS",
                        "sendSuccess": "SMS Sent.",
                        "sendSmsSuccess": "SMS Sent.",
                        "titleResult": "Send SMS Result",
                        "configSaved": "SMS Config Saved",
                        "emailConfigSaved": "Email Config Saved"
                    },
                    "sendEmailDialog": {
                        "selectPrintSettings": "Please select print settings for the attached invoice",
                        "selectInvoiceTemplate": "Please select a template for the attached invoice",
                        "selectQuoteTemplate": "Please select template and print settings for the attached quote",
                        "sending": "Sending Email...",
                        "sendEmailSuccess": "Email Sent.",
                        "title": "Send Email",
                        "titleResult": "Send Email Result",
                        "result": "Result",
                        "error": "Error",
                        "email": "Email",
                        "attachments": "Attachments",
                        "document": "Document",
                        "attach": "Attach",
                        "emailAccountDetails": "Email Account Details",
                        "username": "Username",
                        "password": "Password",
                        "requireAccountDetails": "Email not sent, require valid account details",
                        "configurationRequired": "Email Integration Settings are not configured correctly"
                    },
	                "settings": {
                        "title": "Settings",
                        "download": "Download selected",
                        "close":"Close",
                        "financeHistory": "Previous Transfers",
                        "sectionBusinessDetails": "Business Details",
                        "sectionDefaults": "Defaults & Picklists",
                        "sectionSettings": "General Settings",
                        "sectionUsers": "Users",
                        "sectionPriceMarkups": "Price Markups",
                        "sectionProductsAndPricing": "Products (Marketplace)",
                        "sectionMyProductsAndPricing": "Products (My Products)",
                        "sectionTaxRates": "Tax Rates",
                        "sectionReports": "Reports",
                        "noModuleAccess": "This feature is not available in you current Package, consider upgrading.",
                        "sectionIntegration": "Integration",
                        "messageQueue": "Message Queue",
                        "managePicklists": "Click here to manage Picklists",
                        "manageLookups": "Click here to manage Lookup Tables",
                        "sectionLicensing": "Package",
                        "manageLicenses": "Manage Licenses",
                        "smsIntegration": {
                            "SettingsTitle": "SMS Integration Settings",
                            "trialMsg": "During a trial, no SMS messages will be sent"
                        },
                        "sms": {
                            "sendSms":""
                        },
						"emailIntegration": {
							"SettingsTitle": "Email Integration",
							"insyteWebEmail": "Insyte Web Email",
							"smtpEmail": "SMTP Email Account",
							"trialMsg": "During a trial, email messages will only be sent to the trial email address",
							"providerInfo_undefined": "Please select an email provider",
							"providerInfo_1": "This option uses the built in Email Account for Insyte Web and requires not additional configuration, some email tracking will be available with this option.",
							"providerInfo_2": "This option allows you to configure your own SMTP account so emails will be sent via your company email server. Email tracking will not be available with this option.",
							"smtp": {
								"server": "Mail Server",
								"authentication": "Use Authentication",
								"secureConnection": "User Secure Connection",
								"userName": "Username",
								"password": "Password",
								"portNumber": "Port Number",
								"singleAccount": "Single Account",
								"individualAccounts": "Individual Accounts",
								"accountInfo_Single": "All emails will be sent via this account. Reply To will be set to the representative.",
								"accountInfo_1": "All emails will be sent via this account. Reply To will be set to the representative.",
								"accountInfo_2": "Emails will be sent via the individual users email account. By selecting this account type users will be forced to enter their own username and password the first time they send an email.",
								"enterAccountPasswordInfo": "Enter the username and password for email account.",
								"useGeneralFromEmail": "Use a general email as the sender (FROM) and the user's email as the REPLY TO",
								"useUserFromEmail": "Use the user's email as the sender (FROM)",
								"generalFromEmail": "General FROM email",
								"email": "Email"
							}
						},
                        "financeIntegration": {
                            "SettingsTitle": "Finance Integration",
                            "xero": "Xero",
                            "myob": "MYOB",
                            "generic": "Generic (JSON File)",
                            "providerInfo_1": "Integrate with Xero.",
                            "providerInfo_2": "Integrate with MYOB",
                            "pleaseConnect_Xero": "Please connect to Xero to configure integration",
                            "pleaseConnect_MYOB": "Please connect to MYOB to configure integration",
                            "pleaseConnect_Generic": "JSON Files will be downloaded",
                            "disconnectTitle": "Disconnect First",
                            "disconnectMsg": "Please disconnect the current finance provider before changing to another.",
                            "myobCompany": "Business",
                            "username": "Username",
                            "password": "Password"
                        },
                        "contact": {
                            "emailContact":"Email Contact"
                        },
                        "representative": {
                            "emailRepresentative": "Email Representative",
                            "emailLead": "Email Lead"
                        },
                        "leadsIntegration": {
                            "newApiKey": "Generate New API Key",
                            "apiInfoTitle": "API Details",
                            "apiMethod": "Method: post",
                            "apiContent": "Content-Type: application/json",
                            "apiEndPoint": "End Point: ",
                            "testAPIDescription": "Below is the test data that will be posted to the API. Click on TEST API to test the API. !!WARNING!! Clicking on TEST API will create a real lead in your system.",
                            "testAPI": "TEST API (will create a new lead)",
                            "contactHeader": "Contact Details",
                            "contactDescription": "Enter test details for contact information",
                            "SettingsTitle": "Lead Integration",
                            "FirstName": "First Name",
                            "FirstNameLabel": "Enter first name",
                            "LastName": "Last Name",
                            "LastNameLabel": "Enter last name",
                            "Email": "Email",
                            "EmailLabel": "Enter email",
                            "Mobile": "Mobile",
                            "MobileLabel": "Enter mobile number",
                            "WorkPh": "Work Ph",
                            "WorkPhLabel": "Enter work phone number",
                            "HomePh": "Home Ph",
                            "HomePhLabel": "Enter home phone number",
                            "LeadSource": "Lead Source",
                            "LeadSourceLabel": "Select a lead source",
                            "leadSourceMap": "{{desc}}: {{value}}",
                            "LeadSourceValues": "Post Values:",
                            "POILabel": "Enter qty for {{poi}}",
                            "poiHeader": "Products of Interest",
                            "poiDescription": "Enter test details for Products of Interest",
                            "POIValue": "Value to post is: {{value}}",
                            "emailDocumentation": "Email Integration Documentation",
							"notLicensed": "Your current license does not allow access to Web Leads.",
							"enableTest": "Show lead integration tester"
                        },
                        "tracking": {
                            "emailAddress": "BCCs the specified email address in all emails that are sent:\n(empty field does not bcc)",
                            "emailAddress_Label": "Tracking - Email Address",
                            "quoteExpiryDays": "Shows an Alert on the Tracking screen for every Job in the Quote stage that  is going to Expire in the specified number of days:\n(empty field does not show any alerts)",
                            "quoteExpiryDays_Label": "Tracking -  Quote Expiry Days",
                            "cmNotBookedDays": "Shows an Alert on the Tracking screen for every Job that can have a Check Measure booked, but nothing has happened for the specified number of days:\n(empty field does not show any alerts)",
                            "cmNotBookedDays_Label": "Tracking - CM Not Booked Days",
                            "cmNotConfirmedDays": "Shows an Alert on the Tracking screen for every Job that has a Check Measure booked, but the Check Measure Appointment has not been confirmed in the specified number of days since it was created:\n(empty field does not show any alerts)",
                            "cmNotConfirmedDays_Label": "Tracking - CM Not Confirmed Days",
                            "stockDueDays": "Shows an Alert on the Tracking screen for every Job that has Stock that has not arrived, and it is the specified number of days away from the ETA date:\n(empty field does not show any alerts)",
                            "stockDueDays_Label": "Tracking - Stock Due Days",
                            "stockNotArrivedDays": "Shows an Alert on the Tracking screen for every Job that has Stock that has not arrived within the specified number of days from the Dispatch Appointment date:\n(empty field does not show any alerts)",
                            "stockNotArrivedDays_Label": "Tracking - Stock Not Arrived Days",
                            "installNotConfirmedDays": "Shows an Alert on the Tracking screen for every Job that has a Dispatch Appointment, but it has not been confirmed within the specified number of days since it was created:\n(empty field does not show any alerts)",
                            "installNotConfirmedDays_Label": "Tracking - Dispatch Appt. Not Confirmed Days",
                            "installNotCompletedDays": "Shows an Alert on the Tracking screen for every Job that has a Dispatch Appointment that has not been closed off after the specified number of days has passed since the Dispatch Appointment date:\n(empty field does not show any alerts)",
                            "installNotCompletedDays_Label": "Tracking - Dispatch Appt. Not Completed Days",
                        },
                        "xeroConnected": "Status: Connected to {{org}}",
                        "xeroDisconnected": "Status: Disconnected",
                        "match": "Match",
                        "new": "New",
                        "skip": "Skip",
                        "manualMatchRequired":"The following contacts could not be matched automatically. Please review the list of Insyte contacts and select the appropriate action for each.",
                        "insyteContacts": "Insyte Contacts",
                        "financeContacts": "Finance Contacts",
                        "apply": "Apply",
                        "xeroSettingsTitle": "Configure Xero",
                        "xeroTaxRatesHeader": "Map Tax Rates",
                        "xeroAccountsHeader": "Map Accounts",
                        "depositAccount": "Deposit Holding Account",
                        "bankAccount": "Bank / Check Account",
                        "receivablesAccount": "Accounts Receivable",
                        "salesAccount": "Sales Revenue Account",
                        "xeroAccountsDescription": "Insyte Web will export journals for Deposits, Sales and Allocations into {{providerName}}. These items need to be mapped to {{providerName}} accounts. Note we cannot journal directly to the {{providerName}}'s Accounts Receivable account so please select an alternative or click create button to create a specific account for Insyte Web.",
                        "xeroContactsHeader": "Contacts",
                        "xeroTaxRatesDescription": "Please map tax rates for Insyte Web to {{providerName}}",
                        "xeroContactsDescription": "Instyle Web will include contact details in transaction exported into {{providerName}}. These contacts need to be transfered to {{providerName}}",
                        "transferContacts": "Transfer to {{providerName}}",
                        "transferContactsError": "Failed to transfer contacts due to the following reason. {{error}}.",
                        "transferContactsErrorTitle": "Transfer Contacts",
                        "createAccounts": "Create in {{providerName}}",
                        "createAccountsErrorTitle": "Create Account",
                        "createAccountsError": "Failed to create the account due to the following reason. {{error}}.",
                        "transferNow": "Transfer Now",
                        "financeTransfer": "Transfer Journals to {{provider}}",
                        "transferNowError": "Failed to complete transfer due to the following reason. {{error}}.",
                        "transferNowErrorTitle": "Transfer Error",
                        "transferNowCompleteTitle": "Transfer Complete",
                        "transferNowComplete": "The transfer has successfully completed",
                        "transferNowCompleteNoDataTitle": "Transfer Complete",
                        "transferNowCompleteNoData": "The transfer has successfully completed, no items available to transfer",
                        "transferNowCompleteDowloadTitle": "Download File",
                        "transferNowCompleteDowloadMsg": "Click here to download file",
                        "resetCompleteTitle": "Reset Complete",
                        "resetComplete": "The reset has successfully completed",
                        "resetFinance": "Reset Insyte Web Journals for {{provider}}",
                        "resetFinanceMsg": "WARNING: You are about to reset {{provider}} journals in Insyte Web. Any finance movements since your last transfer will be lost. You may need to manually correct {{provider}}. To continue type 'reset' in the box below.",
                        "typeReset": "Type Reset",
                        "resetCompare": "reset",
                        "resetDataTitle": "DELETE ALL TRANSACTIONAL DATA",
                        "resetDataCompare": "Delete all my data",
                        "resetDataMsg": "WARNING: If you proceed you will DELETE all you transactional data. This process may be irreversible. Some of your data may be recoverable at a minimum cost of $5,000. To proceed type \"$t(application_strings.views.settings.resetDataCompare)\" in the input box below.",
                        "typeResetData": "Type \"$t(application_strings.views.settings.resetDataCompare)\"",
                        "resetDataRecordTypes": "Record Types to Delete",
                        "discountLimits": "Discount Limits",
                        "editDiscountLimits": "Edit Sales Discount Limits",
                        "salesDiscountLimits": {
                            "title": "Sales Discount Limits"
                        }

                    },
					"template": {
						"title": "Template {{Description}}",
						"missingTemplateMsg": "You are missing a template document. Please select to use the default or upload your own. Hint you can select default then download it to change it for your needs.",
						"downloadTemplate": "Download Template",
						"uploadDocumentTemplateDlgTitle": "Upload Document Template",
						"uploadDocumentTemplateInstructions": "Select a template document to upload.",
						"uploadSuccess": "Upload Complete",
						"previewItem": "Select Item to Preview",
						"keepOptions": {
							"msg": "Select were to copy the product option settings from. Note this does not affect the layout only which product options are printed",
							"current": "Keep previously configured product option settings ",
							"defaultTemplate": "Use product options settings from the default template",
							"copyFrom": "Copy from {{from}} template"
						},
						"insertMsg": "Right-Click to insert merge fields into your template"
					},
					"templateResponse": {
						"title": "Template Response {{ID}}",
					},
					"product": {
                        "title": "My Product",
                        "notesTemplatePlaceHolder": "Will default on to order line customer notes.",
                        "notesTemplate": "Notes Template",
                        "serviceProductMsg": "Service Products do not generate supply jobs and will not be sent to the supplier. They are good for service calls, additional installation charges etc",
                        "alterProductMsg": "Alter Products will be automatically selected when performing an alteration for the product"
                    },
                    "versionInfo": {
                        "title": "Version",
                    },
					"initialsetupwizard": {
						"title": "Initial Setup",
					},
	            },
	            "validation": {
	                "emailIsRequired": "Email is required",
	                "passwordIsRequired": "Password is required"
	            },
	            "dashboards": {
	                "recentPurchases": {
	                    "title": "Purchases",
	                    "titleSub": "(6 months)",
                    },
                    "dashboardRequired": "Please set your default dashboard using the drop down above.",
                    "permissionRequired": "You do not have permission to view this dashboard",
                    "licenseRequired": "You require an upgrade to your license to view this dashboard",
                    "edit": "Edit",
                    "noWidgetAccess": "You require a license to view {{title}} widget",
                    "save": "Save",
                    "empty": "Empty",
                    "sales": "Sales",
                    "purchasing": "Purchasing",
                    "widget": "Widget",
                    "size": "Size",
                    "small": "Small",
                    "normal": "Normal",
                    "options": "Options",
                    "splitH": "split H",
                    "splitV": "split V",
                    "remove": "Remove",
                    "reset": "Default Dashboard Layouts",
                    "resetTitle": "Reset Dashboard",
                    "resetMsg": "Resting the dashboard will loose your current settings. Do you want to continue?",
                    "widgets": {
                        "serviceJobSummaryWidget": {
                            "pendingApproval": "Pending Approval",
                            "pendingApproval_desc": "All active service jobs in the Quote stage",
                            "sent": "Sent",
                            "sent_desc": "Active service jobs in the quoting stage that have had the quote document sent to the customer",
                            "approved": "Approved",
                            "approved_desc": "All active confirmed service jobs",
                            "expired": "Expired",
                            "expired_desc": "Service jobs in the quote stage that have expired",
                        }
                    }
                },
				"grids": {
					"companyPricelistMapping": {
						"title": "Custom Pricing"
					},
					"SmsIntegration": {
						"title": "Sms Integration"
					},
					"templateresponse": {
						"title": "Response Actions",
						"tagAction": "Tag Action"
					},
					"commissionAccountTransactions": {
						"title": "Transactions"
					},
					"commissionRuns": {
						"title": "Commission Runs",
					},
					"salesDiscountLimitsBUs": {
						"title": "Business Units Limits"
					},
					"salesDiscountLimitsUsers": {
						"title": "Users Limits"
					},
					"automations": {
						"title": "Automations",
					},
					"businessUnits": {
						"title": "Business Units",
					},
					"lookuptableitem": {
						"title": "Entries"
					},
					"picklists": {
						"PL_ActivityCategory": "Activity Categories",
						"PL_ActivityColor": "Activity Colours",
						"PL_CommissionAmendmentCategory": "Commission Amendment Categories",
						"PL_ContactJobCategory": "Contact Job Categories",
						"PL_CommitmentReason": "Commitment Reasons",
						"PL_JobCustomStatus": "Job Custom Status",
						"PL_MFDepartment": "Manufacturing Departments",
						"PL_OrderLineLocation": "Job Line Location",
						"PL_OrganisationType": "Organisation Type",
						"PL_QuoteOutcomeReason": "Quote Outcome Reason",
						"PL_LeadSource": "Lead Source",
						"PL_ProductOfInterest": "Products Of Interest",
						"PL_OppConfidenceLevel": "Confidence Level",
						"PL_PaymentMethod": "Payment Method",
						"PL_Tag": "Tags",
						"PL_FaultReason": "Fault Reasons",
						"PL_LeadDiscardReason": "Lead Discard Reason",
						"PL_ResponsibleDepartment": "Responsible Departments"
					},
					"discountLevel": {
						"title": "Customer Discounts",
					},
					"discountItems": {
						"title": "Discounts",
						"showAll": "Show All",
						"showCurrentOnly": "Show Current Only"
					},
					"communications": {
						"title": "Communication Log",
					},
					"teams": {
						"title": "Teams",
						"manage": "Manage"
					},
					"activityCategories": {
						"title": "Activity Categories",
					},
					"suppliers": {
						"title": "Suppliers",
					},
					"companies": {
						"title": "Companies",
					},
					"contacts": {
						"title": "Contacts",
					},
					"documents": {
						"title": "Documents",
						"attachDlgTitle": "Attach Documents",
						"sendToCustomer": "Send to Customer",
						"sendToSupplier": "Send to Supplier",
						"uploadSuccess": "Attachments Completed",
						"supplier": "Supplier"
					},
					"leadSources": {
						"title": "Lead Sources",
					},
					"jobCustomStatus": {
						"title": "Job Custom Status",
					},
					"payments": {
						"title": "Payments",
					},
					"paymentMethods": {
						"title": "Payment Methods",
					},
					"purchaseOrders": {
						"title": "Purchase Orders",
						"showClosed": "Show Closed",
						"hideClosed": "Hide Closed",
					},
					"jobs": {
						"title": "Jobs",
						"showClosed": "Show Closed",
						"hideClosed": "Hide Closed",
					},
					"invoices": {
						"title": "Invoices",
						"showClosed": "Show Closed",
						"hideClosed": "Hide Closed"
					},
					"activities": {
						"title": "Activities",
						"showClosed": "Show Closed",
						"hideClosed": "Hide Closed",
					},
					"opportunities": {
						"title": "Leads/Opportunities",
						"showClosed": "Show Closed",
						"hideClosed": "Hide Closed",
					},
					"orderLines": {
						"title": "Product Lines",
					},
					"jobLines": {
						"title": "Lines",
					},
					"orderLineLocations": {
						"title": "Product Line Locations",
					},
					"organisationTypes": {
						"title": "Organisation Types",
					},
					"oppConfidenceLevel": {
						"title": "Opportunity Confidence Level",
					},
					"priceMarkups": {
						"title": "Price Markups",
					},
					"productOfInterests": {
						"title": "Products of Interest",
					},
					"quotes": {
						"title": "Quotes",
						"showClosed": "Show Closed",
						"hideClosed": "Hide Closed",
					},
					"quoteOutcomeReasons": {
						"title": "Quote Outcome Reasons",
					},
					"taxRates": {
						"title": "Tax Rates",
					},
					"products": {
						"title": "Products (My Products)",
						"testProducts": "Test Products"
					},
					"templates": {
						"title": "Templates",
						"newQuoteEmailTemplate": "Quote Email Template",
						"newQuoteDocumentTemplate": "Quote Document Template",
						"newInvoiceEmailTemplate": "Invoice Email Template",
						"newInvoiceDocumentTemplate": "Invoice Document Template",
						"newOrderConfirmationEmailTemplate": "Order Confirmation Email Template",
						"newOrderConfirmationDocumentTemplate": "Order Confirmation Document Template",
						"newSupplyEmailTemplate": "Supply Email Template",
						"newWebLeadEmailTemplate": "Web Lead Email Template",
						"newStatementEmailTemplate": "Statement Email Template",
						"newAutomatedEmailTemplate": "Activity Email Template",
						"newAutomatedSMSTemplate": "Activity SMS Template",
						"newQuoteSMSTemplate": "Quote SMS Template",
						"newOrderConfirmationSMSTemplate": "Order Confirmation SMS Template",
						"newInvoiceSMSTemplate": "Invoice SMS Template",
						"newWebLeadSMSTemplate": "Web Lead SMS Template",
					},
					"users": {
						"title": "Users",
						"salesDiscountLimits": "Sales Discount Limits",
						"salesDiscountLimitsSmall": "Sales Disc Limts",
						"commissions": "Commissions",
						"commissionsSmall": "Comm."
					},
				},
                "workflows": {
                    "confirmedOrderProcessing": {
                        "open": "Open",
                        "openTooltip": "Quote is open",
                        "priced": "Priced",
                        "pricedTooltip": "Quote has been priced",
                        "sent": "Sent",
                        "sentTooltip": "Quote has been sent to customer",
                        "won": "Won",
                        "wonTooltip": "Quote has been won",
                        "lost": "Lost",
                        "lostTooltip": "Quote has been lost",
                        "tooltip": "Represents any number of paths that could have been taken",
                    },
                    "quoteProcessing": {
                        "open": "Open",
                        "openTooltip": "Order is open",
                        "priced": "Priced",
                        "pricedTooltip": "Order has been priced",
                        "sent": "Sent",
                        "sentTooltip": "Order has been sent to supplier",
                        "manufacturing": "Manufacturing",
                        "manufacturingTooltip": "Order is in manufacturing",
                        "dispatched": "Dispatched",
                        "dispatchedTooltip": "Order has been dispatched",
                        "invoiced": "Invoiced",
                        "invoicedTooltip": "Order has been invoiced",
                        "tooltip": "Represents any number of paths that could have been taken",
                    },
                },                
             }, // application strings
		}
	},
	xx: {
	}
};


window.myinsyteresources.en.translation.application_strings.entities = {
	"UserApiKey": {
		"Description": "Description",
		"Scope": "Scope",
		"Key": "Key",
		"RepID": "Rep"
	},
	"externalLeadGrid": {
		"Date": "Date",
		"LeadSource": "Lead Source",
		"ContactFirstName": "First Name",
		"ContactLastName": "Last Name",
		"ContactMobile": "Mobile",
		"ContactWorkPh": "Work Ph",
		"ContactHomePh": "Home Ph",
		"ContactEmail": "Email",
		"SnoozeDate": "Snoozed"
	},
	"activeJobTrackingViewForID": {
		"entityTitle": "Active Job Tracking View For ID",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"SalesRepMobile": "Sales Rep Mobile",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
		"Contact": "Contact",
		"ContactEmail": "Contact Email",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"City": "City",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"JobType": "Job Type",
		"Status": "Status",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"Paid": "Paid",
		"ETA": "ETA",
		"OrderDate": "Order Date",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"Tags": "Tags",
	},
	"activeJobTrackingViews": {
		"entityTitle": "Active Job Tracking Views",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"SalesRepMobile": "Sales Rep Mobile",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
		"Contact": "Contact",
		"ContactEmail": "Contact Email",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"City": "City",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"JobType": "Job Type",
		"Status": "Status",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"Paid": "Paid",
		"ETA": "ETA",
		"OrderDate": "Order Date",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"Tags": "Tags",
	},
	"activities": {
		"entityTitle": "Activities",
		"Subject": "Subject",
		"Start": "Start",
		"End": "End",
		"AllDay": "All Day",
		"Closed": "Closed",
		"Notes": "Notes",
		"ProcessDetails": "Process Details",
		"ActivityType_abbrev": "Type",
		"ActivityType": "Activity Type",
		"Confirmed": "Confirmed",
		"IsInstall": "Is Install",
		"IsPickup": "Is Pickup",
		"IsDelivery": "Is Delivery",
		"IsService": "Is Service",
		"Category": "Activity Category",
		"PL_ActivityCategoryID_abbrev": "Category",
		"PL_ActivityCategoryID": "Activity Category",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Team": "Team",
		"TeamID": "Team",
		"Address": "Address",
		"AddressID": "Address",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"Job": "Job",
		"JobID": "Job",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"CreatedBy": "Representative",
		"CreatedByID": "Representative",
		"CreatedOn": "Created On",
		"Priority": "Priority",
		"Attendees": "Attendees",
		"JobLines": "Job Lines",
		"TagData": "Tag Data",
		"ActivityID": "Activity ID",
	},
	"activitiesForCompany": {
		"entityTitle": "Activities For Company",
		"ID": "ID",
		"Subject": "Subject",
		"Start": "Start",
		"AllDay": "All Day",
		"Closed": "Closed",
		"Notes": "Notes",
		"ActivityType_abbrev": "Type",
		"ActivityType": "Activity Type",
		"ActivityCategory_abbrev": "Category",
		"ActivityCategory": "Activity Category",
		"Confirmed": "Confirmed",
		"Representative": "Representative",
		"Priority": "Priority",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity ID",
		"IsInstall": "Is Install",
		"IsPickup": "Is Pickup",
		"IsDelivery": "Is Delivery",
		"IsService": "Is Service",
	},
	"activitiesForContact": {
		"entityTitle": "Activities For Contact",
		"ID": "ID",
		"Subject": "Subject",
		"Start": "Start",
		"AllDay": "All Day",
		"Closed": "Closed",
		"Notes": "Notes",
		"ActivityType_abbrev": "Type",
		"ActivityType": "Activity Type",
		"ActivityCategory_abbrev": "Category",
		"ActivityCategory": "Activity Category",
		"Confirmed": "Confirmed",
		"Representative": "Representative",
		"Priority": "Priority",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity ID",
		"IsInstall": "Is Install",
		"IsPickup": "Is Pickup",
		"IsDelivery": "Is Delivery",
		"IsService": "Is Service",
	},
	"activitiesForOpportunity": {
		"entityTitle": "Activities For Opportunity",
		"ID": "ID",
		"Subject": "Subject",
		"Start": "Start",
		"AllDay": "All Day",
		"Closed": "Closed",
		"Notes": "Notes",
		"ActivityType_abbrev": "Type",
		"ActivityType": "Activity Type",
		"ActivityCategory_abbrev": "Category",
		"ActivityCategory": "Activity Category",
		"Confirmed": "Confirmed",
		"Representative": "Representative",
		"Priority": "Priority",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity ID",
		"IsInstall": "Is Install",
		"IsPickup": "Is Pickup",
		"IsDelivery": "Is Delivery",
		"IsService": "Is Service",
	},
	"activitiesForUser": {
		"entityTitle": "Activities For User",
		"ID": "ID",
		"Subject": "Subject",
		"Start": "Start",
		"AllDay": "All Day",
		"Closed": "Closed",
		"Notes": "Notes",
		"ActivityType_abbrev": "Type",
		"ActivityType": "Activity Type",
		"ActivityCategory_abbrev": "Category",
		"ActivityCategory": "Activity Category",
		"Confirmed": "Confirmed",
		"Representative": "Representative",
		"Priority": "Priority",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity ID",
		"IsInstall": "Is Install",
		"IsPickup": "Is Pickup",
		"IsDelivery": "Is Delivery",
		"IsService": "Is Service",
	},
	"activityForID": {
		"entityTitle": "Activity For ID",
		"Subject": "Subject",
		"Start": "Start",
		"End": "End",
		"AllDay": "All Day",
		"Closed": "Closed",
		"Notes": "Notes",
		"ProcessDetails": "Process Details",
		"ActivityType_abbrev": "Type",
		"ActivityType": "Activity Type",
		"Confirmed": "Confirmed",
		"IsInstall": "Is Install",
		"IsPickup": "Is Pickup",
		"IsDelivery": "Is Delivery",
		"IsService": "Is Service",
		"Category": "Activity Category",
		"PL_ActivityCategoryID_abbrev": "Category",
		"PL_ActivityCategoryID": "Activity Category",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Team": "Team",
		"TeamID": "Team",
		"Address": "Address",
		"AddressID": "Address",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"Job": "Job",
		"JobID": "Job",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"CreatedBy": "Representative",
		"CreatedByID": "Representative",
		"CreatedOn": "Created On",
		"Priority": "Priority",
		"Attendees": "Attendees",
		"JobLines": "Job Lines",
		"TagData": "Tag Data",
		"ActivityID": "Activity ID",
	},
	"activityJobLineForID": {
		"entityTitle": "Activity Job Line For ID",
		"Job": "Job",
		"JobID": "Job",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"Activity": "Activity",
		"ActivityID": "Activity",
	},
	"activityJobLines": {
		"entityTitle": "Activity Job Lines",
		"Job": "Job",
		"JobID": "Job",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"Activity": "Activity",
		"ActivityID": "Activity",
	},
	"activityRepForID": {
		"entityTitle": "Activity Rep For ID",
		"Activity": "Activity",
		"ActivityID": "Activity",
		"Rep": "Representative",
		"RepID": "Representative",
	},
	"addresses": {
		"entityTitle": "Addresses",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"Country": "Country",
		"Location": "Location",
		"PosLong": "Pos Long",
		"PosLat": "Pos Lat",
		"AddressID": "Address ID",
		"Formatted": "Formatted",
	},
	"addressForCompanyID": {
		"entityTitle": "Address For Company ID",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"Country": "Country",
		"Location": "Location",
		"PosLong": "Pos Long",
		"PosLat": "Pos Lat",
		"AddressID": "Address ID",
		"Formatted": "Formatted",
	},
	"addressForContactID": {
		"entityTitle": "Address For Contact ID",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"Country": "Country",
		"Location": "Location",
		"PosLong": "Pos Long",
		"PosLat": "Pos Lat",
		"AddressID": "Address ID",
		"Formatted": "Formatted",
	},
	"addressForID": {
		"entityTitle": "Address For ID",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"Country": "Country",
		"Location": "Location",
		"PosLong": "Pos Long",
		"PosLat": "Pos Lat",
		"AddressID": "Address ID",
		"Formatted": "Formatted",
	},
	"addressForJobID": {
		"entityTitle": "Address For Job ID",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"Country": "Country",
		"Location": "Location",
		"PosLong": "Pos Long",
		"PosLat": "Pos Lat",
		"AddressID": "Address ID",
		"Formatted": "Formatted",
	},
	"advancedFilter_Job": {
		"entityTitle": "Advanced Filter _ Job",
		"ID": "ID",
		"QuoteDate": "Quote Date",
		"QuoteExpiryDate": "Quote Expiry Date",
		"HandoverDate": "Handover Date",
		"LJ__CommissionDate1": "LJ __ Commission Date 1",
		"LJ__OrderDate": "LJ __ Order Date",
		"LJ__ETADate": "LJ __ ETA Date",
		"LJ__Amount": "LJ __ Amount",
		"LJ__Paid": "LJ __ Paid",
		"LJ__HasPayment": "LJ __ Has Payment",
		"Stage": "Stage",
		"Status": "Status",
		"JobType": "Job Type",
		"MarginApprovalRequired": "Margin Approval Required",
		"LJ__JobLineStatus": "LJ __ Job Line Status",
		"LJ__JobLineStage": "LJ __ Job Line Stage",
		"LJ__JobLineType": "LJ __ Job Line Type",
		"LJ__FaultReasonID": "LJ __ Fault Reason ID",
		"LJ__AtFaultUserID": "LJ __ At Fault User ID",
		"LJ__AtFaultSupplierID": "LJ __ At Fault Supplier ID",
		"LJ__JobLineDispatchStage": "LJ __ Job Line Dispatch Stage",
		"SalesRepID": "Sales Rep ID",
		"BusinessUnitID": "Business Unit ID",
		"PL_CommitmentReasonID": "PL _ Commitment Reason ID",
		"LJ__Balance": "LJ __ Balance",
		"LJ__PercentagePaid": "LJ __ Percentage Paid",
		"LJ__JobLineProductID": "LJ __ Job Line Product ID",
		"LJ__JobLineSupplierID": "LJ __ Job Line Supplier ID",
		"LJ__CompanyName": "LJ __ Company Name",
		"LJ__OrganisationType": "LJ __ Organisation Type",
		"LJ__AccountType": "LJ __ Account Type",
		"LJ__ContactFirstName": "LJ __ Contact First Name",
		"LJ__ContactLastName": "LJ __ Contact Last Name",
		"LJ__ContactHasCredit": "LJ __ Contact Has Credit",
		"LJ__SiteContactFirstName": "LJ __ Site Contact First Name",
		"LJ__SiteContactLastName": "LJ __ Site Contact Last Name",
		"LJ__ActivityType": "LJ __ Activity Type",
		"LJ__ActivityCategory": "LJ __ Activity Category",
		"LJ__ActivityStart": "LJ __ Activity Start",
		"LJ__ActivityRepresentative": "LJ __ Activity Representative",
		"LJ__Tags": "LJ __ Tags",
		"LJ__ActivityClosed": "LJ __ Activity Closed",
		"LJ__ActivityConfirmed": "LJ __ Activity Confirmed",
		"LJ__ReferredByID": "LJ __ Referred By ID",
		"LJ__LeadGenerator": "LJ __ Lead Generator",
		"LJ__OpportunityConfidenceLevel": "LJ __ Opportunity Confidence Level",
		"LJ__OpportunityStatus": "LJ __ Opportunity Status",
		"LJ__OpportunityOutcomeReason": "LJ __ Opportunity Outcome Reason",
		"LJ__OpportunityForecast": "LJ __ Opportunity Forecast",
		"LJ__LeadSource": "LJ __ Lead Source",
		"LJ__VisitedShowroom": "LJ __ Visited Showroom",
		"LJ__OpportunityRepresentative": "LJ __ Opportunity Representative",
		"LJ__CMRequired": "LJ __ CM Required",
		"LJ__InstallRequired": "LJ __ Install Required",
		"LJ__PickupRequired": "LJ __ Pickup Required",
		"LJ__DeliveryRequired": "LJ __ Delivery Required",
		"LJ__PercentageInvoiced": "LJ __ Percentage Invoiced",
	},
	"advancedFilter_Opportunity": {
		"entityTitle": "Advanced Filter _ Opportunity",
		"ID": "ID",
		"Description": "Description",
		"ExpectedCloseDate": "Expected Close Date",
		"Status": "Status",
		"RepresentativeID": "Representative ID",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Forecast": "Forecast",
		"LeadGeneratorID": "Lead Generator ID",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"BusinessUnitID": "Business Unit ID",
		"PL_QuoteOutcomeReasonID": "PL _ Quote Outcome Reason ID",
		"ReferedByID": "Refered By ID",
		"VisitedShowroom": "Visited Showroom",
		"LJ__Tags": "LJ __ Tags",
		"LJ__ActivityType": "LJ __ Activity Type",
		"LJ__ActivityCategory": "LJ __ Activity Category",
		"LJ__ActivityStart": "LJ __ Activity Start",
		"LJ__ActivityRepresentative": "LJ __ Activity Representative",
		"LJ__ActivityClosed": "LJ __ Activity Closed",
		"LJ__ActivityConfirmed": "LJ __ Activity Confirmed",
		"LJ__CompanyName": "LJ __ Company Name",
		"LJ__OrganisationType": "LJ __ Organisation Type",
		"LJ__CompanyAccountType": "LJ __ Company Account Type",
		"LJ__ContactFirstName": "LJ __ Contact First Name",
		"LJ__ContactLastName": "LJ __ Contact Last Name",
		"LJ__Amount": "LJ __ Amount",
		"LJ__QuoteLastSentDate": "LJ __ Quote Last Sent Date",
		"LJ__QuoteDate": "LJ __ Quote Date",
		"LJ__QuoteExpiryDate": "LJ __ Quote Expiry Date",
		"LJ__JobID": "LJ __ Job ID",
		"LJ__JobSalesRep": "LJ __ Job Sales Rep",
		"LJ__JobStage": "LJ __ Job Stage",
		"LJ__JobTags": "LJ __ Job Tags",
		"LJ__JobCompanyName": "LJ __ Job Company Name",
		"LJ__JobOrganisationType": "LJ __ Job Organisation Type",
		"LJ__JobCompanyAccountType": "LJ __ Job Company Account Type",
		"LJ__JobContactFirstName": "LJ __ Job Contact First Name",
		"LJ__JobContactLastName": "LJ __ Job Contact Last Name",
		"LJ__JobLineETADate": "LJ __ Job Line ETA Date",
		"LJ__JobLineProductID": "LJ __ Job Line Product ID",
		"LJ__JobLineSupplierID": "LJ __ Job Line Supplier ID",
		"LJ__JobLineCMRequired": "LJ __ Job Line CM Required",
		"LJ__JobLineInstallRequired": "LJ __ Job Line Install Required",
		"LJ__JobLinePickupRequired": "LJ __ Job Line Pickup Required",
		"LJ__JobLineDeliveryRequired": "LJ __ Job Line Delivery Required",
		"LJ__JobLineStatus": "LJ __ Job Line Status",
		"LJ__JobLineStage": "LJ __ Job Line Stage",
	},
	"advancedFilter_SupplyJob": {
		"entityTitle": "Advanced Filter _ Supply Job",
		"ID": "ID",
		"PurchaseOrderID": "Purchase Order ID",
		"RepresentativeID": "Representative ID",
		"OrderNumber": "Order Number",
		"CustomerReference": "Customer Reference",
		"Status": "Status",
		"SupplierID": "Supplier ID",
		"ETADate": "ETA Date",
		"LJ__Internal": "LJ __ Internal",
		"LJ__SupplyLineProductID": "LJ __ Supply Line Product ID",
		"LJ__SupplyLineStatus": "LJ __ Supply Line Status",
		"LJ__SupplyLineMFDate": "LJ __ Supply Line MF Date",
		"LJ__SupplyLineDepartment": "LJ __ Supply Line Department",
		"LJ__CommitmentReason": "LJ __ Commitment Reason",
		"LJ__HandoverDate": "LJ __ Handover Date",
		"LJ__SalesRepID": "LJ __ Sales Rep ID",
		"LJ__DispatchDate": "LJ __ Dispatch Date",
		"LJ__DispatchBy": "LJ __ Dispatch By",
		"LJ__CompanyName": "LJ __ Company Name",
		"LJ__OrganisationType": "LJ __ Organisation Type",
		"LJ__AccountType": "LJ __ Account Type",
		"LJ__Tags": "LJ __ Tags",
		"LJ__InstallRequired": "LJ __ Install Required",
		"LJ__PickupRequired": "LJ __ Pickup Required",
		"LJ__DeliveryRequired": "LJ __ Delivery Required",
	},
	"attachDocumentSuppliersForJob": {
		"entityTitle": "Attach Document Suppliers For Job",
		"Name": "Name",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"SupplierID": "Supplier ID",
		"TaxRate": "Tax Rate",
		"Internal": "Internal",
		"SplitByProduct": "Split By Product",
		"IsMarketplaceLicensed": "Is Marketplace Licensed",
		"MarketplaceID": "Marketplace ID",
		"AccountID": "Account ID",
		"FabricTemplate": "Binary Data Field",
		"FabricTemplateID": "Binary Data Field",
		"ComponentTemplate": "Binary Data Field",
		"ComponentTemplateID": "Binary Data Field",
		"TemplatePrintType": "Template Print Type",
	},
	"attendees": {
		"entityTitle": "Attendees",
		"Activity": "Activity",
		"ActivityID": "Activity",
		"Rep": "Representative",
		"RepID": "Representative",
	},
	"authTokenForID": {
		"entityTitle": "Auth Token For ID",
		"Token": "Token",
		"Type": "Type",
		"Application": "Application",
		"Date": "Date",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
	},
	"authTokens": {
		"entityTitle": "Auth Tokens",
		"Token": "Token",
		"Type": "Type",
		"Application": "Application",
		"Date": "Date",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
	},
	"automationForID": {
		"entityTitle": "Automation For ID",
		"AutomationID": "Automation ID",
		"Name": "Name",
		"Notes": "Notes",
		"Type": "Type",
		"SMSTemplate": "Template",
		"SMSTemplateID": "Template",
		"EmailTemplate": "Template",
		"EmailTemplateID": "Template",
		"SendTo": "Send To",
		"ScheduleTimeframe": "Schedule Timeframe",
		"ScheduleAmount": "Schedule Amount",
		"ScheduleTimeOfDay": "Schedule Time Of Day",
		"Confirmed": "Confirmed",
		"BalanceOutstanding": "Balance Outstanding",
		"AppointmentType": "Appointment Type",
		"DispatchType": "Dispatch Type",
		"JobType": "Job Type",
		"Enabled": "Enabled",
		"SmsDeliveryReceipt": "Sms Delivery Receipt",
	},
	"automations": {
		"entityTitle": "Automations",
		"AutomationID": "Automation ID",
		"Name": "Name",
		"Notes": "Notes",
		"Type": "Type",
		"SMSTemplate": "Template",
		"SMSTemplateID": "Template",
		"EmailTemplate": "Template",
		"EmailTemplateID": "Template",
		"SendTo": "Send To",
		"ScheduleTimeframe": "Schedule Timeframe",
		"ScheduleAmount": "Schedule Amount",
		"ScheduleTimeOfDay": "Schedule Time Of Day",
		"Confirmed": "Confirmed",
		"BalanceOutstanding": "Balance Outstanding",
		"AppointmentType": "Appointment Type",
		"DispatchType": "Dispatch Type",
		"JobType": "Job Type",
		"Enabled": "Enabled",
		"SmsDeliveryReceipt": "Sms Delivery Receipt",
	},
	"binaryDataFieldForID": {
		"entityTitle": "Binary Data Field For ID",
		"Data": "Data",
	},
	"binaryDataFields": {
		"entityTitle": "Binary Data Fields",
		"Data": "Data",
	},
	"blobItemForID": {
		"entityTitle": "Blob Item For ID",
		"Data": "Data",
		"BlobItemID": "Blob Item ID",
	},
	"blobItems": {
		"entityTitle": "Blob Items",
		"Data": "Data",
		"BlobItemID": "Blob Item ID",
	},
	"bUProductExclusionForID": {
		"entityTitle": "BU Product Exclusion For ID",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"ProductID": "Product ID",
	},
	"businessUnit_Default": {
		"entityTitle": "Business Unit _ Default",
		"BusinessUnitID": "Business Unit ID",
		"Name": "Name",
		"Logo": "Logo",
		"BusinessNumber": "Business Number",
		"Phone": "Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"Locale": "Locale",
		"TimeZone": "Time Zone",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"BillingAddress": "Address",
		"BillingAddressID": "Address",
		"ProductExclusions": "Product Exclusions",
	},
	"businessUnitForID": {
		"entityTitle": "Business Unit For ID",
		"BusinessUnitID": "Business Unit ID",
		"Name": "Name",
		"Logo": "Logo",
		"BusinessNumber": "Business Number",
		"Phone": "Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"Locale": "Locale",
		"TimeZone": "Time Zone",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"BillingAddress": "Address",
		"BillingAddressID": "Address",
		"ProductExclusions": "Product Exclusions",
	},
	"businessUnitProductExclusions": {
		"entityTitle": "Business Unit Product Exclusions",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"ProductID": "Product ID",
	},
	"businessUnits": {
		"entityTitle": "Business Units",
		"BusinessUnitID": "Business Unit ID",
		"Name": "Name",
		"Logo": "Logo",
		"BusinessNumber": "Business Number",
		"Phone": "Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"Locale": "Locale",
		"TimeZone": "Time Zone",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"BillingAddress": "Address",
		"BillingAddressID": "Address",
		"ProductExclusions": "Product Exclusions",
	},
	"commandActionForID": {
		"entityTitle": "Command Action For ID",
		"Type": "Type",
		"Date": "Date",
		"CommandData": "Command Data",
		"CommandActionKey": "Command Action Key",
	},
	"commandActions": {
		"entityTitle": "Command Actions",
		"Type": "Type",
		"Date": "Date",
		"CommandData": "Command Data",
		"CommandActionKey": "Command Action Key",
	},
	"commissionAccountTransactionDetails": {
		"entityTitle": "Commission Account Transaction Details",
		"Transaction": "Commission Account Tx",
		"TransactionID": "Commission Account Tx",
		"Job": "Job",
		"JobID": "Job",
		"Values": "Values",
	},
	"commissionAccountTransactionGridForJob": {
		"entityTitle": "Commission Account Transaction Grid For Job",
		"ID": "ID",
		"Date": "Date",
		"User": "User",
		"UserID": "User ID",
		"RunID": "Run ID",
		"Run": "Run",
		"Plan": "Plan",
		"Type": "Type",
		"Description": "Description",
		"Status": "Status",
		"Amount": "Amount",
		"Notes": "Notes",
		"IDs": "I Ds",
	},
	"commissionAccountTransactionGridForRun": {
		"entityTitle": "Commission Account Transaction Grid For Run",
		"ID": "ID",
		"Date": "Date",
		"User": "User",
		"UserID": "User ID",
		"RunID": "Run ID",
		"Run": "Run",
		"Plan": "Plan",
		"Type": "Type",
		"Description": "Description",
		"Status": "Status",
		"Amount": "Amount",
		"Notes": "Notes",
		"IDs": "I Ds",
	},
	"commissionAccountTransactions": {
		"entityTitle": "Commission Account Transactions",
		"Date": "Date",
		"User": "Representative",
		"UserID": "Representative",
		"Run": "Commission Run",
		"RunID": "Commission Run",
		"Type": "Type",
		"Description": "Description",
		"Status": "Status",
		"Amount": "Amount",
		"Notes": "Notes",
		"Details": "Details",
	},
	"commissionAccountTxDetailForID": {
		"entityTitle": "Commission Account Tx Detail For ID",
		"Transaction": "Commission Account Tx",
		"TransactionID": "Commission Account Tx",
		"Job": "Job",
		"JobID": "Job",
		"Values": "Values",
	},
	"commissionAccountTxForID": {
		"entityTitle": "Commission Account Tx For ID",
		"Date": "Date",
		"User": "Representative",
		"UserID": "Representative",
		"Run": "Commission Run",
		"RunID": "Commission Run",
		"Type": "Type",
		"Description": "Description",
		"Status": "Status",
		"Amount": "Amount",
		"Notes": "Notes",
		"Details": "Details",
	},
	"commissionPlanForID": {
		"entityTitle": "Commission Plan For ID",
		"Description": "Description",
		"Rules": "Rules",
	},
	"commissionPlanRuleForID": {
		"entityTitle": "Commission Plan Rule For ID",
		"CommissionPlan": "Commission Plan",
		"CommissionPlanID": "Commission Plan",
		"CommissionRule": "Commission Rule",
		"CommissionRuleID": "Commission Rule",
	},
	"commissionPlanRules": {
		"entityTitle": "Commission Plan Rules",
		"CommissionPlan": "Commission Plan",
		"CommissionPlanID": "Commission Plan",
		"CommissionRule": "Commission Rule",
		"CommissionRuleID": "Commission Rule",
	},
	"commissionPlans": {
		"entityTitle": "Commission Plans",
		"Description": "Description",
		"Rules": "Rules",
	},
	"commissionRuleForID": {
		"entityTitle": "Commission Rule For ID",
		"Name": "Name",
		"Expression": "Expression",
		"TextValues": "Text Values",
		"ByJob": "By Job",
		"Users": "Users",
	},
	"commissionRules": {
		"entityTitle": "Commission Rules",
		"Name": "Name",
		"Expression": "Expression",
		"TextValues": "Text Values",
		"ByJob": "By Job",
		"Users": "Users",
	},
	"commissionRuleUserForID": {
		"entityTitle": "Commission Rule User For ID",
		"User": "Representative",
		"UserID": "Representative",
		"CommissionRule": "Commission Rule",
		"CommissionRuleID": "Commission Rule",
	},
	"commissionRunForID": {
		"entityTitle": "Commission Run For ID",
		"Description": "Description",
		"StartDate": "Start Date",
		"EndDate": "End Date",
		"Plan": "Commission Plan",
		"PlanID": "Commission Plan",
		"LastRunBy": "Representative",
		"LastRunByID": "Representative",
		"PaidBy": "Representative",
		"PaidByID": "Representative",
		"Paid": "Representative",
		"InProgress": "In Progress",
	},
	"commissionRuns": {
		"entityTitle": "Commission Runs",
		"Description": "Description",
		"StartDate": "Start Date",
		"EndDate": "End Date",
		"Plan": "Commission Plan",
		"PlanID": "Commission Plan",
		"LastRunBy": "Representative",
		"LastRunByID": "Representative",
		"PaidBy": "Representative",
		"PaidByID": "Representative",
		"Paid": "Representative",
		"InProgress": "In Progress",
	},
	"commissionRunsGrid": {
		"entityTitle": "Commission Runs Grid",
		"ID": "ID",
		"Plan": "Plan",
		"Description": "Description",
		"StartDate": "Start Date",
		"EndDate": "End Date",
		"LastRunBy": "Last Run By",
		"PaidBy": "Paid By",
	},
	"commissionsDataView": {
		"entityTitle": "Commissions Data View",
		"Line_ID": "Line _ ID",
		"Line_OrderDate": "Line _ Order Date",
		"Line_ETA": "Line _ ETA",
		"Line_Width": "Line _ Width",
		"Line_Drop": "Line _ Drop",
		"Line_Qty": "Line _ Qty",
		"Line_Price": "Line _ Price",
		"Line_PriceTax": "Line _ Price Tax",
		"Line_PriceIncTax": "Line _ Price Inc Tax",
		"Line_SalePrice": "Line _ Sale Price",
		"Line_SalePriceTax": "Line _ Sale Price Tax",
		"Line_SalePriceIncTax": "Line _ Sale Price Inc Tax",
		"Line_CostPrice": "Line _ Cost Price",
		"Line_CostPriceTax": "Line _ Cost Price Tax",
		"Line_CostPriceIncTax": "Line _ Cost Price Inc Tax",
		"Line_RequireCheckMeasure": "Line _ Require Check Measure",
		"Line_CM_Approved": "Line _ CM _ Approved",
		"Line_RequireInstall": "Line _ Require Install",
		"Line_Inst_Completed": "Line _ Inst _ Completed",
		"Line_ProductID": "Line _ Product ID",
		"Line_ProductName": "Line _ Product Name",
		"Line_Options": "Line _ Options",
		"Line_SupplierID": "Line _ Supplier ID",
		"Line_Status": "Line _ Status",
		"Line_Stage": "Line _ Stage",
		"Line_LineType": "Line _ Line Type",
		"Line_BasePrice": "Line _ Base Price",
		"Line_BaseCost": "Line _ Base Cost",
		"Line_InstallPrice": "Line _ Install Price",
		"Line_InstallCost": "Line _ Install Cost",
		"Line_OptionsPrice": "Line _ Options Price",
		"Line_OptionsCost": "Line _ Options Cost",
		"Line_DeliveryPrice": "Line _ Delivery Price",
		"Line_DeliveryCost": "Line _ Delivery Cost",
		"Line_AdjustmentPrice": "Line _ Adjustment Price",
		"Line_AdjustmentCost": "Line _ Adjustment Cost",
		"Line_Invoiced": "Line _ Invoiced",
		"Line_DisplayOption1": "Line _ Display Option 1",
		"Line_DisplayOption2": "Line _ Display Option 2",
		"Line_DisplayOption3": "Line _ Display Option 3",
		"Line_DisplayOption4": "Line _ Display Option 4",
		"Line_DisplayOption5": "Line _ Display Option 5",
		"Line_DisplayOption6": "Line _ Display Option 6",
		"Line_DisplayOption7": "Line _ Display Option 7",
		"Line_DisplayOption8": "Line _ Display Option 8",
		"Line_DisplayOption9": "Line _ Display Option 9",
		"Line_DisplayOption10": "Line _ Display Option 10",
		"Line_IsService": "Line _ Is Service",
		"Line_RequireDelivery": "Line _ Require Delivery",
		"Line_RequirePickup": "Line _ Require Pickup",
		"Line_DispatchStage": "Line _ Dispatch Stage",
		"Line_StandardCostPrice": "Line _ Standard Cost Price",
		"Line_StandardCostPriceTax": "Line _ Standard Cost Price Tax",
		"Line_StandardCostPriceIncTax": "Line _ Standard Cost Price Inc Tax",
		"ServiceLine_ID": "Service Line _ ID",
		"ServiceLine_OrderDate": "Service Line _ Order Date",
		"ServiceLine_ETA": "Service Line _ ETA",
		"ServiceLine_Width": "Service Line _ Width",
		"ServiceLine_Drop": "Service Line _ Drop",
		"ServiceLine_Qty": "Service Line _ Qty",
		"ServiceLine_Price": "Service Line _ Price",
		"ServiceLine_PriceTax": "Service Line _ Price Tax",
		"ServiceLine_PriceIncTax": "Service Line _ Price Inc Tax",
		"ServiceLine_SalePrice": "Service Line _ Sale Price",
		"ServiceLine_SalePriceTax": "Service Line _ Sale Price Tax",
		"ServiceLine_SalePriceIncTax": "Service Line _ Sale Price Inc Tax",
		"ServiceLine_CostPrice": "Service Line _ Cost Price",
		"ServiceLine_CostPriceTax": "Service Line _ Cost Price Tax",
		"ServiceLine_CostPriceIncTax": "Service Line _ Cost Price Inc Tax",
		"ServiceLine_RequireCheckMeasure": "Service Line _ Require Check Measure",
		"ServiceLine_CM_Approved": "Service Line _ CM _ Approved",
		"ServiceLine_RequireInstall": "Service Line _ Require Install",
		"ServiceLine_Inst_Completed": "Service Line _ Inst _ Completed",
		"ServiceLine_ProductID": "Service Line _ Product ID",
		"ServiceLine_ProductName": "Service Line _ Product Name",
		"ServiceLine_Options": "Service Line _ Options",
		"ServiceLine_SupplierID": "Service Line _ Supplier ID",
		"ServiceLine_Status": "Service Line _ Status",
		"ServiceLine_Stage": "Service Line _ Stage",
		"ServiceLine_LineType": "Service Line _ Line Type",
		"ServiceLine_BasePrice": "Service Line _ Base Price",
		"ServiceLine_BaseCost": "Service Line _ Base Cost",
		"ServiceLine_InstallPrice": "Service Line _ Install Price",
		"ServiceLine_InstallCost": "Service Line _ Install Cost",
		"ServiceLine_OptionsPrice": "Service Line _ Options Price",
		"ServiceLine_OptionsCost": "Service Line _ Options Cost",
		"ServiceLine_DeliveryPrice": "Service Line _ Delivery Price",
		"ServiceLine_DeliveryCost": "Service Line _ Delivery Cost",
		"ServiceLine_AdjustmentPrice": "Service Line _ Adjustment Price",
		"ServiceLine_AdjustmentCost": "Service Line _ Adjustment Cost",
		"ServiceLine_Invoiced": "Service Line _ Invoiced",
		"ServiceLine_DisplayOption1": "Service Line _ Display Option 1",
		"ServiceLine_DisplayOption2": "Service Line _ Display Option 2",
		"ServiceLine_DisplayOption3": "Service Line _ Display Option 3",
		"ServiceLine_DisplayOption4": "Service Line _ Display Option 4",
		"ServiceLine_DisplayOption5": "Service Line _ Display Option 5",
		"ServiceLine_DisplayOption6": "Service Line _ Display Option 6",
		"ServiceLine_DisplayOption7": "Service Line _ Display Option 7",
		"ServiceLine_DisplayOption8": "Service Line _ Display Option 8",
		"ServiceLine_DisplayOption9": "Service Line _ Display Option 9",
		"ServiceLine_DisplayOption10": "Service Line _ Display Option 10",
		"ServiceLine_IsService": "Service Line _ Is Service",
		"ServiceLine_RequireDelivery": "Service Line _ Require Delivery",
		"ServiceLine_RequirePickup": "Service Line _ Require Pickup",
		"ServiceLine_DispatchStage": "Service Line _ Dispatch Stage",
		"ServiceLine_StandardCostPrice": "Service Line _ Standard Cost Price",
		"ServiceLine_StandardCostPriceTax": "Service Line _ Standard Cost Price Tax",
		"ServiceLine_StandardCostPriceIncTax": "Service Line _ Standard Cost Price Inc Tax",
		"Job_ID": "Job _ ID",
		"Job_QuoteSentDate": "Job _ Quote Sent Date",
		"Job_SalesRep": "Job _ Sales Rep",
		"Job_Reference": "Job _ Reference",
		"Job_Number": "Job _ Number",
		"Job_Forecasted": "Job _ Forecasted",
		"Job_IsClosed": "Job _ Is Closed",
		"Job_JobDate": "Job _ Job Date",
		"Job_CloseDate": "Job _ Close Date",
		"Job_Stage": "Job _ Stage",
		"Job_JobType": "Job _ Job Type",
		"Job_Priority": "Job _ Priority",
		"Job_ShowroomQuote": "Job _ Showroom Quote",
		"Job_SalesRepID": "Job _ Sales Rep ID",
		"Job_CompanyID": "Job _ Company ID",
		"Job_PL_CustomStatusID": "Job _ PL _ Custom Status ID",
		"Job_HandoverDate": "Job _ Handover Date",
		"CommissionDate1": "Commission Date 1",
		"Job_Status": "Job _ Status",
		"Job_MarginApproved": "Job _ Margin Approved",
		"ServiceJob_ID": "Service Job _ ID",
		"ServiceJob_QuoteSentDate": "Service Job _ Quote Sent Date",
		"ServiceJob_Forecasted": "Service Job _ Forecasted",
		"ServiceJob_IsClosed": "Service Job _ Is Closed",
		"ServiceJob_JobDate": "Service Job _ Job Date",
		"ServiceJob_CloseDate": "Service Job _ Close Date",
		"ServiceJob_Stage": "Service Job _ Stage",
		"ServiceJob_JobType": "Service Job _ Job Type",
		"ServiceJob_Priority": "Service Job _ Priority",
		"ServiceJob_ShowroomQuote": "Service Job _ Showroom Quote",
		"ServiceJob_SalesRepID": "Service Job _ Sales Rep ID",
		"ServiceJob_CompanyID": "Service Job _ Company ID",
		"ServiceJob_PL_CustomStatusID": "Service Job _ PL _ Custom Status ID",
		"ServiceJob_HandoverDate": "Service Job _ Handover Date",
		"ServiceJob_Status": "Service Job _ Status",
		"ServiceJob_MarginApproved": "Service Job _ Margin Approved",
		"Line_UserAtFaultID": "Line _ User At Fault ID",
		"Line_PL_FaultReasonID": "Line _ PL _ Fault Reason ID",
		"Payment_Date": "Payment _ Date",
		"Payment_Type": "Payment _ Type",
		"Payment_Status": "Payment _ Status",
		"Payment_Amount": "Payment _ Amount",
		"Payment_IsDeposit": "Payment _ Is Deposit",
		"Payment_ID": "Payment _ ID",
		"Company_AccountType": "Company _ Account Type",
		"Opportunity_LeadSourceID": "Opportunity _ Lead Source ID",
	},
	"commissionsDataViewForID": {
		"entityTitle": "Commissions Data View For ID",
		"Line_ID": "Line _ ID",
		"Line_OrderDate": "Line _ Order Date",
		"Line_ETA": "Line _ ETA",
		"Line_Width": "Line _ Width",
		"Line_Drop": "Line _ Drop",
		"Line_Qty": "Line _ Qty",
		"Line_Price": "Line _ Price",
		"Line_PriceTax": "Line _ Price Tax",
		"Line_PriceIncTax": "Line _ Price Inc Tax",
		"Line_SalePrice": "Line _ Sale Price",
		"Line_SalePriceTax": "Line _ Sale Price Tax",
		"Line_SalePriceIncTax": "Line _ Sale Price Inc Tax",
		"Line_CostPrice": "Line _ Cost Price",
		"Line_CostPriceTax": "Line _ Cost Price Tax",
		"Line_CostPriceIncTax": "Line _ Cost Price Inc Tax",
		"Line_RequireCheckMeasure": "Line _ Require Check Measure",
		"Line_CM_Approved": "Line _ CM _ Approved",
		"Line_RequireInstall": "Line _ Require Install",
		"Line_Inst_Completed": "Line _ Inst _ Completed",
		"Line_ProductID": "Line _ Product ID",
		"Line_ProductName": "Line _ Product Name",
		"Line_Options": "Line _ Options",
		"Line_SupplierID": "Line _ Supplier ID",
		"Line_Status": "Line _ Status",
		"Line_Stage": "Line _ Stage",
		"Line_LineType": "Line _ Line Type",
		"Line_BasePrice": "Line _ Base Price",
		"Line_BaseCost": "Line _ Base Cost",
		"Line_InstallPrice": "Line _ Install Price",
		"Line_InstallCost": "Line _ Install Cost",
		"Line_OptionsPrice": "Line _ Options Price",
		"Line_OptionsCost": "Line _ Options Cost",
		"Line_DeliveryPrice": "Line _ Delivery Price",
		"Line_DeliveryCost": "Line _ Delivery Cost",
		"Line_AdjustmentPrice": "Line _ Adjustment Price",
		"Line_AdjustmentCost": "Line _ Adjustment Cost",
		"Line_Invoiced": "Line _ Invoiced",
		"Line_DisplayOption1": "Line _ Display Option 1",
		"Line_DisplayOption2": "Line _ Display Option 2",
		"Line_DisplayOption3": "Line _ Display Option 3",
		"Line_DisplayOption4": "Line _ Display Option 4",
		"Line_DisplayOption5": "Line _ Display Option 5",
		"Line_DisplayOption6": "Line _ Display Option 6",
		"Line_DisplayOption7": "Line _ Display Option 7",
		"Line_DisplayOption8": "Line _ Display Option 8",
		"Line_DisplayOption9": "Line _ Display Option 9",
		"Line_DisplayOption10": "Line _ Display Option 10",
		"Line_IsService": "Line _ Is Service",
		"Line_RequireDelivery": "Line _ Require Delivery",
		"Line_RequirePickup": "Line _ Require Pickup",
		"Line_DispatchStage": "Line _ Dispatch Stage",
		"Line_StandardCostPrice": "Line _ Standard Cost Price",
		"Line_StandardCostPriceTax": "Line _ Standard Cost Price Tax",
		"Line_StandardCostPriceIncTax": "Line _ Standard Cost Price Inc Tax",
		"ServiceLine_ID": "Service Line _ ID",
		"ServiceLine_OrderDate": "Service Line _ Order Date",
		"ServiceLine_ETA": "Service Line _ ETA",
		"ServiceLine_Width": "Service Line _ Width",
		"ServiceLine_Drop": "Service Line _ Drop",
		"ServiceLine_Qty": "Service Line _ Qty",
		"ServiceLine_Price": "Service Line _ Price",
		"ServiceLine_PriceTax": "Service Line _ Price Tax",
		"ServiceLine_PriceIncTax": "Service Line _ Price Inc Tax",
		"ServiceLine_SalePrice": "Service Line _ Sale Price",
		"ServiceLine_SalePriceTax": "Service Line _ Sale Price Tax",
		"ServiceLine_SalePriceIncTax": "Service Line _ Sale Price Inc Tax",
		"ServiceLine_CostPrice": "Service Line _ Cost Price",
		"ServiceLine_CostPriceTax": "Service Line _ Cost Price Tax",
		"ServiceLine_CostPriceIncTax": "Service Line _ Cost Price Inc Tax",
		"ServiceLine_RequireCheckMeasure": "Service Line _ Require Check Measure",
		"ServiceLine_CM_Approved": "Service Line _ CM _ Approved",
		"ServiceLine_RequireInstall": "Service Line _ Require Install",
		"ServiceLine_Inst_Completed": "Service Line _ Inst _ Completed",
		"ServiceLine_ProductID": "Service Line _ Product ID",
		"ServiceLine_ProductName": "Service Line _ Product Name",
		"ServiceLine_Options": "Service Line _ Options",
		"ServiceLine_SupplierID": "Service Line _ Supplier ID",
		"ServiceLine_Status": "Service Line _ Status",
		"ServiceLine_Stage": "Service Line _ Stage",
		"ServiceLine_LineType": "Service Line _ Line Type",
		"ServiceLine_BasePrice": "Service Line _ Base Price",
		"ServiceLine_BaseCost": "Service Line _ Base Cost",
		"ServiceLine_InstallPrice": "Service Line _ Install Price",
		"ServiceLine_InstallCost": "Service Line _ Install Cost",
		"ServiceLine_OptionsPrice": "Service Line _ Options Price",
		"ServiceLine_OptionsCost": "Service Line _ Options Cost",
		"ServiceLine_DeliveryPrice": "Service Line _ Delivery Price",
		"ServiceLine_DeliveryCost": "Service Line _ Delivery Cost",
		"ServiceLine_AdjustmentPrice": "Service Line _ Adjustment Price",
		"ServiceLine_AdjustmentCost": "Service Line _ Adjustment Cost",
		"ServiceLine_Invoiced": "Service Line _ Invoiced",
		"ServiceLine_DisplayOption1": "Service Line _ Display Option 1",
		"ServiceLine_DisplayOption2": "Service Line _ Display Option 2",
		"ServiceLine_DisplayOption3": "Service Line _ Display Option 3",
		"ServiceLine_DisplayOption4": "Service Line _ Display Option 4",
		"ServiceLine_DisplayOption5": "Service Line _ Display Option 5",
		"ServiceLine_DisplayOption6": "Service Line _ Display Option 6",
		"ServiceLine_DisplayOption7": "Service Line _ Display Option 7",
		"ServiceLine_DisplayOption8": "Service Line _ Display Option 8",
		"ServiceLine_DisplayOption9": "Service Line _ Display Option 9",
		"ServiceLine_DisplayOption10": "Service Line _ Display Option 10",
		"ServiceLine_IsService": "Service Line _ Is Service",
		"ServiceLine_RequireDelivery": "Service Line _ Require Delivery",
		"ServiceLine_RequirePickup": "Service Line _ Require Pickup",
		"ServiceLine_DispatchStage": "Service Line _ Dispatch Stage",
		"ServiceLine_StandardCostPrice": "Service Line _ Standard Cost Price",
		"ServiceLine_StandardCostPriceTax": "Service Line _ Standard Cost Price Tax",
		"ServiceLine_StandardCostPriceIncTax": "Service Line _ Standard Cost Price Inc Tax",
		"Job_ID": "Job _ ID",
		"Job_QuoteSentDate": "Job _ Quote Sent Date",
		"Job_SalesRep": "Job _ Sales Rep",
		"Job_Reference": "Job _ Reference",
		"Job_Number": "Job _ Number",
		"Job_Forecasted": "Job _ Forecasted",
		"Job_IsClosed": "Job _ Is Closed",
		"Job_JobDate": "Job _ Job Date",
		"Job_CloseDate": "Job _ Close Date",
		"Job_Stage": "Job _ Stage",
		"Job_JobType": "Job _ Job Type",
		"Job_Priority": "Job _ Priority",
		"Job_ShowroomQuote": "Job _ Showroom Quote",
		"Job_SalesRepID": "Job _ Sales Rep ID",
		"Job_CompanyID": "Job _ Company ID",
		"Job_PL_CustomStatusID": "Job _ PL _ Custom Status ID",
		"Job_HandoverDate": "Job _ Handover Date",
		"CommissionDate1": "Commission Date 1",
		"Job_Status": "Job _ Status",
		"Job_MarginApproved": "Job _ Margin Approved",
		"ServiceJob_ID": "Service Job _ ID",
		"ServiceJob_QuoteSentDate": "Service Job _ Quote Sent Date",
		"ServiceJob_Forecasted": "Service Job _ Forecasted",
		"ServiceJob_IsClosed": "Service Job _ Is Closed",
		"ServiceJob_JobDate": "Service Job _ Job Date",
		"ServiceJob_CloseDate": "Service Job _ Close Date",
		"ServiceJob_Stage": "Service Job _ Stage",
		"ServiceJob_JobType": "Service Job _ Job Type",
		"ServiceJob_Priority": "Service Job _ Priority",
		"ServiceJob_ShowroomQuote": "Service Job _ Showroom Quote",
		"ServiceJob_SalesRepID": "Service Job _ Sales Rep ID",
		"ServiceJob_CompanyID": "Service Job _ Company ID",
		"ServiceJob_PL_CustomStatusID": "Service Job _ PL _ Custom Status ID",
		"ServiceJob_HandoverDate": "Service Job _ Handover Date",
		"ServiceJob_Status": "Service Job _ Status",
		"ServiceJob_MarginApproved": "Service Job _ Margin Approved",
		"Line_UserAtFaultID": "Line _ User At Fault ID",
		"Line_PL_FaultReasonID": "Line _ PL _ Fault Reason ID",
		"Payment_Date": "Payment _ Date",
		"Payment_Type": "Payment _ Type",
		"Payment_Status": "Payment _ Status",
		"Payment_Amount": "Payment _ Amount",
		"Payment_IsDeposit": "Payment _ Is Deposit",
		"Payment_ID": "Payment _ ID",
		"Company_AccountType": "Company _ Account Type",
		"Opportunity_LeadSourceID": "Opportunity _ Lead Source ID",
	},
	"commissionValueForID": {
		"entityTitle": "Commission Value For ID",
		"Name": "Name",
		"SummaryType": "Summary Type",
		"Filter": "Filter",
		"Expression": "Expression",
		"GroupBy": "Group By",
		"SummaryBy": "Summary By",
		"ShowOnStatement": "Show On Statement",
		"IsText": "Is Text",
		"SortOrder": "Sort Order",
	},
	"commissionValues": {
		"entityTitle": "Commission Values",
		"Name": "Name",
		"SummaryType": "Summary Type",
		"Filter": "Filter",
		"Expression": "Expression",
		"GroupBy": "Group By",
		"SummaryBy": "Summary By",
		"ShowOnStatement": "Show On Statement",
		"IsText": "Is Text",
		"SortOrder": "Sort Order",
	},
	"communicationForID": {
		"entityTitle": "Communication For ID",
		"Date": "Date",
		"Type": "Type",
		"Category": "Category",
		"Description": "Description",
		"Outcome": "Outcome",
		"OutcomeDate": "Outcome Date",
		"ContactPoint": "Contact Point",
		"Tag": "Tag",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"Job": "Job",
		"JobID": "Job",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"Supplier": "Supplier",
		"SupplierID": "Supplier",
		"SupplyJob": "Purchase Order",
		"SupplyJobID": "Purchase Order",
		"CommunicationID": "Communication ID",
		"activity": "activity",
		"ActivityID": "Activity",
		"TemplateID": "Template ID",
	},
	"communicationLog": {
		"entityTitle": "Communication Log",
		"Date": "Date",
		"Type": "Type",
		"Category": "Category",
		"Description": "Description",
		"Outcome": "Outcome",
		"OutcomeDate": "Outcome Date",
		"ContactPoint": "Contact Point",
		"Tag": "Tag",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"Job": "Job",
		"JobID": "Job",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"Supplier": "Supplier",
		"SupplierID": "Supplier",
		"SupplyJob": "Purchase Order",
		"SupplyJobID": "Purchase Order",
		"CommunicationID": "Communication ID",
		"activity": "activity",
		"ActivityID": "Activity",
		"TemplateID": "Template ID",
	},
	"companies": {
		"entityTitle": "Companies",
		"Name": "Name",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"DefaultPaymentTerms": "Default Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"InvoiceDueDays": "Invoice Due Days",
		"Notes": "Notes",
		"OrganisationType": "Organisation Type",
		"PL_OrganisationTypeID": "Organisation Type",
		"Address": "Address",
		"AddressID": "Address",
		"PriceListID": "Price List ID",
		"BillingContact": "Contact",
		"BillingContactID": "Contact",
		"FileNotes": "File Notes",
		"PriceMappings": "Price Mappings",
		"AccountType": "Account Type",
		"CompanyID": "Company ID",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
	},
	"companyDiscountForID": {
		"entityTitle": "Company Discount For ID",
		"DiscountLevel": "Discount Level",
		"DiscountLevelID": "Discount Level",
		"Company": "Company",
		"CompanyID": "Company",
	},
	"companyDiscounts": {
		"entityTitle": "Company Discounts",
		"DiscountLevel": "Discount Level",
		"DiscountLevelID": "Discount Level",
		"Company": "Company",
		"CompanyID": "Company",
	},
	"companyForID": {
		"entityTitle": "Company For ID",
		"Name": "Name",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"DefaultPaymentTerms": "Default Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"InvoiceDueDays": "Invoice Due Days",
		"Notes": "Notes",
		"OrganisationType": "Organisation Type",
		"PL_OrganisationTypeID": "Organisation Type",
		"Address": "Address",
		"AddressID": "Address",
		"PriceListID": "Price List ID",
		"BillingContact": "Contact",
		"BillingContactID": "Contact",
		"FileNotes": "File Notes",
		"PriceMappings": "Price Mappings",
		"AccountType": "Account Type",
		"CompanyID": "Company ID",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
	},
	"companyPriceMappingForID": {
		"entityTitle": "Company Price Mapping For ID",
		"Company": "Company",
		"CompanyID": "Company",
		"PriceListID": "Price List ID",
		"ProductID": "Product ID",
	},
	"companyPriceMappings": {
		"entityTitle": "Company Price Mappings",
		"Company": "Company",
		"CompanyID": "Company",
		"PriceListID": "Price List ID",
		"ProductID": "Product ID",		
	},
	"contactForID": {
		"entityTitle": "Contact For ID",
		"FirstName": "First Name",
		"LastName": "Last Name",
		"FullName": "Full Name",
		"JobTitle": "Job Title",
		"JobCategory": "Contact Job Category",
		"PL_ContactJobCategoryID_abbrev": "Job Category",
		"PL_ContactJobCategoryID": "Contact Job Category",
		"WorkPhone": "Work Phone",
		"HomePhone": "Home Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"Notes": "Notes",
		"Address": "Address",
		"AddressID": "Address",
		"Company": "Company",
		"CompanyID": "Company",
		"FileNotes": "File Notes",
		"ContactID": "Contact ID",
		"FinanceIntegrationID": "Finance Integration ID",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
	},
	"contacts": {
		"entityTitle": "Contacts",
		"FirstName": "First Name",
		"LastName": "Last Name",
		"FullName": "Full Name",
		"JobTitle": "Job Title",
		"JobCategory": "Contact Job Category",
		"PL_ContactJobCategoryID_abbrev": "Job Category",
		"PL_ContactJobCategoryID": "Contact Job Category",
		"WorkPhone": "Work Phone",
		"HomePhone": "Home Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"Notes": "Notes",
		"Address": "Address",
		"AddressID": "Address",
		"Company": "Company",
		"CompanyID": "Company",
		"FileNotes": "File Notes",
		"ContactID": "Contact ID",
		"FinanceIntegrationID": "Finance Integration ID",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
	},
	"contactsForCompany": {
		"entityTitle": "Contacts For Company",
		"ID": "ID",
		"FirstName": "First Name",
		"LastName": "Last Name",
		"WorkPhone": "Work Phone",
		"HomePhone": "Home Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"InternalNotes": "Internal Notes",
		"Company": "Company",
		"CompanyID": "Company ID",
		"JobCategory": "Job Category",
		"JobTitle": "Job Title",
	},
	"currentActivities": {
		"entityTitle": "Current Activities",
		"ActivityID": "Activity ID",
		"JobID": "Job ID",
		"JobLineID": "Job Line ID",
		"ActiveTypeID": "Active Type ID",
		"ActiveCategoryID": "Active Category ID",
		"ActivityType": "Activity Type",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"Team": "Team",
		"TeamID": "Team ID",
		"Subject": "Subject",
		"Priority": "Priority",
		"Confirmed": "Confirmed",
		"Closed": "Closed",
		"ContactID": "Contact ID",
		"PL_ActivityCategoryID": "PL _ Activity Category ID",
		"Start": "Start",
		"CreatedOn": "Created On",
	},
	"currentActivityForID": {
		"entityTitle": "Current Activity For ID",
		"ActivityID": "Activity ID",
		"JobID": "Job ID",
		"JobLineID": "Job Line ID",
		"ActiveTypeID": "Active Type ID",
		"ActiveCategoryID": "Active Category ID",
		"ActivityType": "Activity Type",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"Team": "Team",
		"TeamID": "Team ID",
		"Subject": "Subject",
		"Priority": "Priority",
		"Confirmed": "Confirmed",
		"Closed": "Closed",
		"ContactID": "Contact ID",
		"PL_ActivityCategoryID": "PL _ Activity Category ID",
		"Start": "Start",
		"CreatedOn": "Created On",
	},
	"defaultSettingForID": {
		"entityTitle": "Default Setting For ID",
		"DefaultMarkupPercentage": "Default Markup Percentage",
		"QuoteExpireDays": "Quote Expire Days",
		"InvoiceDueDays": "Invoice Due Days",
		"DefaultCommitmentReason": "Commitment Reason",
		"DefaultCommitmentReasonID": "Commitment Reason",
		"PrimaryBusinessUnit": "Business Unit",
		"PrimaryBusinessUnitID": "Business Unit",
		"DefaultPaymentTerms": "Default Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"MinDeposit": "Min Deposit",
		"FinancialYearStartDate": "Financial Year Start Date",
		"CommissionDate1Rule": "Commission Date 1 Rule",
		"CommissionDate1RuleByLine": "Commission Date 1 Rule By Line",
		"DefaultTaxRate": "Tax Rate",
		"DefaultTaxRateID": "Tax Rate",
		"DefaultSupplierEmailTemplate": "Template",
		"DefaultSupplierEmailTemplateID": "Template",
		"DefaultBusinessUnitRule": "Default Business Unit Rule",
		"OrderCustomerRefRequired": "Order Customer Ref Required",
		"DefaultSettingID": "Default Setting ID",
		"SetupWizardCompleted": "Setup Wizard Completed",
		"DocumentStore": "Document Store",
		"TrackingEmailAddress": "Tracking Email Address",
		"ManufacturingETABuffer": "Manufacturing ETA Buffer",
		"Tracking_QuoteExpiryDays": "Tracking _ Quote Expiry Days",
		"Tracking_CMNotBookedDays": "Tracking _ CM Not Booked Days",
		"Tracking_CMNotConfirmedDays": "Tracking _ CM Not Confirmed Days",
		"Tracking_StockDuedDays": "Tracking _ Stock Dued Days",
		"Tracking_StockNotArrivedDays": "Tracking _ Stock Not Arrived Days",
		"Tracking_InstNotConfirmedDays": "Tracking _ Inst Not Confirmed Days",
		"Tracking_InstallerNotCompleteDays": "Tracking _ Installer Not Complete Days",
		"AutomationEnabled": "Automation Enabled",
	},
	"defaultSettings": {
		"entityTitle": "Default Settings",
		"DefaultMarkupPercentage": "Default Markup Percentage",
		"QuoteExpireDays": "Quote Expire Days",
		"InvoiceDueDays": "Invoice Due Days",
		"DefaultCommitmentReason": "Commitment Reason",
		"DefaultCommitmentReasonID": "Commitment Reason",
		"PrimaryBusinessUnit": "Business Unit",
		"PrimaryBusinessUnitID": "Business Unit",
		"DefaultPaymentTerms": "Default Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"MinDeposit": "Min Deposit",
		"FinancialYearStartDate": "Financial Year Start Date",
		"CommissionDate1Rule": "Commission Date 1 Rule",
		"CommissionDate1RuleByLine": "Commission Date 1 Rule By Line",
		"DefaultTaxRate": "Tax Rate",
		"DefaultTaxRateID": "Tax Rate",
		"DefaultSupplierEmailTemplate": "Template",
		"DefaultSupplierEmailTemplateID": "Template",
		"DefaultBusinessUnitRule": "Default Business Unit Rule",
		"OrderCustomerRefRequired": "Order Customer Ref Required",
		"DefaultSettingID": "Default Setting ID",
		"SetupWizardCompleted": "Setup Wizard Completed",
		"DocumentStore": "Document Store",
		"TrackingEmailAddress": "Tracking Email Address",
		"ManufacturingETABuffer": "Manufacturing ETA Buffer",
		"Tracking_QuoteExpiryDays": "Tracking _ Quote Expiry Days",
		"Tracking_CMNotBookedDays": "Tracking _ CM Not Booked Days",
		"Tracking_CMNotConfirmedDays": "Tracking _ CM Not Confirmed Days",
		"Tracking_StockDuedDays": "Tracking _ Stock Dued Days",
		"Tracking_StockNotArrivedDays": "Tracking _ Stock Not Arrived Days",
		"Tracking_InstNotConfirmedDays": "Tracking _ Inst Not Confirmed Days",
		"Tracking_InstallerNotCompleteDays": "Tracking _ Installer Not Complete Days",
		"AutomationEnabled": "Automation Enabled",
	},
	"discountItemForID": {
		"entityTitle": "Discount Item For ID",
		"Active": "Active",
		"Start": "Start",
		"Finish": "Finish",
		"ApplyToBase": "Apply To Base",
		"ApplyToOptions": "Apply To Options",
		"ApplyToDelivery": "Apply To Delivery",
		"ApplyToInstallation": "Apply To Installation",
		"Percentage": "Percentage",
		"Amount": "Amount",
		"DiscountLevel": "Discount Level",
		"DiscountLevelID": "Discount Level",
		"ProductID": "Product ID",
		"Supplier": "Supplier",
		"Product": "Product",
		"Option1": "Option 1",
		"Option1ID": "Option 1 ID",
		"Option2ID": "Option 2 ID",
		"Option2": "Option 2",
		"Option1ValueID": "Option 1 Value ID",
		"Option1Value": "Option 1 Value",
		"Option2ValueID": "Option 2 Value ID",
		"Option2Value": "Option 2 Value",
		"Option1GroupCode": "Option 1 Group Code",
		"Option2GroupCode": "Option 2 Group Code",
		"DiscountItemID": "Discount Item ID",
	},
	"discountItemGrid": {
		"entityTitle": "Discount Item Grid",
		"ID": "ID",
		"Active": "Active",
		"Start": "Start",
		"Finish": "Finish",
		"Base": "Base",
		"Options": "Options",
		"Delivery": "Delivery",
		"Install": "Install",
		"Percentage": "Percentage",
		"Supplier": "Supplier",
		"Product": "Product",
		"Option1": "Option 1",
		"Option2": "Option 2",
		"Option1Value": "Option 1 Value",
		"Option2Value": "Option 2 Value",
		"Option1GroupCode": "Option 1 Group Code",
		"Option2GroupCode": "Option 2 Group Code",
	},
	"discountItems": {
		"entityTitle": "Discount Items",
		"Active": "Active",
		"Start": "Start",
		"Finish": "Finish",
		"ApplyToBase": "Apply To Base",
		"ApplyToOptions": "Apply To Options",
		"ApplyToDelivery": "Apply To Delivery",
		"ApplyToInstallation": "Apply To Installation",
		"Percentage": "Percentage",
		"Amount": "Amount",
		"DiscountLevel": "Discount Level",
		"DiscountLevelID": "Discount Level",
		"ProductID": "Product ID",
		"Supplier": "Supplier",
		"Product": "Product",
		"Option1": "Option 1",
		"Option1ID": "Option 1 ID",
		"Option2ID": "Option 2 ID",
		"Option2": "Option 2",
		"Option1ValueID": "Option 1 Value ID",
		"Option1Value": "Option 1 Value",
		"Option2ValueID": "Option 2 Value ID",
		"Option2Value": "Option 2 Value",
		"Option1GroupCode": "Option 1 Group Code",
		"Option2GroupCode": "Option 2 Group Code",
		"DiscountItemID": "Discount Item ID",
	},
	"discountLevel_CompaniesGrid": {
		"entityTitle": "Discount Level _ Companies Grid",
		"ID": "ID",
		"Name": "Name",
		"Type": "Type",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"InternalNotes": "Internal Notes",
	},
	"discountLevelForID": {
		"entityTitle": "Discount Level For ID",
		"Description": "Description",
		"Code": "Code",
		"Type": "Type",
		"Active": "Active",
		"DiscountLevelID": "Discount Level ID",
		"DicountItems": "Dicount Items",
		"CompanyDiscounts": "Company Discounts",
	},
	"discountLevels": {
		"entityTitle": "Discount Levels",
		"Description": "Description",
		"Code": "Code",
		"Type": "Type",
		"Active": "Active",
		"DiscountLevelID": "Discount Level ID",
		"DicountItems": "Dicount Items",
		"CompanyDiscounts": "Company Discounts",
	},
	"documentForID": {
		"entityTitle": "Document For ID",
		"Job": "Job",
		"JobID": "Job",
		"SendToCustomer": "Send To Customer",
		"SendToSupplier": "Send To Supplier",
		"SendToSuppierID": "Send To Suppier ID",
	},
	"documents": {
		"entityTitle": "Documents",
		"Job": "Job",
		"JobID": "Job",
		"SendToCustomer": "Send To Customer",
		"SendToSupplier": "Send To Supplier",
		"SendToSuppierID": "Send To Suppier ID",
	},
	"documentsForIDWithOrder": {
		"entityTitle": "Documents For ID With Order",
		"Job": "Job",
		"JobID": "Job",
		"SendToCustomer": "Send To Customer",
		"SendToSupplier": "Send To Supplier",
		"SendToSuppierID": "Send To Suppier ID",
	},
	"documentsForJob": {
		"entityTitle": "Documents For Job",
		"ID": "ID",
		"Description": "Description",
		"Job": "Job",
		"JobID": "Job ID",
		"Type": "Type",
		"Date": "Date",
		"Version": "Version",
		"SendToCustomer": "Send To Customer",
		"SendToSupplier": "Send To Supplier",
	},
	"documentTemplateForID": {
		"entityTitle": "Document Template For ID",
		"Name": "Name",
		"Type": "Type",
		"TemplateID": "Template ID",
		"TemplateParams": "Template Params",
	},
	"documentTemplates": {
		"entityTitle": "Document Templates",
		"Name": "Name",
		"Type": "Type",
		"TemplateID": "Template ID",
		"TemplateParams": "Template Params",
	},
	"entitySnapshotForID": {
		"entityTitle": "Entity Snapshot For ID",
		"Name": "Name",
		"Date": "Date",
		"SnapshotData": "Snapshot Data",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"Job": "Job Line",
		"JobID": "Job",
		"OtherID": "Other ID",
	},
	"export_JobDetails": {
		"entityTitle": "Export _ Job Details",
		"JobID": "Job ID",
		"Reference": "Reference",
		"Stage": "Stage",
		"JobType": "Job Type",
		"SalesRep": "Sales Rep",
		"CustomStatus": "Custom Status",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"City": "City",
		"CMBy": "CM By",
		"InstallBy": "Install By",
		"LineNo": "Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Qty": "Qty",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"Product": "Product",
		"Supplier": "Supplier",
		"LineStage": "Line Stage",
		"OrderLineOptionsData": "Order Line Options Data",
		"CMDate": "CM Date",
		"InstallDate": "Install Date",
		"Paid": "Paid",
		"Tags": "Tags",
	},
	"externalLeadForID": {
		"entityTitle": "External Lead For ID",
		"Date": "Date",
		"Processed": "Processed",
		"SnoozeDate": "Snooze Date",
		"ContactFirstName": "Contact First Name",
		"ContactLastName": "Contact Last Name",
		"ContactEmail": "Contact Email",
		"ContactMobile": "Contact Mobile",
		"ContactWorkPh": "Contact Work Ph",
		"ContactHomePh": "Contact Home Ph",
		"LeadData": "Lead Data",
		"LeadSource": "Lead Source",
		"PL_LeadSourceID_abbrev": "Lead Source",
		"PL_LeadSourceID": "Lead Source",
		"DiscardNotes": "Discard Notes",
		"PL_LeadDiscardReason": "Lead Discard Reason",
		"PL_LeadDiscardReasonID": "Lead Discard Reason",
	},
	"externalLeads": {
		"entityTitle": "External Leads",
		"Date": "Date",
		"Processed": "Processed",
		"SnoozeDate": "Snooze Date",
		"ContactFirstName": "Contact First Name",
		"ContactLastName": "Contact Last Name",
		"ContactEmail": "Contact Email",
		"ContactMobile": "Contact Mobile",
		"ContactWorkPh": "Contact Work Ph",
		"ContactHomePh": "Contact Home Ph",
		"LeadData": "Lead Data",
		"LeadSource": "Lead Source",
		"PL_LeadSourceID_abbrev": "Lead Source",
		"PL_LeadSourceID": "Lead Source",
		"DiscardNotes": "Discard Notes",
		"PL_LeadDiscardReason": "Lead Discard Reason",
		"PL_LeadDiscardReasonID": "Lead Discard Reason",
	},
	"fileNoteForID": {
		"entityTitle": "File Note For ID",
		"Date": "Date",
		"Notes": "Notes",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"Job": "Job",
		"JobID": "Job",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"ExternalLead": "External Lead",
		"ExternalLeadID": "External Lead",
		"FileNoteID": "File Note ID",
	},
	"fileNotes": {
		"entityTitle": "File Notes",
		"Date": "Date",
		"Notes": "Notes",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"Job": "Job",
		"JobID": "Job",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"ExternalLead": "External Lead",
		"ExternalLeadID": "External Lead",
		"FileNoteID": "File Note ID",
	},
	"financeIntegrationSettingsRecord": {
		"entityTitle": "Finance Integration Settings Record",
		"Settings": "Settings",
	},
	"financeJournalForID": {
		"entityTitle": "Finance Journal For ID",
		"EntryDate": "Entry Date",
		"TransferDate": "Transfer Date",
		"Amount": "Amount",
		"Tax": "Tax Rate",
		"Type": "Type",
		"Notes": "Notes",
		"FinanceJournalID": "Finance Journal ID",
		"GroupID": "Group ID",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"ItemID": "Item ID",
		"ItemPrompt": "Item Prompt",
		"ContactID": "Contact ID",
		"PaymentID": "Payment ID",
		"JobID": "Job ID",
		"InvoiceID": "Invoice ID",
	},
	"financeJournals": {
		"entityTitle": "Finance Journals",
		"EntryDate": "Entry Date",
		"TransferDate": "Transfer Date",
		"Amount": "Amount",
		"Tax": "Tax Rate",
		"Type": "Type",
		"Notes": "Notes",
		"FinanceJournalID": "Finance Journal ID",
		"GroupID": "Group ID",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"ItemID": "Item ID",
		"ItemPrompt": "Item Prompt",
		"ContactID": "Contact ID",
		"PaymentID": "Payment ID",
		"JobID": "Job ID",
		"InvoiceID": "Invoice ID",
	},
	"financeSettingRecord": {
		"entityTitle": "Finance Setting Record",
		"Settings": "Settings",
	},
	"getCommunicationLogForContact": {
		"entityTitle": "Get Communication Log For Contact",
		"ID": "ID",
		"Date": "Date",
		"Type": "Type",
		"Category": "Category",
		"Description": "Description",
		"ContactPoint": "Contact Point",
		"Outcome": "Outcome",
		"User_abbrev": "User",
		"User": "User",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Job": "Job",
		"JobID": "Job ID",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice ID",
		"SupplyJob": "Supply Job",
		"PurchaseOrderID": "Purchase Order ID",
		"Supplier": "Supplier",
	},
	"getCommunicationLogForInvoice": {
		"entityTitle": "Get Communication Log For Invoice",
		"ID": "ID",
		"Date": "Date",
		"Type": "Type",
		"Category": "Category",
		"Description": "Description",
		"ContactPoint": "Contact Point",
		"Outcome": "Outcome",
		"User_abbrev": "User",
		"User": "User",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Job": "Job",
		"JobID": "Job ID",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice ID",
		"SupplyJob": "Supply Job",
		"PurchaseOrderID": "Purchase Order ID",
		"Supplier": "Supplier",
	},
	"getCommunicationLogForJob": {
		"entityTitle": "Get Communication Log For Job",
		"ID": "ID",
		"Date": "Date",
		"Type": "Type",
		"Category": "Category",
		"Description": "Description",
		"ContactPoint": "Contact Point",
		"Outcome": "Outcome",
		"User_abbrev": "User",
		"User": "User",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Job": "Job",
		"JobID": "Job ID",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice ID",
		"SupplyJob": "Supply Job",
		"PurchaseOrderID": "Purchase Order ID",
		"Supplier": "Supplier",
	},
	"getCommunicationLogForSupplyJob": {
		"entityTitle": "Get Communication Log For Supply Job",
		"ID": "ID",
		"Date": "Date",
		"Type": "Type",
		"Category": "Category",
		"Description": "Description",
		"ContactPoint": "Contact Point",
		"Outcome": "Outcome",
		"User_abbrev": "User",
		"User": "User",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Job": "Job",
		"JobID": "Job ID",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice ID",
		"SupplyJob": "Supply Job",
		"PurchaseOrderID": "Purchase Order ID",
		"Supplier": "Supplier",
	},
	"gridFilterAccess": {
		"entityTitle": "Grid Filter Access",
		"GridFilter": "Grid Filter",
		"GridFilterID": "Grid Filter",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Team": "Team",
		"TeamID": "Team",
	},
	"gridFilterAccessForID": {
		"entityTitle": "Grid Filter Access For ID",
		"GridFilter": "Grid Filter",
		"GridFilterID": "Grid Filter",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Team": "Team",
		"TeamID": "Team",
	},
	"gridFilterForID": {
		"entityTitle": "Grid Filter For ID",
		"Name": "Name",
		"Filter": "Filter",
		"EntityType": "Entity Type",
		"Owner": "Representative",
		"OwnerID": "Representative",
		"FilterAccess": "Filter Access",
	},
	"gridFilters": {
		"entityTitle": "Grid Filters",
		"Name": "Name",
		"Filter": "Filter",
		"EntityType": "Entity Type",
		"Owner": "Representative",
		"OwnerID": "Representative",
		"FilterAccess": "Filter Access",
	},
	"gridSettings": {
		"entityTitle": "Grid Settings",
		"Key": "Key",
		"Settings": "Settings",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
	},
	"gridSettingsForID": {
		"entityTitle": "Grid Settings For ID",
		"Key": "Key",
		"Settings": "Settings",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
	},
	"integrationSettings": {
		"entityTitle": "Integration Settings",
		"Settings": "Settings",
	},
	"integrationSettingsForID": {
		"entityTitle": "Integration Settings For ID",
		"Settings": "Settings",
	},
	"invoiceForID": {
		"entityTitle": "Invoice For ID",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"InvoiceID": "Invoice ID",
		"InvoiceNo": "Invoice No",
		"InvoiceDate": "Invoice Date",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"Locked": "Locked",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"DateExported": "Date Exported",
		"Status": "Job Custom Status",
		"FinanceArchived": "Finance Archived",
		"Job": "Job",
		"JobID": "Job",
		"DueDate": "Due Date",
		"CloseDate": "Close Date",
		"BillingAddress": "Address",
		"BillingAddressID": "Address",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"FinanceExternalID": "Finance External ID",
		"CreditNote": "Credit Note",
		"CustomStatus": "Job Custom Status",
		"PL_CustomStatusID_abbrev": "Custom Status",
		"PL_CustomStatusID": "Job Custom Status",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"ChangeStatus": "Change Status",
		"InvoiceLines": "Invoice Lines",
		"DocumentVersion": "Document Version",
		"DocumentTemplateID": "Document Template ID",
		"ReportSettings": "Report Settings",
	},
	"invoiceLineForID": {
		"entityTitle": "Invoice Line For ID",
		"LineNo_abbrev": "Line No",
		"LineNo": "Line No",
		"Description": "Description",
		"InvoiceLineID": "Invoice Line ID",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice",
		"UnitPrice": "Unit Price",
		"Qty": "Qty",
		"Amount": "Amount",
		"Tax": "Tax Rate",
		"AmountIncTax": "Amount Inc Tax",
		"ProductName": "Product Name",
		"ProductID": "Product ID",
		"Status": "Status",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"Locked": "Locked",
		"ChangeStatus": "Change Status",
		"Notes": "Notes",
	},
	"invoiceLines": {
		"entityTitle": "Invoice Lines",
		"LineNo_abbrev": "Line No",
		"LineNo": "Line No",
		"Description": "Description",
		"InvoiceLineID": "Invoice Line ID",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice",
		"UnitPrice": "Unit Price",
		"Qty": "Qty",
		"Amount": "Amount",
		"Tax": "Tax Rate",
		"AmountIncTax": "Amount Inc Tax",
		"ProductName": "Product Name",
		"ProductID": "Product ID",
		"Status": "Status",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"Locked": "Locked",
		"ChangeStatus": "Change Status",
		"Notes": "Notes",
	},
	"invoices": {
		"entityTitle": "Invoices",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"InvoiceID": "Invoice ID",
		"InvoiceNo": "Invoice No",
		"InvoiceDate": "Invoice Date",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"Locked": "Locked",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"DateExported": "Date Exported",
		"Status": "Job Custom Status",
		"FinanceArchived": "Finance Archived",
		"Job": "Job",
		"JobID": "Job",
		"DueDate": "Due Date",
		"CloseDate": "Close Date",
		"BillingAddress": "Address",
		"BillingAddressID": "Address",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"FinanceExternalID": "Finance External ID",
		"CreditNote": "Credit Note",
		"CustomStatus": "Job Custom Status",
		"PL_CustomStatusID_abbrev": "Custom Status",
		"PL_CustomStatusID": "Job Custom Status",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"ChangeStatus": "Change Status",
		"InvoiceLines": "Invoice Lines",
		"DocumentVersion": "Document Version",
		"DocumentTemplateID": "Document Template ID",
		"ReportSettings": "Report Settings",
	},
	"invoicesAwaitingPayment": {
		"entityTitle": "Invoices Awaiting Payment",
		"ID": "ID",
		"InvoiceDate": "Invoice Date",
		"ProjectCustomerPO_abbrev": "Cust Ref",
		"ProjectCustomerPO": "Project Customer PO",
		"InvoiceNo": "Invoice No",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Amount": "Amount",
		"Tax": "Tax",
		"AmountIncTax": "Amount Inc Tax",
		"Rep": "Rep",
		"Status": "Status",
		"DueDate_abbrev": "Due",
		"DueDate": "Due Date",
	},
	"invoicesForStatus": {
		"entityTitle": "Invoices For Status",
		"ID": "ID",
		"InvoiceDate": "Invoice Date",
		"ProjectCustomerPO_abbrev": "Cust Ref",
		"ProjectCustomerPO": "Project Customer PO",
		"InvoiceNo": "Invoice No",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Amount": "Amount",
		"Tax": "Tax",
		"AmountIncTax": "Amount Inc Tax",
		"Rep": "Rep",
		"Status": "Status",
		"DueDate_abbrev": "Due",
		"DueDate": "Due Date",
	},
	"invoicesOverdue": {
		"entityTitle": "Invoices Overdue",
		"ID": "ID",
		"InvoiceDate": "Invoice Date",
		"ProjectCustomerPO_abbrev": "Cust Ref",
		"ProjectCustomerPO": "Project Customer PO",
		"InvoiceNo": "Invoice No",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Amount": "Amount",
		"Tax": "Tax",
		"AmountIncTax": "Amount Inc Tax",
		"Rep": "Rep",
		"Status": "Status",
		"DueDate_abbrev": "Due",
		"DueDate": "Due Date",
	},
	"invoiceStatementViewForID": {
		"entityTitle": "Invoice Statement View For ID",
		"InvoiceDate": "Invoice Date",
		"DueDate": "Due Date",
		"InvoiceNo": "Invoice No",
		"ProjectCustomerPO": "Project Customer PO",
		"CompanyID": "Company ID",
		"Company": "Company",
		"CompanyMobile": "Company Mobile",
		"CompanyPhone": "Company Phone",
		"CompanyFax": "Company Fax",
		"ContactID": "Contact ID",
		"Contact": "Contact",
		"ContactHomePhone": "Contact Home Phone",
		"ContactMobile": "Contact Mobile",
		"ContactWorkPhone": "Contact Work Phone",
		"Email": "Email",
		"Address": "Address",
		"DaysOverdue": "Days Overdue",
		"Amount": "Amount",
		"Overdue": "Overdue",
		"Paid": "Paid",
		"Balance": "Balance",
	},
	"iV_AdvancedFilter_JobForID": {
		"entityTitle": "IV _ Advanced Filter _ Job For ID",
		"ID": "ID",
		"QuoteDate": "Quote Date",
		"QuoteExpiryDate": "Quote Expiry Date",
		"HandoverDate": "Handover Date",
		"LJ__CommissionDate1": "LJ __ Commission Date 1",
		"LJ__OrderDate": "LJ __ Order Date",
		"LJ__ETADate": "LJ __ ETA Date",
		"LJ__Amount": "LJ __ Amount",
		"LJ__Paid": "LJ __ Paid",
		"LJ__HasPayment": "LJ __ Has Payment",
		"Stage": "Stage",
		"Status": "Status",
		"JobType": "Job Type",
		"MarginApprovalRequired": "Margin Approval Required",
		"LJ__JobLineStatus": "LJ __ Job Line Status",
		"LJ__JobLineStage": "LJ __ Job Line Stage",
		"LJ__JobLineType": "LJ __ Job Line Type",
		"LJ__FaultReasonID": "LJ __ Fault Reason ID",
		"LJ__AtFaultUserID": "LJ __ At Fault User ID",
		"LJ__AtFaultSupplierID": "LJ __ At Fault Supplier ID",
		"LJ__JobLineDispatchStage": "LJ __ Job Line Dispatch Stage",
		"SalesRepID": "Sales Rep ID",
		"BusinessUnitID": "Business Unit ID",
		"PL_CommitmentReasonID": "PL _ Commitment Reason ID",
		"LJ__Balance": "LJ __ Balance",
		"LJ__PercentagePaid": "LJ __ Percentage Paid",
		"LJ__JobLineProductID": "LJ __ Job Line Product ID",
		"LJ__JobLineSupplierID": "LJ __ Job Line Supplier ID",
		"LJ__CompanyName": "LJ __ Company Name",
		"LJ__OrganisationType": "LJ __ Organisation Type",
		"LJ__AccountType": "LJ __ Account Type",
		"LJ__ContactFirstName": "LJ __ Contact First Name",
		"LJ__ContactLastName": "LJ __ Contact Last Name",
		"LJ__ContactHasCredit": "LJ __ Contact Has Credit",
		"LJ__SiteContactFirstName": "LJ __ Site Contact First Name",
		"LJ__SiteContactLastName": "LJ __ Site Contact Last Name",
		"LJ__ActivityType": "LJ __ Activity Type",
		"LJ__ActivityCategory": "LJ __ Activity Category",
		"LJ__ActivityStart": "LJ __ Activity Start",
		"LJ__ActivityRepresentative": "LJ __ Activity Representative",
		"LJ__Tags": "LJ __ Tags",
		"LJ__ActivityClosed": "LJ __ Activity Closed",
		"LJ__ActivityConfirmed": "LJ __ Activity Confirmed",
		"LJ__ReferredByID": "LJ __ Referred By ID",
		"LJ__LeadGenerator": "LJ __ Lead Generator",
		"LJ__OpportunityConfidenceLevel": "LJ __ Opportunity Confidence Level",
		"LJ__OpportunityStatus": "LJ __ Opportunity Status",
		"LJ__OpportunityOutcomeReason": "LJ __ Opportunity Outcome Reason",
		"LJ__OpportunityForecast": "LJ __ Opportunity Forecast",
		"LJ__LeadSource": "LJ __ Lead Source",
		"LJ__VisitedShowroom": "LJ __ Visited Showroom",
		"LJ__OpportunityRepresentative": "LJ __ Opportunity Representative",
		"LJ__CMRequired": "LJ __ CM Required",
		"LJ__InstallRequired": "LJ __ Install Required",
		"LJ__PickupRequired": "LJ __ Pickup Required",
		"LJ__DeliveryRequired": "LJ __ Delivery Required",
		"LJ__PercentageInvoiced": "LJ __ Percentage Invoiced",
	},
	"iV_AdvancedFilter_OpportunityForID": {
		"entityTitle": "IV _ Advanced Filter _ Opportunity For ID",
		"ID": "ID",
		"Description": "Description",
		"ExpectedCloseDate": "Expected Close Date",
		"Status": "Status",
		"RepresentativeID": "Representative ID",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Forecast": "Forecast",
		"LeadGeneratorID": "Lead Generator ID",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"BusinessUnitID": "Business Unit ID",
		"PL_QuoteOutcomeReasonID": "PL _ Quote Outcome Reason ID",
		"ReferedByID": "Refered By ID",
		"VisitedShowroom": "Visited Showroom",
		"LJ__Tags": "LJ __ Tags",
		"LJ__ActivityType": "LJ __ Activity Type",
		"LJ__ActivityCategory": "LJ __ Activity Category",
		"LJ__ActivityStart": "LJ __ Activity Start",
		"LJ__ActivityRepresentative": "LJ __ Activity Representative",
		"LJ__ActivityClosed": "LJ __ Activity Closed",
		"LJ__ActivityConfirmed": "LJ __ Activity Confirmed",
		"LJ__CompanyName": "LJ __ Company Name",
		"LJ__OrganisationType": "LJ __ Organisation Type",
		"LJ__CompanyAccountType": "LJ __ Company Account Type",
		"LJ__ContactFirstName": "LJ __ Contact First Name",
		"LJ__ContactLastName": "LJ __ Contact Last Name",
		"LJ__Amount": "LJ __ Amount",
		"LJ__QuoteLastSentDate": "LJ __ Quote Last Sent Date",
		"LJ__QuoteDate": "LJ __ Quote Date",
		"LJ__QuoteExpiryDate": "LJ __ Quote Expiry Date",
		"LJ__JobID": "LJ __ Job ID",
		"LJ__JobSalesRep": "LJ __ Job Sales Rep",
		"LJ__JobStage": "LJ __ Job Stage",
		"LJ__JobTags": "LJ __ Job Tags",
		"LJ__JobCompanyName": "LJ __ Job Company Name",
		"LJ__JobOrganisationType": "LJ __ Job Organisation Type",
		"LJ__JobCompanyAccountType": "LJ __ Job Company Account Type",
		"LJ__JobContactFirstName": "LJ __ Job Contact First Name",
		"LJ__JobContactLastName": "LJ __ Job Contact Last Name",
		"LJ__JobLineETADate": "LJ __ Job Line ETA Date",
		"LJ__JobLineProductID": "LJ __ Job Line Product ID",
		"LJ__JobLineSupplierID": "LJ __ Job Line Supplier ID",
		"LJ__JobLineCMRequired": "LJ __ Job Line CM Required",
		"LJ__JobLineInstallRequired": "LJ __ Job Line Install Required",
		"LJ__JobLinePickupRequired": "LJ __ Job Line Pickup Required",
		"LJ__JobLineDeliveryRequired": "LJ __ Job Line Delivery Required",
		"LJ__JobLineStatus": "LJ __ Job Line Status",
		"LJ__JobLineStage": "LJ __ Job Line Stage",
	},
	"iV_AdvancedFilter_SupplyJobForID": {
		"entityTitle": "IV _ Advanced Filter _ Supply Job For ID",
		"ID": "ID",
		"PurchaseOrderID": "Purchase Order ID",
		"RepresentativeID": "Representative ID",
		"OrderNumber": "Order Number",
		"CustomerReference": "Customer Reference",
		"Status": "Status",
		"SupplierID": "Supplier ID",
		"ETADate": "ETA Date",
		"LJ__Internal": "LJ __ Internal",
		"LJ__SupplyLineProductID": "LJ __ Supply Line Product ID",
		"LJ__SupplyLineStatus": "LJ __ Supply Line Status",
		"LJ__SupplyLineMFDate": "LJ __ Supply Line MF Date",
		"LJ__SupplyLineDepartment": "LJ __ Supply Line Department",
		"LJ__CommitmentReason": "LJ __ Commitment Reason",
		"LJ__HandoverDate": "LJ __ Handover Date",
		"LJ__SalesRepID": "LJ __ Sales Rep ID",
		"LJ__DispatchDate": "LJ __ Dispatch Date",
		"LJ__DispatchBy": "LJ __ Dispatch By",
		"LJ__CompanyName": "LJ __ Company Name",
		"LJ__OrganisationType": "LJ __ Organisation Type",
		"LJ__AccountType": "LJ __ Account Type",
		"LJ__Tags": "LJ __ Tags",
		"LJ__InstallRequired": "LJ __ Install Required",
		"LJ__PickupRequired": "LJ __ Pickup Required",
		"LJ__DeliveryRequired": "LJ __ Delivery Required",
	},
	"iV_JobAmountForID": {
		"entityTitle": "IV _ Job Amount For ID",
		"ID": "ID",
		"OpportunityID": "Opportunity ID",
		"Amount": "Amount",
		"AmountEx": "Amount Ex",
		"Count": "Count",
	},
	"iV_JobLineForID": {
		"entityTitle": "IV _ Job Line For ID",
		"ID": "ID",
		"RequireCM": "Require CM",
		"RequireInstall": "Require Install",
		"RequirePickup": "Require Pickup",
		"RequireDelivery": "Require Delivery",
		"Stage": "Stage",
		"LineType": "Line Type",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"ProductID": "Product ID",
		"SupplierID": "Supplier ID",
		"ETA": "ETA",
		"OrderDate": "Order Date",
		"CommissionDate1": "Commission Date 1",
		"Count": "Count",
	},
	"iV_JobPaidForID": {
		"entityTitle": "IV _ Job Paid For ID",
		"ID": "ID",
		"Paid": "Paid",
		"Count": "Count",
	},
	"job_Activities": {
		"entityTitle": "Job _ Activities",
		"ID": "ID",
		"Subject": "Subject",
		"Start": "Start",
		"AllDay": "All Day",
		"Closed": "Closed",
		"Notes": "Notes",
		"ActivityType_abbrev": "Type",
		"ActivityType": "Activity Type",
		"ActivityCategory_abbrev": "Category",
		"ActivityCategory": "Activity Category",
		"Confirmed": "Confirmed",
		"Representative": "Representative",
		"Priority": "Priority",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity ID",
		"IsInstall": "Is Install",
		"IsPickup": "Is Pickup",
		"IsDelivery": "Is Delivery",
		"IsService": "Is Service",
	},
	"job_Lines": {
		"entityTitle": "Job _ Lines",
		"JobLineNo_abbrev": "Line No",
		"JobLineNo": "Job Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"BasePrice": "Base Price",
		"BaseCost": "Base Cost",
		"InstallPrice": "Install Price",
		"InstallCost": "Install Cost",
		"OptionsPrice": "Options Price",
		"OptionsCost": "Options Cost",
		"DeliveryPrice": "Delivery Price",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentPrice": "Adjustment Price",
		"AdjustmentCost": "Adjustment Cost",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Job": "Job",
		"JobID": "Job",
		"SplitParent": "Job Line",
		"SplitParentID": "Job Line",
		"JobLineService": "Job Line Service",
		"JobLineServiceID": "Job Line Service",
		"RequireCheckMeasure": "Require Check Measure",
		"CM_Approved": "CM _ Approved",
		"Invoiced": "Invoiced",
		"SupplyJobCreated": "Supply Job Created",
		"RequireInstall": "Require Install",
		"RequireDelivery": "Require Delivery",
		"RequirePickup": "Require Pickup",
		"RequireService": "Require Service",
		"Inst_Completed": "Inst _ Completed",
		"EstInstallTime": "Est Install Time",
		"IsService": "Is Service",
		"Srvc_Completed": "Srvc _ Completed",
		"QtyReceived": "Qty Received",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"ProductVersion": "Product Version",
		"PriceListVersion": "Price List Version",
		"SortIndex": "Sort Index",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"InstallationNotes": "Installation Notes",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"PreviousStage": "Previous Stage",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"Stage": "Stage",
		"LineType": "Line Type",
		"StageDate": "Stage Date",
		"OrderLineOptionsData": "Order Line Options Data",
		"JobLineID": "Job Line ID",
		"ValidationErrors": "Validation Errors",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"IsConfirmed": "Is Confirmed",
		"IsDispatched": "Is Dispatched",
		"HasActiveService": "Has Active Service",
		"CommissionDate1": "Commission Date 1",
		"GroupKey": "Group Key",
		"PriceModel": "Price Model",
		"RelatedPOLines": "Related PO Lines",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
	},
	"job_PriceDetails": {
		"entityTitle": "Job _ Price Details",
		"JobLineNo_abbrev": "Line No",
		"JobLineNo": "Job Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"BasePrice": "Base Price",
		"BaseCost": "Base Cost",
		"InstallPrice": "Install Price",
		"InstallCost": "Install Cost",
		"OptionsPrice": "Options Price",
		"OptionsCost": "Options Cost",
		"DeliveryPrice": "Delivery Price",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentPrice": "Adjustment Price",
		"AdjustmentCost": "Adjustment Cost",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Job": "Job",
		"JobID": "Job",
		"SplitParent": "Job Line",
		"SplitParentID": "Job Line",
		"JobLineService": "Job Line Service",
		"JobLineServiceID": "Job Line Service",
		"RequireCheckMeasure": "Require Check Measure",
		"CM_Approved": "CM _ Approved",
		"Invoiced": "Invoiced",
		"SupplyJobCreated": "Supply Job Created",
		"RequireInstall": "Require Install",
		"RequireDelivery": "Require Delivery",
		"RequirePickup": "Require Pickup",
		"RequireService": "Require Service",
		"Inst_Completed": "Inst _ Completed",
		"EstInstallTime": "Est Install Time",
		"IsService": "Is Service",
		"Srvc_Completed": "Srvc _ Completed",
		"QtyReceived": "Qty Received",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"ProductVersion": "Product Version",
		"PriceListVersion": "Price List Version",
		"SortIndex": "Sort Index",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"InstallationNotes": "Installation Notes",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"PreviousStage": "Previous Stage",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"Stage": "Stage",
		"LineType": "Line Type",
		"StageDate": "Stage Date",
		"OrderLineOptionsData": "Order Line Options Data",
		"JobLineID": "Job Line ID",
		"ValidationErrors": "Validation Errors",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"IsConfirmed": "Is Confirmed",
		"IsDispatched": "Is Dispatched",
		"HasActiveService": "Has Active Service",
		"CommissionDate1": "Commission Date 1",
		"GroupKey": "Group Key",
		"PriceModel": "Price Model",
		"RelatedPOLines": "Related PO Lines",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
	},
	"job_ReceiveLines": {
		"entityTitle": "Job _ Receive Lines",
		"JobLineNo_abbrev": "Line No",
		"JobLineNo": "Job Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"BasePrice": "Base Price",
		"BaseCost": "Base Cost",
		"InstallPrice": "Install Price",
		"InstallCost": "Install Cost",
		"OptionsPrice": "Options Price",
		"OptionsCost": "Options Cost",
		"DeliveryPrice": "Delivery Price",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentPrice": "Adjustment Price",
		"AdjustmentCost": "Adjustment Cost",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Job": "Job",
		"JobID": "Job",
		"SplitParent": "Job Line",
		"SplitParentID": "Job Line",
		"JobLineService": "Job Line Service",
		"JobLineServiceID": "Job Line Service",
		"RequireCheckMeasure": "Require Check Measure",
		"CM_Approved": "CM _ Approved",
		"Invoiced": "Invoiced",
		"SupplyJobCreated": "Supply Job Created",
		"RequireInstall": "Require Install",
		"RequireDelivery": "Require Delivery",
		"RequirePickup": "Require Pickup",
		"RequireService": "Require Service",
		"Inst_Completed": "Inst _ Completed",
		"EstInstallTime": "Est Install Time",
		"IsService": "Is Service",
		"Srvc_Completed": "Srvc _ Completed",
		"QtyReceived": "Qty Received",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"ProductVersion": "Product Version",
		"PriceListVersion": "Price List Version",
		"SortIndex": "Sort Index",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"InstallationNotes": "Installation Notes",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"PreviousStage": "Previous Stage",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"Stage": "Stage",
		"LineType": "Line Type",
		"StageDate": "Stage Date",
		"OrderLineOptionsData": "Order Line Options Data",
		"JobLineID": "Job Line ID",
		"ValidationErrors": "Validation Errors",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"IsConfirmed": "Is Confirmed",
		"IsDispatched": "Is Dispatched",
		"HasActiveService": "Has Active Service",
		"CommissionDate1": "Commission Date 1",
		"GroupKey": "Group Key",
		"PriceModel": "Price Model",
		"RelatedPOLines": "Related PO Lines",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
	},
	"jobDetailExport": {
		"entityTitle": "Job Detail Export",
		"JobID": "Job ID",
		"Reference": "Reference",
		"Stage": "Stage",
		"JobType": "Job Type",
		"SalesRep": "Sales Rep",
		"CustomStatus": "Custom Status",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"City": "City",
		"CMBy": "CM By",
		"InstallBy": "Install By",
		"LineNo": "Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Qty": "Qty",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"Product": "Product",
		"Supplier": "Supplier",
		"LineStage": "Line Stage",
		"OrderLineOptionsData": "Order Line Options Data",
		"CMDate": "CM Date",
		"InstallDate": "Install Date",
		"Paid": "Paid",
		"Tags": "Tags",
	},
	"jobDetailExportForID": {
		"entityTitle": "Job Detail Export For ID",
		"JobID": "Job ID",
		"Reference": "Reference",
		"Stage": "Stage",
		"JobType": "Job Type",
		"SalesRep": "Sales Rep",
		"CustomStatus": "Custom Status",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"City": "City",
		"CMBy": "CM By",
		"InstallBy": "Install By",
		"LineNo": "Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Qty": "Qty",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"Product": "Product",
		"Supplier": "Supplier",
		"LineStage": "Line Stage",
		"OrderLineOptionsData": "Order Line Options Data",
		"CMDate": "CM Date",
		"InstallDate": "Install Date",
		"Paid": "Paid",
		"Tags": "Tags",
	},
	"jobDiscountRuleForID": {
		"entityTitle": "Job Discount Rule For ID",
		"Job": "Job",
		"JobID": "Job",
		"TaxIncluded": "Tax Included",
		"IsPercentage": "Is Percentage",
		"Level": "Level",
		"Type": "Type",
		"PriceType": "Price Type",
		"AmountType": "Amount Type",
		"ProductID": "Product ID",
		"JobLineID": "Job Line ID",
		"ProductOptionID": "Product Option ID",
		"ProductOptionValueID": "Product Option Value ID",
		"Value": "Value",
	},
	"jobDiscountRules": {
		"entityTitle": "Job Discount Rules",
		"Job": "Job",
		"JobID": "Job",
		"TaxIncluded": "Tax Included",
		"IsPercentage": "Is Percentage",
		"Level": "Level",
		"Type": "Type",
		"PriceType": "Price Type",
		"AmountType": "Amount Type",
		"ProductID": "Product ID",
		"JobLineID": "Job Line ID",
		"ProductOptionID": "Product Option ID",
		"ProductOptionValueID": "Product Option Value ID",
		"Value": "Value",
	},
	"jobForID": {
		"entityTitle": "Job For ID",
		"JobID": "Job ID",
		"QuoteExpiryDate_abbrev": "Expiry Date",
		"QuoteExpiryDate": "Quote Expiry Date",
		"HandoverDate": "Handover Date",
		"QuoteSentDate_abbrev": "Quote Sent",
		"QuoteSentDate": "Quote Sent Date",
		"Forecasted": "Forecasted",
		"IsConfirmed": "Is Confirmed",
		"IsClosed": "Is Closed",
		"IsLost": "Is Lost",
		"IgnoreDepositLimit": "Ignore Deposit Limit",
		"Status": "Job Custom Status",
		"PaymentTerms": "Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"InvoiceDueDays": "Invoice Due Days",
		"SupplyNotes": "Supply Notes",
		"Reference": "Reference",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"DispatchSummary": "Dispatch Summary",
		"ProductSummary": "Product Summary",
		"StageSummary": "Stage Summary",
		"JobDate": "Job Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"EstETADate": "Est ETA Date",
		"OrderDate": "Order Date",
		"Stage": "Stage",
		"JobType": "Job Type",
		"Priority": "Priority",
		"ShowroomQuote": "Showroom Quote",
		"InstallNotes": "Install Notes",
		"InternalNotes": "Internal Notes",
		"CustomerNotes": "Customer Notes",
		"PriceChangedWarning": "Price Changed Warning",
		"ProductOrder": "Product Order",
		"SalesRep": "Representative",
		"SalesRepID": "Representative",
		"MarginApproved": "Representative",
		"MarginApprovedBy": "Representative",
		"MarginApprovedByID": "Representative",
		"MarginApprovalRequired": "Margin Approval Required",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"Contact": "Contact",
		"ContactID": "Contact",
		"SiteContact": "Contact",
		"SiteContactID": "Contact",
		"Parent": "Job",
		"ParentID": "Job",
		"ParentGroupKey": "Parent Group Key",
		"Company": "Company",
		"CompanyID": "Company",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"Actions": "Advanced Filters . Views . IV _ Job Actions",
		"ActionsID": "Advanced Filters . Views . IV _ Job Actions",
		"JobLines": "Job Lines",
		"DiscountRules": "Discount Rules",
		"CustomStatus": "Job Custom Status",
		"PL_CustomStatusID_abbrev": "Custom Status",
		"PL_CustomStatusID": "Job Custom Status",
		"CommitmentReason": "Commitment Reason",
		"PL_CommitmentReasonID_abbrev": "Commit Reason",
		"PL_CommitmentReasonID": "Commitment Reason",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"NoSalesCommission": "No Sales Commission",
		"FileNotes": "File Notes",
		"Documents": "Documents",
		"Tags": "Tags",
		"ReportSettings": "Report Settings",
		"Tracking": "Tracking",
		"TrackingID": "Tracking",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"TaxRateValue": "Tax Rate Value",
		"CompanyPriceMappings": "Company Price Mappings",
	},
	"jobGridView": {
		"entityTitle": "Job Grid View",
		"Reference": "Reference",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"Company": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"SalesRep": "Sales Rep",
		"JobDate": "Job Date",
		"Stage": "Stage",
		"Stages": "Stages",
		"JobType": "Job Type",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"BusinessUnit": "Business Unit",
		"Expiry": "Expiry",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
		"MinOrderDate": "Min Order Date",
		"MaxOrderDate": "Max Order Date",
		"CustomStatus": "Custom Status",
		"SalesPriceInc_abbrev": "Sales Inc",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
	},
	"jobGridViewForID": {
		"entityTitle": "Job Grid View For ID",
		"Reference": "Reference",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"Company": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"SalesRep": "Sales Rep",
		"JobDate": "Job Date",
		"Stage": "Stage",
		"Stages": "Stages",
		"JobType": "Job Type",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"BusinessUnit": "Business Unit",
		"Expiry": "Expiry",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
		"MinOrderDate": "Min Order Date",
		"MaxOrderDate": "Max Order Date",
		"CustomStatus": "Custom Status",
		"SalesPriceInc_abbrev": "Sales Inc",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
	},
	"jobLine_SelectList": {
		"entityTitle": "Job Line _ Select List",
		"JobLineNo_abbrev": "Line No",
		"JobLineNo": "Job Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"BasePrice": "Base Price",
		"BaseCost": "Base Cost",
		"InstallPrice": "Install Price",
		"InstallCost": "Install Cost",
		"OptionsPrice": "Options Price",
		"OptionsCost": "Options Cost",
		"DeliveryPrice": "Delivery Price",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentPrice": "Adjustment Price",
		"AdjustmentCost": "Adjustment Cost",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Job": "Job",
		"JobID": "Job",
		"SplitParent": "Job Line",
		"SplitParentID": "Job Line",
		"JobLineService": "Job Line Service",
		"JobLineServiceID": "Job Line Service",
		"RequireCheckMeasure": "Require Check Measure",
		"CM_Approved": "CM _ Approved",
		"Invoiced": "Invoiced",
		"SupplyJobCreated": "Supply Job Created",
		"RequireInstall": "Require Install",
		"RequireDelivery": "Require Delivery",
		"RequirePickup": "Require Pickup",
		"RequireService": "Require Service",
		"Inst_Completed": "Inst _ Completed",
		"EstInstallTime": "Est Install Time",
		"IsService": "Is Service",
		"Srvc_Completed": "Srvc _ Completed",
		"QtyReceived": "Qty Received",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"ProductVersion": "Product Version",
		"PriceListVersion": "Price List Version",
		"SortIndex": "Sort Index",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"InstallationNotes": "Installation Notes",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"PreviousStage": "Previous Stage",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"Stage": "Stage",
		"LineType": "Line Type",
		"StageDate": "Stage Date",
		"OrderLineOptionsData": "Order Line Options Data",
		"JobLineID": "Job Line ID",
		"ValidationErrors": "Validation Errors",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"IsConfirmed": "Is Confirmed",
		"IsDispatched": "Is Dispatched",
		"HasActiveService": "Has Active Service",
		"CommissionDate1": "Commission Date 1",
		"GroupKey": "Group Key",
		"PriceModel": "Price Model",
		"RelatedPOLines": "Related PO Lines",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
	},
	"jobLineForID": {
		"entityTitle": "Job Line For ID",
		"JobLineNo_abbrev": "Line No",
		"JobLineNo": "Job Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"BasePrice": "Base Price",
		"BaseCost": "Base Cost",
		"InstallPrice": "Install Price",
		"InstallCost": "Install Cost",
		"OptionsPrice": "Options Price",
		"OptionsCost": "Options Cost",
		"DeliveryPrice": "Delivery Price",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentPrice": "Adjustment Price",
		"AdjustmentCost": "Adjustment Cost",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Job": "Job",
		"JobID": "Job",
		"SplitParent": "Job Line",
		"SplitParentID": "Job Line",
		"JobLineService": "Job Line Service",
		"JobLineServiceID": "Job Line Service",
		"RequireCheckMeasure": "Require Check Measure",
		"CM_Approved": "CM _ Approved",
		"Invoiced": "Invoiced",
		"SupplyJobCreated": "Supply Job Created",
		"RequireInstall": "Require Install",
		"RequireDelivery": "Require Delivery",
		"RequirePickup": "Require Pickup",
		"RequireService": "Require Service",
		"Inst_Completed": "Inst _ Completed",
		"EstInstallTime": "Est Install Time",
		"IsService": "Is Service",
		"Srvc_Completed": "Srvc _ Completed",
		"QtyReceived": "Qty Received",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"ProductVersion": "Product Version",
		"PriceListVersion": "Price List Version",
		"SortIndex": "Sort Index",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"InstallationNotes": "Installation Notes",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"PreviousStage": "Previous Stage",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"Stage": "Stage",
		"LineType": "Line Type",
		"StageDate": "Stage Date",
		"OrderLineOptionsData": "Order Line Options Data",
		"JobLineID": "Job Line ID",
		"ValidationErrors": "Validation Errors",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"IsConfirmed": "Is Confirmed",
		"IsDispatched": "Is Dispatched",
		"HasActiveService": "Has Active Service",
		"CommissionDate1": "Commission Date 1",
		"GroupKey": "Group Key",
		"PriceModel": "Price Model",
		"RelatedPOLines": "Related PO Lines",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
	},
	"jobLineInfoForID": {
		"entityTitle": "Job Line Info For ID",
		"JobID": "Job ID",
		"CheckMeasureID": "Check Measure ID",
		"CM_PL_ActivityCategoryID": "CM _ PL _ Activity Category ID",
		"CM_ActivityType": "CM _ Activity Type",
		"CM_Representative": "CM _ Representative",
		"CM_RepresentativeID": "CM _ Representative ID",
		"CM_Team": "CM _ Team",
		"CM_TeamID": "CM _ Team ID",
		"CM_Subject": "CM _ Subject",
		"CM_Priority": "CM _ Priority",
		"CM_Confirmed": "CM _ Confirmed",
		"CM_Closed": "CM _ Closed",
		"CM_ContactID": "CM _ Contact ID",
		"CM_Start": "CM _ Start",
		"CM_CreatedOn": "CM _ Created On",
		"InstallID": "Install ID",
		"IA_PL_ActivityCategoryID": "IA _ PL _ Activity Category ID",
		"IA_ActivityType": "IA _ Activity Type",
		"IA_RepresentativeID": "IA _ Representative ID",
		"IA_Representative": "IA _ Representative",
		"IA_TeamID": "IA _ Team ID",
		"IA_Team": "IA _ Team",
		"IA_Subject": "IA _ Subject",
		"IA_Priority": "IA _ Priority",
		"IA_Confirmed": "IA _ Confirmed",
		"IA_Closed": "IA _ Closed",
		"IA_ContactID": "IA _ Contact ID",
		"IA_Start": "IA _ Start",
		"IA_CreatedOn": "IA _ Created On",
		"Tracking_IsNotValid": "Tracking _ Is Not Valid",
	},
	"jobLineInfos": {
		"entityTitle": "Job Line Infos",
		"JobID": "Job ID",
		"CheckMeasureID": "Check Measure ID",
		"CM_PL_ActivityCategoryID": "CM _ PL _ Activity Category ID",
		"CM_ActivityType": "CM _ Activity Type",
		"CM_Representative": "CM _ Representative",
		"CM_RepresentativeID": "CM _ Representative ID",
		"CM_Team": "CM _ Team",
		"CM_TeamID": "CM _ Team ID",
		"CM_Subject": "CM _ Subject",
		"CM_Priority": "CM _ Priority",
		"CM_Confirmed": "CM _ Confirmed",
		"CM_Closed": "CM _ Closed",
		"CM_ContactID": "CM _ Contact ID",
		"CM_Start": "CM _ Start",
		"CM_CreatedOn": "CM _ Created On",
		"InstallID": "Install ID",
		"IA_PL_ActivityCategoryID": "IA _ PL _ Activity Category ID",
		"IA_ActivityType": "IA _ Activity Type",
		"IA_RepresentativeID": "IA _ Representative ID",
		"IA_Representative": "IA _ Representative",
		"IA_TeamID": "IA _ Team ID",
		"IA_Team": "IA _ Team",
		"IA_Subject": "IA _ Subject",
		"IA_Priority": "IA _ Priority",
		"IA_Confirmed": "IA _ Confirmed",
		"IA_Closed": "IA _ Closed",
		"IA_ContactID": "IA _ Contact ID",
		"IA_Start": "IA _ Start",
		"IA_CreatedOn": "IA _ Created On",
		"Tracking_IsNotValid": "Tracking _ Is Not Valid",
	},
	"jobLineOrderLineForID": {
		"entityTitle": "Job Line Order Line For ID",
		"OrderLine": "Order Line",
		"OrderLineID": "Order Line",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"CostRatio": "Cost Ratio",
	},
	"jobLineOrderLines": {
		"entityTitle": "Job Line Order Lines",
		"OrderLine": "Order Line",
		"OrderLineID": "Order Line",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"CostRatio": "Cost Ratio",
	},
	"jobLines": {
		"entityTitle": "Job Lines",
		"JobLineNo_abbrev": "Line No",
		"JobLineNo": "Job Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"BasePrice": "Base Price",
		"BaseCost": "Base Cost",
		"InstallPrice": "Install Price",
		"InstallCost": "Install Cost",
		"OptionsPrice": "Options Price",
		"OptionsCost": "Options Cost",
		"DeliveryPrice": "Delivery Price",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentPrice": "Adjustment Price",
		"AdjustmentCost": "Adjustment Cost",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Job": "Job",
		"JobID": "Job",
		"SplitParent": "Job Line",
		"SplitParentID": "Job Line",
		"JobLineService": "Job Line Service",
		"JobLineServiceID": "Job Line Service",
		"RequireCheckMeasure": "Require Check Measure",
		"CM_Approved": "CM _ Approved",
		"Invoiced": "Invoiced",
		"SupplyJobCreated": "Supply Job Created",
		"RequireInstall": "Require Install",
		"RequireDelivery": "Require Delivery",
		"RequirePickup": "Require Pickup",
		"RequireService": "Require Service",
		"Inst_Completed": "Inst _ Completed",
		"EstInstallTime": "Est Install Time",
		"IsService": "Is Service",
		"Srvc_Completed": "Srvc _ Completed",
		"QtyReceived": "Qty Received",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"ProductVersion": "Product Version",
		"PriceListVersion": "Price List Version",
		"SortIndex": "Sort Index",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"InstallationNotes": "Installation Notes",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"PreviousStage": "Previous Stage",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"Stage": "Stage",
		"LineType": "Line Type",
		"StageDate": "Stage Date",
		"OrderLineOptionsData": "Order Line Options Data",
		"JobLineID": "Job Line ID",
		"ValidationErrors": "Validation Errors",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"IsConfirmed": "Is Confirmed",
		"IsDispatched": "Is Dispatched",
		"HasActiveService": "Has Active Service",
		"CommissionDate1": "Commission Date 1",
		"GroupKey": "Group Key",
		"PriceModel": "Price Model",
		"RelatedPOLines": "Related PO Lines",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
	},
	"jobLineServiceForID": {
		"entityTitle": "Job Line Service For ID",
		"Job": "Job",
		"JobID": "Job",
		"JobLine": "Job",
		"JobLineID": "Job",
		"ChargeAmount": "Charge Amount",
		"ReasonNotes": "Reason Notes",
		"UserAtFault": "Representative",
		"UserAtFaultID": "Representative",
		"SupplierAtFault": "Supplier",
		"SupplierAtFaultID": "Supplier",
		"FaultReason": "Fault Reason",
		"PL_FaultReasonID_abbrev": "Fault Reason",
		"PL_FaultReasonID": "Fault Reason",
	},
	"jobLineServices": {
		"entityTitle": "Job Line Services",
		"Job": "Job",
		"JobID": "Job",
		"JobLine": "Job",
		"JobLineID": "Job",
		"ChargeAmount": "Charge Amount",
		"ReasonNotes": "Reason Notes",
		"UserAtFault": "Representative",
		"UserAtFaultID": "Representative",
		"SupplierAtFault": "Supplier",
		"SupplierAtFaultID": "Supplier",
		"FaultReason": "Fault Reason",
		"PL_FaultReasonID_abbrev": "Fault Reason",
		"PL_FaultReasonID": "Fault Reason",
	},
	"jobLinesForJob": {
		"entityTitle": "Job Lines For Job",
		"JobLineNo_abbrev": "Line No",
		"JobLineNo": "Job Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"BasePrice": "Base Price",
		"BaseCost": "Base Cost",
		"InstallPrice": "Install Price",
		"InstallCost": "Install Cost",
		"OptionsPrice": "Options Price",
		"OptionsCost": "Options Cost",
		"DeliveryPrice": "Delivery Price",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentPrice": "Adjustment Price",
		"AdjustmentCost": "Adjustment Cost",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Job": "Job",
		"JobID": "Job",
		"SplitParent": "Job Line",
		"SplitParentID": "Job Line",
		"JobLineService": "Job Line Service",
		"JobLineServiceID": "Job Line Service",
		"RequireCheckMeasure": "Require Check Measure",
		"CM_Approved": "CM _ Approved",
		"Invoiced": "Invoiced",
		"SupplyJobCreated": "Supply Job Created",
		"RequireInstall": "Require Install",
		"RequireDelivery": "Require Delivery",
		"RequirePickup": "Require Pickup",
		"RequireService": "Require Service",
		"Inst_Completed": "Inst _ Completed",
		"EstInstallTime": "Est Install Time",
		"IsService": "Is Service",
		"Srvc_Completed": "Srvc _ Completed",
		"QtyReceived": "Qty Received",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"ProductVersion": "Product Version",
		"PriceListVersion": "Price List Version",
		"SortIndex": "Sort Index",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"InstallationNotes": "Installation Notes",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"PreviousStage": "Previous Stage",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"Stage": "Stage",
		"LineType": "Line Type",
		"StageDate": "Stage Date",
		"OrderLineOptionsData": "Order Line Options Data",
		"JobLineID": "Job Line ID",
		"ValidationErrors": "Validation Errors",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"IsConfirmed": "Is Confirmed",
		"IsDispatched": "Is Dispatched",
		"HasActiveService": "Has Active Service",
		"CommissionDate1": "Commission Date 1",
		"GroupKey": "Group Key",
		"PriceModel": "Price Model",
		"RelatedPOLines": "Related PO Lines",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
	},
	"jobMultiLineView": {
		"entityTitle": "Job Multi Line View",
		"JobID": "Job ID",
		"QuoteExpiryDate_abbrev": "Expiry Date",
		"QuoteExpiryDate": "Quote Expiry Date",
		"HandoverDate": "Handover Date",
		"QuoteSentDate_abbrev": "Quote Sent",
		"QuoteSentDate": "Quote Sent Date",
		"Forecasted": "Forecasted",
		"IsConfirmed": "Is Confirmed",
		"IsClosed": "Is Closed",
		"IsLost": "Is Lost",
		"IgnoreDepositLimit": "Ignore Deposit Limit",
		"Status": "Job Custom Status",
		"PaymentTerms": "Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"InvoiceDueDays": "Invoice Due Days",
		"SupplyNotes": "Supply Notes",
		"Reference": "Reference",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"DispatchSummary": "Dispatch Summary",
		"ProductSummary": "Product Summary",
		"StageSummary": "Stage Summary",
		"JobDate": "Job Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"EstETADate": "Est ETA Date",
		"OrderDate": "Order Date",
		"Stage": "Stage",
		"JobType": "Job Type",
		"Priority": "Priority",
		"ShowroomQuote": "Showroom Quote",
		"InstallNotes": "Install Notes",
		"InternalNotes": "Internal Notes",
		"CustomerNotes": "Customer Notes",
		"PriceChangedWarning": "Price Changed Warning",
		"ProductOrder": "Product Order",
		"SalesRep": "Representative",
		"SalesRepID": "Representative",
		"MarginApproved": "Representative",
		"MarginApprovedBy": "Representative",
		"MarginApprovedByID": "Representative",
		"MarginApprovalRequired": "Margin Approval Required",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"Contact": "Contact",
		"ContactID": "Contact",
		"SiteContact": "Contact",
		"SiteContactID": "Contact",
		"Parent": "Job",
		"ParentID": "Job",
		"ParentGroupKey": "Parent Group Key",
		"Company": "Company",
		"CompanyID": "Company",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"Actions": "Advanced Filters . Views . IV _ Job Actions",
		"ActionsID": "Advanced Filters . Views . IV _ Job Actions",
		"JobLines": "Job Lines",
		"DiscountRules": "Discount Rules",
		"CustomStatus": "Job Custom Status",
		"PL_CustomStatusID_abbrev": "Custom Status",
		"PL_CustomStatusID": "Job Custom Status",
		"CommitmentReason": "Commitment Reason",
		"PL_CommitmentReasonID_abbrev": "Commit Reason",
		"PL_CommitmentReasonID": "Commitment Reason",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"NoSalesCommission": "No Sales Commission",
		"FileNotes": "File Notes",
		"Documents": "Documents",
		"Tags": "Tags",
		"ReportSettings": "Report Settings",
		"Tracking": "Tracking",
		"TrackingID": "Tracking",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"TaxRateValue": "Tax Rate Value",
		"CompanyPriceMappings": "Company Price Mappings",
	},
	"jobs": {
		"entityTitle": "Jobs",
		"JobID": "Job ID",
		"QuoteExpiryDate_abbrev": "Expiry Date",
		"QuoteExpiryDate": "Quote Expiry Date",
		"HandoverDate": "Handover Date",
		"QuoteSentDate_abbrev": "Quote Sent",
		"QuoteSentDate": "Quote Sent Date",
		"Forecasted": "Forecasted",
		"IsConfirmed": "Is Confirmed",
		"IsClosed": "Is Closed",
		"IsLost": "Is Lost",
		"IgnoreDepositLimit": "Ignore Deposit Limit",
		"Status": "Job Custom Status",
		"PaymentTerms": "Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"InvoiceDueDays": "Invoice Due Days",
		"SupplyNotes": "Supply Notes",
		"Reference": "Reference",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"DispatchSummary": "Dispatch Summary",
		"ProductSummary": "Product Summary",
		"StageSummary": "Stage Summary",
		"JobDate": "Job Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"EstETADate": "Est ETA Date",
		"OrderDate": "Order Date",
		"Stage": "Stage",
		"JobType": "Job Type",
		"Priority": "Priority",
		"ShowroomQuote": "Showroom Quote",
		"InstallNotes": "Install Notes",
		"InternalNotes": "Internal Notes",
		"CustomerNotes": "Customer Notes",
		"PriceChangedWarning": "Price Changed Warning",
		"ProductOrder": "Product Order",
		"SalesRep": "Representative",
		"SalesRepID": "Representative",
		"MarginApproved": "Representative",
		"MarginApprovedBy": "Representative",
		"MarginApprovedByID": "Representative",
		"MarginApprovalRequired": "Margin Approval Required",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"Contact": "Contact",
		"ContactID": "Contact",
		"SiteContact": "Contact",
		"SiteContactID": "Contact",
		"Parent": "Job",
		"ParentID": "Job",
		"ParentGroupKey": "Parent Group Key",
		"Company": "Company",
		"CompanyID": "Company",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"Actions": "Advanced Filters . Views . IV _ Job Actions",
		"ActionsID": "Advanced Filters . Views . IV _ Job Actions",
		"JobLines": "Job Lines",
		"DiscountRules": "Discount Rules",
		"CustomStatus": "Job Custom Status",
		"PL_CustomStatusID_abbrev": "Custom Status",
		"PL_CustomStatusID": "Job Custom Status",
		"CommitmentReason": "Commitment Reason",
		"PL_CommitmentReasonID_abbrev": "Commit Reason",
		"PL_CommitmentReasonID": "Commitment Reason",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"NoSalesCommission": "No Sales Commission",
		"FileNotes": "File Notes",
		"Documents": "Documents",
		"Tags": "Tags",
		"ReportSettings": "Report Settings",
		"Tracking": "Tracking",
		"TrackingID": "Tracking",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"TaxRateValue": "Tax Rate Value",
		"CompanyPriceMappings": "Company Price Mappings",
	},
	"jobs_For_Rep_Month": {
		"entityTitle": "Jobs _ For _ Rep _ Month",
		"Reference": "Reference",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"Company": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"SalesRep": "Sales Rep",
		"JobDate": "Job Date",
		"Stage": "Stage",
		"Stages": "Stages",
		"JobType": "Job Type",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"BusinessUnit": "Business Unit",
		"Expiry": "Expiry",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
		"MinOrderDate": "Min Order Date",
		"MaxOrderDate": "Max Order Date",
		"CustomStatus": "Custom Status",
		"SalesPriceInc_abbrev": "Sales Inc",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
	},
	"jobsForCompany": {
		"entityTitle": "Jobs For Company",
		"Reference": "Reference",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"Company": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"SalesRep": "Sales Rep",
		"JobDate": "Job Date",
		"Stage": "Stage",
		"Stages": "Stages",
		"JobType": "Job Type",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"BusinessUnit": "Business Unit",
		"Expiry": "Expiry",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
		"MinOrderDate": "Min Order Date",
		"MaxOrderDate": "Max Order Date",
		"CustomStatus": "Custom Status",
		"SalesPriceInc_abbrev": "Sales Inc",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
	},
	"jobsForContact": {
		"entityTitle": "Jobs For Contact",
		"Reference": "Reference",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"Company": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"SalesRep": "Sales Rep",
		"JobDate": "Job Date",
		"Stage": "Stage",
		"Stages": "Stages",
		"JobType": "Job Type",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"BusinessUnit": "Business Unit",
		"Expiry": "Expiry",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
		"MinOrderDate": "Min Order Date",
		"MaxOrderDate": "Max Order Date",
		"CustomStatus": "Custom Status",
		"SalesPriceInc_abbrev": "Sales Inc",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
	},
	"jobsForOpportunity": {
		"entityTitle": "Jobs For Opportunity",
		"Reference": "Reference",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"Company": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"SalesRep": "Sales Rep",
		"JobDate": "Job Date",
		"Stage": "Stage",
		"Stages": "Stages",
		"JobType": "Job Type",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"BusinessUnit": "Business Unit",
		"Expiry": "Expiry",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
		"MinOrderDate": "Min Order Date",
		"MaxOrderDate": "Max Order Date",
		"CustomStatus": "Custom Status",
		"SalesPriceInc_abbrev": "Sales Inc",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
	},
	"jobWithTaxRateForID": {
		"entityTitle": "Job With Tax Rate For ID",
		"JobID": "Job ID",
		"QuoteExpiryDate_abbrev": "Expiry Date",
		"QuoteExpiryDate": "Quote Expiry Date",
		"HandoverDate": "Handover Date",
		"QuoteSentDate_abbrev": "Quote Sent",
		"QuoteSentDate": "Quote Sent Date",
		"Forecasted": "Forecasted",
		"IsConfirmed": "Is Confirmed",
		"IsClosed": "Is Closed",
		"IsLost": "Is Lost",
		"IgnoreDepositLimit": "Ignore Deposit Limit",
		"Status": "Job Custom Status",
		"PaymentTerms": "Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"InvoiceDueDays": "Invoice Due Days",
		"SupplyNotes": "Supply Notes",
		"Reference": "Reference",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"DispatchSummary": "Dispatch Summary",
		"ProductSummary": "Product Summary",
		"StageSummary": "Stage Summary",
		"JobDate": "Job Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"EstETADate": "Est ETA Date",
		"OrderDate": "Order Date",
		"Stage": "Stage",
		"JobType": "Job Type",
		"Priority": "Priority",
		"ShowroomQuote": "Showroom Quote",
		"InstallNotes": "Install Notes",
		"InternalNotes": "Internal Notes",
		"CustomerNotes": "Customer Notes",
		"PriceChangedWarning": "Price Changed Warning",
		"ProductOrder": "Product Order",
		"SalesRep": "Representative",
		"SalesRepID": "Representative",
		"MarginApproved": "Representative",
		"MarginApprovedBy": "Representative",
		"MarginApprovedByID": "Representative",
		"MarginApprovalRequired": "Margin Approval Required",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"Contact": "Contact",
		"ContactID": "Contact",
		"SiteContact": "Contact",
		"SiteContactID": "Contact",
		"Parent": "Job",
		"ParentID": "Job",
		"ParentGroupKey": "Parent Group Key",
		"Company": "Company",
		"CompanyID": "Company",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"Actions": "Advanced Filters . Views . IV _ Job Actions",
		"ActionsID": "Advanced Filters . Views . IV _ Job Actions",
		"JobLines": "Job Lines",
		"DiscountRules": "Discount Rules",
		"CustomStatus": "Job Custom Status",
		"PL_CustomStatusID_abbrev": "Custom Status",
		"PL_CustomStatusID": "Job Custom Status",
		"CommitmentReason": "Commitment Reason",
		"PL_CommitmentReasonID_abbrev": "Commit Reason",
		"PL_CommitmentReasonID": "Commitment Reason",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"NoSalesCommission": "No Sales Commission",
		"FileNotes": "File Notes",
		"Documents": "Documents",
		"Tags": "Tags",
		"ReportSettings": "Report Settings",
		"Tracking": "Tracking",
		"TrackingID": "Tracking",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"TaxRateValue": "Tax Rate Value",
		"CompanyPriceMappings": "Company Price Mappings",
	},
	"journalEntries": {
		"entityTitle": "Journal Entries",
		"EntryDate": "Entry Date",
		"TransferDate": "Transfer Date",
		"Amount": "Amount",
		"Tax": "Tax Rate",
		"Type": "Type",
		"Notes": "Notes",
		"JournalEntryID": "Journal Entry ID",
		"GroupID": "Group ID",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
	},
	"journalEntryForID": {
		"entityTitle": "Journal Entry For ID",
		"EntryDate": "Entry Date",
		"TransferDate": "Transfer Date",
		"Amount": "Amount",
		"Tax": "Tax Rate",
		"Type": "Type",
		"Notes": "Notes",
		"JournalEntryID": "Journal Entry ID",
		"GroupID": "Group ID",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
	},
	"leadIntegrationSettingsRecord": {
		"entityTitle": "Lead Integration Settings Record",
		"Settings": "Settings",
	},
	"leads_Search": {
		"entityTitle": "Leads _ Search",
		"ID": "ID",
		"LastName": "Last Name",
		"FirstName": "First Name",
		"Email": "Email",
		"WorkPhone": "Work Phone",
		"HomePhone": "Home Phone",
		"Mobile": "Mobile",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"JobCategory": "Job Category",
		"JobTitle": "Job Title",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"CustomerName": "Customer Name",
		"CompanyName": "Company Name",
		"BillingContactName": "Billing Contact Name",
	},
	"localeCountry_SelectList": {
		"entityTitle": "Locale Country _ Select List",
		"ID": "ID",
		"Description": "Description",
	},
	"lookupTableForID": {
		"entityTitle": "Lookup Table For ID",
		"Name": "Name",
	},
	"lookupTableItemForID": {
		"entityTitle": "Lookup Table Item For ID",
		"LookupTable": "Lookup Table",
		"LookupTableID": "Lookup Table",
		"Notes": "Notes",
		"From": "From",
		"To": "To",
		"Text": "Text",
		"ProductID": "Product ID",
		"RepresentativeID": "Representative ID",
		"Value": "Value",
	},
	"lookupTableItems": {
		"entityTitle": "Lookup Table Items",
		"LookupTable": "Lookup Table",
		"LookupTableID": "Lookup Table",
		"Notes": "Notes",
		"From": "From",
		"To": "To",
		"Text": "Text",
		"ProductID": "Product ID",
		"RepresentativeID": "Representative ID",
		"Value": "Value",
	},
	"lookupTables": {
		"entityTitle": "Lookup Tables",
		"Name": "Name",
	},
	"mFSchedulingTrackingViewForID": {
		"entityTitle": "MF Scheduling Tracking View For ID",
		"PurchaseOrderID": "Purchase Order ID",
		"OrderNumber": "Order Number",
		"JobID": "Job ID",
		"Job": "Job",
		"CustomerReference": "Customer Reference",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Supplier": "Supplier",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"Product": "Product",
		"Department": "Department",
		"DispatchDate": "Dispatch Date",
		"ETADate": "ETA Date",
		"Status": "Status",
		"MFDate": "MF Date",
		"OrderDate": "Order Date",
		"Internal": "Internal",
		"Qty": "Qty",
		"JobQty": "Job Qty",
		"DispatchDays": "Dispatch Days",
		"Age": "Age",
		"IDs": "I Ds",
		"SupplyNotes": "Supply Notes",
	},
	"mFSchedulingTrackingViews": {
		"entityTitle": "MF Scheduling Tracking Views",
		"PurchaseOrderID": "Purchase Order ID",
		"OrderNumber": "Order Number",
		"JobID": "Job ID",
		"Job": "Job",
		"CustomerReference": "Customer Reference",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Supplier": "Supplier",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"Product": "Product",
		"Department": "Department",
		"DispatchDate": "Dispatch Date",
		"ETADate": "ETA Date",
		"Status": "Status",
		"MFDate": "MF Date",
		"OrderDate": "Order Date",
		"Internal": "Internal",
		"Qty": "Qty",
		"JobQty": "Job Qty",
		"DispatchDays": "Dispatch Days",
		"Age": "Age",
		"IDs": "I Ds",
		"SupplyNotes": "Supply Notes",
	},
	"notificationForID": {
		"entityTitle": "Notification For ID",
		"Subject": "Subject",
		"Message": "Message",
		"Expiry": "Expiry",
		"StartDate": "Start Date",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"NotificationID": "Notification ID",
	},
	"notifications": {
		"entityTitle": "Notifications",
		"Subject": "Subject",
		"Message": "Message",
		"Expiry": "Expiry",
		"StartDate": "Start Date",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"NotificationID": "Notification ID",
	},
	"opportunities": {
		"entityTitle": "Opportunities",
		"Code": "Code",
		"Description_abbrev": "Desc",
		"Description": "Description",
		"Status": "Status",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"SiteContact": "Contact",
		"SiteContactID": "Contact",
		"ExpectedRevenue_abbrev": "Exp Rev",
		"ExpectedRevenue": "Expected Revenue",
		"Representative_abbrev": "Rep",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"LeadGenerator_abbrev": "Generator",
		"LeadGenerator": "Representative",
		"LeadGeneratorID": "Representative",
		"ReferedBy_abbrev": "Ref By",
		"ReferedBy": "Contact",
		"ReferedByID": "Contact",
		"LeadDate": "Lead Date",
		"EstQuoteDate": "Est Quote Date",
		"ExpectedCloseDate": "Expected Close Date",
		"CloseDate": "Close Date",
		"AssignedDate": "Assigned Date",
		"Forecast": "Forecast",
		"VisitedShowroom": "Visited Showroom",
		"Address": "Address",
		"AddressID": "Address",
		"ProductsOfInterest": "Products Of Interest",
		"LeadSource": "Lead Source",
		"PL_LeadSourceID_abbrev": "Lead Source",
		"PL_LeadSourceID": "Lead Source",
		"ConfidenceLevel": "Opp Confidence Level",
		"PL_OppConfidenceLevelID_abbrev": "Confidence Level",
		"PL_OppConfidenceLevelID": "Opp Confidence Level",
		"QuoteOutcomeReason": "Quote Outcome Reason",
		"PL_QuoteOutcomeReasonID": "Quote Outcome Reason",
		"QuoteOutcomeNotes": "Quote Outcome Notes",
		"ExternalLead": "External Lead",
		"ExternalLeadID": "External Lead",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"Notes": "Notes",
		"FileNotes": "File Notes",
		"Jobs": "Jobs",
		"Tags": "Tags",
	},
	"opportunities_ForCompany": {
		"entityTitle": "Opportunities _ For Company",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"opportunities_ForContact": {
		"entityTitle": "Opportunities _ For Contact",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"opportunitiesForContact": {
		"entityTitle": "Opportunities For Contact",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"opportunityForID": {
		"entityTitle": "Opportunity For ID",
		"Code": "Code",
		"Description_abbrev": "Desc",
		"Description": "Description",
		"Status": "Status",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"SiteContact": "Contact",
		"SiteContactID": "Contact",
		"ExpectedRevenue_abbrev": "Exp Rev",
		"ExpectedRevenue": "Expected Revenue",
		"Representative_abbrev": "Rep",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"LeadGenerator_abbrev": "Generator",
		"LeadGenerator": "Representative",
		"LeadGeneratorID": "Representative",
		"ReferedBy_abbrev": "Ref By",
		"ReferedBy": "Contact",
		"ReferedByID": "Contact",
		"LeadDate": "Lead Date",
		"EstQuoteDate": "Est Quote Date",
		"ExpectedCloseDate": "Expected Close Date",
		"CloseDate": "Close Date",
		"AssignedDate": "Assigned Date",
		"Forecast": "Forecast",
		"VisitedShowroom": "Visited Showroom",
		"Address": "Address",
		"AddressID": "Address",
		"ProductsOfInterest": "Products Of Interest",
		"LeadSource": "Lead Source",
		"PL_LeadSourceID_abbrev": "Lead Source",
		"PL_LeadSourceID": "Lead Source",
		"ConfidenceLevel": "Opp Confidence Level",
		"PL_OppConfidenceLevelID_abbrev": "Confidence Level",
		"PL_OppConfidenceLevelID": "Opp Confidence Level",
		"QuoteOutcomeReason": "Quote Outcome Reason",
		"PL_QuoteOutcomeReasonID": "Quote Outcome Reason",
		"QuoteOutcomeNotes": "Quote Outcome Notes",
		"ExternalLead": "External Lead",
		"ExternalLeadID": "External Lead",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"Notes": "Notes",
		"FileNotes": "File Notes",
		"Jobs": "Jobs",
		"Tags": "Tags",
	},
	"opportunityTrackingViewForID": {
		"entityTitle": "Opportunity Tracking View For ID",
		"ID": "ID",
		"Description": "Description",
		"LeadDate": "Lead Date",
		"Forecast": "Forecast",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"LeadSource": "Lead Source",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Status": "Status",
		"ConfidenceLevel": "Confidence Level",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"BusinessUnit": "Business Unit",
		"Tags": "Tags",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"ExpectedRevenue_abbrev": "Forcast $",
		"ExpectedRevenue": "Expected Revenue",
		"ActivityID": "Activity ID",
		"ActivityContactID": "Activity Contact ID",
		"ActivityType": "Activity Type",
		"ActivityClosed": "Activity Closed",
		"ActivityConfirmed": "Activity Confirmed",
		"ActivityPriority": "Activity Priority",
		"ActivityStart": "Activity Start",
		"ActivitySubject": "Activity Subject",
		"Notes": "Notes",
	},
	"opportunityTrackingViews": {
		"entityTitle": "Opportunity Tracking Views",
		"ID": "ID",
		"Description": "Description",
		"LeadDate": "Lead Date",
		"Forecast": "Forecast",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"LeadSource": "Lead Source",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Status": "Status",
		"ConfidenceLevel": "Confidence Level",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"BusinessUnit": "Business Unit",
		"Tags": "Tags",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"ExpectedRevenue_abbrev": "Forcast $",
		"ExpectedRevenue": "Expected Revenue",
		"ActivityID": "Activity ID",
		"ActivityContactID": "Activity Contact ID",
		"ActivityType": "Activity Type",
		"ActivityClosed": "Activity Closed",
		"ActivityConfirmed": "Activity Confirmed",
		"ActivityPriority": "Activity Priority",
		"ActivityStart": "Activity Start",
		"ActivitySubject": "Activity Subject",
		"Notes": "Notes",
	},
	"orderLineForID": {
		"entityTitle": "Order Line For ID",
		"OrderLineNo_abbrev": "Line No",
		"OrderLineNo": "Order Line No",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"BaseCost": "Base Cost",
		"InstallCost": "Install Cost",
		"OptionsCost": "Options Cost",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentCost": "Adjustment Cost",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Order": "Purchase Order",
		"OrderID": "Purchase Order",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"Status": "Status",
		"OrderLineOptionsData": "Order Line Options Data",
		"ConsolidationData": "Consolidation Data",
		"PriceModel": "Price Model",
		"RelatedJobLines": "Related Job Lines",
		"MFDate": "MF Date",
		"Department": "MF Department",
		"PL_MFDepartmentID_abbrev": "Department",
		"PL_MFDepartmentID": "MF Department",
		"OrderLineID": "Order Line ID",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
		"InstallationNotes": "Installation Notes",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"BasePrice": "Base Price",
		"InstallPrice": "Install Price",
		"OptionsPrice": "Options Price",
		"DeliveryPrice": "Delivery Price",
		"AdjustmentPrice": "Adjustment Price",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"ValidationErrors": "Validation Errors",
		"PriceListVersion": "Price List Version",
	},
	"orderLines": {
		"entityTitle": "Order Lines",
		"OrderLineNo_abbrev": "Line No",
		"OrderLineNo": "Order Line No",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"BaseCost": "Base Cost",
		"InstallCost": "Install Cost",
		"OptionsCost": "Options Cost",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentCost": "Adjustment Cost",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Order": "Purchase Order",
		"OrderID": "Purchase Order",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"Status": "Status",
		"OrderLineOptionsData": "Order Line Options Data",
		"ConsolidationData": "Consolidation Data",
		"PriceModel": "Price Model",
		"RelatedJobLines": "Related Job Lines",
		"MFDate": "MF Date",
		"Department": "MF Department",
		"PL_MFDepartmentID_abbrev": "Department",
		"PL_MFDepartmentID": "MF Department",
		"OrderLineID": "Order Line ID",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
		"InstallationNotes": "Installation Notes",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"BasePrice": "Base Price",
		"InstallPrice": "Install Price",
		"OptionsPrice": "Options Price",
		"DeliveryPrice": "Delivery Price",
		"AdjustmentPrice": "Adjustment Price",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"ValidationErrors": "Validation Errors",
		"PriceListVersion": "Price List Version",
	},
	"orderLinesForPurchaseOrder": {
		"entityTitle": "Order Lines For Purchase Order",
		"ID": "ID",
		"ProductName": "Product Name",
		"OrderLineNo": "Order Line No",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"LocationOther": "Location Other",
		"CostPrice": "Cost Price",
		"Status": "Status",
		"ValidationErrors": "Validation Errors",
	},
	"paymentAllocationForID": {
		"entityTitle": "Payment Allocation For ID",
		"Payment": "Payment",
		"PaymentID": "Payment",
		"Amount": "Amount",
		"Job": "Job",
		"JobID": "Job",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice",
		"PaymentAllocationID": "Payment Allocation ID",
		"HasRefund": "Has Refund",
	},
	"paymentAllocations": {
		"entityTitle": "Payment Allocations",
		"Payment": "Payment",
		"PaymentID": "Payment",
		"Amount": "Amount",
		"Job": "Job",
		"JobID": "Job",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice",
		"PaymentAllocationID": "Payment Allocation ID",
		"HasRefund": "Has Refund",
	},
	"paymentForID": {
		"entityTitle": "Payment For ID",
		"ReceiptNo": "Receipt No",
		"Date": "Date",
		"Method": "Payment Method",
		"PL_PaymentMethodID_abbrev": "Method",
		"PL_PaymentMethodID": "Payment Method",
		"Amount": "Amount",
		"Type": "Type",
		"Status": "Status",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Notes": "Notes",
		"CreditNote": "Invoice",
		"CreditNoteID": "Invoice",
		"FinanceArchived": "Finance Archived",
		"IsDeposit": "Is Deposit",
		"PaymentAllocations": "Payment Allocations",
	},
	"payments": {
		"entityTitle": "Payments",
		"ReceiptNo": "Receipt No",
		"Date": "Date",
		"Method": "Payment Method",
		"PL_PaymentMethodID_abbrev": "Method",
		"PL_PaymentMethodID": "Payment Method",
		"Amount": "Amount",
		"Type": "Type",
		"Status": "Status",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Notes": "Notes",
		"CreditNote": "Invoice",
		"CreditNoteID": "Invoice",
		"FinanceArchived": "Finance Archived",
		"IsDeposit": "Is Deposit",
		"PaymentAllocations": "Payment Allocations",
	},
	"paymentsForContact": {
		"entityTitle": "Payments For Contact",
		"ID": "ID",
		"ReceiptNo": "Receipt No",
		"Date": "Date",
		"Amount": "Amount",
		"Method": "Method",
		"Status": "Status",
		"UnAllocated": "Un Allocated",
	},
	"paymentsForJob": {
		"entityTitle": "Payments For Job",
		"ID": "ID",
		"ReceiptNo": "Receipt No",
		"Date": "Date",
		"Amount": "Amount",
		"Method": "Method",
		"Status": "Status",
		"UnAllocated": "Un Allocated",
	},
	"pL_ActivityCategories": {
		"entityTitle": "PL _ Activity Categories",
		"PL_ActivityCategoryID": "PL _ Activity Category ID",
	},
	"pL_ActivityCategoryForID": {
		"entityTitle": "PL _ Activity Category For ID",
		"PL_ActivityCategoryID": "PL _ Activity Category ID",
	},
	"pL_CommissionAmendmentCategories": {
		"entityTitle": "PL _ Commission Amendment Categories",
		"PL_CommissionAmendmentCategoryID": "PL _ Commission Amendment Category ID",
	},
	"pL_CommissionAmendmentCategoryForID": {
		"entityTitle": "PL _ Commission Amendment Category For ID",
		"PL_CommissionAmendmentCategoryID": "PL _ Commission Amendment Category ID",
	},
	"pL_CommitmentReasonForID": {
		"entityTitle": "PL _ Commitment Reason For ID",
		"PL_CommitmentReasonID": "PL _ Commitment Reason ID",
	},
	"pL_CommitmentReasons": {
		"entityTitle": "PL _ Commitment Reasons",
		"PL_CommitmentReasonID": "PL _ Commitment Reason ID",
	},
	"pL_ContactJobCategories": {
		"entityTitle": "PL _ Contact Job Categories",
		"PL_ContactJobCategoryID": "PL _ Contact Job Category ID",
	},
	"pL_ContactJobCategoryForID": {
		"entityTitle": "PL _ Contact Job Category For ID",
		"PL_ContactJobCategoryID": "PL _ Contact Job Category ID",
	},
	"pL_FaultReasonForID": {
		"entityTitle": "PL _ Fault Reason For ID",
		"PL_FaultReasonID": "PL _ Fault Reason ID",
	},
	"pL_FaultReasons": {
		"entityTitle": "PL _ Fault Reasons",
		"PL_FaultReasonID": "PL _ Fault Reason ID",
	},
	"pL_JobCustomStatuses": {
		"entityTitle": "PL _ Job Custom Statuses",
		"PL_JobCustomStatusID": "PL _ Job Custom Status ID",
	},
	"pL_JobCustomStatusForID": {
		"entityTitle": "PL _ Job Custom Status For ID",
		"PL_JobCustomStatusID": "PL _ Job Custom Status ID",
	},
	"pL_LeadDiscardReasonForID": {
		"entityTitle": "PL _ Lead Discard Reason For ID",
		"PL_LeadDiscardReasonID": "PL _ Lead Discard Reason ID",
	},
	"pL_LeadDiscardReasons": {
		"entityTitle": "PL _ Lead Discard Reasons",
		"PL_LeadDiscardReasonID": "PL _ Lead Discard Reason ID",
	},
	"pL_LeadSourceForID": {
		"entityTitle": "PL _ Lead Source For ID",
		"PL_LeadSourceID": "PL _ Lead Source ID",
	},
	"pL_LeadSources": {
		"entityTitle": "PL _ Lead Sources",
		"PL_LeadSourceID": "PL _ Lead Source ID",
	},
	"pL_MFDepartmentForID": {
		"entityTitle": "PL _ MF Department For ID",
		"PL_MFDepartmentID": "PL _ MF Department ID",
	},
	"pL_MFDepartments": {
		"entityTitle": "PL _ MF Departments",
		"PL_MFDepartmentID": "PL _ MF Department ID",
	},
	"pL_OppConfidenceLevelForID": {
		"entityTitle": "PL _ Opp Confidence Level For ID",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
	},
	"pL_OppConfidenceLevels": {
		"entityTitle": "PL _ Opp Confidence Levels",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
	},
	"pL_OrderLineLocationForID": {
		"entityTitle": "PL _ Order Line Location For ID",
		"PL_OrderLineLocationID": "PL _ Order Line Location ID",
	},
	"pL_OrderLineLocations": {
		"entityTitle": "PL _ Order Line Locations",
		"PL_OrderLineLocationID": "PL _ Order Line Location ID",
	},
	"pL_OrganisationTypeForID": {
		"entityTitle": "PL _ Organisation Type For ID",
		"PL_OrganisationTypeID": "PL _ Organisation Type ID",
	},
	"pL_OrganisationTypes": {
		"entityTitle": "PL _ Organisation Types",
		"PL_OrganisationTypeID": "PL _ Organisation Type ID",
	},
	"pL_PaymentMethodForID": {
		"entityTitle": "PL _ Payment Method For ID",
		"PL_PaymentMethodID": "PL _ Payment Method ID",
	},
	"pL_PaymentMethods": {
		"entityTitle": "PL _ Payment Methods",
		"PL_PaymentMethodID": "PL _ Payment Method ID",
	},
	"pL_ProductOfInterestForID": {
		"entityTitle": "PL _ Product Of Interest For ID",
		"PL_ProductOfInterestID": "PL _ Product Of Interest ID",
	},
	"pL_ProductOfInterests": {
		"entityTitle": "PL _ Product Of Interests",
		"PL_ProductOfInterestID": "PL _ Product Of Interest ID",
	},
	"pL_QuoteOutcomeReasonForID": {
		"entityTitle": "PL _ Quote Outcome Reason For ID",
		"PL_QuoteOutcomeReasonID": "PL _ Quote Outcome Reason ID",
	},
	"pL_QuoteOutcomeReasons": {
		"entityTitle": "PL _ Quote Outcome Reasons",
		"PL_QuoteOutcomeReasonID": "PL _ Quote Outcome Reason ID",
	},
	"pL_TagForID": {
		"entityTitle": "PL _ Tag For ID",
		"PL_TagID": "PL _ Tag ID",
	},
	"pL_Tags": {
		"entityTitle": "PL _ Tags",
		"PL_TagID": "PL _ Tag ID",
	},
	"printSettingForID": {
		"entityTitle": "Print Setting For ID",
		"Name": "Name",
		"ProductPrintSettings": "Product Print Settings",
	},
	"printSettings": {
		"entityTitle": "Print Settings",
		"Name": "Name",
		"ProductPrintSettings": "Product Print Settings",
	},
	"product_Grid": {
		"entityTitle": "Product _ Grid",
		"ID": "ID",
		"Product": "Product",
		"Supplier": "Supplier",
		"Active": "Active",
		"ServiceProduct": "Service Product",
	},
	"productForID": {
		"entityTitle": "Product For ID",
		"ProductName": "Product Name",
		"Active": "Active",
		"ServiceProduct": "Service Product",
		"NotesTemplate": "Notes Template",
		"ProductID": "Product ID",
		"Supplier": "Supplier",
		"SupplierID": "Supplier",
		"BlobUrl": "Blob Url",
		"CutSheetTemplate": "Binary Data Field",
		"CutSheetTemplateID": "Binary Data Field",
		"CutSheetPrintType_abbrev": "Attach As",
		"CutSheetPrintType": "Cut Sheet Print Type",
	},
	"productOfInterestForID": {
		"entityTitle": "Product Of Interest For ID",
		"Qty": "Qty",
		"Full": "Full",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"ProductOfInterestID": "Product Of Interest",
		"Product": "Product Of Interest",
		"PL_ProductOfInterestID": "Product Of Interest",
	},
	"products": {
		"entityTitle": "Products",
		"ProductName": "Product Name",
		"Active": "Active",
		"ServiceProduct": "Service Product",
		"NotesTemplate": "Notes Template",
		"ProductID": "Product ID",
		"Supplier": "Supplier",
		"SupplierID": "Supplier",
		"BlobUrl": "Blob Url",
		"CutSheetTemplate": "Binary Data Field",
		"CutSheetTemplateID": "Binary Data Field",
		"CutSheetPrintType_abbrev": "Attach As",
		"CutSheetPrintType": "Cut Sheet Print Type",
	},
	"productShared": {
		"entityTitle": "Product Shared",
		"ProductID": "Product ID",
		"AlterProductID": "Alter Product ID",
	},
	"productShared_ForProduct": {
		"entityTitle": "Product Shared _ For Product",
		"ProductID": "Product ID",
		"AlterProductID": "Alter Product ID",
	},
	"productSharedForID": {
		"entityTitle": "Product Shared For ID",
		"ProductID": "Product ID",
		"AlterProductID": "Alter Product ID",
	},
	"productsOfInterest": {
		"entityTitle": "Products Of Interest",
		"Qty": "Qty",
		"Full": "Full",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"ProductOfInterestID": "Product Of Interest",
		"Product": "Product Of Interest",
		"PL_ProductOfInterestID": "Product Of Interest",
	},
	"productsOfInterestForOpportunity": {
		"entityTitle": "Products Of Interest For Opportunity",
		"Qty": "Qty",
		"Full": "Full",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"ProductOfInterestID": "Product Of Interest",
		"Product": "Product Of Interest",
		"PL_ProductOfInterestID": "Product Of Interest",
	},
	"projectStageForID": {
		"entityTitle": "Project Stage For ID",
		"Description": "Description",
		"ActivityType": "Activity Type",
		"SortOrder": "Sort Order",
		"ActivityCategory": "Activity Category",
		"PL_ActivityCategoryID": "Activity Category",
		"ProjectTemplate": "Project Template",
		"ProjectTemplateID": "Project Template",
		"ProjectStageID": "Project Stage ID",
	},
	"projectStages": {
		"entityTitle": "Project Stages",
		"Description": "Description",
		"ActivityType": "Activity Type",
		"SortOrder": "Sort Order",
		"ActivityCategory": "Activity Category",
		"PL_ActivityCategoryID": "Activity Category",
		"ProjectTemplate": "Project Template",
		"ProjectTemplateID": "Project Template",
		"ProjectStageID": "Project Stage ID",
	},
	"projectStageViewForID": {
		"entityTitle": "Project Stage View For ID",
		"ProjectTemplateID": "Project Template ID",
		"JobLineID": "Job Line ID",
		"JobID": "Job ID",
		"Stage_0": "Stage _0",
		"Stage_1": "Stage _1",
		"Stage_2": "Stage _2",
		"Stage_3": "Stage _3",
		"Stage_4": "Stage _4",
		"Stage_5": "Stage _5",
		"Stage_6": "Stage _6",
		"Stage_7": "Stage _7",
		"Stage_8": "Stage _8",
		"Stage_9": "Stage _9",
		"Stage_10": "Stage _10",
		"Stage_11": "Stage _11",
		"Stage_12": "Stage _12",
		"Stage_13": "Stage _13",
		"Stage_14": "Stage _14",
		"Stage_15": "Stage _15",
		"Stage_16": "Stage _16",
		"Stage_17": "Stage _17",
		"Stage_18": "Stage _18",
		"Stage_19": "Stage _19",
		"Stage_20": "Stage _20",
		"Stage_21": "Stage _21",
		"Stage_22": "Stage _22",
		"Stage_23": "Stage _23",
		"Stage_24": "Stage _24",
		"Stage_25": "Stage _25",
		"Stage_26": "Stage _26",
		"Stage_27": "Stage _27",
		"Stage_28": "Stage _28",
		"Stage_29": "Stage _29",
		"Stage_30": "Stage _30",
		"Stage_0_ID": "Stage _0_ ID",
		"Stage_1_ID": "Stage _1_ ID",
		"Stage_2_ID": "Stage _2_ ID",
		"Stage_3_ID": "Stage _3_ ID",
		"Stage_4_ID": "Stage _4_ ID",
		"Stage_5_ID": "Stage _5_ ID",
		"Stage_6_ID": "Stage _6_ ID",
		"Stage_7_ID": "Stage _7_ ID",
		"Stage_8_ID": "Stage _8_ ID",
		"Stage_9_ID": "Stage _9_ ID",
		"Stage_10_ID": "Stage _10_ ID",
		"Stage_11_ID": "Stage _11_ ID",
		"Stage_12_ID": "Stage _12_ ID",
		"Stage_13_ID": "Stage _13_ ID",
		"Stage_14_ID": "Stage _14_ ID",
		"Stage_15_ID": "Stage _15_ ID",
		"Stage_16_ID": "Stage _16_ ID",
		"Stage_17_ID": "Stage _17_ ID",
		"Stage_18_ID": "Stage _18_ ID",
		"Stage_19_ID": "Stage _19_ ID",
		"Stage_20_ID": "Stage _20_ ID",
		"Stage_21_ID": "Stage _21_ ID",
		"Stage_22_ID": "Stage _22_ ID",
		"Stage_23_ID": "Stage _23_ ID",
		"Stage_24_ID": "Stage _24_ ID",
		"Stage_25_ID": "Stage _25_ ID",
		"Stage_26_ID": "Stage _26_ ID",
		"Stage_27_ID": "Stage _27_ ID",
		"Stage_28_ID": "Stage _28_ ID",
		"Stage_29_ID": "Stage _29_ ID",
		"Stage_30_ID": "Stage _30_ ID",
		"Stage_0_Closed": "Stage _0_ Closed",
		"Stage_1_Closed": "Stage _1_ Closed",
		"Stage_2_Closed": "Stage _2_ Closed",
		"Stage_3_Closed": "Stage _3_ Closed",
		"Stage_4_Closed": "Stage _4_ Closed",
		"Stage_5_Closed": "Stage _5_ Closed",
		"Stage_6_Closed": "Stage _6_ Closed",
		"Stage_7_Closed": "Stage _7_ Closed",
		"Stage_8_Closed": "Stage _8_ Closed",
		"Stage_9_Closed": "Stage _9_ Closed",
		"Stage_10_Closed": "Stage _10_ Closed",
		"Stage_11_Closed": "Stage _11_ Closed",
		"Stage_12_Closed": "Stage _12_ Closed",
		"Stage_13_Closed": "Stage _13_ Closed",
		"Stage_14_Closed": "Stage _14_ Closed",
		"Stage_15_Closed": "Stage _15_ Closed",
		"Stage_16_Closed": "Stage _16_ Closed",
		"Stage_17_Closed": "Stage _17_ Closed",
		"Stage_18_Closed": "Stage _18_ Closed",
		"Stage_19_Closed": "Stage _19_ Closed",
		"Stage_20_Closed": "Stage _20_ Closed",
		"Stage_21_Closed": "Stage _21_ Closed",
		"Stage_22_Closed": "Stage _22_ Closed",
		"Stage_23_Closed": "Stage _23_ Closed",
		"Stage_24_Closed": "Stage _24_ Closed",
		"Stage_25_Closed": "Stage _25_ Closed",
		"Stage_26_Closed": "Stage _26_ Closed",
		"Stage_27_Closed": "Stage _27_ Closed",
		"Stage_28_Closed": "Stage _28_ Closed",
		"Stage_29_Closed": "Stage _29_ Closed",
		"Stage_30_Closed": "Stage _30_ Closed",
	},
	"projectStageViews": {
		"entityTitle": "Project Stage Views",
		"ProjectTemplateID": "Project Template ID",
		"JobLineID": "Job Line ID",
		"JobID": "Job ID",
		"Stage_0": "Stage _0",
		"Stage_1": "Stage _1",
		"Stage_2": "Stage _2",
		"Stage_3": "Stage _3",
		"Stage_4": "Stage _4",
		"Stage_5": "Stage _5",
		"Stage_6": "Stage _6",
		"Stage_7": "Stage _7",
		"Stage_8": "Stage _8",
		"Stage_9": "Stage _9",
		"Stage_10": "Stage _10",
		"Stage_11": "Stage _11",
		"Stage_12": "Stage _12",
		"Stage_13": "Stage _13",
		"Stage_14": "Stage _14",
		"Stage_15": "Stage _15",
		"Stage_16": "Stage _16",
		"Stage_17": "Stage _17",
		"Stage_18": "Stage _18",
		"Stage_19": "Stage _19",
		"Stage_20": "Stage _20",
		"Stage_21": "Stage _21",
		"Stage_22": "Stage _22",
		"Stage_23": "Stage _23",
		"Stage_24": "Stage _24",
		"Stage_25": "Stage _25",
		"Stage_26": "Stage _26",
		"Stage_27": "Stage _27",
		"Stage_28": "Stage _28",
		"Stage_29": "Stage _29",
		"Stage_30": "Stage _30",
		"Stage_0_ID": "Stage _0_ ID",
		"Stage_1_ID": "Stage _1_ ID",
		"Stage_2_ID": "Stage _2_ ID",
		"Stage_3_ID": "Stage _3_ ID",
		"Stage_4_ID": "Stage _4_ ID",
		"Stage_5_ID": "Stage _5_ ID",
		"Stage_6_ID": "Stage _6_ ID",
		"Stage_7_ID": "Stage _7_ ID",
		"Stage_8_ID": "Stage _8_ ID",
		"Stage_9_ID": "Stage _9_ ID",
		"Stage_10_ID": "Stage _10_ ID",
		"Stage_11_ID": "Stage _11_ ID",
		"Stage_12_ID": "Stage _12_ ID",
		"Stage_13_ID": "Stage _13_ ID",
		"Stage_14_ID": "Stage _14_ ID",
		"Stage_15_ID": "Stage _15_ ID",
		"Stage_16_ID": "Stage _16_ ID",
		"Stage_17_ID": "Stage _17_ ID",
		"Stage_18_ID": "Stage _18_ ID",
		"Stage_19_ID": "Stage _19_ ID",
		"Stage_20_ID": "Stage _20_ ID",
		"Stage_21_ID": "Stage _21_ ID",
		"Stage_22_ID": "Stage _22_ ID",
		"Stage_23_ID": "Stage _23_ ID",
		"Stage_24_ID": "Stage _24_ ID",
		"Stage_25_ID": "Stage _25_ ID",
		"Stage_26_ID": "Stage _26_ ID",
		"Stage_27_ID": "Stage _27_ ID",
		"Stage_28_ID": "Stage _28_ ID",
		"Stage_29_ID": "Stage _29_ ID",
		"Stage_30_ID": "Stage _30_ ID",
		"Stage_0_Closed": "Stage _0_ Closed",
		"Stage_1_Closed": "Stage _1_ Closed",
		"Stage_2_Closed": "Stage _2_ Closed",
		"Stage_3_Closed": "Stage _3_ Closed",
		"Stage_4_Closed": "Stage _4_ Closed",
		"Stage_5_Closed": "Stage _5_ Closed",
		"Stage_6_Closed": "Stage _6_ Closed",
		"Stage_7_Closed": "Stage _7_ Closed",
		"Stage_8_Closed": "Stage _8_ Closed",
		"Stage_9_Closed": "Stage _9_ Closed",
		"Stage_10_Closed": "Stage _10_ Closed",
		"Stage_11_Closed": "Stage _11_ Closed",
		"Stage_12_Closed": "Stage _12_ Closed",
		"Stage_13_Closed": "Stage _13_ Closed",
		"Stage_14_Closed": "Stage _14_ Closed",
		"Stage_15_Closed": "Stage _15_ Closed",
		"Stage_16_Closed": "Stage _16_ Closed",
		"Stage_17_Closed": "Stage _17_ Closed",
		"Stage_18_Closed": "Stage _18_ Closed",
		"Stage_19_Closed": "Stage _19_ Closed",
		"Stage_20_Closed": "Stage _20_ Closed",
		"Stage_21_Closed": "Stage _21_ Closed",
		"Stage_22_Closed": "Stage _22_ Closed",
		"Stage_23_Closed": "Stage _23_ Closed",
		"Stage_24_Closed": "Stage _24_ Closed",
		"Stage_25_Closed": "Stage _25_ Closed",
		"Stage_26_Closed": "Stage _26_ Closed",
		"Stage_27_Closed": "Stage _27_ Closed",
		"Stage_28_Closed": "Stage _28_ Closed",
		"Stage_29_Closed": "Stage _29_ Closed",
		"Stage_30_Closed": "Stage _30_ Closed",
	},
	"projectTemplateForID": {
		"entityTitle": "Project Template For ID",
		"Description": "Description",
		"ProjectTemplateID": "Project Template ID",
		"Stages": "Stages",
	},
	"projectTemplates": {
		"entityTitle": "Project Templates",
		"Description": "Description",
		"ProjectTemplateID": "Project Template ID",
		"Stages": "Stages",
	},
	"projectTrackingViewForID": {
		"entityTitle": "Project Tracking View For ID",
		"JobID": "Job ID",
		"ProductID": "Product ID",
		"ProductName": "Product Name",
		"Stage": "Stage",
		"Reference": "Reference",
		"Company": "Company",
		"SiteContact": "Site Contact",
		"ContactEmail": "Contact Email",
		"CustomStatus": "Custom Status",
		"ProjectCustomerPO": "Project Customer PO",
		"SiteAddress": "Site Address",
		"SalesRep": "Sales Rep",
		"SalesRepID": "Sales Rep ID",
		"Tags": "Tags",
		"HandoverDate": "Handover Date",
		"ProjectTemplateID": "Project Template ID",
		"CompanyID": "Company ID",
		"SiteContactID": "Site Contact ID",
		"OpportunityID": "Opportunity ID",
		"SiteAddressID": "Site Address ID",
		"Action_CanMoveToSupply": "Action _ Can Move To Supply",
		"Action_CanResendToSupply": "Action _ Can Resend To Supply",
		"Action_CanUpdateETA": "Action _ Can Update ETA",
		"Action_CanReceiptGoods": "Action _ Can Receipt Goods",
		"Action_CanInvoice": "Action _ Can Invoice",
		"ETA": "ETA",
		"Qty": "Qty",
		"SalePrice": "Sale Price",
		"StageID_0": "Stage ID _0",
		"StageID_1": "Stage ID _1",
		"StageID_2": "Stage ID _2",
		"StageID_3": "Stage ID _3",
		"StageID_4": "Stage ID _4",
		"StageID_5": "Stage ID _5",
		"StageID_6": "Stage ID _6",
		"StageID_7": "Stage ID _7",
		"StageID_8": "Stage ID _8",
		"StageID_9": "Stage ID _9",
		"StageDate_0": "Stage Date _0",
		"StageDate_1": "Stage Date _1",
		"StageDate_2": "Stage Date _2",
		"StageDate_3": "Stage Date _3",
		"StageDate_4": "Stage Date _4",
		"StageDate_5": "Stage Date _5",
		"StageDate_6": "Stage Date _6",
		"StageDate_7": "Stage Date _7",
		"StageDate_8": "Stage Date _8",
		"StageDate_9": "Stage Date _9",
		"Adv_Q_P_0": "Adv _ Q _ P _0",
	},
	"projectTrackingViews": {
		"entityTitle": "Project Tracking Views",
		"JobID": "Job ID",
		"ProductID": "Product ID",
		"ProductName": "Product Name",
		"Stage": "Stage",
		"Reference": "Reference",
		"Company": "Company",
		"SiteContact": "Site Contact",
		"ContactEmail": "Contact Email",
		"CustomStatus": "Custom Status",
		"ProjectCustomerPO": "Project Customer PO",
		"SiteAddress": "Site Address",
		"SalesRep": "Sales Rep",
		"SalesRepID": "Sales Rep ID",
		"Tags": "Tags",
		"HandoverDate": "Handover Date",
		"ProjectTemplateID": "Project Template ID",
		"CompanyID": "Company ID",
		"SiteContactID": "Site Contact ID",
		"OpportunityID": "Opportunity ID",
		"SiteAddressID": "Site Address ID",
		"Action_CanMoveToSupply": "Action _ Can Move To Supply",
		"Action_CanResendToSupply": "Action _ Can Resend To Supply",
		"Action_CanUpdateETA": "Action _ Can Update ETA",
		"Action_CanReceiptGoods": "Action _ Can Receipt Goods",
		"Action_CanInvoice": "Action _ Can Invoice",
		"ETA": "ETA",
		"Qty": "Qty",
		"SalePrice": "Sale Price",
		"StageID_0": "Stage ID _0",
		"StageID_1": "Stage ID _1",
		"StageID_2": "Stage ID _2",
		"StageID_3": "Stage ID _3",
		"StageID_4": "Stage ID _4",
		"StageID_5": "Stage ID _5",
		"StageID_6": "Stage ID _6",
		"StageID_7": "Stage ID _7",
		"StageID_8": "Stage ID _8",
		"StageID_9": "Stage ID _9",
		"StageDate_0": "Stage Date _0",
		"StageDate_1": "Stage Date _1",
		"StageDate_2": "Stage Date _2",
		"StageDate_3": "Stage Date _3",
		"StageDate_4": "Stage Date _4",
		"StageDate_5": "Stage Date _5",
		"StageDate_6": "Stage Date _6",
		"StageDate_7": "Stage Date _7",
		"StageDate_8": "Stage Date _8",
		"StageDate_9": "Stage Date _9",
		"Adv_Q_P_0": "Adv _ Q _ P _0",
	},
	"purchaseOrderForID": {
		"entityTitle": "Purchase Order For ID",
		"OrderNumber": "Order Number",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"CustomerReference_abbrev": "Cust. Ref",
		"CustomerReference": "Customer Reference",
		"ProductSummary": "Product Summary",
		"OrderDate": "Order Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"DateReceived": "Date Received",
		"DateSent": "Date Sent",
		"UndoCancelState": "Undo Cancel State",
		"Status": "Status",
		"StatusDate": "Status Date",
		"InternalNotes": "Internal Notes",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Job": "Job",
		"JobID": "Job",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"BillingAddress": "Address",
		"BillingAddressID": "Address",
		"TaxRateValue": "Tax Rate Value",
		"OrderLines": "Order Lines",
		"SupplyNotes": "Supply Notes",
		"SupplierName": "Supplier Name",
		"SupplierID": "Supplier ID",
		"PurchaseOrderID": "Purchase Order ID",
		"JobType": "Job Type",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"CompanyPriceMappings": "Company Price Mappings",
		"CompanyID": "Company ID",
		"ProjectCustomerPO": "Project Customer PO",
	},
	"purchaseOrders": {
		"entityTitle": "Purchase Orders",
		"OrderNumber": "Order Number",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"CustomerReference_abbrev": "Cust. Ref",
		"CustomerReference": "Customer Reference",
		"ProductSummary": "Product Summary",
		"OrderDate": "Order Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"DateReceived": "Date Received",
		"DateSent": "Date Sent",
		"UndoCancelState": "Undo Cancel State",
		"Status": "Status",
		"StatusDate": "Status Date",
		"InternalNotes": "Internal Notes",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Job": "Job",
		"JobID": "Job",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"BillingAddress": "Address",
		"BillingAddressID": "Address",
		"TaxRateValue": "Tax Rate Value",
		"OrderLines": "Order Lines",
		"SupplyNotes": "Supply Notes",
		"SupplierName": "Supplier Name",
		"SupplierID": "Supplier ID",
		"PurchaseOrderID": "Purchase Order ID",
		"JobType": "Job Type",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"CompanyPriceMappings": "Company Price Mappings",
		"CompanyID": "Company ID",
		"ProjectCustomerPO": "Project Customer PO",
	},
	"purchaseOrdersByETA": {
		"entityTitle": "Purchase Orders By ETA",
		"ID": "ID",
		"OrderDate": "Order Date",
		"CustomerReference_abbrev": "Cust Ref",
		"CustomerReference": "Customer Reference",
		"OrderNumber": "Order Number",
		"CostPrice": "Cost Price",
		"Rep": "Rep",
		"Status": "Status",
		"Supplier": "Supplier",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
	},
	"purchaseOrdersForStatus": {
		"entityTitle": "Purchase Orders For Status",
		"ID": "ID",
		"OrderDate": "Order Date",
		"CustomerReference_abbrev": "Cust Ref",
		"CustomerReference": "Customer Reference",
		"OrderNumber": "Order Number",
		"CostPrice": "Cost Price",
		"Rep": "Rep",
		"Status": "Status",
		"Supplier": "Supplier",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
	},
	"quotesBySalePriceDesc": {
		"entityTitle": "Quotes By Sale Price Desc",
		"ID": "ID",
		"Reference": "Reference",
		"Contact": "Contact",
		"ExpiryDate": "Expiry Date",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
	},
	"quotesForStatus": {
		"entityTitle": "Quotes For Status",
		"JobID": "Job ID",
		"QuoteExpiryDate_abbrev": "Expiry Date",
		"QuoteExpiryDate": "Quote Expiry Date",
		"HandoverDate": "Handover Date",
		"QuoteSentDate_abbrev": "Quote Sent",
		"QuoteSentDate": "Quote Sent Date",
		"Forecasted": "Forecasted",
		"IsConfirmed": "Is Confirmed",
		"IsClosed": "Is Closed",
		"IsLost": "Is Lost",
		"IgnoreDepositLimit": "Ignore Deposit Limit",
		"Status": "Job Custom Status",
		"PaymentTerms": "Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"InvoiceDueDays": "Invoice Due Days",
		"SupplyNotes": "Supply Notes",
		"Reference": "Reference",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"DispatchSummary": "Dispatch Summary",
		"ProductSummary": "Product Summary",
		"StageSummary": "Stage Summary",
		"JobDate": "Job Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"EstETADate": "Est ETA Date",
		"OrderDate": "Order Date",
		"Stage": "Stage",
		"JobType": "Job Type",
		"Priority": "Priority",
		"ShowroomQuote": "Showroom Quote",
		"InstallNotes": "Install Notes",
		"InternalNotes": "Internal Notes",
		"CustomerNotes": "Customer Notes",
		"PriceChangedWarning": "Price Changed Warning",
		"ProductOrder": "Product Order",
		"SalesRep": "Representative",
		"SalesRepID": "Representative",
		"MarginApproved": "Representative",
		"MarginApprovedBy": "Representative",
		"MarginApprovedByID": "Representative",
		"MarginApprovalRequired": "Margin Approval Required",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"Contact": "Contact",
		"ContactID": "Contact",
		"SiteContact": "Contact",
		"SiteContactID": "Contact",
		"Parent": "Job",
		"ParentID": "Job",
		"ParentGroupKey": "Parent Group Key",
		"Company": "Company",
		"CompanyID": "Company",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"Actions": "Advanced Filters . Views . IV _ Job Actions",
		"ActionsID": "Advanced Filters . Views . IV _ Job Actions",
		"JobLines": "Job Lines",
		"DiscountRules": "Discount Rules",
		"CustomStatus": "Job Custom Status",
		"PL_CustomStatusID_abbrev": "Custom Status",
		"PL_CustomStatusID": "Job Custom Status",
		"CommitmentReason": "Commitment Reason",
		"PL_CommitmentReasonID_abbrev": "Commit Reason",
		"PL_CommitmentReasonID": "Commitment Reason",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"NoSalesCommission": "No Sales Commission",
		"FileNotes": "File Notes",
		"Documents": "Documents",
		"Tags": "Tags",
		"ReportSettings": "Report Settings",
		"Tracking": "Tracking",
		"TrackingID": "Tracking",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"TaxRateValue": "Tax Rate Value",
		"CompanyPriceMappings": "Company Price Mappings",
	},
	"quoteTrackingViewForID": {
		"entityTitle": "Quote Tracking View For ID",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"quoteTrackingViews": {
		"entityTitle": "Quote Tracking Views",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"reminderForID": {
		"entityTitle": "Reminder For ID",
		"Message": "Message",
		"StartDate": "Start Date",
		"Job": "Job",
		"JobID": "Job",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"ReminderID": "Reminder ID",
	},
	"reminders": {
		"entityTitle": "Reminders",
		"Message": "Message",
		"StartDate": "Start Date",
		"Job": "Job",
		"JobID": "Job",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"ReminderID": "Reminder ID",
	},
	"reportAccess": {
		"entityTitle": "Report Access",
		"AccessControl": "Access Control",
		"QuoteStyles": "Quote Styles",
		"QuoteTermsAndConditions": "Quote Terms And Conditions",
		"QuotePaymentDetails": "Quote Payment Details",
		"ProductPrintSettings": "Product Print Settings",
		"ReportSettingsID": "Report Settings ID",
	},
	"reportSettings": {
		"entityTitle": "Report Settings",
		"AccessControl": "Access Control",
		"QuoteStyles": "Quote Styles",
		"QuoteTermsAndConditions": "Quote Terms And Conditions",
		"QuotePaymentDetails": "Quote Payment Details",
		"ProductPrintSettings": "Product Print Settings",
		"ReportSettingsID": "Report Settings ID",
	},
	"reportSettingsForID": {
		"entityTitle": "Report Settings For ID",
		"AccessControl": "Access Control",
		"QuoteStyles": "Quote Styles",
		"QuoteTermsAndConditions": "Quote Terms And Conditions",
		"QuotePaymentDetails": "Quote Payment Details",
		"ProductPrintSettings": "Product Print Settings",
		"ReportSettingsID": "Report Settings ID",
	},
	"reportSettingsRecord": {
		"entityTitle": "Report Settings Record",
		"AccessControl": "Access Control",
		"QuoteStyles": "Quote Styles",
		"QuoteTermsAndConditions": "Quote Terms And Conditions",
		"QuotePaymentDetails": "Quote Payment Details",
		"ProductPrintSettings": "Product Print Settings",
		"ReportSettingsID": "Report Settings ID",
	},
	"representative_Grid": {
		"entityTitle": "Representative _ Grid",
		"ID": "ID",
		"FullName": "Full Name",
		"Active": "Active",
		"DefaultTeam": "Default Team",
		"DefaultBusinessUnit": "Default Business Unit",
		"Email": "Email",
	},
	"representative_SelectList": {
		"entityTitle": "Representative _ Select List",
		"ID": "ID",
		"FullName": "Full Name",
	},
	"representativeForID": {
		"entityTitle": "Representative For ID",
		"FirstName": "First Name",
		"LastName": "Last Name",
		"FullName": "Full Name",
		"Email": "Email",
		"RepresentativeID": "Representative ID",
		"UserID": "User ID",
		"FeatureAllowSetup": "Feature Allow Setup",
		"FeatureAllowManagement": "Feature Allow Management",
		"FeatureAllowSendOrder": "Feature Allow Send Order",
		"FeatureAllowViewCostPrice": "Feature Allow View Cost Price",
		"FeatureCanViewSalesData": "Feature Can View Sales Data",
		"FeatureCanViewCalendars": "Feature Can View Calendars",
		"FeatureAllowQuoting": "Feature Allow Quoting",
		"FeatureShowInCalendar": "Feature Show In Calendar",
		"FeatureSyncCalendar": "Feature Sync Calendar",
		"FeatureCanViewFinance": "Feature Can View Finance",
		"FeatureCanViewProjects": "Feature Can View Projects",
		"FeatureAllowInstallations": "Feature Allow Installations",
		"FeatureCanProcessWebLeads": "Feature Can Process Web Leads",
		"FeatureAllowEditReadOnlyOpportunities": "Feature Allow Edit Read Only Opportunities",
		"FeatureAllowOpenExpiredQuotes": "Feature Allow Open Expired Quotes",
		"FeatureAllowPerformCheckMeasure": "Feature Allow Perform Check Measure",
		"FeatureAllowApproveCheckMeasure": "Feature Allow Approve Check Measure",
		"FeatureAllowDiscounting": "Feature Allow Discounting",
		"FeatureAllowEditJobs": "Feature Allow Edit Jobs",
		"FeatureAllowChangeBusinessUnit": "Feature Allow Change Business Unit",
		"FeatureAllowCreateServiceJob": "Feature Allow Create Service Job",
		"FeatureAllowChangeCostPrice": "Feature Allow Change Cost Price",
		"FeatureAllowOverrideDispatch": "Feature Allow Override Dispatch",
		"FeatureAllowRunCommissions": "Feature Allow Run Commissions",
		"FeatureAllowEditCommissionDate": "Feature Allow Edit Commission Date",
		"FeatureAllowViewEstimatedCommissions": "Feature Allow View Estimated Commissions",
		"FeatureAllowDeleteAppointment": "Feature Allow Delete Appointment",
		"FeatureAllowReceiptGoods": "Feature Allow Receipt Goods",
		"Active": "Active",
		"DefaultTeam": "Team",
		"DefaultTeamID": "Team",
		"DefaultBusinessUnit": "Business Unit",
		"DefaultBusinessUnitID": "Business Unit",
		"CalendarColor": "Calendar Color",
		"Licensed": "Licensed",
		"LicenseIDs": "License I Ds",
		"CalendarSettings": "Calendar Settings",
		"ApplicationSettings": "Application Settings",
		"VersionSettings": "Version Settings",
		"Mobile": "Mobile",
		"WorkPhone": "Work Phone",
		"Address": "Address",
		"AddressID": "Address",
		"JobTitle": "Job Title",
		"EmailSignature": "Email Signature",
		"CalendarID": "Calendar ID",
		"CommissionRules": "Commission Rules",
	},
	"representatives": {
		"entityTitle": "Representatives",
		"FirstName": "First Name",
		"LastName": "Last Name",
		"FullName": "Full Name",
		"Email": "Email",
		"RepresentativeID": "Representative ID",
		"UserID": "User ID",
		"FeatureAllowSetup": "Feature Allow Setup",
		"FeatureAllowManagement": "Feature Allow Management",
		"FeatureAllowSendOrder": "Feature Allow Send Order",
		"FeatureAllowViewCostPrice": "Feature Allow View Cost Price",
		"FeatureCanViewSalesData": "Feature Can View Sales Data",
		"FeatureCanViewCalendars": "Feature Can View Calendars",
		"FeatureAllowQuoting": "Feature Allow Quoting",
		"FeatureShowInCalendar": "Feature Show In Calendar",
		"FeatureSyncCalendar": "Feature Sync Calendar",
		"FeatureCanViewFinance": "Feature Can View Finance",
		"FeatureCanViewProjects": "Feature Can View Projects",
		"FeatureAllowInstallations": "Feature Allow Installations",
		"FeatureCanProcessWebLeads": "Feature Can Process Web Leads",
		"FeatureAllowEditReadOnlyOpportunities": "Feature Allow Edit Read Only Opportunities",
		"FeatureAllowOpenExpiredQuotes": "Feature Allow Open Expired Quotes",
		"FeatureAllowPerformCheckMeasure": "Feature Allow Perform Check Measure",
		"FeatureAllowApproveCheckMeasure": "Feature Allow Approve Check Measure",
		"FeatureAllowDiscounting": "Feature Allow Discounting",
		"FeatureAllowEditJobs": "Feature Allow Edit Jobs",
		"FeatureAllowChangeBusinessUnit": "Feature Allow Change Business Unit",
		"FeatureAllowCreateServiceJob": "Feature Allow Create Service Job",
		"FeatureAllowChangeCostPrice": "Feature Allow Change Cost Price",
		"FeatureAllowOverrideDispatch": "Feature Allow Override Dispatch",
		"FeatureAllowRunCommissions": "Feature Allow Run Commissions",
		"FeatureAllowEditCommissionDate": "Feature Allow Edit Commission Date",
		"FeatureAllowViewEstimatedCommissions": "Feature Allow View Estimated Commissions",
		"FeatureAllowDeleteAppointment": "Feature Allow Delete Appointment",
		"FeatureAllowReceiptGoods": "Feature Allow Receipt Goods",
		"Active": "Active",
		"DefaultTeam": "Team",
		"DefaultTeamID": "Team",
		"DefaultBusinessUnit": "Business Unit",
		"DefaultBusinessUnitID": "Business Unit",
		"CalendarColor": "Calendar Color",
		"Licensed": "Licensed",
		"LicenseIDs": "License I Ds",
		"CalendarSettings": "Calendar Settings",
		"ApplicationSettings": "Application Settings",
		"VersionSettings": "Version Settings",
		"Mobile": "Mobile",
		"WorkPhone": "Work Phone",
		"Address": "Address",
		"AddressID": "Address",
		"JobTitle": "Job Title",
		"EmailSignature": "Email Signature",
		"CalendarID": "Calendar ID",
		"CommissionRules": "Commission Rules",
	},
	"salesDiscountRule": {
		"entityTitle": "Sales Discount Rule",
		"Level": "Level",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"User": "Representative",
		"UserID": "Representative",
		"MinimumMargin": "Minimum Margin",
	},
	"salesDiscountRule_BusinessUnits": {
		"entityTitle": "Sales Discount Rule _ Business Units",
		"ID": "ID",
		"BusinessUnit": "Business Unit",
		"MinimumMargin": "Minimum Margin",
		"MinimumMarkup": "Minimum Markup",
	},
	"salesDiscountRule_Representative_SelectList": {
		"entityTitle": "Sales Discount Rule _ Representative _ Select List",
		"ID": "ID",
		"FullName": "Full Name",
	},
	"salesDiscountRule_System": {
		"entityTitle": "Sales Discount Rule _ System",
		"Level": "Level",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"User": "Representative",
		"UserID": "Representative",
		"MinimumMargin": "Minimum Margin",
	},
	"salesDiscountRule_Users": {
		"entityTitle": "Sales Discount Rule _ Users",
		"ID": "ID",
		"User": "User",
		"MinimumMargin": "Minimum Margin",
		"MinimumMarkup": "Minimum Markup",
	},
	"salesDiscountRuleForID": {
		"entityTitle": "Sales Discount Rule For ID",
		"Level": "Level",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"User": "Representative",
		"UserID": "Representative",
		"MinimumMargin": "Minimum Margin",
	},
	"saveLookupItem": {
		"entityTitle": "Save Lookup Item",
		"LookupTable": "Lookup Table",
		"LookupTableID": "Lookup Table",
		"Notes": "Notes",
		"From": "From",
		"To": "To",
		"Text": "Text",
		"ProductID": "Product ID",
		"RepresentativeID": "Representative ID",
		"Value": "Value",
	},
	"scheduler_UserSelectList": {
		"entityTitle": "Scheduler _ User Select List",
		"ID": "ID",
		"FullName": "Full Name",
	},
	"search_Company": {
		"entityTitle": "Search _ Company",
		"ID": "ID",
		"Name": "Name",
		"Type": "Type",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"InternalNotes": "Internal Notes",
	},
	"search_Contact": {
		"entityTitle": "Search _ Contact",
		"ID": "ID",
		"FirstName": "First Name",
		"LastName": "Last Name",
		"WorkPhone": "Work Phone",
		"HomePhone": "Home Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"InternalNotes": "Internal Notes",
		"Company": "Company",
		"CompanyID": "Company ID",
		"JobCategory": "Job Category",
		"JobTitle": "Job Title",
	},
	"search_Invoice": {
		"entityTitle": "Search _ Invoice",
		"ID": "ID",
		"InvoiceDate": "Invoice Date",
		"ProjectCustomerPO_abbrev": "Cust Ref",
		"ProjectCustomerPO": "Project Customer PO",
		"InvoiceNo": "Invoice No",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Amount": "Amount",
		"Tax": "Tax",
		"AmountIncTax": "Amount Inc Tax",
		"Rep": "Rep",
		"Status": "Status",
		"DueDate_abbrev": "Due",
		"DueDate": "Due Date",
	},
	"search_Job": {
		"entityTitle": "Search _ Job",
		"Reference": "Reference",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"Company": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"SalesRep": "Sales Rep",
		"JobDate": "Job Date",
		"Stage": "Stage",
		"Stages": "Stages",
		"JobType": "Job Type",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"BusinessUnit": "Business Unit",
		"Expiry": "Expiry",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
		"MinOrderDate": "Min Order Date",
		"MaxOrderDate": "Max Order Date",
		"CustomStatus": "Custom Status",
		"SalesPriceInc_abbrev": "Sales Inc",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
	},
	"search_Job_ForContact": {
		"entityTitle": "Search _ Job _ For Contact",
		"Reference": "Reference",
		"Contact": "Contact",
		"SiteContact": "Site Contact",
		"Company": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"SalesRep": "Sales Rep",
		"JobDate": "Job Date",
		"Stage": "Stage",
		"Stages": "Stages",
		"JobType": "Job Type",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"BusinessUnit": "Business Unit",
		"Expiry": "Expiry",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
		"MinOrderDate": "Min Order Date",
		"MaxOrderDate": "Max Order Date",
		"CustomStatus": "Custom Status",
		"SalesPriceInc_abbrev": "Sales Inc",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
	},
	"search_PurchaseOrder": {
		"entityTitle": "Search _ Purchase Order",
		"ID": "ID",
		"OrderDate": "Order Date",
		"CustomerReference_abbrev": "Cust Ref",
		"CustomerReference": "Customer Reference",
		"OrderNumber": "Order Number",
		"CostPrice": "Cost Price",
		"Rep": "Rep",
		"Status": "Status",
		"Supplier": "Supplier",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
	},
	"settingRecord": {
		"entityTitle": "Setting Record",
		"DefaultMarkupPercentage": "Default Markup Percentage",
		"QuoteExpireDays": "Quote Expire Days",
		"InvoiceDueDays": "Invoice Due Days",
		"DefaultCommitmentReason": "Commitment Reason",
		"DefaultCommitmentReasonID": "Commitment Reason",
		"PrimaryBusinessUnit": "Business Unit",
		"PrimaryBusinessUnitID": "Business Unit",
		"DefaultPaymentTerms": "Default Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"MinDeposit": "Min Deposit",
		"FinancialYearStartDate": "Financial Year Start Date",
		"CommissionDate1Rule": "Commission Date 1 Rule",
		"CommissionDate1RuleByLine": "Commission Date 1 Rule By Line",
		"DefaultTaxRate": "Tax Rate",
		"DefaultTaxRateID": "Tax Rate",
		"DefaultSupplierEmailTemplate": "Template",
		"DefaultSupplierEmailTemplateID": "Template",
		"DefaultBusinessUnitRule": "Default Business Unit Rule",
		"OrderCustomerRefRequired": "Order Customer Ref Required",
		"DefaultSettingID": "Default Setting ID",
		"SetupWizardCompleted": "Setup Wizard Completed",
		"DocumentStore": "Document Store",
		"TrackingEmailAddress": "Tracking Email Address",
		"ManufacturingETABuffer": "Manufacturing ETA Buffer",
		"Tracking_QuoteExpiryDays": "Tracking _ Quote Expiry Days",
		"Tracking_CMNotBookedDays": "Tracking _ CM Not Booked Days",
		"Tracking_CMNotConfirmedDays": "Tracking _ CM Not Confirmed Days",
		"Tracking_StockDuedDays": "Tracking _ Stock Dued Days",
		"Tracking_StockNotArrivedDays": "Tracking _ Stock Not Arrived Days",
		"Tracking_InstNotConfirmedDays": "Tracking _ Inst Not Confirmed Days",
		"Tracking_InstallerNotCompleteDays": "Tracking _ Installer Not Complete Days",
		"AutomationEnabled": "Automation Enabled",
	},
	"snapshots": {
		"entityTitle": "Snapshots",
		"Name": "Name",
		"Date": "Date",
		"SnapshotData": "Snapshot Data",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"Job": "Job Line",
		"JobID": "Job",
		"OtherID": "Other ID",
	},
	"statements": {
		"entityTitle": "Statements",
		"ID": "ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Email": "Email",
		"Address": "Address",
		"OutstandingBalance": "Outstanding Balance",
		"Overdue": "Overdue",
	},
	"supplierForID": {
		"entityTitle": "Supplier For ID",
		"Name": "Name",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"SupplierID": "Supplier ID",
		"TaxRate": "Tax Rate",
		"Internal": "Internal",
		"SplitByProduct": "Split By Product",
		"IsMarketplaceLicensed": "Is Marketplace Licensed",
		"MarketplaceID": "Marketplace ID",
		"AccountID": "Account ID",
		"FabricTemplate": "Binary Data Field",
		"FabricTemplateID": "Binary Data Field",
		"ComponentTemplate": "Binary Data Field",
		"ComponentTemplateID": "Binary Data Field",
		"TemplatePrintType": "Template Print Type",
	},
	"suppliers": {
		"entityTitle": "Suppliers",
		"Name": "Name",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"SupplierID": "Supplier ID",
		"TaxRate": "Tax Rate",
		"Internal": "Internal",
		"SplitByProduct": "Split By Product",
		"IsMarketplaceLicensed": "Is Marketplace Licensed",
		"MarketplaceID": "Marketplace ID",
		"AccountID": "Account ID",
		"FabricTemplate": "Binary Data Field",
		"FabricTemplateID": "Binary Data Field",
		"ComponentTemplate": "Binary Data Field",
		"ComponentTemplateID": "Binary Data Field",
		"TemplatePrintType": "Template Print Type",
	},
	"supplyJobMultiLineView": {
		"entityTitle": "Supply Job Multi Line View",
		"OrderNumber": "Order Number",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"CustomerReference_abbrev": "Cust. Ref",
		"CustomerReference": "Customer Reference",
		"ProductSummary": "Product Summary",
		"OrderDate": "Order Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"DateReceived": "Date Received",
		"DateSent": "Date Sent",
		"UndoCancelState": "Undo Cancel State",
		"Status": "Status",
		"StatusDate": "Status Date",
		"InternalNotes": "Internal Notes",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Job": "Job",
		"JobID": "Job",
		"DeliveryAddress": "Address",
		"DeliveryAddressID": "Address",
		"BillingAddress": "Address",
		"BillingAddressID": "Address",
		"TaxRateValue": "Tax Rate Value",
		"OrderLines": "Order Lines",
		"SupplyNotes": "Supply Notes",
		"SupplierName": "Supplier Name",
		"SupplierID": "Supplier ID",
		"PurchaseOrderID": "Purchase Order ID",
		"JobType": "Job Type",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"CompanyPriceMappings": "Company Price Mappings",
		"CompanyID": "Company ID",
		"ProjectCustomerPO": "Project Customer PO",
	},
	"tagRelatedForID": {
		"entityTitle": "Tag Related For ID",
		"Tag": "Tag",
		"TagID": "Tag",
		"Job": "Job",
		"JobID": "Job",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"TemplateResponse": "Template Response",
		"TemplateResponseID": "Template Response",
	},
	"tagsRelated": {
		"entityTitle": "Tags Related",
		"Tag": "Tag",
		"TagID": "Tag",
		"Job": "Job",
		"JobID": "Job",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"TemplateResponse": "Template Response",
		"TemplateResponseID": "Template Response",
	},
	"taxRateForID": {
		"entityTitle": "Tax Rate For ID",
		"Rate_abbrev": "Rate $",
		"Rate": "Rate",
		"Description": "Description",
		"TaxRateID": "Tax Rate ID",
	},
	"taxRates": {
		"entityTitle": "Tax Rates",
		"Rate_abbrev": "Rate $",
		"Rate": "Rate",
		"Description": "Description",
		"TaxRateID": "Tax Rate ID",
	},
	"teamForID": {
		"entityTitle": "Team For ID",
		"Name": "Name",
		"Active": "Active",
		"TeamID": "Team ID",
	},
	"teamRepForID": {
		"entityTitle": "Team Rep For ID",
		"Team": "Team",
		"TeamID": "Team",
		"Rep": "Representative",
		"RepID": "Representative",
	},
	"teamReps": {
		"entityTitle": "Team Reps",
		"Team": "Team",
		"TeamID": "Team",
		"Rep": "Representative",
		"RepID": "Representative",
	},
	"teams": {
		"entityTitle": "Teams",
		"Name": "Name",
		"Active": "Active",
		"TeamID": "Team ID",
	},
	"teams_Active": {
		"entityTitle": "Teams _ Active",
		"Name": "Name",
		"Active": "Active",
		"TeamID": "Team ID",
	},
	"templateForID": {
		"entityTitle": "Template For ID",
		"Description": "Description",
		"Type": "Type",
		"Category": "Category",
		"Subject": "Subject",
		"Body": "Body",
		"IsHTML": "Is HTML",
		"TemplateID": "Template ID",
		"Document": "Document",
		"Version": "Version",
		"AllowReply": "Allow Reply",
	},
	"templateResponseForID": {
		"entityTitle": "Template Response For ID",
		"Template": "Template",
		"TemplateID": "Template ID",
		"Condition": "Condition",
		"Value": "Value",
		"Action": "Action",
		"ResponseTimeout": "Response Timeout",
		"ModifyTags": "Modify Tags",
		"TagAction": "Tag Action",
		"Tags": "Tags",
	},
	"templateResponses": {
		"entityTitle": "Template Responses",
		"Template": "Template",
		"TemplateID": "Template ID",
		"Condition": "Condition",
		"Value": "Value",
		"Action": "Action",
		"ResponseTimeout": "Response Timeout",
		"ModifyTags": "Modify Tags",
		"TagAction": "Tag Action",
		"Tags": "Tags",
	},
	"templates": {
		"entityTitle": "Templates",
		"Description": "Description",
		"Type": "Type",
		"Category": "Category",
		"Subject": "Subject",
		"Body": "Body",
		"IsHTML": "Is HTML",
		"TemplateID": "Template ID",
		"Document": "Document",
		"Version": "Version",
		"AllowReply": "Allow Reply",
	},
	"templatesByType": {
		"entityTitle": "Templates By Type",
		"Description": "Description",
		"Type": "Type",
		"Category": "Category",
		"Subject": "Subject",
		"Body": "Body",
		"IsHTML": "Is HTML",
		"TemplateID": "Template ID",
		"Document": "Document",
		"Version": "Version",
		"AllowReply": "Allow Reply",
	},
	"tracking": {
		"entityTitle": "Tracking",
		"TrackingData": "Tracking Data",
	},
	"tracking_ActiveJobs": {
		"entityTitle": "Tracking _ Active Jobs",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"SalesRepMobile": "Sales Rep Mobile",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
		"Contact": "Contact",
		"ContactEmail": "Contact Email",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"City": "City",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"JobType": "Job Type",
		"Status": "Status",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"Paid": "Paid",
		"ETA": "ETA",
		"OrderDate": "Order Date",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"Tags": "Tags",
	},
	"tracking_ActiveQuotes": {
		"entityTitle": "Tracking _ Active Quotes",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"tracking_ActiveServiceJobs": {
		"entityTitle": "Tracking _ Active Service Jobs",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"SalesRepMobile": "Sales Rep Mobile",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
		"Contact": "Contact",
		"ContactEmail": "Contact Email",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"City": "City",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"JobType": "Job Type",
		"Status": "Status",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"Paid": "Paid",
		"ETA": "ETA",
		"OrderDate": "Order Date",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"Tags": "Tags",
	},
	"tracking_AllOrderJobs": {
		"entityTitle": "Tracking _ All Order Jobs",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"SalesRepMobile": "Sales Rep Mobile",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
		"Contact": "Contact",
		"ContactEmail": "Contact Email",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"City": "City",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"JobType": "Job Type",
		"Status": "Status",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"Paid": "Paid",
		"ETA": "ETA",
		"OrderDate": "Order Date",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"Tags": "Tags",
	},
	"tracking_ExpiredQuotes": {
		"entityTitle": "Tracking _ Expired Quotes",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"tracking_ExpiredService": {
		"entityTitle": "Tracking _ Expired Service",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"tracking_LostQuotes": {
		"entityTitle": "Tracking _ Lost Quotes",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"tracking_MFScheduling": {
		"entityTitle": "Tracking _ MF Scheduling",
		"PurchaseOrderID": "Purchase Order ID",
		"OrderNumber": "Order Number",
		"JobID": "Job ID",
		"Job": "Job",
		"CustomerReference": "Customer Reference",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Supplier": "Supplier",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"Product": "Product",
		"Department": "Department",
		"DispatchDate": "Dispatch Date",
		"ETADate": "ETA Date",
		"Status": "Status",
		"MFDate": "MF Date",
		"OrderDate": "Order Date",
		"Internal": "Internal",
		"Qty": "Qty",
		"JobQty": "Job Qty",
		"DispatchDays": "Dispatch Days",
		"Age": "Age",
		"IDs": "I Ds",
		"SupplyNotes": "Supply Notes",
	},
	"tracking_OpenQuotes": {
		"entityTitle": "Tracking _ Open Quotes",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"tracking_PendingApprovalService": {
		"entityTitle": "Tracking _ Pending Approval Service",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"tracking_Projects": {
		"entityTitle": "Tracking _ Projects",
		"ID": "ID",
		"JobID": "Job ID",
		"Reference": "Reference",
		"Company": "Company",
		"SiteContact": "Site Contact",
		"CustomStatus": "Custom Status",
		"ProjectCustomerPO": "Project Customer PO",
		"SiteAddress": "Site Address",
		"SalesRep": "Sales Rep",
		"SalesRepID": "Sales Rep ID",
		"Tags": "Tags",
		"HandoverDate": "Handover Date",
		"ProjectTemplateID": "Project Template ID",
		"CompanyID": "Company ID",
		"SiteContactID": "Site Contact ID",
		"OpportunityID": "Opportunity ID",
		"SiteAddressID": "Site Address ID",
		"ETA": "ETA",
		"SalePrice": "Sale Price",
		"ContactEmail": "Contact Email",
		"StageDate_0": "Stage Date _0",
		"StageDate_1": "Stage Date _1",
		"StageDate_2": "Stage Date _2",
		"StageDate_3": "Stage Date _3",
		"StageDate_4": "Stage Date _4",
		"StageDate_5": "Stage Date _5",
		"StageDate_6": "Stage Date _6",
		"StageDate_7": "Stage Date _7",
		"StageDate_8": "Stage Date _8",
		"StageDate_9": "Stage Date _9",
		"StageID_0": "Stage ID _0",
		"StageID_1": "Stage ID _1",
		"StageID_2": "Stage ID _2",
		"StageID_3": "Stage ID _3",
		"StageID_4": "Stage ID _4",
		"StageID_5": "Stage ID _5",
		"StageID_6": "Stage ID _6",
		"StageID_7": "Stage ID _7",
		"StageID_8": "Stage ID _8",
		"StageID_9": "Stage ID _9",
		"Action_CanMoveToSupply": "Action _ Can Move To Supply",
		"Action_CanResendToSupply": "Action _ Can Resend To Supply",
		"Action_CanUpdateETA": "Action _ Can Update ETA",
		"Action_CanReceiptGoods": "Action _ Can Receipt Goods",
		"Action_CanInvoice": "Action _ Can Invoice",
		"Products": "Products",
	},
	"tracking_SentQuotes": {
		"entityTitle": "Tracking _ Sent Quotes",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"tracking_SentService": {
		"entityTitle": "Tracking _ Sent Service",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"trackingForID": {
		"entityTitle": "Tracking For ID",
		"TrackingData": "Tracking Data",
	},
	"user_NoTeamFilter": {
		"entityTitle": "User _ No Team Filter",
		"Name": "Name",
		"Active": "Active",
		"TeamID": "Team ID",
	},
	"user_NoUserFilter": {
		"entityTitle": "User _ No User Filter",
		"ID": "ID",
		"FullName": "Full Name",
	},
	"user_SalesTeamFilter": {
		"entityTitle": "User _ Sales Team Filter",
		"Name": "Name",
		"Active": "Active",
		"TeamID": "Team ID",
	},
	"user_SalesUserFilter": {
		"entityTitle": "User _ Sales User Filter",
		"ID": "ID",
		"FullName": "Full Name",
	},
	"user_TeamFilter": {
		"entityTitle": "User _ Team Filter",
		"Name": "Name",
		"Active": "Active",
		"TeamID": "Team ID",
	},
	"user_UserFilter": {
		"entityTitle": "User _ User Filter",
		"ID": "ID",
		"FullName": "Full Name",
	},
	"userApplicationSettings": {
		"entityTitle": "User Application Settings",
		"ActivityFilter": "Activity Filter",
		"CalendarSettings": "Calendar Settings",
		"ApplicationSettings": "Application Settings",
		"VersionSettings": "Version Settings",
		"DashboardSettings": "Dashboard Settings",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
	},
	"userApplicationSettingsForID": {
		"entityTitle": "User Application Settings For ID",
		"ActivityFilter": "Activity Filter",
		"CalendarSettings": "Calendar Settings",
		"ApplicationSettings": "Application Settings",
		"VersionSettings": "Version Settings",
		"DashboardSettings": "Dashboard Settings",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
	},
	"userCommissionRules": {
		"entityTitle": "User Commission Rules",
		"User": "Representative",
		"UserID": "Representative",
		"CommissionRule": "Commission Rule",
		"CommissionRuleID": "Commission Rule",
	},
	"view_InvoiceStatements": {
		"entityTitle": "View _ Invoice Statements",
		"InvoiceDate": "Invoice Date",
		"DueDate": "Due Date",
		"InvoiceNo": "Invoice No",
		"ProjectCustomerPO": "Project Customer PO",
		"CompanyID": "Company ID",
		"Company": "Company",
		"CompanyMobile": "Company Mobile",
		"CompanyPhone": "Company Phone",
		"CompanyFax": "Company Fax",
		"ContactID": "Contact ID",
		"Contact": "Contact",
		"ContactHomePhone": "Contact Home Phone",
		"ContactMobile": "Contact Mobile",
		"ContactWorkPhone": "Contact Work Phone",
		"Email": "Email",
		"Address": "Address",
		"DaysOverdue": "Days Overdue",
		"Amount": "Amount",
		"Overdue": "Overdue",
		"Paid": "Paid",
		"Balance": "Balance",
	},
	"view_JobAmount": {
		"entityTitle": "View _ Job Amount",
		"ID": "ID",
		"OpportunityID": "Opportunity ID",
		"Amount": "Amount",
		"AmountEx": "Amount Ex",
		"Count": "Count",
	},
	"view_JobLine": {
		"entityTitle": "View _ Job Line",
		"ID": "ID",
		"RequireCM": "Require CM",
		"RequireInstall": "Require Install",
		"RequirePickup": "Require Pickup",
		"RequireDelivery": "Require Delivery",
		"Stage": "Stage",
		"LineType": "Line Type",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"ProductID": "Product ID",
		"SupplierID": "Supplier ID",
		"ETA": "ETA",
		"OrderDate": "Order Date",
		"CommissionDate1": "Commission Date 1",
		"Count": "Count",
	},
	"view_JobPaid": {
		"entityTitle": "View _ Job Paid",
		"ID": "ID",
		"Paid": "Paid",
		"Count": "Count",
	},
	"views_Opportunities_Confirmed": {
		"entityTitle": "Views _ Opportunities _ Confirmed",
		"ID": "ID",
		"Description": "Description",
		"LeadDate": "Lead Date",
		"Forecast": "Forecast",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"LeadSource": "Lead Source",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Status": "Status",
		"ConfidenceLevel": "Confidence Level",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"BusinessUnit": "Business Unit",
		"Tags": "Tags",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"ExpectedRevenue_abbrev": "Forcast $",
		"ExpectedRevenue": "Expected Revenue",
		"ActivityID": "Activity ID",
		"ActivityContactID": "Activity Contact ID",
		"ActivityType": "Activity Type",
		"ActivityClosed": "Activity Closed",
		"ActivityConfirmed": "Activity Confirmed",
		"ActivityPriority": "Activity Priority",
		"ActivityStart": "Activity Start",
		"ActivitySubject": "Activity Subject",
		"Notes": "Notes",
	},
	"views_Opportunities_Leads": {
		"entityTitle": "Views _ Opportunities _ Leads",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"views_Opportunities_Leads_For_Rep": {
		"entityTitle": "Views _ Opportunities _ Leads _ For _ Rep",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"views_Opportunities_Lost": {
		"entityTitle": "Views _ Opportunities _ Lost",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"views_Opportunities_Lost_For": {
		"entityTitle": "Views _ Opportunities _ Lost _ For",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"views_Opportunities_Lost_For_Rep": {
		"entityTitle": "Views _ Opportunities _ Lost _ For _ Rep",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"views_Opportunities_Negotiation": {
		"entityTitle": "Views _ Opportunities _ Negotiation",
		"ID": "ID",
		"Description": "Description",
		"LeadDate": "Lead Date",
		"Forecast": "Forecast",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"LeadSource": "Lead Source",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Status": "Status",
		"ConfidenceLevel": "Confidence Level",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"BusinessUnit": "Business Unit",
		"Tags": "Tags",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"ExpectedRevenue_abbrev": "Forcast $",
		"ExpectedRevenue": "Expected Revenue",
		"ActivityID": "Activity ID",
		"ActivityContactID": "Activity Contact ID",
		"ActivityType": "Activity Type",
		"ActivityClosed": "Activity Closed",
		"ActivityConfirmed": "Activity Confirmed",
		"ActivityPriority": "Activity Priority",
		"ActivityStart": "Activity Start",
		"ActivitySubject": "Activity Subject",
		"Notes": "Notes",
	},
	"views_Opportunities_Negotiation_For_Rep": {
		"entityTitle": "Views _ Opportunities _ Negotiation _ For _ Rep",
		"ID": "ID",
		"Description": "Description",
		"LeadDate": "Lead Date",
		"Forecast": "Forecast",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"LeadSource": "Lead Source",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Status": "Status",
		"ConfidenceLevel": "Confidence Level",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"BusinessUnit": "Business Unit",
		"Tags": "Tags",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"ExpectedRevenue_abbrev": "Forcast $",
		"ExpectedRevenue": "Expected Revenue",
		"ActivityID": "Activity ID",
		"ActivityContactID": "Activity Contact ID",
		"ActivityType": "Activity Type",
		"ActivityClosed": "Activity Closed",
		"ActivityConfirmed": "Activity Confirmed",
		"ActivityPriority": "Activity Priority",
		"ActivityStart": "Activity Start",
		"ActivitySubject": "Activity Subject",
		"Notes": "Notes",
	},
	"views_Opportunities_Quotation": {
		"entityTitle": "Views _ Opportunities _ Quotation",
		"ID": "ID",
		"Description": "Description",
		"LeadDate": "Lead Date",
		"Forecast": "Forecast",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"LeadSource": "Lead Source",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Status": "Status",
		"ConfidenceLevel": "Confidence Level",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"BusinessUnit": "Business Unit",
		"Tags": "Tags",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"ExpectedRevenue_abbrev": "Forcast $",
		"ExpectedRevenue": "Expected Revenue",
		"ActivityID": "Activity ID",
		"ActivityContactID": "Activity Contact ID",
		"ActivityType": "Activity Type",
		"ActivityClosed": "Activity Closed",
		"ActivityConfirmed": "Activity Confirmed",
		"ActivityPriority": "Activity Priority",
		"ActivityStart": "Activity Start",
		"ActivitySubject": "Activity Subject",
		"Notes": "Notes",
	},
	"views_Opportunities_Quotation_For_Rep": {
		"entityTitle": "Views _ Opportunities _ Quotation _ For _ Rep",
		"ID": "ID",
		"Description": "Description",
		"LeadDate": "Lead Date",
		"Forecast": "Forecast",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"LeadSource": "Lead Source",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Status": "Status",
		"ConfidenceLevel": "Confidence Level",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"BusinessUnit": "Business Unit",
		"Tags": "Tags",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"ExpectedRevenue_abbrev": "Forcast $",
		"ExpectedRevenue": "Expected Revenue",
		"ActivityID": "Activity ID",
		"ActivityContactID": "Activity Contact ID",
		"ActivityType": "Activity Type",
		"ActivityClosed": "Activity Closed",
		"ActivityConfirmed": "Activity Confirmed",
		"ActivityPriority": "Activity Priority",
		"ActivityStart": "Activity Start",
		"ActivitySubject": "Activity Subject",
		"Notes": "Notes",
	},
	"views_Opportunities_Won": {
		"entityTitle": "Views _ Opportunities _ Won",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"views_Opportunities_Won_For": {
		"entityTitle": "Views _ Opportunities _ Won _ For",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"views_Opportunities_Won_For_Rep": {
		"entityTitle": "Views _ Opportunities _ Won _ For _ Rep",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},

	"basePicklist": {
		"entityTitle": "Base Picklist",
		"ID": "Record ID",
		"Description": "Description",
		"Active": "Active",
	},
	"documentBase": {
		"entityTitle": "Document Base",
		"ID": "Record ID",
		"Uri": "Uri",
		"Description": "Description",
		"Tag": "Tag",
		"Protected": "Protected",
		"DocumentID": "Document ID",
		"Version": "Version",
		"DocumentDate_abbrev": "Date",
		"DocumentDate": "Document Date",
	},
	"commissionAccountTransactionGrid": {
		"entityTitle": "Commission Account Transaction Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Date": "Date",
		"User": "User",
		"UserID": "User ID",
		"RunID": "Run ID",
		"Run": "Run",
		"Plan": "Plan",
		"Type": "Type",
		"Description": "Description",
		"Status": "Status",
		"Amount": "Amount",
		"Notes": "Notes",
		"IDs": "I Ds",
	},
	"templateResponseGrid": {
		"entityTitle": "Template Response Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Condition": "Condition",
		"Value": "Value",
		"Action": "Action",
		"ResponseTimeout": "Response Timeout",
	},
	"localeCountry": {
		"entityTitle": "Locale Country",
		"ID": "Record ID",
		"ID": "ID",
		"Description": "Description",
	},
	"purchaseOrderGrid": {
		"entityTitle": "Purchase Order Grid",
		"ID": "Record ID",
		"ID": "ID",
		"OrderDate": "Order Date",
		"CustomerReference_abbrev": "Cust Ref",
		"CustomerReference": "Customer Reference",
		"OrderNumber": "Order Number",
		"CostPrice": "Cost Price",
		"Rep": "Rep",
		"Status": "Status",
		"Supplier": "Supplier",
		"ETADate_abbrev": "ETA",
		"ETADate": "ETA Date",
	},
	"salesDiscountRuleBusinessUnitGrid": {
		"entityTitle": "Sales Discount Rule Business Unit Grid",
		"ID": "Record ID",
		"ID": "ID",
		"BusinessUnit": "Business Unit",
		"MinimumMargin": "Minimum Margin",
		"MinimumMarkup": "Minimum Markup",
	},
	"salesDiscountRuleUserGrid": {
		"entityTitle": "Sales Discount Rule User Grid",
		"ID": "Record ID",
		"ID": "ID",
		"User": "User",
		"MinimumMargin": "Minimum Margin",
		"MinimumMarkup": "Minimum Markup",
	},
	"companyGrid": {
		"entityTitle": "Company Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Name": "Name",
		"Type": "Type",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"InternalNotes": "Internal Notes",
	},
	"documentGrid": {
		"entityTitle": "Document Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Description": "Description",
		"Job": "Job",
		"JobID": "Job ID",
		"Type": "Type",
		"Date": "Date",
		"Version": "Version",
		"SendToCustomer": "Send To Customer",
		"SendToSupplier": "Send To Supplier",
	},
	"communicationGrid": {
		"entityTitle": "Communication Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Date": "Date",
		"Type": "Type",
		"Category": "Category",
		"Description": "Description",
		"ContactPoint": "Contact Point",
		"Outcome": "Outcome",
		"User_abbrev": "User",
		"User": "User",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Job": "Job",
		"JobID": "Job ID",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice ID",
		"SupplyJob": "Supply Job",
		"PurchaseOrderID": "Purchase Order ID",
		"Supplier": "Supplier",
	},
	"statementGrid": {
		"entityTitle": "Statement Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Email": "Email",
		"Address": "Address",
		"OutstandingBalance": "Outstanding Balance",
		"Overdue": "Overdue",
	},
	"orderLineGrid": {
		"entityTitle": "Order Line Grid",
		"ID": "Record ID",
		"ID": "ID",
		"ProductName": "Product Name",
		"OrderLineNo": "Order Line No",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"LocationOther": "Location Other",
		"CostPrice": "Cost Price",
		"Status": "Status",
		"ValidationErrors": "Validation Errors",
	},
	"lookupTableItemGrid": {
		"entityTitle": "Lookup Table Item Grid",
		"ID": "Record ID",
		"ID": "ID",
		"From": "From",
		"To": "To",
		"Text": "Text",
		"ProductName": "Product Name",
		"ProductID": "Product ID",
		"RepresentativeName": "Representative Name",
		"RepresentativeID": "Representative ID",
		"Value": "Value",
		"Notes": "Notes",
	},
	"activityGrid": {
		"entityTitle": "Activity Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Subject": "Subject",
		"Start": "Start",
		"AllDay": "All Day",
		"Closed": "Closed",
		"Notes": "Notes",
		"ActivityType_abbrev": "Type",
		"ActivityType": "Activity Type",
		"ActivityCategory_abbrev": "Category",
		"ActivityCategory": "Activity Category",
		"Confirmed": "Confirmed",
		"Representative": "Representative",
		"Priority": "Priority",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity ID",
		"IsInstall": "Is Install",
		"IsPickup": "Is Pickup",
		"IsDelivery": "Is Delivery",
		"IsService": "Is Service",
	},
	"opportunityGrid": {
		"entityTitle": "Opportunity Grid",
		"ID": "Record ID",
		"ID": "ID",
		"LeadDate": "Lead Date",
		"Description": "Description",
		"LeadSource": "Lead Source",
		"Forecast": "Forecast",
		"Status": "Status",
		"PipelineStage": "Pipeline Stage",
		"ConfidenceLevel": "Confidence Level",
		"Representative": "Representative",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"Address": "Address",
		"Notes": "Notes",
		"Phone": "Phone",
		"Email": "Email",
	},
	"invoiceGrid": {
		"entityTitle": "Invoice Grid",
		"ID": "Record ID",
		"ID": "ID",
		"InvoiceDate": "Invoice Date",
		"ProjectCustomerPO_abbrev": "Cust Ref",
		"ProjectCustomerPO": "Project Customer PO",
		"InvoiceNo": "Invoice No",
		"Contact": "Contact",
		"BusinessUnit": "Business Unit",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Amount": "Amount",
		"Tax": "Tax",
		"AmountIncTax": "Amount Inc Tax",
		"Rep": "Rep",
		"Status": "Status",
		"DueDate_abbrev": "Due",
		"DueDate": "Due Date",
	},
	"productGrid": {
		"entityTitle": "Product Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Product": "Product",
		"Supplier": "Supplier",
		"Active": "Active",
		"ServiceProduct": "Service Product",
	},
	"leadSearchGrid": {
		"entityTitle": "Lead Search Grid",
		"ID": "Record ID",
		"ID": "ID",
		"LastName": "Last Name",
		"FirstName": "First Name",
		"Email": "Email",
		"WorkPhone": "Work Phone",
		"HomePhone": "Home Phone",
		"Mobile": "Mobile",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"JobCategory": "Job Category",
		"JobTitle": "Job Title",
		"Company": "Company",
		"ContactID": "Contact ID",
		"CompanyID": "Company ID",
		"CustomerName": "Customer Name",
		"CompanyName": "Company Name",
		"BillingContactName": "Billing Contact Name",
	},
	"contactGrid": {
		"entityTitle": "Contact Grid",
		"ID": "Record ID",
		"ID": "ID",
		"FirstName": "First Name",
		"LastName": "Last Name",
		"WorkPhone": "Work Phone",
		"HomePhone": "Home Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"InternalNotes": "Internal Notes",
		"Company": "Company",
		"CompanyID": "Company ID",
		"JobCategory": "Job Category",
		"JobTitle": "Job Title",
	},
	"commissionRunGrid": {
		"entityTitle": "Commission Run Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Plan": "Plan",
		"Description": "Description",
		"StartDate": "Start Date",
		"EndDate": "End Date",
		"LastRunBy": "Last Run By",
		"PaidBy": "Paid By",
	},
	"largestQuoteGrid": {
		"entityTitle": "Largest Quote Grid",
		"ID": "Record ID",
		"ID": "ID",
		"Reference": "Reference",
		"Contact": "Contact",
		"ExpiryDate": "Expiry Date",
		"SalesPriceInc": "Sales Price Inc",
		"ContactID": "Contact ID",
	},
	"paymentGrid": {
		"entityTitle": "Payment Grid",
		"ID": "Record ID",
		"ID": "ID",
		"ReceiptNo": "Receipt No",
		"Date": "Date",
		"Amount": "Amount",
		"Method": "Method",
		"Status": "Status",
		"UnAllocated": "Un Allocated",
	},
	"communication": {
		"entityTitle": "Communication",
		"ID": "Record ID",
		"Date": "Date",
		"Type": "Type",
		"Category": "Category",
		"Description": "Description",
		"Outcome": "Outcome",
		"OutcomeDate": "Outcome Date",
		"ContactPoint": "Contact Point",
		"Tag": "Tag",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"Job": "Job",
		"JobID": "Job",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"Supplier": "Supplier",
		"SupplierID": "Supplier",
		"SupplyJob": "Supply Job",
		"SupplyJobID": "Purchase Order",
		"CommunicationID": "Communication ID",
		"activity": "activity",
		"ActivityID": "Activity",
		"TemplateID": "Template ID",
	},
	"commandAction": {
		"entityTitle": "Command Action",
		"ID": "Record ID",
		"Type": "Type",
		"Date": "Date",
		"CommandData": "Command Data",
		"CommandActionKey": "Command Action Key",
	},
	"iV_JobLine": {
		"entityTitle": "IV _ Job Line",
		"ID": "Record ID",
		"ID": "ID",
		"RequireCM": "Require CM",
		"RequireInstall": "Require Install",
		"RequirePickup": "Require Pickup",
		"RequireDelivery": "Require Delivery",
		"Stage": "Stage",
		"LineType": "Line Type",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"ProductID": "Product ID",
		"SupplierID": "Supplier ID",
		"ETA": "ETA",
		"OrderDate": "Order Date",
		"CommissionDate1": "Commission Date 1",
		"Count": "Count",
	},
	"iV_AdvancedFilter_Job": {
		"entityTitle": "IV _ Advanced Filter _ Job",
		"ID": "Record ID",
		"ID": "ID",
		"QuoteDate": "Quote Date",
		"QuoteExpiryDate": "Quote Expiry Date",
		"HandoverDate": "Handover Date",
		"LJ__CommissionDate1": "LJ __ Commission Date 1",
		"LJ__OrderDate": "LJ __ Order Date",
		"LJ__ETADate": "LJ __ ETA Date",
		"LJ__Amount": "LJ __ Amount",
		"LJ__Paid": "LJ __ Paid",
		"LJ__HasPayment": "LJ __ Has Payment",
		"Stage": "Stage",
		"Status": "Status",
		"JobType": "Job Type",
		"MarginApprovalRequired": "Margin Approval Required",
		"LJ__JobLineStatus": "LJ __ Job Line Status",
		"LJ__JobLineStage": "LJ __ Job Line Stage",
		"LJ__JobLineType": "LJ __ Job Line Type",
		"LJ__FaultReasonID": "LJ __ Fault Reason ID",
		"LJ__AtFaultUserID": "LJ __ At Fault User ID",
		"LJ__AtFaultSupplierID": "LJ __ At Fault Supplier ID",
		"LJ__JobLineDispatchStage": "LJ __ Job Line Dispatch Stage",
		"SalesRepID": "Sales Rep ID",
		"BusinessUnitID": "Business Unit ID",
		"PL_CommitmentReasonID": "PL _ Commitment Reason ID",
		"LJ__Balance": "LJ __ Balance",
		"LJ__PercentagePaid": "LJ __ Percentage Paid",
		"LJ__JobLineProductID": "LJ __ Job Line Product ID",
		"LJ__JobLineSupplierID": "LJ __ Job Line Supplier ID",
		"LJ__CompanyName": "LJ __ Company Name",
		"LJ__OrganisationType": "LJ __ Organisation Type",
		"LJ__AccountType": "LJ __ Account Type",
		"LJ__ContactFirstName": "LJ __ Contact First Name",
		"LJ__ContactLastName": "LJ __ Contact Last Name",
		"LJ__ContactHasCredit": "LJ __ Contact Has Credit",
		"LJ__SiteContactFirstName": "LJ __ Site Contact First Name",
		"LJ__SiteContactLastName": "LJ __ Site Contact Last Name",
		"LJ__ActivityType": "LJ __ Activity Type",
		"LJ__ActivityCategory": "LJ __ Activity Category",
		"LJ__ActivityStart": "LJ __ Activity Start",
		"LJ__ActivityRepresentative": "LJ __ Activity Representative",
		"LJ__Tags": "LJ __ Tags",
		"LJ__ActivityClosed": "LJ __ Activity Closed",
		"LJ__ActivityConfirmed": "LJ __ Activity Confirmed",
		"LJ__ReferredByID": "LJ __ Referred By ID",
		"LJ__LeadGenerator": "LJ __ Lead Generator",
		"LJ__OpportunityConfidenceLevel": "LJ __ Opportunity Confidence Level",
		"LJ__OpportunityStatus": "LJ __ Opportunity Status",
		"LJ__OpportunityOutcomeReason": "LJ __ Opportunity Outcome Reason",
		"LJ__OpportunityForecast": "LJ __ Opportunity Forecast",
		"LJ__LeadSource": "LJ __ Lead Source",
		"LJ__VisitedShowroom": "LJ __ Visited Showroom",
		"LJ__OpportunityRepresentative": "LJ __ Opportunity Representative",
		"LJ__CMRequired": "LJ __ CM Required",
		"LJ__InstallRequired": "LJ __ Install Required",
		"LJ__PickupRequired": "LJ __ Pickup Required",
		"LJ__DeliveryRequired": "LJ __ Delivery Required",
		"LJ__PercentageInvoiced": "LJ __ Percentage Invoiced",
	},
	"iV_AdvancedFilter_Opportunity": {
		"entityTitle": "IV _ Advanced Filter _ Opportunity",
		"ID": "Record ID",
		"ID": "ID",
		"Description": "Description",
		"ExpectedCloseDate": "Expected Close Date",
		"Status": "Status",
		"RepresentativeID": "Representative ID",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Forecast": "Forecast",
		"LeadGeneratorID": "Lead Generator ID",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"BusinessUnitID": "Business Unit ID",
		"PL_QuoteOutcomeReasonID": "PL _ Quote Outcome Reason ID",
		"ReferedByID": "Refered By ID",
		"VisitedShowroom": "Visited Showroom",
		"LJ__Tags": "LJ __ Tags",
		"LJ__ActivityType": "LJ __ Activity Type",
		"LJ__ActivityCategory": "LJ __ Activity Category",
		"LJ__ActivityStart": "LJ __ Activity Start",
		"LJ__ActivityRepresentative": "LJ __ Activity Representative",
		"LJ__ActivityClosed": "LJ __ Activity Closed",
		"LJ__ActivityConfirmed": "LJ __ Activity Confirmed",
		"LJ__CompanyName": "LJ __ Company Name",
		"LJ__OrganisationType": "LJ __ Organisation Type",
		"LJ__CompanyAccountType": "LJ __ Company Account Type",
		"LJ__ContactFirstName": "LJ __ Contact First Name",
		"LJ__ContactLastName": "LJ __ Contact Last Name",
		"LJ__Amount": "LJ __ Amount",
		"LJ__QuoteLastSentDate": "LJ __ Quote Last Sent Date",
		"LJ__QuoteDate": "LJ __ Quote Date",
		"LJ__QuoteExpiryDate": "LJ __ Quote Expiry Date",
		"LJ__JobID": "LJ __ Job ID",
		"LJ__JobSalesRep": "LJ __ Job Sales Rep",
		"LJ__JobStage": "LJ __ Job Stage",
		"LJ__JobTags": "LJ __ Job Tags",
		"LJ__JobCompanyName": "LJ __ Job Company Name",
		"LJ__JobOrganisationType": "LJ __ Job Organisation Type",
		"LJ__JobCompanyAccountType": "LJ __ Job Company Account Type",
		"LJ__JobContactFirstName": "LJ __ Job Contact First Name",
		"LJ__JobContactLastName": "LJ __ Job Contact Last Name",
		"LJ__JobLineETADate": "LJ __ Job Line ETA Date",
		"LJ__JobLineProductID": "LJ __ Job Line Product ID",
		"LJ__JobLineSupplierID": "LJ __ Job Line Supplier ID",
		"LJ__JobLineCMRequired": "LJ __ Job Line CM Required",
		"LJ__JobLineInstallRequired": "LJ __ Job Line Install Required",
		"LJ__JobLinePickupRequired": "LJ __ Job Line Pickup Required",
		"LJ__JobLineDeliveryRequired": "LJ __ Job Line Delivery Required",
		"LJ__JobLineStatus": "LJ __ Job Line Status",
		"LJ__JobLineStage": "LJ __ Job Line Stage",
	},
	"iV_JobAmount": {
		"entityTitle": "IV _ Job Amount",
		"ID": "Record ID",
		"ID": "ID",
		"OpportunityID": "Opportunity ID",
		"Amount": "Amount",
		"AmountEx": "Amount Ex",
		"Count": "Count",
	},
	"iV_JobPaid": {
		"entityTitle": "IV _ Job Paid",
		"ID": "Record ID",
		"ID": "ID",
		"Paid": "Paid",
		"Count": "Count",
	},
	"iV_AdvancedFilter_SupplyJob": {
		"entityTitle": "IV _ Advanced Filter _ Supply Job",
		"ID": "Record ID",
		"ID": "ID",
		"PurchaseOrderID": "Purchase Order ID",
		"RepresentativeID": "Representative ID",
		"OrderNumber": "Order Number",
		"CustomerReference": "Customer Reference",
		"Status": "Status",
		"SupplierID": "Supplier ID",
		"ETADate": "ETA Date",
		"LJ__Internal": "LJ __ Internal",
		"LJ__SupplyLineProductID": "LJ __ Supply Line Product ID",
		"LJ__SupplyLineStatus": "LJ __ Supply Line Status",
		"LJ__SupplyLineMFDate": "LJ __ Supply Line MF Date",
		"LJ__SupplyLineDepartment": "LJ __ Supply Line Department",
		"LJ__CommitmentReason": "LJ __ Commitment Reason",
		"LJ__HandoverDate": "LJ __ Handover Date",
		"LJ__SalesRepID": "LJ __ Sales Rep ID",
		"LJ__DispatchDate": "LJ __ Dispatch Date",
		"LJ__DispatchBy": "LJ __ Dispatch By",
		"LJ__CompanyName": "LJ __ Company Name",
		"LJ__OrganisationType": "LJ __ Organisation Type",
		"LJ__AccountType": "LJ __ Account Type",
		"LJ__Tags": "LJ __ Tags",
		"LJ__InstallRequired": "LJ __ Install Required",
		"LJ__PickupRequired": "LJ __ Pickup Required",
		"LJ__DeliveryRequired": "LJ __ Delivery Required",
	},
	"defaultSetting": {
		"entityTitle": "Default Setting",
		"ID": "Record ID",
		"DefaultMarkupPercentage": "Default Markup Percentage",
		"QuoteExpireDays": "Quote Expire Days",
		"InvoiceDueDays": "Invoice Due Days",
		"DefaultCommitmentReason": "Default Commitment Reason",
		"DefaultCommitmentReasonID": "Commitment Reason",
		"PrimaryBusinessUnit": "Primary Business Unit",
		"PrimaryBusinessUnitID": "Business Unit",
		"DefaultPaymentTerms": "Default Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"MinDeposit": "Min Deposit",
		"FinancialYearStartDate": "Financial Year Start Date",
		"CommissionDate1Rule": "Commission Date 1 Rule",
		"CommissionDate1RuleByLine": "Commission Date 1 Rule By Line",
		"DefaultTaxRate": "Default Tax Rate",
		"DefaultTaxRateID": "Tax Rate",
		"DefaultSupplierEmailTemplate": "Default Supplier Email Template",
		"DefaultSupplierEmailTemplateID": "Template",
		"DefaultBusinessUnitRule": "Default Business Unit Rule",
		"OrderCustomerRefRequired": "Order Customer Ref Required",
		"DefaultSettingID": "Default Setting ID",
		"SetupWizardCompleted": "Setup Wizard Completed",
		"DocumentStore": "Document Store",
		"TrackingEmailAddress": "Tracking Email Address",
		"ManufacturingETABuffer": "Manufacturing ETA Buffer",
		"Tracking_QuoteExpiryDays": "Tracking _ Quote Expiry Days",
		"Tracking_CMNotBookedDays": "Tracking _ CM Not Booked Days",
		"Tracking_CMNotConfirmedDays": "Tracking _ CM Not Confirmed Days",
		"Tracking_StockDuedDays": "Tracking _ Stock Dued Days",
		"Tracking_StockNotArrivedDays": "Tracking _ Stock Not Arrived Days",
		"Tracking_InstNotConfirmedDays": "Tracking _ Inst Not Confirmed Days",
		"Tracking_InstallerNotCompleteDays": "Tracking _ Installer Not Complete Days",
		"AutomationEnabled": "Automation Enabled",
	},
	"opportunity": {
		"entityTitle": "Opportunity",
		"ID": "Record ID",
		"Code": "Code",
		"Description_abbrev": "Desc",
		"Description": "Description",
		"Status": "Status",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"SiteContact": "Site Contact",
		"SiteContactID": "Contact",
		"ExpectedRevenue_abbrev": "Exp Rev",
		"ExpectedRevenue": "Expected Revenue",
		"Representative_abbrev": "Rep",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"LeadGenerator_abbrev": "Generator",
		"LeadGenerator": "Lead Generator",
		"LeadGeneratorID": "Lead Generator",
		"ReferedBy_abbrev": "Ref By",
		"ReferedBy": "Referred By",
		"ReferedByID": "Referred By",
		"LeadDate": "Lead Date",
		"EstQuoteDate": "Est Quote Date",
		"ExpectedCloseDate": "Expected Close Date",
		"CloseDate": "Close Date",
		"AssignedDate": "Assigned Date",
		"Forecast": "Forecast",
		"VisitedShowroom": "Visited Showroom",
		"Address": "Address",
		"AddressID": "Address",
		"ProductsOfInterest": "Products Of Interest",
		"LeadSource": "Lead Source",
		"PL_LeadSourceID_abbrev": "Lead Source",
		"PL_LeadSourceID": "Lead Source",
		"ConfidenceLevel": "Confidence Level",
		"PL_OppConfidenceLevelID_abbrev": "Confidence Level",
		"PL_OppConfidenceLevelID": "Opp Confidence Level",
		"QuoteOutcomeReason": "Quote Outcome Reason",
		"PL_QuoteOutcomeReasonID": "Quote Outcome Reason",
		"QuoteOutcomeNotes": "Quote Outcome Notes",
		"ExternalLead": "External Lead",
		"ExternalLeadID": "External Lead",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"Notes": "Notes",
		"FileNotes": "File Notes",
		"Jobs": "Jobs",
		"Tags": "Tags",
	},
	"team": {
		"entityTitle": "Team",
		"ID": "Record ID",
		"Name": "Name",
		"Active": "Active",
		"TeamID": "Team ID",
	},
	"commissionRule": {
		"entityTitle": "Commission Rule",
		"ID": "Record ID",
		"Name": "Name",
		"Expression": "Expression",
		"TextValues": "Text Values",
		"ByJob": "By Job",
		"Users": "Users",
	},
	"commissionRuleUser": {
		"entityTitle": "Commission Rule User",
		"ID": "Record ID",
		"User": "User",
		"UserID": "Representative",
		"CommissionRule": "Commission Rule",
		"CommissionRuleID": "Commission Rule",
	},
	"contact": {
		"entityTitle": "Contact",
		"ID": "Record ID",
		"FirstName": "First Name",
		"LastName": "Last Name",
		"FullName": "Full Name",
		"JobTitle": "Job Title",
		"JobCategory": "Job Category",
		"PL_ContactJobCategoryID_abbrev": "Job Category",
		"PL_ContactJobCategoryID": "Contact Job Category",
		"WorkPhone": "Work Phone",
		"HomePhone": "Home Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"Notes": "Notes",
		"Address": "Address",
		"AddressID": "Address",
		"Company": "Company",
		"CompanyID": "Company",
		"FileNotes": "File Notes",
		"ContactID": "Contact ID",
		"FinanceIntegrationID": "Finance Integration ID",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
	},
	"fileNote": {
		"entityTitle": "File Note",
		"ID": "Record ID",
		"Date": "Date",
		"Notes": "Notes",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"Job": "Job",
		"JobID": "Job",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"ExternalLead": "External Lead",
		"ExternalLeadID": "External Lead",
		"FileNoteID": "File Note ID",
	},
	"productOfInterest": {
		"entityTitle": "Product Of Interest",
		"ID": "Record ID",
		"Qty": "Qty",
		"Full": "Full",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"ProductOfInterestID": "Product Of Interest ID",
		"Product": "Product",
		"PL_ProductOfInterestID": "Product Of Interest",
	},
	"commitmentReason": {
		"entityTitle": "Commitment Reason",
		"ID": "Record ID",
		"PL_CommitmentReasonID": "PL _ Commitment Reason ID",
		"Description": "Description",
		"Active": "Active",
	},
	"purchaseOrder": {
		"entityTitle": "Supply   Job",
		"ID": "Record ID",
		"OrderNumber": "Order Number",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"CustomerReference_abbrev": "Cust. Ref",
		"CustomerReference": "Customer Reference",
		"ProductSummary": "Product Summary",
		"OrderDate": "Order Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"DateReceived": "Date Received",
		"DateSent": "Date Sent",
		"UndoCancelState": "Undo Cancel State",
		"Status": "Status",
		"StatusDate": "Status Date",
		"InternalNotes": "Internal Notes",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Job": "Job",
		"JobID": "Job",
		"DeliveryAddress": "Delivery Address",
		"DeliveryAddressID": "Address",
		"BillingAddress": "Billing Address",
		"BillingAddressID": "Address",
		"TaxRateValue": "Tax Rate Value",
		"OrderLines": "Order Lines",
		"SupplyNotes": "Supply Notes",
		"SupplierName": "Supplier Name",
		"SupplierID": "Supplier ID",
		"PurchaseOrderID": "Purchase Order ID",
		"JobType": "Job Type",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"CompanyPriceMappings": "Company Price Mappings",
		"CompanyID": "Company ID",
		"ProjectCustomerPO": "Project Customer PO",
	},
	"supplier": {
		"entityTitle": "Supplier",
		"ID": "Record ID",
		"Name": "Name",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"SupplierID": "Supplier ID",
		"TaxRate": "Tax Rate",
		"Internal": "Internal",
		"SplitByProduct": "Split By Product",
		"IsMarketplaceLicensed": "Is Marketplace Licensed",
		"MarketplaceID": "Marketplace ID",
		"AccountID": "Account ID",
		"FabricTemplate": "Fabric Template",
		"FabricTemplateID": "Binary Data Field",
		"ComponentTemplate": "Component Template",
		"ComponentTemplateID": "Binary Data Field",
		"TemplatePrintType": "Template Print Type",
	},
	"jobLineService": {
		"entityTitle": "Job Line Service",
		"ID": "Record ID",
		"Job": "Job",
		"JobID": "Job",
		"JobLine": "Job Line",
		"JobLineID": "Job",
		"ChargeAmount": "Charge Amount",
		"ReasonNotes": "Reason Notes",
		"UserAtFault": "User At Fault",
		"UserAtFaultID": "Representative",
		"SupplierAtFault": "Supplier At Fault",
		"SupplierAtFaultID": "Supplier",
		"FaultReason": "Fault Reason",
		"PL_FaultReasonID_abbrev": "Fault Reason",
		"PL_FaultReasonID": "Fault Reason",
	},
	"invoice": {
		"entityTitle": "Invoice",
		"ID": "Record ID",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"InvoiceID": "Invoice ID",
		"InvoiceNo": "Invoice No",
		"InvoiceDate": "Invoice Date",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"Locked": "Locked",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"DateExported": "Date Exported",
		"Status": "Status",
		"FinanceArchived": "Finance Archived",
		"Job": "Job",
		"JobID": "Job",
		"DueDate": "Due Date",
		"CloseDate": "Close Date",
		"BillingAddress": "Billing Address",
		"BillingAddressID": "Address",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"FinanceExternalID": "Finance External ID",
		"CreditNote": "Credit Note",
		"CustomStatus": "Custom Status",
		"PL_CustomStatusID_abbrev": "Custom Status",
		"PL_CustomStatusID": "Job Custom Status",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"ChangeStatus": "Change Status",
		"InvoiceLines": "Invoice Lines",
		"DocumentVersion": "Document Version",
		"DocumentTemplateID": "Document Template ID",
		"ReportSettings": "Report Settings",
	},
	"companyPriceMapping": {
		"entityTitle": "Company Price Mapping",
		"ID": "Record ID",
		"Company": "Company",
		"CompanyID": "Company",
		"PriceListID": "Price List ID",
		"ProductID": "Product ID",
		"PriceList": "Price List",
		"Product": "Product",
		"Supplier": "Supplier",

	},
	"jobCustomStatus": {
		"entityTitle": "Job Custom Status",
		"ID": "Record ID",
		"PL_JobCustomStatusID": "PL _ Job Custom Status ID",
		"Description": "Description",
		"Active": "Active",
	},
	"activityCategory": {
		"entityTitle": "Activity Category",
		"ID": "Record ID",
		"PL_ActivityCategoryID": "PL _ Activity Category ID",
		"Description": "Description",
		"Active": "Active",
	},
	"reminder": {
		"entityTitle": "Reminder",
		"ID": "Record ID",
		"Message": "Message",
		"StartDate": "Start Date",
		"Job": "Job",
		"JobID": "Job",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"ReminderID": "Reminder ID",
	},
	"commissionRun": {
		"entityTitle": "Commission Run",
		"ID": "Record ID",
		"Description": "Description",
		"StartDate": "Start Date",
		"EndDate": "End Date",
		"Plan": "Plan",
		"PlanID": "Commission Plan",
		"LastRunBy": "Last Run By",
		"LastRunByID": "Representative",
		"PaidBy": "Paid By",
		"PaidByID": "Representative",
		"Paid": "Paid",
		"InProgress": "In Progress",
	},
	"activity": {
		"entityTitle": "Activity",
		"ID": "Record ID",
		"Subject": "Subject",
		"Start": "Start",
		"End": "End",
		"AllDay": "All Day",
		"Closed": "Closed",
		"Notes": "Notes",
		"ProcessDetails": "Process Details",
		"ActivityType_abbrev": "Type",
		"ActivityType": "Activity Type",
		"Confirmed": "Confirmed",
		"IsInstall": "Is Install",
		"IsPickup": "Is Pickup",
		"IsDelivery": "Is Delivery",
		"IsService": "Is Service",
		"Category": "Category",
		"PL_ActivityCategoryID_abbrev": "Category",
		"PL_ActivityCategoryID": "Activity Category",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"Team": "Team",
		"TeamID": "Team",
		"Address": "Address",
		"AddressID": "Address",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Company": "Company",
		"CompanyID": "Company",
		"Job": "Job",
		"JobID": "Job",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"CreatedBy": "Created By",
		"CreatedByID": "Representative",
		"CreatedOn": "Created On",
		"Priority": "Priority",
		"Attendees": "Attendees",
		"JobLines": "Job Lines",
		"TagData": "Tag Data",
		"ActivityID": "Activity ID",
		"Address": {
			"entityTitle": "Address",
			"ID": "Record ID",
			"AddressLines": "Address Lines",
			"City": "City",
			"State": "State",
			"Postcode": "Postcode",
			"Country": "Country",
			"Location": "Location",
			"PosLong": "Pos Long",
			"PosLat": "Pos Lat",
			"AddressID": "Address ID",
			"Formatted": "Formatted",
		},
	},
	"currentActivity": {
		"entityTitle": "Current Activity",
		"ID": "Record ID",
		"ActivityID": "Activity ID",
		"JobID": "Job ID",
		"JobLineID": "Job Line ID",
		"ActiveTypeID": "Active Type ID",
		"ActiveCategoryID": "Active Category ID",
		"ActivityType": "Activity Type",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"Team": "Team",
		"TeamID": "Team ID",
		"Subject": "Subject",
		"Priority": "Priority",
		"Confirmed": "Confirmed",
		"Closed": "Closed",
		"ContactID": "Contact ID",
		"PL_ActivityCategoryID": "PL _ Activity Category ID",
		"Start": "Start",
		"CreatedOn": "Created On",
	},
	"taxRate": {
		"entityTitle": "Tax Rate",
		"ID": "Record ID",
		"Rate_abbrev": "Rate $",
		"Rate": "Rate",
		"Description": "Description",
		"TaxRateID": "Tax Rate ID",
	},
	"discountLevel": {
		"entityTitle": "Discount Level",
		"ID": "Record ID",
		"Description": "Description",
		"Code": "Code",
		"Type": "Type",
		"Active": "Active",
		"DiscountLevelID": "Discount Level ID",
		"DicountItems": "Dicount Items",
		"CompanyDiscounts": "Company Discounts",
	},
	"companyDiscount": {
		"entityTitle": "Company Discount",
		"ID": "Record ID",
		"DiscountLevel": "Discount Level",
		"DiscountLevelID": "Discount Level",
		"Company": "Company",
		"CompanyID": "Company",
	},
	"discountItem": {
		"entityTitle": "Discount Item",
		"ID": "Record ID",
		"Active": "Active",
		"Start": "Start",
		"Finish": "Finish",
		"ApplyToBase": "Apply To Base",
		"ApplyToOptions": "Apply To Options",
		"ApplyToDelivery": "Apply To Delivery",
		"ApplyToInstallation": "Apply To Installation",
		"Percentage": "Percentage",
		"Amount": "Amount",
		"DiscountLevel": "Discount Level",
		"DiscountLevelID": "Discount Level",
		"ProductID": "Product ID",
		"Supplier": "Supplier",
		"Product": "Product",
		"Option1": "Option 1",
		"Option1ID": "Option 1 ID",
		"Option2ID": "Option 2 ID",
		"Option2": "Option 2",
		"Option1ValueID": "Option 1 Value ID",
		"Option1Value": "Option 1 Value",
		"Option2ValueID": "Option 2 Value ID",
		"Option2Value": "Option 2 Value",
		"Option1GroupCode": "Option 1 Group Code",
		"Option2GroupCode": "Option 2 Group Code",
		"DiscountItemID": "Discount Item ID",
	},
	"printSetting": {
		"entityTitle": "Print Setting",
		"ID": "Record ID",
		"Name": "Name",
		"ProductPrintSettings": "Product Print Settings",
	},
	"paymentMethod": {
		"entityTitle": "Payment Method",
		"ID": "Record ID",
		"PL_PaymentMethodID": "PL _ Payment Method ID",
		"Description": "Description",
		"Active": "Active",
	},
	"jobDiscountRule": {
		"entityTitle": "Job Discount Rule",
		"ID": "Record ID",
		"Job": "Job",
		"JobID": "Job",
		"TaxIncluded": "Tax Included",
		"IsPercentage": "Is Percentage",
		"Level": "Level",
		"Type": "Type",
		"PriceType": "Price Type",
		"AmountType": "Amount Type",
		"ProductID": "Product ID",
		"JobLineID": "Job Line ID",
		"ProductOptionID": "Product Option ID",
		"ProductOptionValueID": "Product Option Value ID",
		"Value": "Value",
	},
	"lookupTableItem": {
		"entityTitle": "Lookup Table Item",
		"ID": "Record ID",
		"LookupTable": "Lookup Table",
		"LookupTableID": "Lookup Table",
		"Notes": "Notes",
		"From": "From",
		"To": "To",
		"Text": "Text",
		"ProductID": "Product ID",
		"RepresentativeID": "Representative ID",
		"Value": "Value",
	},
	"commissionPlan": {
		"entityTitle": "Commission Plan",
		"ID": "Record ID",
		"Description": "Description",
		"Rules": "Rules",
	},
	"commissionPlanRule": {
		"entityTitle": "Commission Plan Rule",
		"ID": "Record ID",
		"CommissionPlan": "Commission Plan",
		"CommissionPlanID": "Commission Plan",
		"CommissionRule": "Commission Rule",
		"CommissionRuleID": "Commission Rule",
	},
	"document": {
		"entityTitle": "Document",
		"ID": "Record ID",
		"Job": "Job",
		"JobID": "Job",
		"SendToCustomer": "Send To Customer",
		"SendToSupplier": "Send To Supplier",
		"SendToSuppierID": "Send To Suppier ID",
		"Uri": "Uri",
		"Description": "Description",
		"Tag": "Tag",
		"Protected": "Protected",
		"DocumentID": "Document ID",
		"Version": "Version",
		"DocumentDate_abbrev": "Date",
		"DocumentDate": "Document Date",
	},
	"notification": {
		"entityTitle": "Notification",
		"ID": "Record ID",
		"Subject": "Subject",
		"Message": "Message",
		"Expiry": "Expiry",
		"StartDate": "Start Date",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
		"NotificationID": "Notification ID",
	},
	"binaryDataField": {
		"entityTitle": "Binary Data Field",
		"ID": "Record ID",
		"Data": "Data",
	},
	"commissionAccountTx": {
		"entityTitle": "Commission Account Tx",
		"ID": "Record ID",
		"Date": "Date",
		"User": "User",
		"UserID": "Representative",
		"Run": "Run",
		"RunID": "Commission Run",
		"Type": "Type",
		"Description": "Description",
		"Status": "Status",
		"Amount": "Amount",
		"Notes": "Notes",
		"Details": "Details",
	},
	"commissionAccountTxDetail": {
		"entityTitle": "Commission Account Tx Detail",
		"ID": "Record ID",
		"Transaction": "Transaction",
		"TransactionID": "Commission Account Tx",
		"Job": "Job",
		"JobID": "Job",
		"Values": "Values",
	},
	"quoteTrackingView": {
		"entityTitle": "Quote Tracking View",
		"ID": "Record ID",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"Tags": "Tags",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"BusinessUnit": "Business Unit",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"QuoteDate": "Quote Date",
		"City": "City",
		"Paid": "Paid",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"DaysSinceSent": "Days Since Sent",
		"DaysToExpiry": "Days To Expiry",
		"IsActive": "Is Active",
		"IsOpen": "Is Open",
		"IsExpired": "Is Expired",
		"IsSent": "Is Sent",
		"IsLost": "Is Lost",
		"CommitReason": "Commit Reason",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
	},
	"activeJobTrackingView": {
		"entityTitle": "Active Job Tracking View",
		"ID": "Record ID",
		"Reference": "Reference",
		"SalesRep": "Sales Rep",
		"SalesRepMobile": "Sales Rep Mobile",
		"SalesRepID": "Sales Rep ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"OpportunityID": "Opportunity ID",
		"Contact": "Contact",
		"ContactEmail": "Contact Email",
		"ContactID": "Contact ID",
		"SiteContact": "Site Contact",
		"SiteContactID": "Site Contact ID",
		"City": "City",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"JobType": "Job Type",
		"Status": "Status",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"Paid": "Paid",
		"ETA": "ETA",
		"OrderDate": "Order Date",
		"SalePrice": "Sale Price",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"Stage": "Stage",
		"Stages": "Stages",
		"Products": "Products",
		"Dispatch": "Dispatch",
		"Tags": "Tags",
	},
	"opportunityTrackingView": {
		"entityTitle": "Opportunity Tracking View",
		"ID": "Record ID",
		"ID": "ID",
		"Description": "Description",
		"LeadDate": "Lead Date",
		"Forecast": "Forecast",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"LeadSource": "Lead Source",
		"PipelineStage_abbrev": "Stage",
		"PipelineStage": "Pipeline Stage",
		"Status": "Status",
		"ConfidenceLevel": "Confidence Level",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Representative": "Representative",
		"RepresentativeID": "Representative ID",
		"BusinessUnit": "Business Unit",
		"Tags": "Tags",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"SiteContactID": "Site Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"ExpectedRevenue_abbrev": "Forcast $",
		"ExpectedRevenue": "Expected Revenue",
		"ActivityID": "Activity ID",
		"ActivityContactID": "Activity Contact ID",
		"ActivityType": "Activity Type",
		"ActivityClosed": "Activity Closed",
		"ActivityConfirmed": "Activity Confirmed",
		"ActivityPriority": "Activity Priority",
		"ActivityStart": "Activity Start",
		"ActivitySubject": "Activity Subject",
		"Notes": "Notes",
	},
	"mFSchedulingTrackingView": {
		"entityTitle": "MF Scheduling Tracking View",
		"ID": "Record ID",
		"PurchaseOrderID": "Purchase Order ID",
		"OrderNumber": "Order Number",
		"JobID": "Job ID",
		"Job": "Job",
		"CustomerReference": "Customer Reference",
		"Contact": "Contact",
		"ContactID": "Contact ID",
		"Company": "Company",
		"CompanyID": "Company ID",
		"Supplier": "Supplier",
		"CommitReason": "Commit Reason",
		"BusinessUnit": "Business Unit",
		"Product": "Product",
		"Department": "Department",
		"DispatchDate": "Dispatch Date",
		"ETADate": "ETA Date",
		"Status": "Status",
		"MFDate": "MF Date",
		"OrderDate": "Order Date",
		"Internal": "Internal",
		"Qty": "Qty",
		"JobQty": "Job Qty",
		"DispatchDays": "Dispatch Days",
		"Age": "Age",
		"IDs": "I Ds",
		"SupplyNotes": "Supply Notes",
	},
	"job": {
		"entityTitle": "Job",
		"ID": "Record ID",
		"JobID": "Job ID",
		"QuoteExpiryDate_abbrev": "Expiry Date",
		"QuoteExpiryDate": "Quote Expiry Date",
		"HandoverDate": "Handover Date",
		"QuoteSentDate_abbrev": "Quote Sent",
		"QuoteSentDate": "Quote Sent Date",
		"Forecasted": "Forecasted",
		"IsConfirmed": "Is Confirmed",
		"IsClosed": "Is Closed",
		"IsLost": "Is Lost",
		"IgnoreDepositLimit": "Ignore Deposit Limit",
		"Status": "Status",
		"PaymentTerms": "Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"InvoiceDueDays": "Invoice Due Days",
		"SupplyNotes": "Supply Notes",
		"Reference": "Reference",
		"LinkedReference": "Linked Reference",
		"LinkedReferenceID": "Linked Reference ID",
		"ProjectCustomerPO_abbrev": "Project/Cust PO",
		"ProjectCustomerPO": "Project Customer PO",
		"DispatchSummary": "Dispatch Summary",
		"ProductSummary": "Product Summary",
		"StageSummary": "Stage Summary",
		"JobDate": "Job Date",
		"CloseDate": "Close Date",
		"ETADate": "ETA Date",
		"EstETADate": "Est ETA Date",
		"OrderDate": "Order Date",
		"Stage": "Stage",
		"JobType": "Job Type",
		"Priority": "Priority",
		"ShowroomQuote": "Showroom Quote",
		"InstallNotes": "Install Notes",
		"InternalNotes": "Internal Notes",
		"CustomerNotes": "Customer Notes",
		"PriceChangedWarning": "Price Changed Warning",
		"ProductOrder": "Product Order",
		"SalesRep": "Sales Rep",
		"SalesRepID": "Representative",
		"MarginApproved": "Margin Approved",
		"MarginApprovedBy": "Margin Approved By",
		"MarginApprovedByID": "Representative",
		"MarginApprovalRequired": "Margin Approval Required",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Delivery Address",
		"DeliveryAddressID": "Address",
		"Contact": "Contact",
		"ContactID": "Contact",
		"SiteContact": "Site Contact",
		"SiteContactID": "Contact",
		"Parent": "Parent",
		"ParentID": "Job",
		"ParentGroupKey": "Parent Group Key",
		"Company": "Company",
		"CompanyID": "Company",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"Actions": "Actions",
		"ActionsID": "Advanced Filters . Views . IV _ Job Actions",
		"JobLines": "Job Lines",
		"DiscountRules": "Discount Rules",
		"CustomStatus": "Custom Status",
		"PL_CustomStatusID_abbrev": "Custom Status",
		"PL_CustomStatusID": "Job Custom Status",
		"CommitmentReason": "Commitment Reason",
		"PL_CommitmentReasonID_abbrev": "Commit Reason",
		"PL_CommitmentReasonID": "Commitment Reason",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"NoSalesCommission": "No Sales Commission",
		"FileNotes": "File Notes",
		"Documents": "Documents",
		"Tags": "Tags",
		"ReportSettings": "Report Settings",
		"Tracking": "Tracking",
		"TrackingID": "Tracking",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
		"ItemID": "Item ID",
		"PriceListID": "Price List ID",
		"TaxRateValue": "Tax Rate Value",
		"CompanyPriceMappings": "Company Price Mappings",
	},
	"faultReason": {
		"entityTitle": "Fault Reason",
		"ID": "Record ID",
		"PL_FaultReasonID": "PL _ Fault Reason ID",
		"Description": "Description",
		"Active": "Active",
	},
	"lookupTable": {
		"entityTitle": "Lookup Table",
		"ID": "Record ID",
		"Name": "Name",
	},
	"teamRep": {
		"entityTitle": "Team Rep",
		"ID": "Record ID",
		"Team": "Team",
		"TeamID": "Team",
		"Rep": "Rep",
		"RepID": "Representative",
	},
	"projectTemplate": {
		"entityTitle": "Project Template",
		"ID": "Record ID",
		"Description": "Description",
		"ProjectTemplateID": "Project Template ID",
		"Stages": "Stages",
	},
	"projectStage": {
		"entityTitle": "Project Stage",
		"ID": "Record ID",
		"Description": "Description",
		"ActivityType": "Activity Type",
		"SortOrder": "Sort Order",
		"ActivityCategory": "Activity Category",
		"PL_ActivityCategoryID": "Activity Category",
		"ProjectTemplate": "Project Template",
		"ProjectTemplateID": "Project Template",
		"ProjectStageID": "Project Stage ID",
	},
	"projectStageView": {
		"entityTitle": "Project Stage View",
		"ID": "Record ID",
		"ProjectTemplateID": "Project Template ID",
		"JobLineID": "Job Line ID",
		"JobID": "Job ID",
		"Stage_0": "Stage _0",
		"Stage_1": "Stage _1",
		"Stage_2": "Stage _2",
		"Stage_3": "Stage _3",
		"Stage_4": "Stage _4",
		"Stage_5": "Stage _5",
		"Stage_6": "Stage _6",
		"Stage_7": "Stage _7",
		"Stage_8": "Stage _8",
		"Stage_9": "Stage _9",
		"Stage_10": "Stage _10",
		"Stage_11": "Stage _11",
		"Stage_12": "Stage _12",
		"Stage_13": "Stage _13",
		"Stage_14": "Stage _14",
		"Stage_15": "Stage _15",
		"Stage_16": "Stage _16",
		"Stage_17": "Stage _17",
		"Stage_18": "Stage _18",
		"Stage_19": "Stage _19",
		"Stage_20": "Stage _20",
		"Stage_21": "Stage _21",
		"Stage_22": "Stage _22",
		"Stage_23": "Stage _23",
		"Stage_24": "Stage _24",
		"Stage_25": "Stage _25",
		"Stage_26": "Stage _26",
		"Stage_27": "Stage _27",
		"Stage_28": "Stage _28",
		"Stage_29": "Stage _29",
		"Stage_30": "Stage _30",
		"Stage_0_ID": "Stage _0_ ID",
		"Stage_1_ID": "Stage _1_ ID",
		"Stage_2_ID": "Stage _2_ ID",
		"Stage_3_ID": "Stage _3_ ID",
		"Stage_4_ID": "Stage _4_ ID",
		"Stage_5_ID": "Stage _5_ ID",
		"Stage_6_ID": "Stage _6_ ID",
		"Stage_7_ID": "Stage _7_ ID",
		"Stage_8_ID": "Stage _8_ ID",
		"Stage_9_ID": "Stage _9_ ID",
		"Stage_10_ID": "Stage _10_ ID",
		"Stage_11_ID": "Stage _11_ ID",
		"Stage_12_ID": "Stage _12_ ID",
		"Stage_13_ID": "Stage _13_ ID",
		"Stage_14_ID": "Stage _14_ ID",
		"Stage_15_ID": "Stage _15_ ID",
		"Stage_16_ID": "Stage _16_ ID",
		"Stage_17_ID": "Stage _17_ ID",
		"Stage_18_ID": "Stage _18_ ID",
		"Stage_19_ID": "Stage _19_ ID",
		"Stage_20_ID": "Stage _20_ ID",
		"Stage_21_ID": "Stage _21_ ID",
		"Stage_22_ID": "Stage _22_ ID",
		"Stage_23_ID": "Stage _23_ ID",
		"Stage_24_ID": "Stage _24_ ID",
		"Stage_25_ID": "Stage _25_ ID",
		"Stage_26_ID": "Stage _26_ ID",
		"Stage_27_ID": "Stage _27_ ID",
		"Stage_28_ID": "Stage _28_ ID",
		"Stage_29_ID": "Stage _29_ ID",
		"Stage_30_ID": "Stage _30_ ID",
		"Stage_0_Closed": "Stage _0_ Closed",
		"Stage_1_Closed": "Stage _1_ Closed",
		"Stage_2_Closed": "Stage _2_ Closed",
		"Stage_3_Closed": "Stage _3_ Closed",
		"Stage_4_Closed": "Stage _4_ Closed",
		"Stage_5_Closed": "Stage _5_ Closed",
		"Stage_6_Closed": "Stage _6_ Closed",
		"Stage_7_Closed": "Stage _7_ Closed",
		"Stage_8_Closed": "Stage _8_ Closed",
		"Stage_9_Closed": "Stage _9_ Closed",
		"Stage_10_Closed": "Stage _10_ Closed",
		"Stage_11_Closed": "Stage _11_ Closed",
		"Stage_12_Closed": "Stage _12_ Closed",
		"Stage_13_Closed": "Stage _13_ Closed",
		"Stage_14_Closed": "Stage _14_ Closed",
		"Stage_15_Closed": "Stage _15_ Closed",
		"Stage_16_Closed": "Stage _16_ Closed",
		"Stage_17_Closed": "Stage _17_ Closed",
		"Stage_18_Closed": "Stage _18_ Closed",
		"Stage_19_Closed": "Stage _19_ Closed",
		"Stage_20_Closed": "Stage _20_ Closed",
		"Stage_21_Closed": "Stage _21_ Closed",
		"Stage_22_Closed": "Stage _22_ Closed",
		"Stage_23_Closed": "Stage _23_ Closed",
		"Stage_24_Closed": "Stage _24_ Closed",
		"Stage_25_Closed": "Stage _25_ Closed",
		"Stage_26_Closed": "Stage _26_ Closed",
		"Stage_27_Closed": "Stage _27_ Closed",
		"Stage_28_Closed": "Stage _28_ Closed",
		"Stage_29_Closed": "Stage _29_ Closed",
		"Stage_30_Closed": "Stage _30_ Closed",
	},
	"projectTrackingViewGrid": {
		"entityTitle": "Project Tracking View Grid",
		"ID": "Record ID",
		"ID": "ID",
		"JobID": "Job ID",
		"Reference": "Reference",
		"Company": "Company",
		"SiteContact": "Site Contact",
		"CustomStatus": "Custom Status",
		"ProjectCustomerPO": "Project Customer PO",
		"SiteAddress": "Site Address",
		"SalesRep": "Sales Rep",
		"SalesRepID": "Sales Rep ID",
		"Tags": "Tags",
		"HandoverDate": "Handover Date",
		"ProjectTemplateID": "Project Template ID",
		"CompanyID": "Company ID",
		"SiteContactID": "Site Contact ID",
		"OpportunityID": "Opportunity ID",
		"SiteAddressID": "Site Address ID",
		"ETA": "ETA",
		"SalePrice": "Sale Price",
		"ContactEmail": "Contact Email",
		"StageDate_0": "Stage Date _0",
		"StageDate_1": "Stage Date _1",
		"StageDate_2": "Stage Date _2",
		"StageDate_3": "Stage Date _3",
		"StageDate_4": "Stage Date _4",
		"StageDate_5": "Stage Date _5",
		"StageDate_6": "Stage Date _6",
		"StageDate_7": "Stage Date _7",
		"StageDate_8": "Stage Date _8",
		"StageDate_9": "Stage Date _9",
		"StageID_0": "Stage ID _0",
		"StageID_1": "Stage ID _1",
		"StageID_2": "Stage ID _2",
		"StageID_3": "Stage ID _3",
		"StageID_4": "Stage ID _4",
		"StageID_5": "Stage ID _5",
		"StageID_6": "Stage ID _6",
		"StageID_7": "Stage ID _7",
		"StageID_8": "Stage ID _8",
		"StageID_9": "Stage ID _9",
		"Action_CanMoveToSupply": "Action _ Can Move To Supply",
		"Action_CanResendToSupply": "Action _ Can Resend To Supply",
		"Action_CanUpdateETA": "Action _ Can Update ETA",
		"Action_CanReceiptGoods": "Action _ Can Receipt Goods",
		"Action_CanInvoice": "Action _ Can Invoice",
		"Products": "Products",
	},
	"projectTrackingView": {
		"entityTitle": "Project Tracking View",
		"ID": "Record ID",
		"JobID": "Job ID",
		"ProductID": "Product ID",
		"ProductName": "Product Name",
		"Stage": "Stage",
		"Reference": "Reference",
		"Company": "Company",
		"SiteContact": "Site Contact",
		"ContactEmail": "Contact Email",
		"CustomStatus": "Custom Status",
		"ProjectCustomerPO": "Project Customer PO",
		"SiteAddress": "Site Address",
		"SalesRep": "Sales Rep",
		"SalesRepID": "Sales Rep ID",
		"Tags": "Tags",
		"HandoverDate": "Handover Date",
		"ProjectTemplateID": "Project Template ID",
		"CompanyID": "Company ID",
		"SiteContactID": "Site Contact ID",
		"OpportunityID": "Opportunity ID",
		"SiteAddressID": "Site Address ID",
		"Action_CanMoveToSupply": "Action _ Can Move To Supply",
		"Action_CanResendToSupply": "Action _ Can Resend To Supply",
		"Action_CanUpdateETA": "Action _ Can Update ETA",
		"Action_CanReceiptGoods": "Action _ Can Receipt Goods",
		"Action_CanInvoice": "Action _ Can Invoice",
		"ETA": "ETA",
		"Qty": "Qty",
		"SalePrice": "Sale Price",
		"StageID_0": "Stage ID _0",
		"StageID_1": "Stage ID _1",
		"StageID_2": "Stage ID _2",
		"StageID_3": "Stage ID _3",
		"StageID_4": "Stage ID _4",
		"StageID_5": "Stage ID _5",
		"StageID_6": "Stage ID _6",
		"StageID_7": "Stage ID _7",
		"StageID_8": "Stage ID _8",
		"StageID_9": "Stage ID _9",
		"StageDate_0": "Stage Date _0",
		"StageDate_1": "Stage Date _1",
		"StageDate_2": "Stage Date _2",
		"StageDate_3": "Stage Date _3",
		"StageDate_4": "Stage Date _4",
		"StageDate_5": "Stage Date _5",
		"StageDate_6": "Stage Date _6",
		"StageDate_7": "Stage Date _7",
		"StageDate_8": "Stage Date _8",
		"StageDate_9": "Stage Date _9",
		"Adv_Q_P_0": "Adv _ Q _ P _0",
	},
	"mFDepartment": {
		"entityTitle": "MF Department",
		"ID": "Record ID",
		"PL_MFDepartmentID": "PL _ MF Department ID",
		"Description": "Description",
		"Active": "Active",
	},
	"journalEntry": {
		"entityTitle": "Journal Entry",
		"ID": "Record ID",
		"EntryDate": "Entry Date",
		"TransferDate": "Transfer Date",
		"Amount": "Amount",
		"Tax": "Tax",
		"Type": "Type",
		"Notes": "Notes",
		"JournalEntryID": "Journal Entry ID",
		"GroupID": "Group ID",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
	},
	"financeJournal": {
		"entityTitle": "Finance Journal",
		"ID": "Record ID",
		"EntryDate": "Entry Date",
		"TransferDate": "Transfer Date",
		"Amount": "Amount",
		"Tax": "Tax",
		"Type": "Type",
		"Notes": "Notes",
		"FinanceJournalID": "Finance Journal ID",
		"GroupID": "Group ID",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"ItemID": "Item ID",
		"ItemPrompt": "Item Prompt",
		"ContactID": "Contact ID",
		"PaymentID": "Payment ID",
		"JobID": "Job ID",
		"InvoiceID": "Invoice ID",
	},
	"orderLineLocation": {
		"entityTitle": "Order Line Location",
		"ID": "Record ID",
		"PL_OrderLineLocationID": "PL _ Order Line Location ID",
		"Description": "Description",
		"Active": "Active",
	},
	"quoteOutcomeReason": {
		"entityTitle": "Quote Outcome Reason",
		"ID": "Record ID",
		"PL_QuoteOutcomeReasonID": "PL _ Quote Outcome Reason ID",
		"Description": "Description",
		"Active": "Active",
	},
	"oppConfidenceLevel": {
		"entityTitle": "Opp Confidence Level",
		"ID": "Record ID",
		"PL_OppConfidenceLevelID": "PL _ Opp Confidence Level ID",
		"Description": "Description",
		"Active": "Active",
	},
	"jobLine": {
		"entityTitle": "Product Line",
		"ID": "Record ID",
		"JobLineNo_abbrev": "Line No",
		"JobLineNo": "Job Line No",
		"OrderDate": "Order Date",
		"ETA": "ETA",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"BasePrice": "Base Price",
		"BaseCost": "Base Cost",
		"InstallPrice": "Install Price",
		"InstallCost": "Install Cost",
		"OptionsPrice": "Options Price",
		"OptionsCost": "Options Cost",
		"DeliveryPrice": "Delivery Price",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentPrice": "Adjustment Price",
		"AdjustmentCost": "Adjustment Cost",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Job": "Job",
		"JobID": "Job",
		"SplitParent": "Split Parent",
		"SplitParentID": "Job Line",
		"JobLineService": "Job Line Service",
		"JobLineServiceID": "Job Line Service",
		"RequireCheckMeasure": "Require Check Measure",
		"CM_Approved": "CM _ Approved",
		"Invoiced": "Invoiced",
		"SupplyJobCreated": "Supply Job Created",
		"RequireInstall": "Require Install",
		"RequireDelivery": "Require Delivery",
		"RequirePickup": "Require Pickup",
		"RequireService": "Require Service",
		"Inst_Completed": "Inst _ Completed",
		"EstInstallTime": "Est Install Time",
		"IsService": "Is Service",
		"Srvc_Completed": "Srvc _ Completed",
		"QtyReceived": "Qty Received",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"ProductVersion": "Product Version",
		"PriceListVersion": "Price List Version",
		"SortIndex": "Sort Index",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"InstallationNotes": "Installation Notes",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"PreviousStage": "Previous Stage",
		"DispatchStage": "Dispatch Stage",
		"Status": "Status",
		"Stage": "Stage",
		"LineType": "Line Type",
		"StageDate": "Stage Date",
		"OrderLineOptionsData": "Order Line Options Data",
		"JobLineID": "Job Line ID",
		"ValidationErrors": "Validation Errors",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"IsConfirmed": "Is Confirmed",
		"IsDispatched": "Is Dispatched",
		"HasActiveService": "Has Active Service",
		"CommissionDate1": "Commission Date 1",
		"GroupKey": "Group Key",
		"PriceModel": "Price Model",
		"RelatedPOLines": "Related PO Lines",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
	},
	"jobLineInfo": {
		"entityTitle": "Job Line Info",
		"ID": "Record ID",
		"JobID": "Job ID",
		"CheckMeasureID": "Check Measure ID",
		"CM_PL_ActivityCategoryID": "CM _ PL _ Activity Category ID",
		"CM_ActivityType": "CM _ Activity Type",
		"CM_Representative": "CM _ Representative",
		"CM_RepresentativeID": "CM _ Representative ID",
		"CM_Team": "CM _ Team",
		"CM_TeamID": "CM _ Team ID",
		"CM_Subject": "CM _ Subject",
		"CM_Priority": "CM _ Priority",
		"CM_Confirmed": "CM _ Confirmed",
		"CM_Closed": "CM _ Closed",
		"CM_ContactID": "CM _ Contact ID",
		"CM_Start": "CM _ Start",
		"CM_CreatedOn": "CM _ Created On",
		"InstallID": "Install ID",
		"IA_PL_ActivityCategoryID": "IA _ PL _ Activity Category ID",
		"IA_ActivityType": "IA _ Activity Type",
		"IA_RepresentativeID": "IA _ Representative ID",
		"IA_Representative": "IA _ Representative",
		"IA_TeamID": "IA _ Team ID",
		"IA_Team": "IA _ Team",
		"IA_Subject": "IA _ Subject",
		"IA_Priority": "IA _ Priority",
		"IA_Confirmed": "IA _ Confirmed",
		"IA_Closed": "IA _ Closed",
		"IA_ContactID": "IA _ Contact ID",
		"IA_Start": "IA _ Start",
		"IA_CreatedOn": "IA _ Created On",
		"Tracking_IsNotValid": "Tracking _ Is Not Valid",
	},
	"templateResponse": {
		"entityTitle": "Template Response",
		"ID": "Record ID",
		"Template": "Template",
		"TemplateID": "Template ID",
		"Condition": "Condition",
		"Value": "Value",
		"Action": "Action",
		"ResponseTimeout": "Response Timeout",
		"ModifyTags": "Modify Tags",
		"TagAction": "Tag Action",
		"Tags": "Tags",
	},
	"paymentAllocation": {
		"entityTitle": "Payment Allocation",
		"ID": "Record ID",
		"Payment": "Payment",
		"PaymentID": "Payment",
		"Amount": "Amount",
		"Job": "Job",
		"JobID": "Job",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice",
		"PaymentAllocationID": "Payment Allocation ID",
		"HasRefund": "Has Refund",
	},
	"invoiceLine": {
		"entityTitle": "Invoice Line",
		"ID": "Record ID",
		"LineNo_abbrev": "Line No",
		"LineNo": "Line No",
		"Description": "Description",
		"InvoiceLineID": "Invoice Line ID",
		"Invoice": "Invoice",
		"InvoiceID": "Invoice",
		"UnitPrice": "Unit Price",
		"Qty": "Qty",
		"Amount": "Amount",
		"Tax": "Tax",
		"AmountIncTax": "Amount Inc Tax",
		"ProductName": "Product Name",
		"ProductID": "Product ID",
		"Status": "Status",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"TaxRate": "Tax Rate",
		"TaxRateID": "Tax Rate",
		"Locked": "Locked",
		"ChangeStatus": "Change Status",
		"Notes": "Notes",
	},
	"documentTemplate": {
		"entityTitle": "Document Template",
		"ID": "Record ID",
		"Name": "Name",
		"Type": "Type",
		"TemplateID": "Template ID",
		"TemplateParams": "Template Params",
	},
	"blobItem": {
		"entityTitle": "Blob Item",
		"ID": "Record ID",
		"Data": "Data",
		"BlobItemID": "Blob Item ID",
	},
	"activityRep": {
		"entityTitle": "Activity Rep",
		"ID": "Record ID",
		"Activity": "Activity",
		"ActivityID": "Activity",
		"Rep": "Rep",
		"RepID": "Representative",
	},
	"leadDiscardReason": {
		"entityTitle": "Lead Discard Reason",
		"ID": "Record ID",
		"PL_LeadDiscardReasonID": "PL _ Lead Discard Reason ID",
		"Description": "Description",
		"Active": "Active",
	},
	"organisationType": {
		"entityTitle": "Organisation Type",
		"ID": "Record ID",
		"PL_OrganisationTypeID": "PL _ Organisation Type ID",
		"Description": "Description",
		"Active": "Active",
	},
	"activityJobLine": {
		"entityTitle": "Activity Job Line",
		"ID": "Record ID",
		"Job": "Job",
		"JobID": "Job",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"Activity": "Activity",
		"ActivityID": "Activity",
	},
	"payment": {
		"entityTitle": "Payment",
		"ID": "Record ID",
		"ReceiptNo": "Receipt No",
		"Date": "Date",
		"Method": "Method",
		"PL_PaymentMethodID_abbrev": "Method",
		"PL_PaymentMethodID": "Payment Method",
		"Amount": "Amount",
		"Type": "Type",
		"Status": "Status",
		"Contact": "Contact",
		"ContactID": "Contact",
		"Notes": "Notes",
		"CreditNote": "Credit Note",
		"CreditNoteID": "Invoice",
		"FinanceArchived": "Finance Archived",
		"IsDeposit": "Is Deposit",
		"PaymentAllocations": "Payment Allocations",
	},
	"tag": {
		"entityTitle": "Tag",
		"ID": "Record ID",
		"PL_TagID": "PL _ Tag ID",
		"Description": "Description",
		"Active": "Active",
	},
	"tagRelated": {
		"entityTitle": "Tag Related",
		"ID": "Record ID",
		"Tag": "Tag",
		"TagID": "Tag",
		"Job": "Job",
		"JobID": "Job",
		"Opportunity": "Opportunity",
		"OpportunityID": "Opportunity",
		"TemplateResponse": "Template Response",
		"TemplateResponseID": "Template Response",
	},
	"externalLead": {
		"entityTitle": "External Lead",
		"ID": "Record ID",
		"Date": "Date",
		"Processed": "Processed",
		"SnoozeDate": "Snooze Date",
		"ContactFirstName": "Contact First Name",
		"ContactLastName": "Contact Last Name",
		"ContactEmail": "Contact Email",
		"ContactMobile": "Contact Mobile",
		"ContactWorkPh": "Contact Work Ph",
		"ContactHomePh": "Contact Home Ph",
		"LeadData": "Lead Data",
		"LeadSource": "Lead Source",
		"PL_LeadSourceID_abbrev": "Lead Source",
		"PL_LeadSourceID": "Lead Source",
		"DiscardNotes": "Discard Notes",
		"PL_LeadDiscardReason": "PL _ Lead Discard Reason",
		"PL_LeadDiscardReasonID": "Lead Discard Reason",
	},
	"representative": {
		"entityTitle": "Representative",
		"ID": "Record ID",
		"FirstName": "First Name",
		"LastName": "Last Name",
		"FullName": "Full Name",
		"Email": "Email",
		"RepresentativeID": "Representative ID",
		"UserID": "User ID",
		"FeatureAllowSetup": "Feature Allow Setup",
		"FeatureAllowManagement": "Feature Allow Management",
		"FeatureAllowSendOrder": "Feature Allow Send Order",
		"FeatureAllowViewCostPrice": "Feature Allow View Cost Price",
		"FeatureCanViewSalesData": "Feature Can View Sales Data",
		"FeatureCanViewCalendars": "Feature Can View Calendars",
		"FeatureAllowQuoting": "Feature Allow Quoting",
		"FeatureShowInCalendar": "Feature Show In Calendar",
		"FeatureSyncCalendar": "Feature Sync Calendar",
		"FeatureCanViewFinance": "Feature Can View Finance",
		"FeatureCanViewProjects": "Feature Can View Projects",
		"FeatureAllowInstallations": "Feature Allow Installations",
		"FeatureCanProcessWebLeads": "Feature Can Process Web Leads",
		"FeatureAllowEditReadOnlyOpportunities": "Feature Allow Edit Read Only Opportunities",
		"FeatureAllowOpenExpiredQuotes": "Feature Allow Open Expired Quotes",
		"FeatureAllowPerformCheckMeasure": "Feature Allow Perform Check Measure",
		"FeatureAllowApproveCheckMeasure": "Feature Allow Approve Check Measure",
		"FeatureAllowDiscounting": "Feature Allow Discounting",
		"FeatureAllowEditJobs": "Feature Allow Edit Jobs",
		"FeatureAllowChangeBusinessUnit": "Feature Allow Change Business Unit",
		"FeatureAllowCreateServiceJob": "Feature Allow Create Service Job",
		"FeatureAllowChangeCostPrice": "Feature Allow Change Cost Price",
		"FeatureAllowOverrideDispatch": "Feature Allow Override Dispatch",
		"FeatureAllowRunCommissions": "Feature Allow Run Commissions",
		"FeatureAllowEditCommissionDate": "Feature Allow Edit Commission Date",
		"FeatureAllowViewEstimatedCommissions": "Feature Allow View Estimated Commissions",
		"FeatureAllowDeleteAppointment": "Feature Allow Delete Appointment",
		"FeatureAllowReceiptGoods": "Feature Allow Receipt Goods",
		"Active": "Active",
		"DefaultTeam": "Default Team",
		"DefaultTeamID": "Team",
		"DefaultBusinessUnit": "Default Business Unit",
		"DefaultBusinessUnitID": "Business Unit",
		"CalendarColor": "Calendar Color",
		"Licensed": "Licensed",
		"LicenseIDs": "License I Ds",
		"CalendarSettings": "Calendar Settings",
		"ApplicationSettings": "Application Settings",
		"VersionSettings": "Version Settings",
		"Mobile": "Mobile",
		"WorkPhone": "Work Phone",
		"Address": "Address",
		"AddressID": "Address",
		"JobTitle": "Job Title",
		"EmailSignature": "Email Signature",
		"CalendarID": "Calendar ID",
		"CommissionRules": "Commission Rules",
	},
	"address": {
		"entityTitle": "Address",
		"ID": "Record ID",
		"AddressLines": "Address Lines",
		"City": "City",
		"State": "State",
		"Postcode": "Postcode",
		"Country": "Country",
		"Location": "Location",
		"PosLong": "Pos Long",
		"PosLat": "Pos Lat",
		"AddressID": "Address ID",
		"Formatted": "Formatted",
	},
	"template": {
		"entityTitle": "Template",
		"ID": "Record ID",
		"Description": "Description",
		"Type": "Type",
		"Category": "Category",
		"Subject": "Subject",
		"Body": "Body",
		"IsHTML": "Is HTML",
		"TemplateID": "Template ID",
		"Document": "Document",
		"Version": "Version",
		"AllowReply": "Allow Reply",
	},
	"product": {
		"entityTitle": "Product",
		"ID": "Record ID",
		"ProductName": "Product Name",
		"Active": "Active",
		"ServiceProduct": "Service Product",
		"NotesTemplate": "Notes Template",
		"ProductID": "Product ID",
		"Supplier": "Supplier",
		"SupplierID": "Supplier",
		"BlobUrl": "Blob Url",
		"CutSheetTemplate": "Cut Sheet Template",
		"CutSheetTemplateID": "Binary Data Field",
		"CutSheetPrintType_abbrev": "Attach As",
		"CutSheetPrintType": "Cut Sheet Print Type",
	},
	"automation": {
		"entityTitle": "Automation",
		"ID": "Record ID",
		"AutomationID": "Automation ID",
		"Name": "Name",
		"Notes": "Notes",
		"Type": "Type",
		"SMSTemplate": "SMS Template",
		"SMSTemplateID": "Template",
		"EmailTemplate": "Email Template",
		"EmailTemplateID": "Template",
		"SendTo": "Send To",
		"ScheduleTimeframe": "Schedule Timeframe",
		"ScheduleAmount": "Schedule Amount",
		"ScheduleTimeOfDay": "Schedule Time Of Day",
		"Confirmed": "Confirmed",
		"BalanceOutstanding": "Balance Outstanding",
		"AppointmentType": "Appointment Type",
		"DispatchType": "Dispatch Type",
		"JobType": "Job Type",
		"Enabled": "Enabled",
		"SmsDeliveryReceipt": "Sms Delivery Receipt",
	},
	"gridFilter": {
		"entityTitle": "Grid Filter",
		"ID": "Record ID",
		"Name": "Name",
		"Filter": "Filter",
		"EntityType": "Entity Type",
		"Owner": "Owner",
		"OwnerID": "Representative",
		"FilterAccess": "Filter Access",
	},
	"commissionValue": {
		"entityTitle": "Commission Value",
		"ID": "Record ID",
		"Name": "Name",
		"SummaryType": "Summary Type",
		"Filter": "Filter",
		"Expression": "Expression",
		"GroupBy": "Group By",
		"SummaryBy": "Summary By",
		"ShowOnStatement": "Show On Statement",
		"IsText": "Is Text",
		"SortOrder": "Sort Order",
	},
	"businessUnit": {
		"entityTitle": "Business Unit",
		"ID": "Record ID",
		"BusinessUnitID": "Business Unit ID",
		"Name": "Name",
		"Logo": "Logo",
		"BusinessNumber": "Business Number",
		"Phone": "Phone",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"Locale": "Locale",
		"TimeZone": "Time Zone",
		"Address": "Address",
		"AddressID": "Address",
		"DeliveryAddress": "Delivery Address",
		"DeliveryAddressID": "Address",
		"BillingAddress": "Billing Address",
		"BillingAddressID": "Address",
		"ProductExclusions": "Product Exclusions",
	},
	"bUProductExclusion": {
		"entityTitle": "BU Product Exclusion",
		"ID": "Record ID",
		"BusinessUnit": "Business Unit",
		"BusinessUnitID": "Business Unit",
		"ProductID": "Product ID",
	},
	"orderLine": {
		"entityTitle": "Product Line",
		"ID": "Record ID",
		"OrderLineNo_abbrev": "Line No",
		"OrderLineNo": "Order Line No",
		"Width": "Width",
		"Drop": "Drop",
		"Qty": "Qty",
		"CostPrice": "Cost Price",
		"CostPriceTax": "Cost Price Tax",
		"CostPriceIncTax": "Cost Price Inc Tax",
		"BaseCost": "Base Cost",
		"InstallCost": "Install Cost",
		"OptionsCost": "Options Cost",
		"DeliveryCost": "Delivery Cost",
		"AdjustmentCost": "Adjustment Cost",
		"WidthMeasurements": "Width Measurements",
		"DropMeasurements": "Drop Measurements",
		"UndoCancelState": "Undo Cancel State",
		"SupportsOptimisation": "Supports Optimisation",
		"Order": "Order",
		"OrderID": "Purchase Order",
		"LocationOther_abbrev": "Location/Desc",
		"LocationOther": "Location Other",
		"ProductName": "Product Name",
		"ProductCode": "Product Code",
		"ProductID": "Product ID",
		"SupplierName": "Supplier Name",
		"SupplierID": "Supplier ID",
		"Notes": "Notes",
		"SupplyNotes": "Supply Notes",
		"Status": "Status",
		"OrderLineOptionsData": "Order Line Options Data",
		"ConsolidationData": "Consolidation Data",
		"PriceModel": "Price Model",
		"RelatedJobLines": "Related Job Lines",
		"MFDate": "MF Date",
		"Department": "Department",
		"PL_MFDepartmentID_abbrev": "Department",
		"PL_MFDepartmentID": "MF Department",
		"OrderLineID": "Order Line ID",
		"DisplayOption1": "Display Option 1",
		"DisplayOption2": "Display Option 2",
		"DisplayOption3": "Display Option 3",
		"DisplayOption4": "Display Option 4",
		"DisplayOption5": "Display Option 5",
		"DisplayOption6": "Display Option 6",
		"DisplayOption7": "Display Option 7",
		"DisplayOption8": "Display Option 8",
		"DisplayOption9": "Display Option 9",
		"DisplayOption10": "Display Option 10",
		"LineNo": "Line No",
		"ItemID": "Item ID",
		"LineItemID": "Line Item ID",
		"InstallationNotes": "Installation Notes",
		"Price": "Price",
		"PriceTax": "Price Tax",
		"SalePrice": "Sale Price",
		"SalePriceTax": "Sale Price Tax",
		"PriceIncTax": "Price Inc Tax",
		"SalePriceIncTax": "Sale Price Inc Tax",
		"OriginalCostPrice": "Original Cost Price",
		"OriginalCostPriceTax": "Original Cost Price Tax",
		"OriginalCostPriceIncTax": "Original Cost Price Inc Tax",
		"BasePrice": "Base Price",
		"InstallPrice": "Install Price",
		"OptionsPrice": "Options Price",
		"DeliveryPrice": "Delivery Price",
		"AdjustmentPrice": "Adjustment Price",
		"SalesTaxRate": "Sales Tax Rate",
		"CostTaxRate": "Cost Tax Rate",
		"ValidationErrors": "Validation Errors",
		"PriceListVersion": "Price List Version",
	},
	"jobLineOrderLine": {
		"entityTitle": "Job Line Order Line",
		"ID": "Record ID",
		"OrderLine": "Order Line",
		"OrderLineID": "Order Line",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"CostRatio": "Cost Ratio",
	},
	"company": {
		"entityTitle": "Company",
		"ID": "Record ID",
		"Name": "Name",
		"Phone": "Phone",
		"Fax": "Fax",
		"Mobile": "Mobile",
		"Email": "Email",
		"WWW": "WWW",
		"DefaultPaymentTerms": "Default Payment Terms",
		"DefaultPaymentDue": "Default Payment Due",
		"InvoiceDueDays": "Invoice Due Days",
		"Notes": "Notes",
		"OrganisationType": "Organisation Type",
		"PL_OrganisationTypeID": "Organisation Type",
		"Address": "Address",
		"AddressID": "Address",
		"PriceListID": "Price List ID",
		"BillingContact": "Billing Contact",
		"BillingContactID": "Contact",
		"FileNotes": "File Notes",
		"PriceMappings": "Price Mappings",
		"AccountType": "Account Type",
		"CompanyID": "Company ID",
		"ReceiveAutomatedEmail": "Receive Automated Email",
		"ReceiveAutomatedSMS": "Receive Automated SMS",
	},
	"leadSource": {
		"entityTitle": "Lead Source",
		"ID": "Record ID",
		"PL_LeadSourceID": "PL _ Lead Source ID",
		"Description": "Description",
		"Active": "Active",
	},
	"entitySnapshot": {
		"entityTitle": "Entity Snapshot",
		"ID": "Record ID",
		"Name": "Name",
		"Date": "Date",
		"SnapshotData": "Snapshot Data",
		"JobLine": "Job Line",
		"JobLineID": "Job Line",
		"Job": "Job",
		"JobID": "Job",
		"OtherID": "Other ID",
	},
	"contactJobCategory": {
		"entityTitle": "Contact Job Category",
		"ID": "Record ID",
		"PL_ContactJobCategoryID": "PL _ Contact Job Category ID",
		"Description": "Description",
		"Active": "Active",
	},
	"invoiceStatementView": {
		"entityTitle": "Invoice Statement View",
		"ID": "Record ID",
		"InvoiceDate": "Invoice Date",
		"DueDate": "Due Date",
		"InvoiceNo": "Invoice No",
		"ProjectCustomerPO": "Project Customer PO",
		"CompanyID": "Company ID",
		"Company": "Company",
		"CompanyMobile": "Company Mobile",
		"CompanyPhone": "Company Phone",
		"CompanyFax": "Company Fax",
		"ContactID": "Contact ID",
		"Contact": "Contact",
		"ContactHomePhone": "Contact Home Phone",
		"ContactMobile": "Contact Mobile",
		"ContactWorkPhone": "Contact Work Phone",
		"Email": "Email",
		"Address": "Address",
		"DaysOverdue": "Days Overdue",
		"Amount": "Amount",
		"Overdue": "Overdue",
		"Paid": "Paid",
		"Balance": "Balance",
	},
	"authToken": {
		"entityTitle": "Auth Token",
		"ID": "Record ID",
		"Token": "Token",
		"Type": "Type",
		"Application": "Application",
		"Date": "Date",
		"Representative": "Representative",
		"RepresentativeID": "Representative",
	},
	"commissionAmendmentCategory": {
		"entityTitle": "Commission Amendment Category",
		"ID": "Record ID",
		"PL_CommissionAmendmentCategoryID": "PL _ Commission Amendment Category ID",
		"Description": "Description",
		"Active": "Active",
	},
	"PL_ActivityColor": {
		"Color": "Colour"
	}
};






