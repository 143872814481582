import * as React from 'react'
import { GridColumnMenuFilter, GridColumnMenuCheckboxFilter} from '@progress/kendo-react-grid';
import { accentUtils, from } from '../../services/HelperService';
import { AccentSpinner } from '../AccentSpinner';



const toText = (filter, field, data) => {

    if (accentUtils.isNull(filter)) return filter;

    let filterStr = JSON.stringify(filter);

    const translateRegex = new RegExp(`"field":"${field}".*?"value":"(?<txt>.*?)"`, "g");


    for (const match of filterStr.matchAll(translateRegex)) {


        const translation = from(data).firstOrDefault(x => x.ID === match[1]);

        if (!accentUtils.isNull(translation)) {
            filterStr = filterStr.replace(match[0], match[0].replace(`"${match[1]}"`, `"${translation[field]}"`));
        }
    }

    return JSON.parse(filterStr);


};


const fromText = (filter, field, data) => {

    if (accentUtils.isNull(filter)) return filter;

    let filterStr = JSON.stringify(filter);

    const translateRegex = new RegExp(`"field":"${field}".*?"value":"(?<txt>.*?)"`, "g");


    for (const match of filterStr.matchAll(translateRegex)) {


        const translation = from(data).firstOrDefault(x => x[field] === match[1]);

        if (!accentUtils.isNull(translation)) {
            filterStr = filterStr.replace(match[0], match[0].replace(`"${match[1]}"`, `"${translation.ID}"`));
        }
    }

    return JSON.parse(filterStr);

}


export const ColumnMenu = React.memo((props) => {

    return (
        <div>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>
    );    
});




export const ColumnMenuCheckboxFilter = (props) => {


    const [data, setData] = React.useState(null);



    const onFilterChange = e => {


        if (props.requireTranslate) {
            props.onFilterChange(fromText(e, props.column.field, data));

        } else {
            props.onFilterChange(e);
        }        
        
    };


    React.useEffect(() => {

        props.query().then(res => {
            setData(res);
        });


    },[]);


    if (accentUtils.isNull(data)) return <AccentSpinner />;


    const filter = props.requireTranslate ? toText(props.filter, props.column.field, data) : props.filter;

    return (
        <div>
            <GridColumnMenuCheckboxFilter
                {...props}                
                filter={filter}
                onFilterChange={onFilterChange }
                data={data}
                expanded={true}
            />
        </div>
    );
};