export class AccentUtils {


    calculateMargin(price, cost) {
        return (price === 0 ? 0 : (price - cost) / price).toFixed(2);
    }

    today() {
        var date = new Date();
        date.setHours(0, 0, 0, 0);

        return date;
    }


    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    dateDiff(firstDate, secondDate) {
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

        var first = new Date(firstDate);
        first.setHours(0, 0, 0);
        var second = new Date(secondDate);
        second.setHours(0, 0, 0);

        var diffDays = Math.round(((first.getTime() - second.getTime()) / (oneDay)));

        return diffDays;

    }

    createBlob(b64Data, contentType = '') {

        const blob = accentUtils.b64toBlob(b64Data, contentType)

        return URL.createObjectURL(blob);

    }

    proRata(total, rounding, items) {
        var baseItems = items.map(i => window.InsyteProduct.InsyteWeb.ProRataItem.FromJS(i.ID, i.BaseAmount));

        var calc = new window.InsyteProduct.InsyteWeb.ProrataCalculator();
        return calc.Calculate(new window.System.Decimal(total), rounding, baseItems).map(r => r.ToJS());
    }



    isNull(value) {
        if (typeof value === 'undefined' || value == null) {
            return true;
        };

        return false;
    }

    isNullOrEmpty(value) {
        return accentUtils.isNull(value) || value == "" || value == 0 || value == '00000000-0000-0000-0000-000000000000' || value == [] || typeof value === 'undefined' || value == null;
    }

    getEmptyGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }

    isEmpty(value) {

        return accentUtils.isNull(value) || value == "" || value === 0 || value === '00000000-0000-0000-0000-000000000000' || value == [];
    }

    round(n) {

        if (accentUtils.isNull(n)) {
            n = 0;
        }

        if (Math.abs(n) < 0.0001) {
            n = 0;
        }

        if (n < 0) {
            return accentUtils.round(-1 * n) * -1;
        }

        return +(Math.round(n + "e+2") + "e-2");
    }
    roundUp(x, d) {
        var res = Math.pow(10, d);
        return Math.ceil(res * x) / res;
    }
    roundDown(x, d) {
        var res = Math.pow(10, d);
        return Math.floor(res * x) / res;
    }

    roundNormal(x, d) {

        if (x < 0) {

            var px = -1 * x;

            return -1 * Math.round(Math.pow(10, d) * px) / (Math.pow(10, d));
        }

        return Math.round(Math.pow(10, d) * x) / (Math.pow(10, d));
    }

    getCountryCode(country, user) {

        if (accentUtils.isEmpty(country) && user) {
            country = user.DefaultSettings.Country
        }


        var res = 'AU';

        if (!accentUtils.isEmpty(country)) {

            if (country.toUpperCase() === "NEW ZEALAND") {
                res = 'NZ';
            }

        }

        return res;

    }

    stringsToDates(obj) {

        // Simple isObject and isArray functions
        let isObj = value => Object.prototype.toString.call(value) == '[object Object]';
        let isArr = value => Array.isArray(value);
        // Test if value is string date, object or array and convert, recurse or ignore
        let resolveValue = (value, key, obj) => {
            if (typeof value == 'string' && re.test(value)) {
                obj[key] = accentUtils.toDate(value);
            } else if (isArr(value) || isObj(value)) {
                go(value);
            }
        }

        // Regular expression for ISO date string
        var re = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z?$/;

        // Recursive function looking for strings matching re
        function go(obj) {

            if (isArr(obj)) {
                obj.forEach((value, i, arr) => resolveValue(value, i, arr));

            } else if (isObj(obj)) {
                Object.keys(obj).forEach(key => resolveValue(obj[key], key, obj))
            }

            return obj;
        }
        return go(obj);
    }

    toPlanObject(entity) {
        if (accentUtils.isNull(entity)) {
            return null;
        }

        var res = {};

        if (!accentUtils.isNull(entity._backingStore)) {
            entity = entity._backingStore;
        }



        var values = Object.entries(entity);


        for (const [key, value] of values) {

            if (!accentUtils.isNull(value) && !accentUtils.isNull(value.entityAspect)) {
                res[key] = accentUtils.toPlanObject(value);
            } else if (key !== "_backingStore" && key !== "entityAspect") {
                res[key] = value;
            }

        }


        return res;
    }
    isEmail(email) {
        if (accentUtils.isEmpty(email)) {
            return false;
        }

        var r = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return r.test(email.toLowerCase());
    }

    stringInsert(main_string, ins_string, pos) {
        if (accentUtils.isNull(pos)) {
            pos = main_string.length;
        }
        if (accentUtils.isNull(ins_string)) {
            ins_string = '';
        }
        if (accentUtils.isNull(main_string)) {
            main_string = '';
        }
        return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
    }

    titleCase(str) {

        if (accentUtils.isEmail(str) || accentUtils.isNull(str)) {
            return str;
        }

        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    }



    fullNameToObject(fullname, titleCase) {


        var res = {
            FirstName: "",
            LastName: "",
            FullName: ""
        };

        if (!accentUtils.isEmpty(fullname)) {

            var parts = fullname.split(' ');

            var firstName = parts[0];
            var lastName = "";
            if (parts.length > 1) {
                lastName = parts.splice(1).join(' ');
            }


            if (titleCase) {
                firstName = accentUtils.titleCase(firstName);
                lastName = accentUtils.titleCase(lastName);
                fullname = firstName + " " + lastName;
            }

            res.FirstName = firstName;
            res.LastName = lastName;
            res.FullName = fullname;


        }
        return res;
    }


}



const accentUtils = new AccentUtils();

export default accentUtils;