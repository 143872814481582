import { from, accentUtils , goTo, showOK, t, showDialog, hideDialog} from './HelperService'
import { queryNoTracking, update } from './DataService'
import * as AppSettingsService from './AppSettingsService'
import { getAccess } from './UserService';


const query_Activity_ForViewInCalendar = queryNoTracking("Activity_ForViewInCalendar");
const query_Activity_IsCMImprogress = queryNoTracking("Activity_IsCMImprogress");
const query_Activity_RequirePromptUserIfJobTaskCompleted = queryNoTracking("Activity_RequirePromptUserIfJobTaskCompleted");
const query_PL_ActivityColors = queryNoTracking("PL_ActivityColors");

let activityColors = null;

class ActivityHelper {

    typeTask = "Task";

    typeSales = "Sales Appointment";
    typeCheckMeasure = "Check Measure";
    typeInstall = "Dispatch Appointment";

    typeWarranty = "Warranty";
    typeService = "Service";
    typeRework = "Rework";
    typeLeave = "Leave";
    typeCalendarNote = "Calendar Note";
    typeShowroomQuote = "Showroom Quote";

    activityTypes = ["Task", "Sales Appointment", "Check Measure", "Dispatch Appointment", "Warranty", "Service", "Rework", "Leave", "Calendar Note", "Showroom Quote"];

    getActivityTypePicklistSource() {
        var res = [];//[{ ID: "", Description: "" }];

        from(Helper.activityTypes).orderBy(v => v).toArray().forEach(function (t) {
            res.push({
                ID: t,
                Description: t
            });
        });

        return res;
    }

    activityPriorities = ["priority_activity_high", "priority_activity_medium", "priority_activity_low"];
    getActivityPriorityPicklistSource() {
        var res = [];//[{ ID: "", Description: "" }];

        Helper.activityPriorities.forEach(function (ty) {
            res.push({
                ID: ty,
                Description: t(ty)
            });
        });

        return res;
    }

    priorityHigh = "priority_activity_high";
    priorityMedium = "priority_activity_medium";
    priorityLow = "priority_activity_low";


    showInCalendar(id) {

        query_Activity_ForViewInCalendar.getFirstOrDefault({id: id }).then(function (a) {
            goTo("/Calendar", { showAppointment: { apptID: a.ID, repID: a.RepresentativeID, type: a.ActivityType, date: new Date(a.Start) } });
        });


    }

    activityTypeCanHaveJob(activityType) {
        return activityType === Helper.typeInstall ||
            activityType === Helper.typeCheckMeasure || activityType === Helper.typeTask
            || activityType === Helper.typeService || activityType === Helper.typeWarranty
            || activityType === Helper.typeRework;
    }

    activityTypeCanHaveOpportunity(activityType) {
        return activityType === Helper.typeSales ||
            activityType === Helper.typeShowroomQuote || activityType === Helper.typeTask;
    }



    activityRequiresContact(activity) {

        if (activity.ActivityType === Helper.typeCalendarNote)
            return false;
        else if (activity.ActivityType === Helper.typeTask)
            return false;
        else if (activity.ActivityType === Helper.typeLeave)
            return false;
        else if (activity.ActivityType === Helper.typeTask)
            return false;
        else
            return true;
    }

    activityRequiresAddress(activity) {

        if (activity.ActivityType === Helper.typeCalendarNote) {
            return false;
        } else if (activity.ActivityType === Helper.typeTask) {
            return false;
        } else if (activity.ActivityType === Helper.typeLeave) {
            return false;
        } else if (activity.ActivityType === Helper.typeShowroomQuote) {
            return false;
        } else {
            return true;
        }
    }
    defaultContactDetails(appt, parent) {

        var contactID = parent.SiteContactID;
        var companyID = null;

        if (parent.SiteContactID === parent.ContactID) {
            companyID = parent.CompanyID
        }

        appt.ContactID = contactID;
        appt.CompanyID = companyID;

    }

    copyAddress(appt, address) {
        appt.Address.AddressLines = address.AddressLines;
        appt.Address.City = address.City;
        appt.Address.State = address.State;
        appt.Address.Postcode = address.Postcode;
        appt.Address.Country = address.Country;
        appt.Address.PosLat = address.PosLat;
        appt.Address.PosLong = address.PosLong;
    }


    isProductValid(jobIsConfirmed, productStage) {


        if (!jobIsConfirmed) {
            return true;
        }

        return productStage !== "stage_job_line_unconfirmed"
            && productStage !== "stage_job_line_unconfirmed_sent"
            && productStage !== "stage_job_line_unconfirmed_cm"
            && productStage !== "stage_job_line_cm_completed_unconfirmed";

    }

    canDeleteAppointment() {
        return getAccess().features().FeatureAllowDeleteAppointment();
    }


    deleteActivity(activity) {

        return new Promise(function (p) {

            if (!Helper.canDeleteAppointment()) {
                showOK("application_strings.views.calendar.deleteActivityFeatureDisabledTitle", "application_strings.views.calendar.deleteActivityFeatureDisabledMsg", ["application_strings.application.buttons.ok"], false, null, true, 600).then(r => {
                    p(false);
                });
            } else if (activity.Closed) {

                showOK("application_strings.views.calendar.deleteClosedActivityTitle", "application_strings.views.calendar.deleteClosedActivityMsg", ["application_strings.application.buttons.ok"], false, null, true, 600).then(r => {
                    p(false);
                });

            } else if (!accentUtils.isNull(activity.JobID) && (activity.ActivityType === Helper.typeCheckMeasure || activity.ActivityType === Helper.typeInstall)) {


                var canDeletePromise = activity.ActivityType === Helper.typeCheckMeasure ? query_Activity_IsCMImprogress.getFirstOrDefault({ cmID: activity.ID }) : Promise.resolve({ IsInprogress: false });


                canDeletePromise.then(dr => {


                    if (!dr.IsInprogress) {

                        query_Activity_RequirePromptUserIfJobTaskCompleted.getAll({ activityID: activity.ID, activityType: activity.ActivityType, jobID :activity.JobID }).then(function (lines) {
                            if (lines.length === 0) {
                                p(true);
                            } else {

                                var title = activity.ActivityType === Helper.typeCheckMeasure ? "application_strings.views.calendar.deleteCMWithCompletedTitle" : "application_strings.views.calendar.deleteInstallWithCompletedTitle"
                                var msg = activity.ActivityType === Helper.typeCheckMeasure ? "application_strings.views.calendar.deleteCMWithCompletedTitleMsg" : "application_strings.views.calendar.deleteInstallWithCompletedTitleMsg"

                                showOK(title, msg, ["application_strings.application.buttons.yes", "application_strings.application.buttons.no"], false, null, true, 800)
                                    .then(function (res) {
                                        if (res == "application_strings.application.buttons.yes") {

                                            var tag = accentUtils.isEmpty(activity.TagData) ? {} : JSON.parse(accentUtils.isEmpty(activity.TagData));


                                            tag = Object.assign({}, tag, {
                                                "DeleteAppFallbackToCompleted": true
                                            });

                                            activity.TagData = JSON.stringify(tag);

                                            p(true);
                                        } else {
                                            p(true);

                                        }
                                    });


                            }

                        });
                    } else {

                        showOK("application_strings.views.calendar.deleteInProgressCMTitle", "application_strings.views.calendar.deleteInProgressCMMsg", ["application_strings.application.buttons.ok"], false, null, true, 800).then(ci => {

                            p(false);

                        })

                    }

                });



            } else {
                p(true);
            }
        });


    }

    createNewQuote(activityID, repID) {

        return new Promise(p => {

            if (!(getAccess().modules().RTL_Quotes())) {
                showOK("application_strings.application.dialogs.licensingTitle", "application_strings.application.dialogs.licQuoteCalendar", ["application_strings.application.buttons.ok"], false, null, true);
                p(false);
                return;
            }


            if (accentUtils.isEmpty(repID)) {
                showOK("application_strings.application.dialogs.activityRepRequiredTitle", "application_strings.application.dialogs.activityRepRequiredMsg", ["application_strings.application.buttons.ok"]);
                p(false);
                return;
            }

            update("NewQuoteFromActivity", { id: activityID }).then(function (quoteID) {
                goTo("/Job/" + quoteID);

                p(true);
                return;

            });

        });

        

    }

    startCM(activityID, jobID) {

        update("StartEditJobCheckMeasure", { ID: activityID, jobID })
            .then(r => {

                if (r.Started) {
                    goTo(`/Job/${jobID}`);
                } else if (r.ExistingCMInprogress) {
                    showOK("application_strings.application.dialogs.existingCMInprogressTitle", "application_strings.application.dialogs.existingCMInprogressMsg", ["application_strings.application.buttons.viewOtherCM"]).then(a => {

                        if (a == "application_strings.application.buttons.viewOtherCM") {

                            if (!accentUtils.isNull(r.ExistingCMID)) {
                                Helper.showInCalendar(r.ExistingCMID);
                            }
                        }

                    });
                } else if (r.ExistingEditInprogress) {
                    showOK("application_strings.application.dialogs.existingEditInprogressTitle", "application_strings.application.dialogs.existingEditInprogressMsg", ["application_strings.application.buttons.ok"]);
                }
            });

    }

    async getActivityColors() {

        if (activityColors === null) {

            activityColors = await query_PL_ActivityColors.getAll({ includeID: null, activeOnly: true });

        }


        return activityColors;
    }
};


export const Helper = new ActivityHelper();


