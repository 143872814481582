import React from 'react'
import { NumericTextBox } from '@progress/kendo-react-all'
import { InsyteQueryGroupCtrl } from './InsyteQueryGroupCtrl'
import { accentUtils } from '../../services/HelperService';


export class InsyteQueryCtrl extends React.Component {

    constructor(props) {
        super(props);


        var defaultFilter = props.value;

        if (accentUtils.isNull(defaultFilter)) {
            defaultFilter = { logic : "and", filter:[]};
        }

        this.state = {
            filter: defaultFilter
        };

        this.onItemChanged = this.onItemChanged.bind(this);

        this.refreshKey = 0;


    }

    onItemChanged(preventRedraw){


        if (!preventRedraw)
            this.refreshKey++;

        var newFilter = {...this.state.filter};

        this.setState({ filter: newFilter})        

        this.props.onChange(newFilter);

    }

    render() {

        return <InsyteQueryGroupCtrl key={this.refreshKey} readOnly={ this.props.readOnly } item={this.state.filter} level={0} last onItemChanged={this.onItemChanged} schema={this.props.schema} disableDelete fieldStringBase={this.props.fieldStringBase} />;
    }
}


