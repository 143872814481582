import { LogLevel, CacheLookupPolicy } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";
import { accentUtils, getLoginParameters, reloading, clearReloading } from "../services/HelperService";
import { update } from "../services/DataService";

export class AuthorizeService {


    constructor() {

        this.b2cPolicies = null;
        this.msalConfig = null;
        this.msalInstance = null;
        this.defaultScope = null;

        this.impersonateConfig = null;
        this.impersonateInstance = null;

        this.signupConfig = null;
        this.signupInstance = null;

        this.Initialize = this.Initialize.bind(this);

    }





    async Initialize() {

        try {


            const response = await fetch(`api/AuthSettings/ClientConfig`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;

                const text = await response.text();

                throw new Error(message);
            }


            var config = await response.json();

            const domainName = config.Domain.split(".")[0];

            this.defaultScope = {
                scopes: [config.DefaultScope, 'openid']
            };

            this.b2cPolicies = {
                names: {
                    signIn: config.SignInPolicyId,
                    signUpSignIn: config.SignUpSignInPolicyId,
                    impersonate: config.ImpersonatePolicyId,
                    forgotPassword: config.ResetPasswordPolicyId,
                    editProfile: config.EditProfilePolicyId
                },
                authorities: {
                    signIn: {
                        authority: `https://${domainName}.b2clogin.com/${config.Domain}/${config.SignInPolicyId}`,
                    },
                    forgotPassword: {
                        authority: `https://${domainName}.b2clogin.com/${config.Domain}/${config.ResetPasswordPolicyId}`,
                    },
                    editProfile: {
                        authority: `https://${domainName}.b2clogin.com/${config.Domain}/${config.EditProfilePolicyId}`
                    },
                    signUpSignIn: {
                        authority: `https://${domainName}.b2clogin.com/${config.Domain}/${config.SignUpSignInPolicyId}`,
                    },
                    impersonate: {
                        authority: `https://${domainName}.b2clogin.com/${config.Domain}/${config.ImpersonationPolicyId}`,
                    }
                },
                authorityDomain: `${domainName}.b2clogin.com`
            }


            this.msalConfig = {
                auth: {
                    clientId: config.ClientId, // This is the ONLY mandatory field that you need to supply.
                    authority: this.b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
                    knownAuthorities: [this.b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
                    redirectUri: config.CallbackPath, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
                    postLogoutRedirectUri: "/logout", // Indicates the page to navigate after logout.
                    navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.

                },
                cache: {
                    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    console.error(message);
                                    return;
                                case LogLevel.Info:
                                    console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    console.warn(message);
                                    return;
                            }
                        }
                    },
                    windowHashTimeout: 3600000,
                    iframeHashTimeout: 10000,
                    loadFrameTimeout: 0,
                },
            };


            this.msalInstance = new PublicClientApplication(this.msalConfig);

            this.impersonateConfig = {
                auth: {
                    clientId: config.ClientId, // This is the ONLY mandatory field that you need to supply.
                    authority: this.b2cPolicies.authorities.impersonate.authority, // Choose SUSI as your default authority.
                    knownAuthorities: [this.b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
                    redirectUri: config.CallbackPath, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
                    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
                    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.

                },
                cache: {
                    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    console.error(message);
                                    return;
                                case LogLevel.Info:
                                    console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    console.warn(message);
                                    return;
                            }
                        }
                    }
                }

            };
            this.impersonateInstance = new PublicClientApplication(this.impersonateConfig);

            this.signupConfig = {
                auth: {
                    clientId: config.ClientId, // This is the ONLY mandatory field that you need to supply.
                    authority: this.b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
                    knownAuthorities: [this.b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
                    redirectUri: config.SignupCallbackPath, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
                    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
                    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.

                },
                cache: {
                    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    console.error(message);
                                    return;
                                case LogLevel.Info:
                                    console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    console.warn(message);
                                    return;
                            }
                        }
                    }
                }

            };
            this.signupInstance = new PublicClientApplication(this.signupConfig);
            window.imageBaseURL = config.BaseImageURL;
            window.msalInstance = this.msalinstance;
            if (this.isSignUp()) {
                this.removeAllAccounts();
            }
            return this.getInstance();

        } catch (e) {
            console.log(e);
        }
    }

    getConfig() {
        const urlParams = new URLSearchParams(window.location.search);
        const impersonateParam = urlParams.get('impersonate');
        const linkParam = urlParams.get('linkUser');
        if (impersonateParam != null) {
            return this.impersonateConfig;
        }
        if (linkParam != null) {
            return this.signupConfig;
        }


        return this.msalConfig;
    }

    isImpersonation() {
        const urlParams = new URLSearchParams(window.location.search);
        const impersonateParam = urlParams.get('impersonate');
        if (impersonateParam != null) {
            return true;
        }
        return false;
    }

    isSignUp() {
        const urlParams = new URLSearchParams(window.location.search);
        const impersonateParam = urlParams.get('linkUser');
        if (impersonateParam != null) {
            return true;
        }
        return false;
    }
    getInstance() {
        const urlParams = new URLSearchParams(window.location.search);
        const impersonateParam = urlParams.get('impersonate');
        const linkParam = urlParams.get('linkUser');
        if (impersonateParam != null) {
            console.log('impersonate instance');
            return this.impersonateInstance;
        }
        if (linkParam != null) {
            console.log('signup instance');
            return this.signupInstance;
        }

        console.log('normal instance');
        return this.msalInstance;
    }

    getLoginRequest() {
        const urlParams = new URLSearchParams(window.location.search);
        const linkParam = urlParams.get('linkUser');

        const stateParam = urlParams.get('state');
        if (linkParam) {
            return {
                scopes: this.defaultScope.scopes, extraQueryParameters: getLoginParameters(), state: stateParam
            };
        } else {
            return {
                scopes: this.defaultScope.scopes, extraQueryParameters: getLoginParameters()
            };
        }

    }
    signIn() {

        this.getInstance().loginRedirect(this.getLoginRequest());
    }


    signOut() {
        this.msalInstance.logoutRedirect();
    }

    signOutWithRedirect(redirecturi) {
        this.getInstance().logoutRedirect({
            postLogoutRedirectUri: redirecturi
        });
    }

    signOutAuthFailed() {
        var me = this;
        if (this.msalInstance) {
            this.msalInstance.handleRedirectPromise().then(r => { me.msalInstance.logoutRedirect() });
        }
    }
    async resetPassword() {
        const instance = this.getInstance();
        instance.loginRedirect(this.b2cPolicies.authorities.forgotPassword);

    }

    async isSignIn() {
        const instance = this.getInstance();
        var accounts = instance.getAllAccounts();
        var account = accounts[0];
        var signinPolicy = this.msalConfig.auth.authority.split("/").pop().toLowerCase()
        console.log('singin policy is ' + signinPolicy);
        if (account) {
            if (account.homeAccountId.toLowerCase().includes(signinPolicy)) {
                return true;
            }
        }
        return false;
    }

    async completeSignin() {
        const instance = this.msalInstance;
        await instance.handleRedirectPromise();

    }

    async completeSignup() {
        const instance = this.signupInstance;
        await instance.handleRedirectPromise();
        const instancePolicy = this.signupConfig.auth.authority.split("/").pop().toLowerCase();
        var accounts = instance.getAllAccounts().filter(item => item.homeAccountId.toLowerCase().includes(instancePolicy));
        if (accounts.length == 0) {
            accounts = instance.getAllAccounts();
        }
        console.log(accounts);
        var account = accounts[0];
        const t = await instance.acquireTokenSilent({
            account: account,
            ...this.defaultScope,
            cacheLookupPolicy: 2

        });
        if (!accentUtils.isNull(t)) {
            console.log('token acquired');
            //should be linking, we came from signup
            const stateParam = t.account.idTokenClaims.redirectState;
            console.log('state param ' + stateParam);
            if (!accentUtils.isNull(stateParam)) {
                var splitState = stateParam.split(/_(.*)/s);
                if (splitState.length > 1) {
                    var clientId = splitState[0];
                    var email = splitState[1].replace(" ","+");
                    if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(clientId)) {
                        var args = { BusinessClaim: clientId, UserName: email, UserID: t.account.idTokenClaims.sub };
                        const response = await fetch(`api/Update/LinkUserToBusiness`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                ...(!t.accessToken ? {} : { 'Authorization': `Bearer ${t.accessToken}` })
                            },
                            body: JSON.stringify(args)
                        });

                        console.log('completed linking');
                    }
                }
            }
            
        }
    }

    async getAccessToken() {
        if (this.msalInstance) {

            const instance = this.getInstance();
            const instancePolicy = this.getConfig().auth.authority.split("/").pop().toLowerCase();
            var accounts = instance.getAllAccounts().filter(item => item.homeAccountId.toLowerCase().includes(instancePolicy));
            if (accounts.length == 0) {
                accounts = instance.getAllAccounts();
                if (this.isImpersonation()) {
                    instance.loginRedirect(this.getLoginRequest());
                }
            }
            console.log(accounts);
            console.log(window.location.search);
            try {

                const account = accounts.length > 0 ? accounts.reduce((prev, current) => (prev.idTokenClaims.auth_time > current.idTokenClaims.auth_time) ? prev : current) : null;
                console.log(account);
                if (account) {
                    for (const prop in account) {
                        console.log(`${prop}: ${account[prop]}`);
                    }
                }
                const t = await instance.acquireTokenSilent({
                    account: account,
                    ...this.defaultScope,
                    cacheLookupPolicy: 2

                });
                
                if (!accentUtils.isNull(t)) {
                    localStorage.setItem("reloading", "false");
                    return t.accessToken;
                }


            } catch (e) {
                console.log(e);
                if (e.errorCode === "interaction_required") {
                    //instance.loginRedirect(this.getLoginRequest());
                    this.msalInstance.logoutRedirect();
                } else if (e.errorCode === "no_tokens_found") {
                    //alert('no token');
                    //this.msalInstance.loginRedirect(this.getLoginRequest());
                    this.msalInstance.logoutRedirect();
                } else if (e.errorCode === "no_account_error") {
                    //this.msalInstance.loginRedirect(this.getLoginRequest());
                    this.msalInstance.logoutRedirect();
                } else {

                    if (!reloading()) {
                        console.log('Generic Authentication Exception - reloading window once');
                        //window.location.search += '&reloading=true';
                        localStorage.setItem("reloading", "true")
                        window.location.reload();

                    } else {
                        console.log('Generic Authentication Exception - already reloaded');
                        await this.logoutAllAccounts();
                    }
                }



            }


        }

        return null;

    }

   
    async removeAllAccounts() {
        this.msalInstance.getTokenCache().storage.removeAllAccounts();
    }

    async logoutAllAccounts() {
        const instance = this.getInstance();
        const instancePolicy = this.getConfig().auth.authority.split("/").pop().toLowerCase();
        var accounts = instance.getAllAccounts().filter(item => item.homeAccountId.toLowerCase().includes(instancePolicy));
        if (accounts.length == 0) {
            accounts = instance.getAllAccounts();
        }
        await instance.getTokenCache().storage.removeAllAccounts();
        
        window.location.href = '/logout?returnUrl=' + window.location.pathname;
        //this.msalInstance.loginRedirect(this.getLoginRequest());
    }

    async isAuthenticated() {

        const accessToken = await this.getAccessToken();

        return !accentUtils.isEmpty(accessToken);
    }



}

const authService = new AuthorizeService();

export default authService;

