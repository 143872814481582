//app.GooglePhone

import * as React from 'react'
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumberType } from 'google-libphonenumber'
import $ from 'jquery'
import { Input } from '@progress/kendo-react-all'
import { accentUtils, t, formHelper } from '../../services/HelperService'
import { getUser } from '../../services/UserService'
import AccentIcons from '../../icons/Icons'

export class AccentPhoneNumber extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        if (this.props.model.options.translate) {
            this.initialValue = t(this.props.model.getValue());
        } else {
            this.initialValue = this.props.model.getValue();
        }

        this.country = accentUtils.getCountryCode(this.props.model.options.country, getUser());


        
        this.currentUserValue = this.initialValue;
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.getControlValue = this.getControlValue.bind(this);
        this.validateControl = this.validateControl.bind(this);
        this.getValidNumber = this.getValidNumber.bind(this);
        this.isValidNumberForCountry = this.isValidNumberForCountry.bind(this);
        this.onPhoneClick = this.onPhoneClick.bind(this);
    }

    onPhoneClick() {

        

    }

    isValidNumberForCountry(value, country) {
        try {
            var numUtils = PhoneNumberUtil.getInstance();
            var num = numUtils.parse(value, country);

            const isValid = this.props.isMobile ? numUtils.isPossibleNumberForType(num, PhoneNumberType.MOBILE) : numUtils.isPossibleNumber(num);


            if (isValid) {                
                return numUtils.format(num, PhoneNumberFormat.NATIONAL);
            }
        } catch (e) {
            
        }

        return null;
    }

    getValidNumber(value) {

        var num = null;

        if (!accentUtils.isEmpty(value)) {

            num = this.isValidNumberForCountry(value, this.country);

        }

        return num;
    }

    validateControl() {

        var value = this.getControlValue();

        var res =  null;

        if (!accentUtils.isEmpty(value) && this.getValidNumber(value) === null) {
            res = formHelper.getValidation(t("application_strings.application.general.invalidPhoneFormat"), "Error");
        } 
        
        return res;
    }

    handleFocus(e) {
        var me = this;

        $(me.ctrl.current.element).select();

        
    }

    handleBlur(e) {

        var value = e.currentTarget.value;

        var formattedValue = this.getValidNumber(value);

        if (formattedValue !== null)
            this.props.model.setValue(formattedValue);
        else
            this.props.model.setValue(value); // will be picked up by validation

        if (!accentUtils.isNull(this.props.model.options.onFocusLost)) {
            this.props.model.options.onFocusLost({ selectionStart: e.target.selectionStart });
        }

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange();
        }

    }


    handleChange(e) {

        var value = e.value;
        this.currentUserValue = value;
        this.props.model.modified();


    }

    focus() {
        alert("$(ReactDOM.findDOMNode(this.ctrl.current)).trigger('focus')");
    }


    getControlValue() {
        return this.ctrl.current.value;
    }


  
    render() {

        var disabled = formHelper.isControlReadOnly(this.props.model);

        var currValue = undefined;


        var modelValue = this.props.model.getValue();

        if (disabled) {

            if (this.props.model.options.translate) {
                currValue = t(modelValue);
            } else {
                currValue = modelValue;
            }


        } else {
            if (modelValue !== this.currentUserValue) { // model changed so hack                

                if (accentUtils.isNull(modelValue)) {
                    modelValue = "";
                }

                this.currentUserValue = modelValue;
                this.ctrl.current.state.value = modelValue;
            }
        }

        // need to be able to refresh if model has changed

        return (
            <div className="d-flex">
                <div className="flex-fill">
                    <Input ref={this.ctrl} disabled={disabled} value={currValue} label={this.props.model.displayLabel} onBlur={this.handleBlur} onFocus={this.handleFocus} onChange={this.handleChange} defaultValue={this.initialValue} />
                </div>
                <div>
                    <a href={`tel:${modelValue}`} ><AccentIcons.Phone onClick={this.onPhoneClick}/></a>
                </div>
            </div>
            
        );
    }
}
