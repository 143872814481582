import * as React from 'react'
import { AccentNumberUnbound } from './unbound/AccentNumberUnbound'

export class NumberOfCopiesInputCtrl extends React.Component {

    constructor(props) {
        super(props);

    }


    render() {
        return <AccentNumberUnbound defaultValue={this.props.defaultValue} onChange={this.props.onChange} max={this.props.max} label={this.props.label} />;
    }
}
