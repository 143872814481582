import * as React from 'react';
import { Settings } from '../../services/AppSettingsService';
import { queryNoTracking } from '../../services/DataService';


const query_JobLineWithOptions = queryNoTracking("JobLineWithOptions", 0);

export class ActivityInstallerLineViewCtrl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jobLine: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        var me = this;
        var printSettings = nextProps.printSettings ? nextProps.printSettings : Settings.GetAppSettings().DefaultPrintSettings;
        query_JobLineWithOptions.getFirstOrDefault({ jobLineID: this.props.jobLineID, printSettingID: printSettings ? printSettings : 0 }).then(function (r) {
            me.setState({ jobLine: r });
        });

    }

    componentDidMount() {
        var me = this;
        var printSettings = this.props.printSettings ? this.props.printSettings : Settings.GetAppSettings().DefaultPrintSettings;
        query_JobLineWithOptions.getFirstOrDefault({ jobLineID: this.props.jobLineID, printSettingID: printSettings? printSettings: 0 }).then(function (r) {
            me.setState({ jobLine: r });
        });
    }

    render() {
        var jobLineHeader = this.state.jobLine.Product;
        if (this.props.showLocation) { jobLineHeader = jobLineHeader + ' ' + this.state.jobLine.Location_Desc; }
        if (this.props.showLineNumber) { jobLineHeader = this.state.jobLine.Line_No + ' ' + jobLineHeader }

        if (this.state.jobLine.Qty > 1) {
            jobLineHeader = jobLineHeader + ' ( x' + this.state.jobLine.Qty + ' )';
        }
        var installNotes = this.state.jobLine.InstallNotes;

        var supplyNotes = this.state.jobLine.SupplyNotes;
        var customerNotes = this.state.jobLine.Notes;
        var wd = this.state.jobLine.Sizes;
        var options = [];
        if (this.state.jobLine.Options) {
            if (this.props.optionLayout === "report_param_options_format_comma") {
                var optionList = [];
                this.state.jobLine.Options.forEach((opt, index) => {

                    optionList.push(opt.Formatted);
                });
                var joined = optionList.join(', ');
                options.push(<div>{joined}</div>);
            } else if (this.props.optionLayout === "report_param_options_format_hidden") {

            } else {
                var cols = this.props.optionColumns ? this.props.optionColumns : 4;
                var row = [];
                var rows = [];
                this.state.jobLine.Options.forEach((opt, index) => {
                    if (row.length % cols == 0) {
                        if (row.length > 0) {
                            rows.push(<tr>{row}</tr>);
                            row = [];
                        }
                    }
                    row.push(<td>{opt.Formatted}</td>);
                });
                if (row.length > 0) {
                    rows.push(<tr>{row}</tr>);
                    row = [];
                }
                var table = <table border={0} cellpadding={20} > {rows}</table>
                options.push(<div> {table}</div>);

            }
        }
        return (
            <div className="im-sum-line-block">
                <div style={this.props.captionStyle}>
                    {jobLineHeader}
                </div>
                {this.props.showPrice &&
                    <div>
                        <div className="row">
                            <div className="col">
                                Price ex. Tax
                            </div>
                            <div className="col">
                                Tax
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {this.state.jobLine.AmountExTax}
                            </div>
                            <div className="col">
                                {this.state.jobLine.TaxAmount}
                            </div>
                        </div>

                    </div>
                }

                {this.props.showManufacturingNotes &&
                    <div >
                        <div className="im-sum-line-bld">
                            Supply Notes
                        </div>
                        <div style={this.props.noteStyle} >
                            {supplyNotes}
                        </div>
                    </div>
                }
                {this.props.showCustomerNotes &&
                    <div >
                        <div className="im-sum-line-bld">
                            Customer Notes
                        </div>
                        <div style={this.props.noteStyle} >
                            {customerNotes}
                        </div>
                    </div>
                }
                {this.props.showSizes &&
                    <div style={this.props.optionStyle}>
                        <div style={{ flexDirection: "row" }}>
                            <div>WxD {wd}</div>

                        </div>
                    </div>
                }
                <div>
                    {options}
                </div>
            </div>
        );
    }
}