import * as React from 'react'
import * as ReactDOM from 'react-dom'
import $ from 'jquery'
import { Input } from '@progress/kendo-react-all'
import { accentUtils, t, formHelper } from '../../services/HelperService'

export class AccentEmail extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        if (this.props.model.options.translate) {
            this.initialValue = t(this.props.model.getValue());
        } else {
            this.initialValue = this.props.model.getValue();
        }


        this.currentUserValue = this.initialValue;
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.getControlValue = this.getControlValue.bind(this);
        this.validateControl = this.validateControl.bind(this);
    }

    

    validateControl() {

        var value = this.getControlValue();

        var res = null;

        
        if (!accentUtils.isEmpty(value)) {

            var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!re.test(value.toLowerCase())) {
                res = formHelper.getValidation(t("application_strings.application.general.invalidEmailFormat"), "Error");
            }
        }

        return res;
    }

    handleFocus(e) {
        var me = this;

        $(me.ctrl.current.element).select();

        //setTimeout(function () {
        //    $(e.target).select();
        //});
    }

    handleBlur(e) {


        var value = e.currentTarget.value;
        this.props.model.setValue(value);


        if (!accentUtils.isNull(this.props.model.options.onFocusLost)) {
            this.props.model.options.onFocusLost({ selectionStart: e.target.selectionStart });
        }

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange();
        }

    }


    handleChange(e) {

        var value = e.value;
        this.currentUserValue = value;
        this.props.model.modified();


    }

    focus() {        
        alert("$(ReactDOM.findDOMNode(this.ctrl.current)).trigger('focus')");
    }


    getControlValue() {
        return this.ctrl.current.value;
    }



    render() {

        var disabled = formHelper.isControlReadOnly(this.props.model);

        var currValue = undefined;


        var modelValue = this.props.model.getValue();

        if (disabled) {

            if (this.props.model.options.translate) {
                currValue = t(modelValue);
            } else {
                currValue = modelValue;
            }


        } else {
            if (modelValue !== this.currentUserValue) { // model changed so hack                

                if (accentUtils.isNull(modelValue)) {
                    modelValue = "";
                }

                this.currentUserValue = modelValue;
                this.ctrl.current.state.value = modelValue;
            }
        }

        // need to be able to refresh if model has changed

        return (
            <Input ref={this.ctrl} disabled={disabled} value={currValue} label={this.props.model.displayLabel} onBlur={this.handleBlur} onFocus={this.handleFocus} onChange={this.handleChange} defaultValue={this.initialValue} />
        );
    }
}
