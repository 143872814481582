import * as React from 'react'
import { suspend } from 'suspend-react'
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import authService, { AuthorizeService } from './AuthorizeService'
import Services from './../services/registerServices'
import DoLogin from './DoLogin';

const AuthenticatedApp = props => {

    

    const msalInstance = suspend(authService.Initialize, ["authorization"])


    return < MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
            <React.Suspense fallback={<div className="center-screen app-loading-msg">Loading Services</div>}>
                <Services />
            </React.Suspense>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <DoLogin />
        </UnauthenticatedTemplate>
    </MsalProvider>;
};



export default AuthenticatedApp;