import React from 'react'
import { DatePicker, DateTimePicker, TimePicker, IntlProvider, Popup, Calendar, CalendarCell } from '@progress/kendo-react-all'
import * as Moment from 'moment'
import { accentUtils, formHelper } from '../../services/HelperService'

const CustomPopup = (props) => {
    return (
            <Popup
            {...props}              
        >
            <div data-tagname={props.dataTagName }>{props.children}</div>
        </Popup>
    );
};


const CustomDateCell = React.memo(props => {

    const tag = Moment(new Date(props.value)).diff(Moment(new Date()), "days");

    return (<CalendarCell {...props} ><span data-tagname={`${props.dataTagName}_${tag}`}>{props.children}</span></CalendarCell>);
});


export class AccentDate extends React.Component {

    constructor(props) {
        super(props);

        this.dateCtrl = React.createRef();
        this.timeCtrl = React.createRef();

        this.dataKeyDate = 1;
        this.dataKeyTime = 1;

        this.showDate = true;
        this.showTime = false;

        if (!accentUtils.isNull(this.props.showTime)) {
            this.showTime = this.props.showTime;
        }
        if (!accentUtils.isNull(this.props.showDate)) {
            this.showDate = this.props.showDate;
        }

        this.handleChangeDate = this.handleChangeDate.bind(this);        
        this.handleChangeDateTime = this.handleChangeDateTime.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);

        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
    }

    handleChangeDateTime(e) {

        var value = e.value;

        if (value !== null) {

            var originalValue = this.props.model.getValue();

            this.props.model.setValue(value);

            if (!accentUtils.isNull(this.props.model.options.onChange)) {
                this.props.model.options.onChange({ originalValue: originalValue, value: value });
            }

            this.props.model.modified();
        }
        
    }

    handleChangeTime(e) {
        var value = e.value;

        if (value !== null) {

            var oringalValue = this.props.model.getValue();

            this.props.model.setValue(value.toTimeString().substring(0,5));

            if (!accentUtils.isNull(this.props.model.options.onChange)) {
                this.props.model.options.onChange({ originalValue: oringalValue, value: value.toTimeString().substring(0, 5) });
            }

            this.props.model.modified();
        }


    }
    handleChangeDate(e){
       
        var value = e.value;
        var originalValue = this.props.model.getValue();
        this.props.model.setValue(value);

        //value.setHours(0);
        value = Moment(value).startOf('day').toDate();
        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange({ originalValue: originalValue, value: value });
        }

        this.props.model.modified();

        //this.dataKeyTime++;
    }


    render() {
        
        var disabled = formHelper.isControlReadOnly(this.props.model);
        var local = "en-AU";

        const item = (this.showTime && this.showDate)
            ? <DateTimePicker
                key={this.dataKeyTime}
                ref={this.timeCtrl}
                disabled={disabled}
                onChange={this.handleChangeDateTime}
                label={this.props.model.displayLabel}
                defaultValue={this.props.model.getValue()}
                popup={p => <CustomPopup {...p} dataTagName={this.props.model.options?.tagName} />}                
                calendar={p => <Calendar {...p} cell={p => <CustomDateCell {...p} dataTagName={this.props.model.options?.tagName} />} />} />
            : (this.showDate)
                ? <DatePicker key={this.dataKeyDate} format="dd-MM-yyyy" ref={this.dateCtrl} disabled={disabled} onChange={this.handleChangeDate} label={this.props.model.displayLabel} defaultValue={this.props.model.getValue()} />
                : <TimePicker key={this.dataKeyTime} format="HH:mm" ref={this.timeCtrl} disabled={disabled} onChange={this.handleChangeTime} label={this.props.model.displayLabel} defaultValue={new Date('1970-01-01 '+this.props.model.getValue())} />;
        //
        return (
            <IntlProvider locale={local}>
                <div className={"accentDateCtrl"} >
                    <div className="accentDateCtrl-date">
                        {item}
                    </div>                
                </div>
            </IntlProvider>
	    );
  }
}
