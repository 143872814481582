import React, { Component } from 'react';
import { suspend } from 'suspend-react'
import { useIsAuthenticated } from '@azure/msal-react'
import * as MessageService   from './MessageService'
import * as JobService from './JobService'
import * as AppSettingsService from './AppSettingsService'
import userService from './UserService'
import { InitializeHelpers } from './HelperService'
import DoLogin from './../api-authorization/DoLogin'
import { Loader } from '@googlemaps/js-api-loader';
import accentUtils from './helpers/AccentUtils';
import './../../assets/expression-builder.css'
import './../../assets/expression-builder-v2'
import { DefaultValueSetter } from './DefaultValueService';
import { loadLocallyCachedProducts, refreshProductExclusionList, refreshProducts } from './ProductService';
import { locale as devextremeLocale } from "devextreme/localization";

import {
    load,
} from "@progress/kendo-react-intl";
import { queryNoTracking } from './DataService';

const App = React.lazy(() => import('./../../App'));


const query_InitializeBreeze = queryNoTracking("InitializeBreeze")

let googleMaps = null;
let googleMapsLoadCalled = false;
let google = null;
const registerServices = async () => {


    const user = await userService.Initialize();

    if (accentUtils.isNull(user)) {
        return false;

    }

    if (googleMaps === null) {
        googleMaps = new Loader({
            apiKey: user.MapsKey,
            version: "quarterly",
            libraries: ["places", "drawing", "geometry"]
        });
    }

    await query_InitializeBreeze.getAll();

    await InitializeHelpers();

    loadLocallyCachedProducts(user);

    refreshProducts();


    refreshProductExclusionList();

    const otherPromises = [AppSettingsService.Settings.Initialize()];

    const googlePromise = googleMapsLoadCalled ? Promise.resolve() : googleMaps.load();

    if (DefaultValueSetter == null) {
        console.log("DefaultValueSetter: ", DefaultValueSetter);
    }



    let likelySubtags = null;
    let currencyData = null;
    let weekData = null;


    let curr = null;
    let num = null;
    let calgen = null;
    let calgra = null;
    let dateFlds = null;
    let tmx = null;

    const loadAnyway = false;

    if (process.env.NODE_ENV !== 'development' || loadAnyway) {


        likelySubtags = import("cldr-core/supplemental/likelySubtags.json");
        currencyData = import("cldr-core/supplemental/currencyData.json");
        weekData = import("cldr-core/supplemental/weekData.json");


        curr = import(`cldr-numbers-full/main/${user.Locale}/currencies.json`);
        num = import(`cldr-numbers-full/main/${user.Locale}/numbers.json`);
        calgen = import(`cldr-dates-full/main/${user.Locale}/ca-generic.json`);
        calgra = import(`cldr-dates-full/main/${user.Locale}/ca-gregorian.json`);
        dateFlds = import(`cldr-dates-full/main/${user.Locale}/dateFields.json`);
        tmx = import(`cldr-dates-full/main/${user.Locale}/timeZoneNames.json`);

    }

    devextremeLocale(user.Locale);

    

    const modules = await Promise.all([
        // required for loading
        googlePromise,
        likelySubtags,
        currencyData,
        weekData,
        curr,
        num,
        calgen,
        calgra,
        dateFlds,
        tmx,
        Promise.resolve(), //AppSettingsService.Settings.Initialize(),
    //    settingsPromise,
        Promise.resolve(), //refreshProducts(),
        Promise.resolve(), //refreshProductExclusionList()
    ]);



    google = modules[0];


    if (process.env.NODE_ENV !== 'development' || loadAnyway) {
        load(
            modules[1],
            modules[2],
            modules[3],
            modules[4],
            modules[5],
            modules[6],
            modules[7],
            modules[8],
            modules[9],
        );
    }


    await Promise.all(otherPromises);

    // not required for loading
    MessageService.Helper.Initialize();
    JobService.Helper.Initialize();

    return true;

}


const Services = (props) => {

    const [initialized, setInitialized] = React.useState(false);

    const isAuthenticated = useIsAuthenticated();


    const ready = suspend(registerServices, "services");

    var user = userService.getUserModel();

    const content = ready ? <App /> : <DoLogin />;

    return <div>
        {content}
    </div>;
};

export { google };
export default Services;