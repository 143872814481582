import React from 'react'
import { Row, Col} from 'reactstrap'
import { accentUtils, t, formHelper, from } from '../../services/HelperService'
import { queryNoTracking, update } from '../../services/DataService'
import { AccentButton } from './../AccentButton'
import { FilterDropdown } from './../FilterButtons'
import { AccentTextUnbound } from '../unbound/AccentTextUnBound'
import { getUser } from '../../services/UserService'
import { AccentSpinner } from '../AccentSpinner'
import { AccentDialog } from '../../dialogs/AccentDialog'
import { advancedFilterCache } from './AdvancedFilterConstants'







export class GridFilterView extends React.Component {

    constructor(props) {
        super(props);

        this.refreshKey = 0;


        const user = getUser();

        this.state = {
            filterID: accentUtils.isNull(props.filterID) ? null : props.filterID,
            filter: props.filter,
            name: null,
            owner: user.FullName,
            canSave: false,
            selectedUsers: [],
            selectedTeams: [],
            isLoaded: accentUtils.isNull(props.filterID)
        };
        this.onNameChanged = this.onNameChanged.bind(this);
        this.onTeamsChange = this.onTeamsChange.bind(this);
        this.onUsersChange = this.onUsersChange.bind(this);
        this.queryTeams = this.queryTeams.bind(this);
        this.queryUsers = this.queryUsers.bind(this);
        this.saveDlg = this.saveDlg.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }


    componentDidMount() {
        
        var me = this;

        if (!accentUtils.isNull(this.props.filterID)) {
            //insyteData.queries.AdvancedQueries_FiltersForID(this.props.filterID).getFirstOrDefault()
            queryNoTracking('AdvancedQueries_FiltersForID').getFirstOrDefault({id: this.props.filterID})
                .then(f => {

                me.refreshKey++;

                me.setState({
                    selectedTeams: f.Teams,
                    selectedUsers : f.Users,
                    name: f.Name,
                    owner: f.Owner,
                    filterID: me.props.filterID,
                    filter: accentUtils.isEmpty(me.props.filter) ? f.Filter : me.props.filter,
                    isLoaded : true
                });

            });
        }

    }

    componentDidUpdate() {
        var isValid = formHelper.isModalValid();
        if (this.state.canSave !== isValid) {
            this.setState({ canSave: isValid });
        }

    }

    saveDlg() {

        var me = this;
        
        update('SaveGridFilter',{
            ID: this.state.filterID,
            Name: this.state.name,
            Users: this.state.selectedUsers,
            Teams: this.state.selectedTeams,
            Filter: { filter: this.state.filter },
            EntityType: accentUtils.isNull(this.props.filterSchemaName) ? null : this.props.filterSchemaName
        }).then(r => {

            advancedFilterCache.clear();

            me.props.onClose({
                canceled: false,
                newID: r.ID,
                newName: r.Name
            });
        })


    }


    queryUsers() {
        return queryNoTracking('User_UserFilter').getAll({});
    }
    queryTeams() {
        return queryNoTracking('User_TeamFilter').getAll({});
    }

    onNameChanged(e) {
        this.setState({ name: e });
    }

    onTeamsChange(e) {
        const selection = from(e.value).select(i => i.ID).toArray();
        this.setState({ selectedTeams: selection });

    }
    onUsersChange(e) {
        const selection = from(e.value).select(i => i.ID).toArray();
        this.setState({ selectedUsers: selection });

    }

    render() {


        if (!this.state.isLoaded) return <AccentSpinner />;

        const existingUsers = from(this.state.selectedUsers).select(v => { return {ID: v }; }).toArray();
        const existingTeams = from(this.state.selectedTeams).select(v => { return { ID: v }; }).toArray();


        const canSave = this.state.canSave;

        return <AccentDialog
            onClose={this.props.onClose}
            maxWidth={ 1024}
            headerContent={t("application_strings.views.filterView.title")}
            actionsContent={[                                
                <AccentButton key="1" tagName="ptCloseBtn" disabled={!canSave} onClick={this.saveDlg}>{t("application_strings.application.buttons.save")}</AccentButton>
                ]}
        >
                <Row>
                    <Col md={3}>
                        <label>{t("application_strings.views.filterView.owner")}</label>
                    </Col>
                    <Col md={6}>
                        <label>{this.state.owner}</label>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <label>{t("application_strings.views.filterView.filterName")}</label>
                    </Col>
                    <Col md={6}>
                        <AccentTextUnbound required defaultValue={this.state.name} onChange={this.onNameChanged} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <label>{t("application_strings.views.filterView.users")}</label>
                    </Col>
                    <Col md={6}>
                        <FilterDropdown key={this.refreshKey} valueField="ID" textField="FullName" dataSource={this.queryUsers} onChange={this.onUsersChange} selectAllOnLoad={false} value={existingUsers} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <label>{t("application_strings.views.filterView.teams")}</label>
                    </Col>
                    <Col md={6}>
                        <FilterDropdown key={this.refreshKey} valueField="ID" textField="Name" dataSource={this.queryTeams} onChange={this.onTeamsChange} selectAllOnLoad={false} value={existingTeams} />
                    </Col>
                </Row>               
        </AccentDialog>;                
    }
}