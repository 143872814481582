import * as React from 'react'
import { MultiSelect } from '@progress/kendo-react-all'
import { filterBy } from "@progress/kendo-data-query";
import { Row, Col } from 'reactstrap'
import { accentUtils, t, from, showDialog } from '../services/HelperService'
import { queryNoTracking, update } from '../services/DataService'
import { AccentSpinner } from './AccentSpinner'


const query_UserTeam_SelectList = queryNoTracking('UserTeam_SelectList');

export const UserTeamSelectCtrl = (props) => {

    const lastType = React.useRef();


    const loading = React.useRef(false);

    const [data, setData] = React.useState(null);
    const [filter, setFilter] = React.useState(null);

    const userTeamItemRender = (li, itemProps) => {

        if (itemProps.index === 0) {
            lastType.current = null;
        }

        const currType = itemProps.dataItem.value.substr(0,1);

        const showGroup = lastType.current !== currType;
        lastType.current = currType;

        const typeDesc = currType === "u" ? "Users" : "Teams";

        const group = !showGroup ? null : <div className="acc-drop-type">{typeDesc}</div> ;
        

        const itemChildren = <div className="acc-drop-itm">{li.props.children}{group}</div>;

        return React.cloneElement(li, li.props, itemChildren);
    };

    const getControlValue = (e, data) => {

        if (accentUtils.isEmpty(e)) {
            return [];
        }

        const rep = (e.startsWith("u")) ? e : null;

        const team = (!accentUtils.isNull(rep)) ? from(data).where(i => i.value == rep).select(i => i.team).firstOrDefault() : e;

        return from(data).where(i => i.value == rep || i.value == team).toArray();
    };

    const [value, setValue] = React.useState(null);
    const [preValue, setPreValue] = React.useState(null);


    const handleChange = React.useCallback((e) => {

        const currRep = from(value).firstOrDefault(i => i.value.startsWith("u"));
        const newRep = from(e.value).firstOrDefault(i =>  (accentUtils.isNull(currRep) || i.value != currRep.value) && i.value.startsWith("u"));
        
        const newRepSelected = !accentUtils.isNull(newRep) && (accentUtils.isNull(currRep) || currRep.value != newRep.value);


        if (newRepSelected) {
            setValue(getControlValue(newRep.value, data));
        } else {
            const currTeam = from(value).firstOrDefault(i => i.value.startsWith("t"));
            const newTeam = from(e.value).firstOrDefault(i => (accentUtils.isNull(currTeam) || i.value != currTeam.value) && i.value.startsWith("t"));
            const newTeamSelected = !accentUtils.isNull(newTeam) && (accentUtils.isNull(currTeam) || currTeam.value != newTeam.value);
            const currTeamStillSelected = !accentUtils.isNull(currTeam) && from(e.value).firstOrDefault(i => i.value == currTeam.value);

            if (newTeamSelected) {
                setValue([newTeam]);
            } else if (currTeamStillSelected) {
                setValue([currTeam]);
            } else {
                setValue([]);
            }

        }

     


    }, [data, value, setValue, getControlValue, props]);

    const onFilterChange = React.useCallback((e) => {
        setFilter(e.filter);
    }, [setFilter, filter]);


    React.useEffect(() => {

        if (data == null) {

            if (loading.current) return;

            loading.current = true;

            var includeUser = props.defaultValue.UserID > 0 ? props.defaultValue.UserID : null;

            query_UserTeam_SelectList.getAllWithCache({ includeUser: includeUser }).then(d => {
                loading.current = false;
                setData(d);
                setValue(getControlValue((props.defaultValue.UserID > 0 ? `u${props.defaultValue.UserID}` : `t${props.defaultValue.TeamID}`), d));
                setPreValue(getControlValue((props.defaultValue.UserID > 0 ? `u${props.defaultValue.UserID}` : `t${props.defaultValue.TeamID}`), d));
            });
        }

    });


    React.useEffect(() => {

        var teamChange = undefined;
        var userChange = undefined;
        var prevTeam = null;
        var prevUser = null;

        if (props.onTeamChange || props.onUserChange) {
            if (preValue == null && value == null) {
                return;
            }else if ((preValue == null && value != null) || (preValue != null && value == null)) {
                if (value == null) {
                    teamChange = null;
                    userChange = null;
                } else {
                    teamChange = from(value).where(i => i.value.startsWith("t")).select(i => Number(i.value.substr(1))).firstOrDefault();
                    userChange = from(value).where(i => i.value.startsWith("u")).select(i => Number(i.value.substr(1))).firstOrDefault();
                }

            } else {

                const pt2 = from(preValue).where(i => i.value.startsWith("t")).select(i => Number(i.value.substr(1))).firstOrDefault();
                const t2 = from(value).where(i => i.value.startsWith("t")).select(i => Number(i.value.substr(1))).firstOrDefault();
                if (pt2 !== t2) {
                    teamChange = accentUtils.isNull(t2) ? null : t2;
                    prevTeam = pt2;
                }
                const pu2 = from(preValue).where(i => i.value.startsWith("u")).select(i => Number(i.value.substr(1))).firstOrDefault();
                const u2 = from(value).where(i => i.value.startsWith("u")).select(i => Number(i.value.substr(1))).firstOrDefault();
                if (pu2 !== u2) {
                    userChange = accentUtils.isNull(u2) ? null : u2;
                    prevUser = pu2;
                }
            }

            if (props.onTeamChange && teamChange !== undefined) {
                props.onTeamChange({ value: teamChange, previous: prevTeam });
            }
            if (props.onUserChange && userChange !== undefined) {
                props.onUserChange({ value: userChange, previous: prevUser });
            }

            setPreValue(value);
        }
    });

    if (data === null) return <AccentSpinner />;


    const className = accentUtils.isEmpty(value) || value.length == 0 ? "accentInvalidCtrl" : undefined;


    const filteredData = accentUtils.isEmpty(filter) ? data : filterBy(data.slice(), filter);

    return <Row className="acc-team-rep-ctl" >
        <Col xs={12}>
            <Row className="accentFormPreCtrl flex-nowrap row">

                <div className='acc-ctrl-flex'>
                    <div className='acc-pre-ctrl' />
                    <MultiSelect
                        className={className}
                        style={{ width: "100%" }}
                        disabled={props.readOnly}
                        data={filteredData}
                        filterable={true}
                        onFilterChange={onFilterChange}
                        label={t("application_strings.views.calendar.ownerTeam")}
                        onChange={handleChange}
                        value={value}
                        textField="text"
                        dataItemKey="value"
                        itemRender={userTeamItemRender}
                        allowCustom={false}
                    />
                    <div className='acc-post-ctrl' />
                </div>

            </Row>
        </Col>
    </Row>;
        
}