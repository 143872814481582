import pako from 'pako';

import { queryNoTracking } from './DataService';
import { accentUtils, from } from './HelperService'
import { getUser } from './UserService';


const query_ProductList = queryNoTracking("ProductList");
const query_Product_BUExclusions = queryNoTracking("Product_BUExclusions");


let products =  [];
window.productList = products;
let productBUExclusions = [];


export function configFrom(collection) {
    return window.System.Linq.Enumerable.from(collection);
}


export async function refreshProductExclusionList() {
    productBUExclusions = await query_Product_BUExclusions.getAll();
}


function getProductCacheKey(user) {
    return `productList_${user.ClientID}`;
}

export function loadLocallyCachedProducts(user) {

    const localStoredProducts = localStorage.getItem(getProductCacheKey(user));


    if (accentUtils.isEmpty(products)) {
        products = accentUtils.isNull(localStoredProducts) ? [] : JSON.parse(localStoredProducts);
        window.productList = products;
    }
    

}

export async function refreshProducts(clearServerCache){
    products = await query_ProductList.getAll({ clearCache: clearServerCache });

    const user = getUser();

    window.productList = products;

    window.setTimeout(() => {
        localStorage.setItem(getProductCacheKey(user), JSON.stringify(products));
    }, 200);

    return products;
}

export function getProduct(id) {
    return from(getProducts()).firstOrDefault(p => p.ID.toUpperCase() === id.toUpperCase());
}

export function getProducts() {
    return [...products];
}


export const ConfigNotifications = {
    SizeChanging: window.InsyteConfig.InsyteConfigEvents.SizeChanging,
    SizeChangeOccurred: window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred
};


export async function getProductConfig(lineEntity, olos, isReadOnly, isQuote, onSave, onChanged, ignoreSizes, companyInfo) {

    const res = await loadProductConfig(lineEntity, olos, isReadOnly, isQuote, onSave, onChanged, ignoreSizes, companyInfo);

    return res;

}

function loadProductConfig(lineEntity, olos, isReadOnly, isQuote, onSave, onChanged, ignoreSizes, companyInfo) {


    return new Promise(p => {

        window.InsyteHTML.Apps.Configurator.Configurator_App.GetConfigModel(
            loadProductModel,
            res => {
                p(res);
            },
            lineEntity,
            olos,
            isReadOnly,
            isQuote,
            onSave,
            onChanged,
            ignoreSizes,
            companyInfo

        )

    });


}


export function reloadConfigurator(id, lineEntity, olos, isReadOnly, isQuote, onSave, onChanged, onLoaded, ignoreSizes, companyInfo) {

    window.InsyteHTML.Apps.Configurator.Configurator_App.ReloadConfigTRL(
        lineEntity,
        olos,
        isReadOnly,
        isQuote,
        onSave,
        onChanged,
        onLoaded,
        ignoreSizes,
        companyInfo,
        id
    )

}



export function createDiscountNodes(products) {
    return window.InsyteProduct.InsyteWeb.DiscountTreeHelper.CreateDiscountNodes(products);
}



export function InsyteHtml() {
    var tt = 4;
}

const modelCache = {};

export async function loadProductModel(productID) {

    if (!accentUtils.isNull(modelCache[productID])) return modelCache[productID];

    const prodList = (products.length > 0) ? products : await refreshProducts();

    var product = from(prodList).firstOrDefault(p => p.ID.toUpperCase() === productID.toUpperCase());

    if (!accentUtils.isNull(product)) {




        const data = await makeCorsRequest(product.ModelURI, product.ModelIsCompressed);


        const output = product.ModelIsCompressed ? pako.inflate(data) : data;

        const file = new TextDecoder('utf-8').decode(output);
        const model = JSON.parse(file);

        window.InsyteHTML.Apps.Configurator.Configurator_App.Init();

        const dataModel = window.InsyteHTML.Apps.Configurator.RTL.ModelLoader.LoadDataModel(model);

        modelCache[productID] = dataModel;

        return dataModel;

    }

    return null;


}
 
export function getUserProductList(supplierID, productID, all, businessUnitID, allActive) {

    if (accentUtils.isNull(productID)) {
        productID = '00000000-0000-0000-0000-000000000000';
    }

    var exclusions = accentUtils.isEmpty(businessUnitID) ? [] : from(productBUExclusions).where(ex => ex.BusinessUnitID == businessUnitID).toArray();


    var res =
        from(products)
            .select(function (p) {
                return {
                    ID: p.ID,
                    Active: p.Active,
                    Supplier: p.Supplier,
                    SupplierKey: p.SupplierKey,
                    ResellerMarketplaceKeys: p.ResellerMarketplaceKeys,
                    Product: p.ProductName,
                    ProductCode: p.ProductCode,
                    CanSell: p.CanSell,
                    IsMarketplaceProduct: p.IsMarketplaceProduct,
                    ModelURI: p.ModelURI,
                    Version: p.Version
                };
            })
            .where(function (p) { return accentUtils.isEmpty(supplierID) || p.SupplierKey.toUpperCase() == supplierID.toUpperCase(); })
            .where(function (p) { return (p.ID == productID) || (p.Active && p.CanSell && (!from(exclusions).any(ex => p.ID.toUpperCase() == ex.ProductID.toUpperCase()))) || (all) || (allActive && p.Active); })
            .orderBy(function (p) { return p.Product; })
            .thenBy(function (p) { return p.Supplier; })
            .toArray();

    res.unshift({
        ID: '00000000-0000-0000-0000-000000000000',
        Product: "",
    });

    return res;
}




// Create the XHR object.
function createNonCORSRequest(method, url) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "arraybuffer";
    xhr.open(method, url);
    return xhr;
}

// Create the XHR object.
function createCORSRequest(method, url) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "arraybuffer";
    if ("withCredentials" in xhr) {
        // XHR for Chrome/Firefox/Opera/Safari.
        xhr.open(method, url, true);
    } else if (typeof window.XDomainRequest != "undefined") {
        // XDomainRequest for IE.
        xhr = new window.XDomainRequest();
        xhr.open(method, url);
    } else {
        // CORS not supported.
        xhr = null;
    }
    return xhr;
}

function makeCorsRequest(url, useCors) {

    return new Promise(p => {

        var xhr = (useCors) ? createCORSRequest('GET', url) : createNonCORSRequest('GET', url);
        if (useCors && !xhr) {
            alert('CORS not supported');
            p(null);
            return;
        }

        // Response handlers.
        xhr.onload = function () {

            var arrayBuffer = xhr.response;

            // if you want to access the bytes:
            var byteArray = new Uint8Array(arrayBuffer);
            p(byteArray);
        };

        xhr.onerror = function () {
            alert('Woops, there was an error making the request.');
            p(null);
        };

        xhr.send();


    });


    
}

