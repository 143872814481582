import React from 'react'
import { Input } from '@progress/kendo-react-all'
import { accentUtils, t, newGuid } from '../../services/HelperService'
import { AccentTooltip } from '../AccentTooltip'
import AccentIcons from '../../icons/Icons'
import { AccentLabel } from '../AccentLabel'

export class AccentTextUnbound extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();


        this.id = this.props.id;

        if (accentUtils.isNull(this.id)) {
            this.id = 'id_' + newGuid();
        }

        var value = this.props.defaultValue;

        this.state = { value: value, hideText: this.props.password };

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.toggleHide = this.toggleHide.bind(this);
        
    }

    onFocus(e) {

        if (this.props.onFocus) {
            this.props.onFocus(this.state.value);
        }
    }


    onBlur(e) {
        if (!accentUtils.isNull(this.props.onBlur)) {
            this.props.onBlur(this.state.value);
        }
    }

    onChange(e) {

        if (!accentUtils.isNull(this.props.onChange)) {
            this.props.onChange(accentUtils.isNull(e.target.value) ? null : e.target.value);
        }

        this.setState({ value: e.target.value })
    }

    toggleHide() {
        this.setState({hideText : !this.state.hideText});
    }

    render() {

        const formatValidationError = accentUtils.isNull(this.props.onValidate) ? null : this.props.onValidate(this.state.value);
        const isFormatValid = accentUtils.isEmpty(formatValidationError);


        var isValid = isFormatValid && ( !this.props.required || !accentUtils.isEmpty(this.state.value));

        var className = isValid ? "accentTextUnbound" : "accentTextUnbound invalid";


        let control = null;
        let input = null;

        if (this.props.password) {
            input = <Input
                className={className}
                value={this.state.value}
                type={this.state.hideText ? "password" : "text"}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={ this.onBlur}
                disabled={this.props.disabled}
                style={{ width: "100%" }}
            />;
        } else {
            input = <Input
                id={this.id}
                className={className}
                value={this.state.value ?? ""}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                disabled={this.props.disabled}
                valid={isValid}
                style={{ width: "100%" }}
                placeholder={this.props.placeHolder}
                maxLength={this.props.maxLength}
            />;
        }

        const labelCtrl = accentUtils.isEmpty(this.props.label) ? input : <AccentLabel text={t(this.props.label) }>{ input}</AccentLabel>

        if (this.props.password) {
            control = <div className="acc-pass-ctrl" data-tagname={this.props.dataTagName}>
                {labelCtrl}
                <AccentIcons.Eye onClick={this.toggleHide} />
            </div>;
        }
        
        control = <div className="acc-unbound-txt-ctrl" data-tagname={this.props.dataTagName}>
            {!isFormatValid && <AccentTooltip target={this.id} text={formatValidationError} />}
            {labelCtrl}
            </div>;

        return control;
    }

}
