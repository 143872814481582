//insyteData, DataLoader
import React from 'react'
import ReactDOM from 'react-dom'

import { ComboBox } from '@progress/kendo-react-all'
import { accentUtils, t, formHelper , from} from '../../services/HelperService'
import { RTLQueryFetcher } from '../RTLFetch';

export class AccentTextLookup extends React.Component {
    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        this.queryFetcher = new RTLQueryFetcher({
            query: this.props.model.options.source
        })


        var pageSize = 100;
        this.state = {
            data: { data: [], total: 0 },
            dataState: { take: pageSize, skip: 0 },
            selectedID: -1
        };

        this.dataKey = 0;
        
        this.sourceType = this.props.model.options.sourceType;
        this.dataTextField = this.props.model.options.displayField;
        this.dataValueField = this.props.model.options.valueField;

        this.placeholder = this.props.model.options.placeHolder;

        this.onDataRecieved = this.onDataRecieved.bind(this);
        this.onGetDOM = this.onGetDOM.bind(this);
        this.filterChange = this.filterChange.bind(this);

        this.onChange = this.onChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }
    componentDidMount() {
        this.runQuery();
    }


    filterChange(event) {


        this.setState({
            dataState: {
                take: this.state.dataState.take,
                skip: this.state.dataState.skip,
                filter: {
                    logic: 'and',
                    filters: [event.filter]
                }
            }
        }, () => {
            this.runQuery();
        });


    }

    onGetDOM() {
        alert("return ReactDOM.findDOMNode(this.ctrl.current);");
    }

    onDataRecieved(e) {

    }

    runQuery() {

        return new Promise(p => {

            this.queryFetcher.fetchData(this.state.dataState).then(res => {

                this.setState({
                    data: { ...res },
                    loading: false
                }, () => {
                    this.onDataRecieved();
                    p();
                });

            });

            this.setState({ loading: true });


        });


    }

    onChange(e) {


        var value = e.target.value;

        if (typeof value === "string" || accentUtils.isNull(value)) {
            this.props.model.setValue(value);
        } else {
            this.props.model.setValue(value[this.dataTextField]);


        }

        

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange({ value: value});
        }

        this.props.model.modified();


        if (!accentUtils.isNull(this.state.dataState.filter)) {
            // clear filter and cause requiry
            this.setState({
                dataState: {
                    take: this.state.dataState.take,
                    skip: this.state.dataState.skip,
                    filter: null
                }
            }, () => this.runQuery());
        }

    }


    render() {


        var currVal = this.props.model.getValue();


        var data = [...this.state.data.data];



        var currItem = from(data).firstOrDefault(x => x[this.dataTextField] == currVal || x[this.dataValueField] == currVal);


        if (accentUtils.isNull(currItem) && !accentUtils.isEmpty(currVal)) {

            currItem = {};

            currItem[this.dataTextField] = currVal;
            currItem[this.dataValueField] = new Date().getTime()

            data.push(currItem);
        }

        

        var readOnly = formHelper.isControlReadOnly(this.props.model);
        
        return (
            <div>
                <ComboBox                    
                    ref={this.ctrl}
                    data={data}
                    value={currItem}
                    onChange={this.onChange}
                    textField={this.dataTextField}
                    dataItemKey={this.dataValueField}
                    disabled={readOnly}
                    label={this.props.model.displayLabel}
                    filterable={true}
                    allowCustom={true}
                    onFilterChange={this.filterChange}
                />
            </div>
        );
    }
}
