import * as React from 'react'
import { AccentButton } from '../controls/AccentButton';
import { isMobileOnly } from '../controls/AccentDisplay';
import { AccentTokenCtrl } from '../controls/AccentTokenCtrl';
import { AccentTooltip } from '../controls/AccentTooltip';
import { useValidation } from '../controls/bound/AccentFormCtrl';
import { AccentCheckUnbound } from '../controls/unbound/AccentCheckUnbound';
import { AccentComboUnbound } from '../controls/unbound/AccentComboUnbound';
import { AccentTextAreaUnbound } from '../controls/unbound/AccentTextAreaUnbound';
import { accentUtils, t, from, showWait, format } from '../services/HelperService';
import { generateTemplateContent } from '../services/TemplateService';
import { AccentDialog } from './AccentDialog'



export const SmsDlg = props => {

    const [refreshKey, setRefreshKey] = React.useState(1);
    const [to, setTo] = React.useState(props.info?.To?.map(x => ({Mobile: x.Mobile})) ?? []);
    const [toData, setToData] = React.useState(props.info?.To?.map(x => ({ Mobile: x.Mobile })) ?? []);
    const [templateID, setTemplateID] = React.useState(from(props.templates ?? []).firstOrDefault(t => t.ID === props.defaultTemplateID));
    const [body, setBody] = React.useState(null);
    const [deliveryReceipt, setDeliveryReceipt] = React.useState(false);


    const validation = useValidation(".acc-sms-dlg")

    const onToChanged = React.useCallback(e => {

        const newToItems = [];
        const newToData = [...toData];

        e.value.forEach(function (t) {

            var orgItem = from(toData ?? []).firstOrDefault(i => t.value === i.Email);

            if (accentUtils.isNull(orgItem)) {
                orgItem = { Mobile: t.text };
                newToData.push(orgItem);
                setRefreshKey(k => k + 1);
            }

            newToItems.push(orgItem);

        });
        
        setTo(newToItems.slice(0, 1));
        setToData(newToData);



    }, [props, setTo, toData, setToData, setRefreshKey]);

    const displayTemplate = React.useCallback(async templateID => {

        

        setTemplateID(templateID);

        if (accentUtils.isEmpty(templateID)) return;

        const done = showWait();

        var contactID = (props.info?.To?.length ?? 0) > 0 ? props.info.To[0].ID : undefined;

        const res = await generateTemplateContent({
            UseBody: true,
            TemplateID: templateID,
            ActivityID: props.context.ActivityID,
            ContactID: contactID,
            JobID: props.context.JobID,
            OpportunityID: props.context.OpportunityID,
            StatementID: props.context.StatementID,
            SupplyJobID: props.context.SupplyJobID,
            InvoiceID: props.context.InvoiceID,
            WebLeadID: props.context.WebLeadID
        });

        setBody(res[0])
            
        setRefreshKey(k => k + 1);


        done();

    }, [setRefreshKey, setBody, setTemplateID, props]);

    const onTemplateChanged = async e => {

        const newTemplateID = e;

        await displayTemplate(newTemplateID);

    };

    const sendSms = React.useCallback(e => {

        var contactID = (props.info?.To?.length ?? 0) > 0 ? props.info.To[0].ID : undefined;


        props.onClose({
            to: to.map(t => t.Mobile),
            Body: body,
            TemplateID: templateID,
            ActivityID: props.context.ActivityID,
            ContactID: contactID,
            JobID: props.context.JobID,
            OpportunityID: props.context.OpportunityID,
            StatementID: props.context.StatementID,
            SupplyJobID: props.context.SupplyJobID,
            InvoiceID: props.context.InvoiceID,
            DeliveryReceipt: deliveryReceipt
        });

        if (e.promise) e.promise();
        

    }, [props, to, body, deliveryReceipt]);


    const toItems = to.map(function (r) {
        return {
            text: r.Mobile,
            value: r.Mobile,
            error: accentUtils.isEmpty(r.Mobile) ? true : false
        };
    });

    const toDataItems = toData.map(function (r) {
        return {
            text: r.Mobile,
            value: r.Mobile,
            error: accentUtils.isEmpty(r.Mobile) ? true : false
        };
    });



    const allowSend = validation.isValid;

    return <AccentDialog
        className="acc-sms-dlg"
        maxWidth={ 800}
        onClose={props.onClose}
        headerContent={t("application_strings.wizards.sendSms.sms.title")}
        actionLayout="end"
        actionsContent={[<AccentButton key="1" disabled={!allowSend} tagName="sendSMS" onClick={sendSms}>{t("application_strings.application.buttons.send")}</AccentButton>]}
    >
        <div className="d-flex flex-column gap-3">

            {!props.info?.Configured && <div className="alert alert-warning">{t("application_strings.application.general.smsNotConfigured")}</div>}            
            <div>
                <AccentTokenCtrl key={`e_${refreshKey}`} required value={toItems} data={toDataItems} onChange={onToChanged} label="application_strings.application.general.smsTo" />
            </div>
            {(props.info?.Templates?.length ?? 0) > 0 && <div>
                <AccentComboUnbound key={`t_${refreshKey}`} displayField="Description" valueField="ID" defaultValue={templateID} onChange={onTemplateChanged} data={props.info.Templates} label="application_strings.application.general.smsTemplate" />
            </div>}
            <div>
                <AccentTextAreaUnbound key={`b_${refreshKey}`} tagName="smsBody" defaultValue={ body} onChange={setBody} rows={5} required />
                <AccentTooltip delayShow="500" delayHide="0" target="sms_char_count" placement="bottom" text={t("application_strings.application.general.smsLimits")} />
                <div id="sms_char_count" style={{ opacity: 0.5 }}>
                    {`length: ${body?.length ?? 0}/160, message credits ${format.formatInt(accentUtils.roundUp((body?.length ?? 0) / 160, 0), false, 0) }` }
                </div>
            </div>
            <div>
                <AccentCheckUnbound label="Delivery Receipt" defaultValue={deliveryReceipt} onChange={e => setDeliveryReceipt(e.checked)} />
            </div>
        </div>


    </AccentDialog>;

}