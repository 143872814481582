import * as React from 'react'
import classNames from 'classnames'
import { accentUtils, newGuid } from './../services/HelperService';
import { AccentTooltip } from '../controls/AccentTooltip'

class Icon extends React.Component {

    constructor(props) {
        super(props);

        this.id = this.props.id;

        if (accentUtils.isNull(this.id)) {
            this.id = 'id_' + newGuid();
        }

    }

    render() {

        var toolTip = null;


        if (!accentUtils.isNull(this.props.toolTipHtml)) {
            toolTip = <AccentTooltip target={this.id} html={this.props.toolTipHtml} />;
        }
        if (!accentUtils.isNull(this.props.toolTipText)) {
            toolTip = <AccentTooltip target={this.id} text={this.props.toolTipText} />;
        }

        const imgSrc = this.props.img;
        const materialSrc = this.props.matrial;
        const isBtn = !accentUtils.isNull(this.props.onClick);

        const containerClass = classNames(
            "acc-icon-containter",
            {
                "p-2" : isBtn && !this.props.noPadding
            });

        var className = classNames(
            'icon',
            {                
                'material-icons md-18': !accentUtils.isNull(materialSrc) && !this.props.outlined,
                'material-icons-outlined md-18': !accentUtils.isNull(materialSrc) && this.props.outlined,
            });
        

        const style = {
            position: "relative",
            top: !accentUtils.isEmpty(this.props.top) ? `${this.props.top}px` : "unset",
            left: !accentUtils.isEmpty(this.props.left) ? `${this.props.left}px` : "unset",
            cursor: this.props.disabled? "no-drop" :  isBtn ? "pointer" : undefined,
            maxHeight: this.props.maxHeight,
            maxWidth: this.props.maxWidth, 
            opacity: this.props.disabled ? 0.5 : 1,
            ...this.props.style
        };


        return (
            <div className={containerClass} ref={ this.props.iconRef} >
                {toolTip}
                {imgSrc && <img className={className} style={style} id={this.id} onClick={this.props.disabled ? undefined : this.props.onClick} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} src={ imgSrc} />}
                {materialSrc && <i className={className} style={style} id={this.id} onClick={this.props.disabled ? undefined : this.props.onClick} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} >{materialSrc}</i>}                
            </div>
        );
    }

}


const AccentIcons = {
    ArrowDown: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "arrow_downward" }} />),
    ArrowUp: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "arrow_upward" }} />),
    Attach: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "attach_file" }} />),
    BellActive: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "notifications_active" }} />),
    Bell: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "notifications" }} />),
    Bolt: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "bolt" }} />),
    BusinessUnit: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "location_city" }} />),
    Calculate: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "calculate" }} />),
    Calendar: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "date_range" }} />),
    Car: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "directions_car" }} />),
    CheckboxOutline: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "check_box_outline_blank" }} />),
    Checkbox: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "check_box" }} />),
    Unchecked: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "check_box_outline_blank" }} />),
    Checked: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "check_box" }} />),
    Clear: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "clear" }} />),
    Close: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "close" }} />),
    Commissions: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "calculate" }} />),
    Company: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "business" }} />),
    Contact: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "person" }} />),
    Copy: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "content_copy" }} />),
    CreditNote: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "card_giftcard" }} />),
    Curtain: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "curtains" }} />),
    Delete: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "delete_forever" }} />),
    Done: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "done" }} />),
    Down: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "keyboard_arrow_down" }} />),
    Edit: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "edit" }} />),
    Email: React.forwardRef((p, ref) => <Icon {...{ ...p, img: "/images/email-icon.png" }} />),
    Eye: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "remove_red_eye" }} />),
    Error: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "error" }} />),
    ExpandContent: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "expand_content" }} />),
    Filter: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "filter_list" }} />),
    Health: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "local_hospital" }} />),
    History: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "history" }} />),
    Home: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "home" }} />),
    Info: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "info" }} />),
    Invoice: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "receipt" }} />),
    Lead: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "person" }} />),
    Link: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "link" }} />),
    Location: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "location_on" }} />),
    Map: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "map" }} />),
    Message: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "message" }} />),
    More: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: p.vertical ? "more_vert" : "more_horiz" }} />),
    New: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "add" }} />),
    BackAll: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "first_page" }} />),
    Back: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "arrow_back_ios" }} />),
    Forward: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "arrow_forward_ios" }} />),
    FastForward: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "fast_forward" }} />),
    Toggle: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "list" }} />),
    LockClosed: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "lock" }} />),
    LockOpened: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "lock_open" }} />),
    Order: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "library_books" }} />),
    Payment: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "payment" }} />),
    Phone: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "phone" }} />),
    Plus: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "add" }} />),
    Print: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "print" }} />),
    Quote: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "shopping_cart" }} />),
    Refresh: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "refresh" }} />),
    Remove: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "remove" }} />),
    Save: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "save" }} />),
    Search: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "search" }} />),
    SMS: React.forwardRef((p, ref) => <Icon {...{ ...p, img: "/images/sms-icon.png" }} />),
    Spanner: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "build" }} />),
    Sort: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "sort" }} />),
    Split: React.forwardRef((p, ref) => <Icon {...{ ...p, img: "/images/split_icon.png" }} />),
    Support: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "help_outline" }} />),
    Swap: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "swap_horiz" }} />),
    Text: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: p.text }} />),
    Task: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "assignment" }} />),
    ThumbsDown: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "thumb_down" }} />),
    Tracking: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "list_alt" }} />),
    Up: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "keyboard_arrow_up" }} />),
    Visibility: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "visibility" }} />),
    VisibilityOff: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "visibility_off" }} />),
    Warning: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "warning" }} />),
    WarningChanged: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "sync_problem" }} />),
    WhatsNew: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "info" }} />),
    Work: React.forwardRef((p, ref) => <Icon {...{ ...p, iconRef: ref, matrial: "work" }} />)
}


export default AccentIcons;