import React from 'react'
import { Editor, ProseMirror, EditorTools } from '@progress/kendo-react-editor'

import { accentUtils, t, formHelper } from '../../services/HelperService'

const {
    Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
    AlignLeft, AlignCenter, AlignRight, AlignJustify,
    Indent, Outdent, OrderedList, UnorderedList,
    Undo, Redo, FontSize, FontName, FormatBlock,
    Link, Unlink, InsertImage, ViewHtml,
    InsertTable,
    AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
    DeleteRow, DeleteColumn, DeleteTable,
    MergeCells, SplitCell
} = EditorTools;

export class AccentHTML extends React.Component {

    constructor(props) {
        super(props);


        this.ctrl = React.createRef();
        this.currentUserValue = this.props.model.getValue();

        this.onEditorBlur = this.onEditorBlur.bind(this);
        this.onMount = this.onMount.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeydown = this.onKeydown.bind(this);
        this.insertText = this.insertText.bind(this);
        
    }


    onEditorBlur(_view, _domEvent){
        var me = this;    

        if (!accentUtils.isNull(me.props.model.options.onFocusLost)) {                
                me.props.model.options.onFocusLost({ selectionStart: _view.state.selection.from});
        }

        var newValue = this.ctrl.current.view.dom.innerHTML;

        this.currentUserValue = newValue;


        if (accentUtils.isNullOrEmpty(this.ctrl.current.view.dom.textContent)) {
            this.props.model.setValue(null);
        } else {
            this.props.model.setValue(newValue);
        }

        
        this.props.model.modified();


    }

    onMount(event){
        var me = this;
        event.dom.oncontextmenu = me.props.model.options.onContextMenu;
        return new ProseMirror.EditorView(
          { mount: event.dom }, {
            ...event.viewProps,

            handleDOMEvents: {
              ...(event.viewProps.handleDOMEvents || {}),
                'blur': me.onEditorBlur
            },
                handleKeyDown: me.onKeydown
          }
        );
    }

    insertText(pos, value) {

        var newState = this.ctrl.current.view.state.apply(this.ctrl.current.view.state.tr.insertText(value, pos));
        this.ctrl.current.view.updateState(newState);

        var newValue = this.ctrl.current.view.dom.innerHTML;

        this.props.model.setValue(newValue);
        this.props.model.modified();

        
        this.forceUpdate();

    }




    onChange(e) {
        this.props.model.modified();

    }


    onKeydown(e){
        this.props.model.modified();
    }

  

    render() {

        var modelValue = this.props.model.getValue();


        if (modelValue !== this.currentUserValue) { // model changed so hack     

            if (accentUtils.isNull(modelValue)) {
                modelValue = "";
            }
            this.currentUserValue = modelValue;
            this.ctrl.current.view.dom.innerHTML = modelValue;
        }

        var tools = undefined;

        const allTools = (this.props.model.options.htmlOption) ? this.props.model.options.htmlOption.allTools : false;
        const minTools = (this.props.model.options.htmlOption) ? this.props.model.options.htmlOption.minTools : false;
        const height = (this.props.model.options.htmlOption) ? this.props.model.options.htmlOption.height : 500;


        if (allTools) {
            tools = [
                [Bold, Italic, Underline, Strikethrough],
                [Subscript, Superscript],
                [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                [Indent, Outdent],
                [OrderedList, UnorderedList],
                FontSize, FontName, FormatBlock,
                [Undo, Redo],
                [Link, Unlink, InsertImage, ViewHtml],
                [InsertTable],
                [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                [DeleteRow, DeleteColumn, DeleteTable],
                [MergeCells, SplitCell]
                ];
        } else {

            if (minTools) {
                tools =
                    [
                        [Bold, Italic, Underline, Strikethrough],
                        [OrderedList, UnorderedList],
                        [Undo]
                    ];

            } else {

                tools =
                    [
                        [Bold, Italic, Underline, Strikethrough],
                        [Subscript, Superscript],
                        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                        [Indent, Outdent],
                        [OrderedList, UnorderedList],
                        FontSize, FontName, FormatBlock,
                        [Undo, Redo],
                        [Link, Unlink]
                    ];

            }
        }

        if (accentUtils.isNull(modelValue)) {
            modelValue = "";
        }

        return (
            <div className="accentHtml">
                <Editor
                    ref={this.ctrl}
                    contentStyle={{ height: height }}
                    defaultContent={modelValue}
                    defaultEditMode="div"
                    change={this.onChange}
                    tools={tools}
                    onMount={this.onMount}
                    onContextMenu={this.props.model.options.onContextMenu}
                />
            </div>
        );
    }

}
