import * as React from 'react';
import classNames from 'classnames'
import AccentIcons from '../../icons/Icons';
import { accentUtils } from '../../services/HelperService';
import { configFrom } from '../../services/ProductService';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';
import { AccentNumberUnbound } from '../unbound/AccentNumberUnbound';
import { AccentTextUnbound } from '../unbound/AccentTextUnBound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { fromInt, toDecimal, toGuid } from './ProductConfiguratorShared';



const ExtraLine = props => {

    const line = props.line;


   

    const indecator = (!accentUtils.isNull(line.OOSDiscontinuedInfo)) ? <AccentIcons.Warning toolTipText={line.OOSDiscontinuedInfo.GetMessage()} /> : null;

    const indicatorClassName = classNames("config-extra-indicator d-flex", {
        "warning": !line.OOSDiscontinuedInfo?.IsDiscontinued ?? false,
        "error": line.OOSDiscontinuedInfo?.IsDiscontinued?? false
    });

    const addReadonly = props.readOnly || !line.AddEnabled;
    const delReadonly = props.readOnly || !line.DelEnabled;

    const addButtonClassName = classNames("config-extra-actions-add", "config-value-action", {
        "readonly": addReadonly
    });
    const delButtonClassName = classNames("config-extra-actions-remove", "config-value-action", {
        "readonly": delReadonly
    });

    return <div className="config-extra-line">
        <div className={indicatorClassName} >
            <div className="flex-fill" ></div>
            <div>{indecator}</div>            
        </div>
        <div className="config-extra-product"><AccentComboUnbound valueField="ID" displayField="Description" openOnFocus data={props.values} onChange={props.onProductChange} defaultValue={ line.Product?.toUpperCase()} placeHolder="Select product" /></div>
        <div className="config-extra-qty"><AccentNumberUnbound onChange={props.onQtyChange} defaultValue={fromInt(line.Qty)} placeHolder="Select qty" /></div>
        <div className="config-extra-unit">{line.Unit}</div>
        <div className="config-extra-notes"><AccentTextUnbound onChange={props.onNotesChange} defaultValue={line.Notes} placeHolder="enter notes..." /></div>
        <div className="config-extra-actions">
            <div className={addButtonClassName} ><AccentIcons.New noPadding onClick={ addReadonly ? undefined : props.onAdd } /></div>
            <div className={delButtonClassName} ><AccentIcons.Delete noPadding onClick={delReadonly ? undefined : props.onDelete} /></div>
        </div>
    </div>
}


export const ConfigExtras = React.memo(props => {

    const ctx = React.useContext(ConfiguratorContext)
    const groupID = props.groupID;
    const [_, setRefreshKey] = React.useState(0);

    

    const values = React.useMemo(() => configFrom(ctx.config.GetActiveProductOptionsForOrder(toGuid(groupID))).toArray().map(v => ({ID: v.ID.toString().toUpperCase(), Description: v.Description })), [groupID]);

    const viewModel = React.useRef(new window.InsyteHTML.Controls.Configurator.ExtrasViewModel(
        () => configFrom(ctx.config.OrderLineOptions).where(olo => olo.ProductOptionGroupID?.toString()?.toUpperCase() === groupID?.toUpperCase()).toArray(),
        () => {
            var newOlo = ctx.config.NewOrderLineOption(true);
            newOlo.ProductOptionGroupID = toGuid(groupID);
            return newOlo;
        },
        olo => ctx.config.OrderLineOptionMarkForDelete(olo),
        e => {
            ctx.config.SetExtraOrderLineOptions(e.OrderLineOptions);
            ctx.config.NotifyChanged();
        },
        olo => ctx.config.GetExtraOptionOutOfStockDiscontinuedInfo(olo.ProductOptionID, olo.SupplierID)
    ));

    const onProductChange = React.useCallback((e, ln) => {

        const clear = accentUtils.isEmpty(e);
        const index = ln.Index;

        let val = e;

        const config = ctx.config;

        let unit = "";
        let unitID = toGuid(accentUtils.getEmptyGuid());

        if (!clear) {

            var po = config.Data.GetProductOption(toGuid(e));

            if (po != null) {

                if (po.LinkedProductID != toGuid(accentUtils.getEmptyGuid())) {
                    var p = config.Data.GetLinkedProduct(po.LinkedProductID);

                    if (p != null) {
                        unit = p.UnitType.Description;
                        unitID = p.UnitType.ID;
                    }
                }

            }
        } else {
            val = "";
        }

        viewModel.current.SetProduct(index, val);
        viewModel.current.SetUnit(index, unit, unitID);

        setRefreshKey(k => k + 1);
        
    }, [ctx, viewModel, setRefreshKey]);

    const onQtyChange = React.useCallback((e, ln) => {
        viewModel.current.SetQty(ln.Index, toDecimal(e));
    }, [ctx, viewModel]);

    const onNotesChange = React.useCallback((e, ln) => {
        viewModel.current.SetNotes(ln.Index, e);
    }, [ctx, viewModel]);
    

    const onAdd = React.useCallback(e => {
        viewModel.current.AddLine();
        setRefreshKey(k => k + 1);
    }, [viewModel, setRefreshKey]);

    const onDelete = React.useCallback(e => {
        viewModel.current.DeleteLine(e);
        setRefreshKey(k => k + 1);

    }, [viewModel, setRefreshKey]);


    const headerRow = <div className="config-extra-header">
        <div className="config-extra-indicator"></div>
        <div className="config-extra-product">Product</div>
        <div className="config-extra-qty">Qty</div>
        <div className="config-extra-unit">Unit</div>
        <div className="config-extra-notes">Notes</div>
    </div>;

    const extras = configFrom(viewModel.current.Lines).toArray().map((r, i) => <ExtraLine
        key={r.LineID}
        line={r}
        readOnly={ctx.isReadOnly}
        values={values}
        onProductChange={e => onProductChange(e, r)}
        onQtyChange={e => onQtyChange(e, r)}
        onNotesChange={e => onNotesChange(e, r)}
        onAdd={onAdd}
        onDelete={e => onDelete(r.Index)} />);


    return <div className="config-extra-container">
        {headerRow}
        <div className="config-extra-lines-container">
            {extras}
        </div>
    </div>;
});