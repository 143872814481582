import React from 'react'
import { Row, Col } from 'reactstrap'
import { ToolbarSeparator, SplitButtonItem, Button, SplitButton, ToolbarItem, ButtonGroup, Toolbar } from '@progress/kendo-react-all'
import { accentUtils, from } from '../services/HelperService'

export class AccentToolbar extends React.Component {
    constructor(props) {
        super(props);        

        this.items = this.props.getToolbarItems();        
        this.onClick = this.onClick.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.isMoreItem = this.isMoreItem.bind(this);

        this.doubleClicks = {};

        this.updateKey = 1;

    }

    onClick(e) {

        var me = this;
        

        if (!accentUtils.isNull(me.doubleClicks[e.id]) && me.doubleClicks[e.id]) {
            return;
        }

        me.doubleClicks[e.id] = true;


        var doubleClickDelay = 700;

        if (!accentUtils.isNull(e.doubleClickDelay)) {
            doubleClickDelay = e.doubleClickDelay;
        }

        setTimeout(function () {
            me.doubleClicks[e.id] = false;
        }, doubleClickDelay);


        me.props.onClick(e);        
    }

    renderItem(itm, singleMore, ix) {
        var me = this;

        if (accentUtils.isNull(itm)){
            return null;
        }

        if (itm.type === "separator" && itm.overflow != "always") {
            return <ToolbarSeparator key={ix} />;
        }



        const iconComponent = itm.iconItem;
        const icon = accentUtils.isNull(iconComponent) ? itm.icon : undefined;


        var moreItem = this.isMoreItem(itm) && !singleMore;

        if (itm.type === "button" || (itm.type === "separator" && itm.overflow == "always")) {

            if (moreItem) {

                const sepRenderer = (itm.type === "separator") ? p => <div className="moreSep" key={`sep_${ix}`} /> : null;

                return <SplitButtonItem key={(itm.type === "separator") ? `sep_${ix}` : itm.id} render={sepRenderer} icon={icon} type={itm.type} text={itm.text} togglable={false} disabled={!itm.enable || itm.type == "separator"} id={itm.id} className={itm.className} />;
            } else {
                return <Button key={itm.id} icon={icon} togglable={false} disabled={!itm.enable} className={itm.className} onClick={function () { me.onClick(itm); }}>{iconComponent}{itm.text}</Button>;
            }
        } else if (itm.type === "splitButton") {

            var sItems = itm.menuButtons.map(function (i) {
                return <SplitButtonItem key={i.id} text={i.text} disabled={!i.enable} id={i.id} className={itm.className} />;
            });

            return <SplitButton key={itm.id} text={itm.text} icon={icon} disabled={!itm.enable} onItemClick={function (e) { me.onClick(e.item); }} className={itm.className}>
                {sItems}
            </SplitButton>;
        }

        return null;
    }


    isMoreItem(itm) {

        if (accentUtils.isNull(itm)) return true;

        if (((this.props.hideAll) && itm.overflow !== "never")) {
            return true;
        }


        if (accentUtils.isEmpty(itm.overflow)) return false;

        if (itm.overflow === "always") return true;


        const isTablet = this.props.display?.tablet ?? false;
        const isLandscape = this.props.display?.landscape ?? false;

        if (isTablet && !isLandscape && itm.overflow === "tablet") return true;

        if (isTablet && isLandscape && itm.overflow === "tablet-landscape") return true;


        return false;
    }

    render() {

        var me = this;

        var model = this.props.getToolbarItems();

        var items = [];
        var more = null;

        

        if (!accentUtils.isNull(model)) {

            var singleMore = (from(model).where(i => me.isMoreItem(i) && i.type !== "separator").count() === 1);

            var modelItems = from(model).where(i => !me.isMoreItem(i) || singleMore).toArray();

            items = modelItems.map((i, ix) => {

                var iRes = this.renderItem(i, singleMore, ix);

                if (i.type === "separator") {
                    return iRes;
                }

                return (<ToolbarItem key={i.id}>
                    {iRes}
                </ToolbarItem>);
            });

            var moreItems = from(model).where(i => me.isMoreItem(i)).toArray();

            if (!singleMore && moreItems.length > 0) {

                more =
                    <ToolbarItem>
                        <ButtonGroup>
                        <SplitButton text="More..." onItemClick={function (e) { me.onClick(e.item); }}>
                            {moreItems.map((i,ix)=> this.renderItem(i, false, ix))}
                        </SplitButton>    
                        </ButtonGroup>
                    </ToolbarItem>;
            }

        }
        
        return (
            <Row className="acc-toolbar">
                <Col md={12} className={this.props.toolBarName}>
                    <Toolbar key={this.updateKey++}>
                        {items}                        
                        {more}
                    </Toolbar>
                </Col>
            </Row>
        );
    }
}