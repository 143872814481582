import React from 'react';
import authService from "./AuthorizeService";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { accentUtils, getLoginParameters } from './../services/HelperService'

const DoLogin = () => {

    const isAuthenticated = useIsAuthenticated();

    const { instance, inProgress, accounts } = useMsal();


    React.useEffect(() => {
        
            instance.handleRedirectPromise().then(r => {

                if (!isAuthenticated && (inProgress == InteractionStatus.None)) {
                    instance.loginRedirect(authService.getLoginRequest());
                } else {

                    if (isAuthenticated) {

                        authService.getAccessToken().then(t => {

                            if (accentUtils.isEmpty(t)) {
                                instance.loginRedirect(authService.getLoginRequest());
                            }

                        }).catch(async (e) => {

                        });

                    }
                }
            });
        

    });

    


    return null;

}


export default DoLogin;