import React from 'react'
import { debounce } from 'perfect-debounce'
import { NumericTextBox } from '@progress/kendo-react-all'
import { accentUtils, t } from '../../services/HelperService'
import { AccentLabel } from '../AccentLabel';

export class AccentNumberUnbound extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        this.refreshKey = 0;
        const value = this.props.defaultValue;

        this.state = { value: value };


        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);

        this.notifyChange = debounce(action => action(), props.debounceMilliseconds ?? 500);

    }

    onFocus(e) {

        if (this.props.onFocus) {
            this.props.onFocus(this.state.value);
        }
    }

    onBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }


    onChange(e) {


        var newVal = accentUtils.isNull(e.target.value) ? null : e.target.value;



        if (!accentUtils.isNull(this.props.min)) {
            if (newVal < this.props.min) {
                newVal = this.props.min;
                this.refreshKey++;
            }
        }

        if (!accentUtils.isNull(this.props.max)) {
            if (newVal > this.props.max) {
                newVal = this.props.max;
                this.refreshKey++;
            }
        }

        this.setState({ value: newVal });

        if (!accentUtils.isNull(this.props.onChange)) {        
            this.notifyChange(() => this.props.onChange(newVal));
        }


    }


    render() {


        const isNotValid = this.props.required && (accentUtils.isNull(this.state.value) || !!this.props.invalid);

        var className = isNotValid ? "accentNumberUnbound invalid" : "accentNumberUnbound";


        const control = <NumericTextBox key={this.refreshKey}
            className={`${className} ${this.props.className}`}
            value={this.state.value}
            onChange={this.onChange}
            onFocus={ this.onFocus }
            onBlur={this.onBlur }
            disabled={this.props.disabled}
            min={this.props.min}
            max={this.props.max}
            style={{ width: "100%" }}
            format={this.props.isInt ? "n0" : this.props.format}
            placeholder={ this.props.placeHolder}
        />;


        if (accentUtils.isNullOrEmpty(this.props.label)) {
            return <div data-tagname={this.props.dataTagName}>{ control}</div>;
        }

        return <AccentLabel text={this.props.label }>
            <div data-tagname={this.props.dataTagName}>{control}</div>
        </AccentLabel>

    }
}
