import * as React from 'react'
import { useNavigate} from 'react-router-dom';
import { atom, useRecoilState} from "recoil";
import { useAccentDisplay } from '../controls/AccentDisplay';
import { clearEntities, saveEntities } from '../services/DataService';
import { accentUtils, compareObjects, formHelper, from, showOK } from '../services/HelperService';
import { ViewWithToolbars } from './ViewToolbar';



export function getDefaultViewState(isReadOnly, isNew) {
    return {
        isReadOnly: isReadOnly??false,
        isDirty: isNew??false,
        isNew: isNew??false,
        isValid: true
    };
}

export const activeViewState = atom({
    key: `activeViewState`,
    default: null
});


export const validationResults = {
    default: "Default",
    save: "Save",
    cancel: "Cancel",
    discard: "Discard"    
}



export function useViewState() {

    const [viewState, setViewState] = useRecoilState(activeViewState);



    const update = React.useCallback(e => {

        const newState = { ...viewState, ...e };

        if (compareObjects(viewState, newState)) return;

        setViewState(newState);

    }, [viewState, setViewState]);


    return {
        viewState: viewState,
        update: update
    };
}


export const withViewState = (Component) => React.memo(React.forwardRef((props, ref) => {

    const info = useViewState();

    return <Component {...props} ref={ref} viewState={info.viewState} setViewState={info.update}/>;

}));

function getDefaultReadOnly(props) {
    return accentUtils.isNull(props.view.defaultReadOnly) ? false : props.view.defaultReadOnly(props.viewData)
}

function defaultAutoSaveFunction() {
    return Promise.resolve({ allow: true});
}

window.accentAutoSave = defaultAutoSaveFunction;

export const withToolbar = (Component) => React.memo((props) => {

    const [viewComponentRefreshKey, setViewComponentRefreshKey] = React.useState(1);
    const toolbarView = React.useRef();
    const viewRef = React.useRef();
    const [viewState, setViewState] = useRecoilState(activeViewState);    
    const display = useAccentDisplay();
    const navigate = useNavigate();
    const initialStateReady = React.useRef(false);
    
    const refreshToolbar = React.useCallback(() => {

        if (toolbarView.current) {
            toolbarView.current.refreshToolbar();
        }

    }, [toolbarView])

    const beforeValidateView = () => {
        if (!accentUtils.isNull(viewRef.current) && !accentUtils.isNull(viewRef.current.beforeValidate))
            return viewRef.current.beforeValidate();
        return Promise.resolve();
    };

    const beforeSave = () => {
        if (!accentUtils.isNull(viewRef.current) && !accentUtils.isNull(viewRef.current.beforeSave)) {
            return viewRef.current.beforeSave();
        }

        return Promise.resolve();
    };

    const afterSave = () => {

        if (!accentUtils.isNull(viewRef.current) && !accentUtils.isNull(viewRef.current.afterSave))
            return viewRef.current.afterSave();
        else {
            return Promise.resolve();
        }
    };

    const setAsDiscarded = (e) => {
        clearEntities();

        if (e.sameView) {
            props.refresh();
        }
    };

    const onRefresh = React.useCallback(e => {

        if (props.refresh) {
            props.refresh(e);
        }

    },[props]);


    

    const saveData = () => {

        return new Promise((resolve, reject) => {

            saveEntities().then(function () {
                resolve();
            }).catch(function (err) {

                var msg = "";

                if (accentUtils.isNull(err)) {
                    msg = "Unknow error occured saving data. please try again";
                } else {

                    if (err.entityErrors) {
                        if (err.entityErrors.length > 0) {
                            msg = err.entityErrors[0].errorMessage;
                        }
                    } else {
                        if (err.message)
                            msg = err.message;
                        else
                            msg = err;
                    }
                }

                showOK("application_strings.application.dialogs.viewValidationTitle", msg, ["application_strings.application.buttons.close"]);
                reject(err);
            });
        });
    }

    const validateView = () => {
        return new Promise((resolve) => {

            beforeValidateView().then(function (result) {

                if (!formHelper.isViewValid() || !accentUtils.isEmpty(result)) {
                    if (result === validationResults.cancel || result === validationResults.discard || result === validationResults.save) {
                        resolve(result);
                    } else {


                        showOK("application_strings.application.dialogs.viewValidationTitle", "application_strings.application.dialogs.viewValidationMsg", ["application_strings.application.buttons.discardChanges", "application_strings.application.buttons.editChanges"], false, null, true, 800).then(function (res) {
                            if (res === "application_strings.application.buttons.discardChanges") {
                                resolve(validationResults.discard);
                            } else {
                                formHelper.showInvalidFields();
                                resolve(validationResults.cancel);
                            }
                        });
                    }
                } else {
                    resolve(validationResults.save);
                }

            });


        });
    };




    const saveViewInternal = React.useCallback((e) => {

        const wasNew = viewState.isNew;

        return new Promise((resolve, reject) => {

            validateView().then(function (res) {

                if (res === validationResults.cancel) {
                    resolve({ saved: false });

                } else if (res === validationResults.save) {
                    beforeSave().then(function (beforeSaveArgs) {
                        saveData().then(function () {


                            let redirect = null;
                            let defaultPrompt = null;

                            if (wasNew) {

                                const entityID = accentUtils.isNull(beforeSaveArgs?.redirectID) ? props.viewData?.ID : beforeSaveArgs.redirectID;

                                if (!accentUtils.isNull(entityID)) {
                                    redirect = props.path.replace(":id", entityID);                                    
                                }


                                defaultPrompt = (!accentUtils.isNull(props.view?.prompt)) ? props.view.prompt(props.viewData) : null;

                                if (beforeSaveArgs?.processRedirect) {
                                    redirect = beforeSaveArgs.processRedirect({
                                        path: props.path,
                                        entityID: entityID,
                                        redirect: redirect,
                                        defaultPrompt: defaultPrompt
                                    });
                                }

                            }

                            setViewState(getDefaultViewState(getDefaultReadOnly(props), false));

                            afterSave().then(() => {
                                resolve({ saved: true, redirect: redirect, from: `${window.location.pathname}${window.location.search}`, defaultPrompt: defaultPrompt });
                            });

                        }).catch(function (e) {
                            console.error(e);
                            reject({ saved: false });
                        });
                    }).catch(function (e) {
                        console.error(e);
                        reject({ saved: false });
                    });

                } else {
                    
                    resolve({ saved: false, discarded: true });
                    setAsDiscarded({ sameView : !e.navigating});
                }

            });
        });

    }, [setViewState, viewState, props._defaultReadOnly]);

    const saveView = React.useCallback((e) => {

        return new Promise(p => {

            saveViewInternal(e).then(res => {

                if (!accentUtils.isEmpty(res.redirect)) {

                    window.onOneTimeAccentRouted[res.redirect] = () => {
                        p(res);
                    };
                    navigate(res.redirect, { replace: true, state: { defaultPrompt : res.defaultPrompt } });

                } else {

                    if (res.saved) {

                        if (!props.view.preventRefreshAfterSave) {
                            if (e?.refreshOnSaved) {
                                setViewComponentRefreshKey(x => x + 1);
                            }
                        }
                    }
                    p(res);
                }
                
                

                

            }).catch(res => {
                p(res);
            });
        });


    }, [saveViewInternal, setViewComponentRefreshKey, props]);

    const autoSave = React.useCallback((e) => {

        const requireSave = viewState.isDirty;

        return new Promise(p => {

            if (!requireSave) {
                p({ allow: true });
                return;
            }

            const isNew = props.params.id === "NEW";

            saveView({ navigating: true, navigatingBack: e?.navigatingBack }).then(r => {
                p({
                    allow: r.saved || r.discarded,
                    replace: r.discarded && isNew,
                    isNew: isNew
                });
            });


        });


    }, [saveView, viewState]);

    const update = React.useCallback((e, force) => {

        const hasChanged = from(Object.keys(e)).any(k => e[k] !== viewState[k]);

        if (!hasChanged && !force) return;

        setViewState(s => { return { ...s, ...e }; });

    }, [viewState, setViewState]);


    React.useEffect(() => {

        if (accentUtils.isNull(viewState)) return;

        if (viewState.isValid != formHelper.isViewValid()) {
            setViewState(v => { return { ...v, isValid: formHelper.isViewValid() } });
        }

    },[viewState, navigate]);

    React.useEffect(() => {

        const isNew = props.params.id === "NEW";

        if (isNew) {
            setViewState(s => { return { ...s, isNew: true, isDirty: true }; });
        }

    },[]);

    React.useEffect(() => {

        console.log("WITH TOOLBAR MOUNT");

        const isNew = props.params.id === "NEW";

        setViewState(getDefaultViewState(getDefaultReadOnly(props), isNew));

        initialStateReady.current = true;

        return () => {
            window.accentAutoSave = defaultAutoSaveFunction;

            setViewState(null);
            console.log("WITH TOOLBAR UNMOUNT");
        };

    }, []); 



    window.accentAutoSave = autoSave;

    const isViewStateReady = initialStateReady.current && !accentUtils.isNull(viewState) && (props.params.id === "NEW" && viewState.isNew || props.params.id !== "NEW");

    
    

    return isViewStateReady ? <ViewWithToolbars
        ref={ toolbarView }
        refresh={props.refresh}        
        navigate={navigate}
        display={display}
        viewState={viewState}
        saveView={saveView}
        view={<Component key={viewComponentRefreshKey} ref={viewRef} {...props} refresh={onRefresh} viewState={viewState} setViewState={update} saveView={saveView} refreshToolbar={refreshToolbar} />}
        viewRef={viewRef }
    />: null;      

});



