import * as React from 'react'
import { Col, Row, ListGroupItem, ListGroup } from 'reactstrap'
import { useURL } from '../../services/DataService';
import { accentUtils, from } from '../../services/HelperService';
import { AccentSpinner, ProgressBar } from '../AccentSpinner';
import { AccentPdfViewer } from './../AccentPdfViewer'

const documentDisplayTypes = {
    PDF: "PDF",
    Image: "Image",
    Link: "Link"
};


export class RTLDocumentGroupPreview extends React.Component {

    constructor(props) {
        super(props);

        this.selectedDocumentID = from(this.props.documents).select(d => d.ID).firstOrDefault();

        this.onReady = this.onReady.bind(this);
    }

    onReady() {
        if (this.props.onReady) {
            this.props.onReady();
        }
    }

    render() {

        var me = this;
        var selectedDocument = this.props.documents.length > 0 ? this.props.documents[0] : null;
        var documentListBar = null;

        var mainCol = 12;

        if (this.props.documents.length > 1) {

            mainCol = 10;

            var documentList = this.props.documents.map(function (d) {

                var active = d.ID === me.selectedDocumentID;

                if (active) {
                    selectedDocument = d;
                }

                return (
                    <ListGroupItem className="acc-list-group-wrap" key={d.ID} active={active} tag="button"  onClick={function () { me.selectedDocumentID = d.ID; me.forceUpdate(); }}>
                        {d.Name}
                    </ListGroupItem>
                );
            });

            documentListBar = <Col md={12 - mainCol}>
                <ListGroup flush style={{ overflowX: "hidden"}}>
                    {documentList}
                </ListGroup>
            </Col>

        }


        return <Row>
            {documentListBar}
            <Col md={mainCol}>
                {!accentUtils.isNull(selectedDocument) && <RTLDocumentPreview elementID={this.props.elementID} document={selectedDocument} maxHeight={this.props.maxHeight} onReady={ this.onReady } />}
            </Col>
        </Row>;

    }
}


export const RTLDocumentPreview = React.memo(props => {


    const [headerReady, setHeaderReady] = React.useState(accentUtils.isNull(props.document?.HeaderItem));
    const [refreshKey, setRefreshKey] = React.useState(1);
    
    let document = null;


    

    let url = props.document.Url;
    if (!accentUtils.isNull(props.document.Params)) {
        const p = JSON.stringify(props.document.Params);
        url = props.document.Url + "?parameters={0}".format(p);
    }



    const finalUrl = useURL(url, headerReady);


    const height = props.maxHeight;

    if (finalUrl.loading) {
        document = <ProgressBar />;
    } else if (headerReady || accentUtils.isNull(props.document.HeaderItem)) {

        if (props.document.DisplayType === documentDisplayTypes.Image) {
            document = <img src={finalUrl.url} />;
        } else if (props.document.DisplayType === documentDisplayTypes.PDF) {
            document = (<div id={props.elementID}><AccentPdfViewer document={finalUrl.url} height={height} /></div>);
        } else {
            document = <a href={finalUrl.url}>CLICK HERE TO DOWNLOAD</a>
        }
    }

    var documentHeader = null;

    if (!accentUtils.isNull(props.document.HeaderItem)) {
        documentHeader =
            React.cloneElement(
                props.document.HeaderItem,
                {
                    document: props.document, onReady: () => {
                        setHeaderReady(true);
                    }, refresh: function () { setRefreshKey(k => k + 1); }
                },
                null
            );
    }



    React.useEffect(() => {

        if (headerReady || accentUtils.isNull(props.document.HeaderItem)) {
            if (props.onReady) {
                props.onReady();
            }
        }

    }, [headerReady, props.onReady]);


    return <div className="rtl-doc-preview">
        {documentHeader}
        {document}
    </div>;



});


