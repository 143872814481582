import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { isMobileOnly, usePosition} from './AccentDisplay';
import { ProgressBar } from './AccentSpinner';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

let currentZoom = 1500;

export const AccentPdfViewer = ({ document, height }) => {
    const [numPages, setNumPages] = useState(null);
    const [width, setWidth] = useState(isMobileOnly ? window.innerWidth - 50 : currentZoom); 

    const pos = usePosition("acc-pdf-viewer");

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleWidthChange = (event) => {
        const newWidth = parseInt(event.target.value);
        setWidth(newWidth);
        currentZoom = newWidth;
    };


    React.useEffect(() => {
        setNumPages(null);
    }, [document, setNumPages]);


    return (
        <div id="acc-pdf-viewer"
            style={{
                minHeight: `${height}px`
            }}
        >
            {!pos.positionKnown && <ProgressBar />}
            {pos.positionKnown && <div
                style={{
                    width: "100%"
                }}
            >
                
                {!isMobileOnly && <div style={{ margin: '10px 0', width: "100%" }} >
                    <input
                        style={{ width: "100%" }}
                        type="range"
                        min="500"
                        max={pos.toScreenWidth - 50}
                        value={width}
                        onChange={handleWidthChange}
                        placeholder="Zoom"
                    />
                </div>}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Document
                        key={ document}
                        file={document}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<ProgressBar />}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <div style={{ border: '1px solid black', margin: '10px' }} key={`page_container_${index + 1}`}>
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} renderTextLayer={false} />
                        </div>
                    ))}
                </Document>
                </div>
            </div>
            }
        </div>
    );
};


